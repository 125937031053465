import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import React, {forwardRef, memo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {Patient} from '../../interfaces/entities/patient.interface';
import {MultiLinesField} from './shared/multi-lines-field.component';

type PatientRelax = Pick<Patient, 'relax'>;

interface PatientRelaxFormProps extends PatientRelax {
  onChange: (data: PatientRelax) => void;
}

const MIN_ROWS = 5;

function PatientRelaxFormBase({onChange, ...props}: PatientRelaxFormProps, ref: React.Ref<FormikProps<Patient>>) {
  const {t} = useTranslation();

  return (
    <Formik<PatientRelax>
      initialValues={props || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientRelax>>}
      validationSchema={Yup.object<Record<keyof PatientRelax, Yup.AnySchema>>().shape({
        relax: Yup.string().nullable().optional(),
      })}
    >
      {() => (
        <Form>
          <Field name="relax">
            {(props: FieldProps) => {
              return (
                <MultiLinesField
                  {...props}
                  label={t('patient-edit-relax.label')}
                  assistiveText={t('patient-edit-relax.assistive')}
                  assistiveTextPosition={'top'}
                  minRows={MIN_ROWS}
                />
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientRelaxForm = memo(forwardRef(PatientRelaxFormBase));
