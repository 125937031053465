import {AxiosInstance} from 'axios';

import {Photo} from '../interfaces/entities/photo.interface';
import {UploadsService} from '../services/uploads.service';
import {PatientBaseStore} from './shared/patient-base.store';

export class PhotosStore extends PatientBaseStore<Photo> {
  /**
   *
   * @param api AxiosInstance
   * @param entitiesName string. Plural name of entity, it is using for REST API url
   */
  constructor(
    api: AxiosInstance,
    private readonly uploadsService = new UploadsService(api)
  ) {
    super(api, 'photos');
  }
}
