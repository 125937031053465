import Stack from '@mui/material/Stack';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import Locale from 'date-fns/locale';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-US';
import nbLocale from 'date-fns/locale/nb';
import ptLocale from 'date-fns/locale/pt';
import svLocale from 'date-fns/locale/sv';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorWrapperComponent} from '../../components/error-wrapper.component';
import {LOCALES} from '../../utils/constants';
import {FieldProps} from '../interfaces/field-props.interface';
import {FieldWrapperComponent} from './field-wrapper.component';

export const LOCALES_MAPPER: Record<LOCALES, Locale> = {
  [LOCALES.en]: enLocale,
  [LOCALES.nb]: nbLocale,
  [LOCALES.pt]: ptLocale,
  [LOCALES.sv]: svLocale,
  [LOCALES.da]: daLocale,
};

function CustomTextField({error, ...props}: TextFieldProps) {
  return (
    <ErrorWrapperComponent error={error}>
      <TextField {...props} fullWidth error={error} />
    </ErrorWrapperComponent>
  );
}

interface DateFieldProps extends Omit<FieldProps, 'label'> {
  onChange: (date: Date | null) => void;
  value: Date | string | null;
  label: string;
}

function DateField(props: DateFieldProps) {
  const {label, onChange, value, touched, error} = props;

  const {i18n} = useTranslation();

  const locale = LOCALES_MAPPER[i18n.language as LOCALES] || LOCALES_MAPPER.nb;
  return (
    <FieldWrapperComponent {...props}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <Stack spacing={3}>
          <DatePicker
            disableFuture
            label={label}
            format={'dd/MM/yyyy'}
            onChange={onChange}
            value={value ? new Date(value) : null}
            slots={{
              textField: CustomTextField,
            }}
            slotProps={{
              textField: {
                error: touched && !!error,
                sx: {width: '100%'},
              },
              popper: {
                sx: theme => ({
                  zIndex: theme.zIndex.tooltip + 1,
                }),
              },
            }}
          />
        </Stack>
      </LocalizationProvider>
    </FieldWrapperComponent>
  );
}

export const DateFieldComponent = memo(DateField);
