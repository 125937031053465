import {AdminCommonGuestbookPostContainer} from '../containers/guestbook/admin-common-guestbook-post.container';
import RouteItem from '../interfaces/route-item.interface';
import {AcceptInvitationPage} from '../pages/accept-invitation.page';
import {AuthPage} from '../pages/auth.page';
import {CommonGuestbookPostPage} from '../pages/common-guestbook-post.page';
import {CreatePatientPage} from '../pages/create-patient.page';
import {MyPatientsPage} from '../pages/my-patients.page';
import {ProfilePage} from '../pages/profile.page';
import navigationItems, {PATIENT_NAVIGATION_ITEM_PERSONAL} from './patient-navigation';

export const MY_PATIENTS_ROUTE: RouteItem = {
  key: 'router-patients',
  title: 'minmemoria-nav-profiles-list.button',
  tooltip: 'minmemoria-nav-profiles-list.button',
  path: '/profiles',
  component: MyPatientsPage,
  exact: true,
};

export const PERSONA_ROUTE: RouteItem = {
  key: 'router-profile',
  title: navigationItems.map(item => item.title),
  tooltip: PATIENT_NAVIGATION_ITEM_PERSONAL.label,
  path: `${MY_PATIENTS_ROUTE.path}/:id`,
  component: ProfilePage,
};

export const AUTH_ROUTE: RouteItem = {
  key: 'auth-login',
  title: 'login',
  tooltip: 'login',
  path: '/auth/callback',
  component: AuthPage,
};

export const ACCEPT_INVITATION_ROUTE: RouteItem = {
  key: 'accept-invitation',
  title: 'Invitation',
  tooltip: 'invitation',
  path: '/invitations/:invitationKeysId/accept',
  component: AcceptInvitationPage,
};

export const COMMON_GUESTBOOK_POST_ROUTE: RouteItem = {
  key: 'router-common-guestbook-post',
  title: 'minmemoria-nav-profiles-list.button',
  tooltip: 'minmemoria-nav-profiles-list.button',
  path: '/common-guestbook-post',
  component: CommonGuestbookPostPage,
  exact: true,
};

export const ADMIN_COMMON_GUESTBOOK_POST_ROUTE: RouteItem = {
  key: 'router-common-guestbook-post',
  title: 'minmemoria-nav-profiles-list.button',
  tooltip: 'minmemoria-nav-profiles-list.button',
  path: '/admin/common-guestbook-post',
  component: AdminCommonGuestbookPostContainer,
  exact: true,
};

export const CREATE_PATIENT_ROUTE: RouteItem = {
  key: 'router-patients',
  title: 'minmemoria-nav-create-profile.button',
  tooltip: 'minmemoria-nav-create-profile.button',
  path: '/profiles/new',
  component: CreatePatientPage,
  exact: true,
};

export const routes: ReadonlyArray<RouteItem> = [
  MY_PATIENTS_ROUTE,
  CREATE_PATIENT_ROUTE,
  PERSONA_ROUTE,
  AUTH_ROUTE,
  ACCEPT_INVITATION_ROUTE,
  COMMON_GUESTBOOK_POST_ROUTE,
  ADMIN_COMMON_GUESTBOOK_POST_ROUTE,
];
