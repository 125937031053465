import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {AlbumsOneComponent} from '../../components/albums/albums-one.component';
import {EmptyScreenComponent} from '../../components/empty-screen.component';
import {useFavoriteFiles} from '../../hooks/use-favorite-files';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';
import {FAVORITES_FOLDER_ID, FAVORITES_FOLDER_NAME} from '../../utils/constants';

function FavoritesAlbumContainerBase({profileId}: ProfilePageProps) {
  const {t} = useTranslation();

  const {items, loading, loadMore, onClickFavorite} = useFavoriteFiles(profileId);

  return (
    <AlbumsOneComponent
      profileId={profileId}
      id={FAVORITES_FOLDER_ID}
      name={FAVORITES_FOLDER_NAME}
      showDeleteButton={false}
      showEditButton={false}
      description={t('favorite-album-description.body')}
      loading={false}
      files={items}
      loadingFiles={loading}
      hasMoreFiles={false}
      loadMoreFiles={loadMore}
      onMarkFavorite={onClickFavorite}
      EmptyScreen={
        <EmptyScreenComponent
          title={t('photos-list-empty.header')}
          description={t('photos-list-empty.body')}
          text={`${t('photos-list-empty-add-photos.button')}`}
          hasContent={false}
        />
      }
    />
  );
}

export const FavoritesAlbumContainer = observer(FavoritesAlbumContainerBase);
