import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';

import {AvatarComponent} from '../../avatar.component';

function PatientInfoLoading(): ReactElement {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0.5}>
      <Grid item>
        <Skeleton variant="circular">
          <AvatarComponent sx={{height: '98px', width: '98px'}} />
        </Skeleton>
      </Grid>
      <Grid item>
        <Typography variant={'caption'}>
          <Skeleton width={'120px'} height={'20px'} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={'caption'}>
          <Skeleton width={'50px'} height={'20px'} />
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="overline">
          <Skeleton width={'56px'} height={'20px'} />
        </Typography>
      </Grid>
    </Grid>
  );
}

export const PatientInfoLoadingComponent = memo(PatientInfoLoading);
