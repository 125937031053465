import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {GuestbookFile} from '../../interfaces/entities/guestbook-file.interface';
import {GuestbookPost} from '../../interfaces/entities/guestbook-post.interface';
import {GuestbookReply} from '../../interfaces/entities/guestbook-reply.interface';
import {GuestbookPreviewFile} from '../../interfaces/guestbook-preview-file.interface';
import {LIGHT_GRAY_COLOR} from '../../theme';
import {CancelButtonComponent} from '../buttons/cancel-button.component';
import {GuestbookUploadButtonComponent} from '../buttons/guestbook-upload-button.component';
import {GuestbookFileCardComponent} from './guestbook-file-card.component';
import {GuestbookFilesLightboxComponent} from './guestbook-file-lightbox.component';

interface GuestbookReplyInputProps extends Partial<GuestbookReply> {
  parentId: GuestbookPost['id'];
  onSubmit?: (data: Partial<GuestbookReply>) => void;
  onCancel?: () => void;
}

const BUTTON_HEIGHT = '36px';
const GUESTBOOK_REPLAY_UPLOAD_INPUT_ID_PREFIX = 'guestbook-reply-upload-select-file-input';

function GuestbookReplyInput({
  onSubmit,
  onCancel,
  guestbookFiles: defaultFiles,
  message: defaultMessage,
  parentId,
  id,
}: GuestbookReplyInputProps) {
  const {t} = useTranslation();

  const [messageText, setMessageText] = useState(defaultMessage || '');
  const [files, setFiles] = useState<ReadonlyArray<GuestbookPreviewFile>>(defaultFiles || []);
  const [indexToShow, setIndexToShow] = useState<number | null>(null);

  const onChangeMessageText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMessageText(event.target.value);
    },
    [setMessageText]
  );

  const onClickCancel = useCallback(() => {
    setMessageText('');
    setFiles([]);
    if (onCancel) {
      return onCancel();
    }
  }, [setMessageText, onCancel]);

  const onClickSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit({
        message: messageText,
        guestbookFiles: files as Array<GuestbookFile>,
      });
    }
    setMessageText('');
    setFiles([]);
  }, [messageText, files, setMessageText, setFiles, onSubmit]);

  const onSelectFiles = useCallback(
    (arr: ReadonlyArray<GuestbookPreviewFile>) => {
      setFiles([...files, ...arr]);
    },

    [files, setFiles]
  );

  const onClickFile = useCallback(
    (index: number | string) => {
      setIndexToShow(index as number);
    },
    [setIndexToShow]
  );

  const onClickRemoveFile = useCallback(
    (id: number | string) => {
      setFiles(files.filter((_file, index) => id !== index));
    },
    [files, setFiles]
  );

  const onCloseLightBox = useCallback(() => {
    setIndexToShow(null);
  }, [setIndexToShow]);

  const isPostDisabled = useMemo(() => {
    return !messageText.trim() && !files.length;
  }, [messageText, files.length]);

  return (
    <>
      {indexToShow !== null && (
        <GuestbookFilesLightboxComponent files={files} defaultIndex={indexToShow} onClose={onCloseLightBox} />
      )}
      <TextField
        variant="filled"
        multiline
        value={messageText}
        fullWidth
        autoFocus
        onChange={onChangeMessageText}
        placeholder={t(id ? 'guestbook-edit-comment.label' : 'guestbook-new-comment.label')}
        label={t(id ? 'guestbook-edit-comment.label' : 'guestbook-new-comment.label')}
      />
      {files.length > 0 && (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} mt={1} mb={2}>
          {files.map((file, index) => (
            <Grid item key={index} xs={6} md={4} lg={3} xl={2}>
              <GuestbookFileCardComponent
                key={index}
                {...file}
                id={index}
                onClose={onClickRemoveFile}
                onClick={onClickFile}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        marginTop={1}
        sx={theme => ({
          marginTop: '4px !important',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
          },
        })}
      >
        <CancelButtonComponent
          variant="text"
          onClick={onClickCancel}
          sx={theme => ({
            height: BUTTON_HEIGHT,
            [theme.breakpoints.down('sm')]: {
              order: 1,
              width: '100%',
              mt: 1,
              border: `1px solid ${LIGHT_GRAY_COLOR}`,
            },
          })}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              ml: '0 !important',
            },
          })}
        >
          <Box
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                width: '50%',
                ml: '0 !important',
              },
            })}
          >
            <GuestbookUploadButtonComponent
              fullWidth
              sx={{
                height: BUTTON_HEIGHT,
              }}
              onSelectFiles={onSelectFiles}
              inputId={`${GUESTBOOK_REPLAY_UPLOAD_INPUT_ID_PREFIX}-${parentId}`}
            />
          </Box>

          <Button
            variant="contained"
            disabled={isPostDisabled}
            onClick={onClickSubmit}
            sx={theme => ({
              height: BUTTON_HEIGHT,
              [theme.breakpoints.down('sm')]: {
                width: '50%',
              },
            })}
          >
            {t('guestbook-send-post.button')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export const GuestbookReplyInputComponent = memo(GuestbookReplyInput);
