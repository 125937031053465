import Stack from '@mui/material/Stack';
import ToggleButton, {ToggleButtonProps} from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';

interface AccessRoleToggleButtonProps extends ToggleButtonProps {
  label?: string;
}

function AccessRoleToggleButton({label, children, sx, ...props}: AccessRoleToggleButtonProps) {
  return (
    <ToggleButton
      {...props}
      sx={
        sx ||
        (theme => ({
          p: 0.75,
          minWidth: '55px',
          color: theme.palette.grey[600],
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.dark,
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
          '&:hover': {
            transform: 'scale(1.04)',
          },
        }))
      }
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        {children}
        {label && (
          <Typography
            variant="overline"
            textAlign={'center'}
            component={'p'}
            sx={{
              color: 'inherit',
            }}
          >
            {label}
          </Typography>
        )}
      </Stack>
    </ToggleButton>
  );
}

export const AccessRoleToggleButtonComponent = memo(AccessRoleToggleButton);
