import {createContext} from 'react';

import api from '../services/api';
import {AccessesStore} from './accesses.store';
import {AlbumFilesStore} from './album-files.store';
import {AlbumsStore} from './albums.store';
import {AuthStore} from './auth.store';
import {DraftPatientsStore} from './draft-patients.store';
import {GuestbookFilesStore} from './guestbook-files.store';
import {GuestbookPostsStore} from './guestbook-posts.store';
import {InvitationsStore} from './invitations.store';
import {PatientsStore} from './patients.store';
import {PhotosStore} from './photos.store';
import {ProfileDeleteRequestsStore} from './profile-delete-requests.store';
import {RelativesStore} from './relatives.store';
import {StoriesStore as StoriesStore} from './stories.store';
import {UnitsStore} from './units.store';
import {UsersStore} from './users.store';
import {V2PatientsStore} from './v2-patients.store';

class RootStore {
  constructor(
    public readonly authStore = new AuthStore(api),
    public readonly relativesStore = new RelativesStore(api),
    public readonly storiesStore = new StoriesStore(api),
    public readonly albumsStore = new AlbumsStore(api),
    public readonly guestbookFilesStore = new GuestbookFilesStore(api),
    public readonly guestbookPostsStore = new GuestbookPostsStore(api, guestbookFilesStore),
    public readonly accessesStore = new AccessesStore(api),
    public readonly photosStore = new PhotosStore(api),
    public readonly albumFilesStore = new AlbumFilesStore(api, albumsStore),
    public readonly patientsStore = new PatientsStore(api, photosStore, guestbookPostsStore),
    public readonly v2PatientsStore = new V2PatientsStore(api),
    public readonly invitationsStore = new InvitationsStore(api, patientsStore),
    public readonly usersStore = new UsersStore(api, authStore),
    public readonly unitsStore = new UnitsStore(api),
    public readonly draftPatientsStore = new DraftPatientsStore(api),
    public readonly profileDeleteRequestsStore = new ProfileDeleteRequestsStore(api)
  ) {}
}

export const storesContext = createContext(new RootStore());
