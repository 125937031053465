import {useCallback, useEffect, useState} from 'react';

import {AlbumFilePreview} from '../../interfaces/album-file-preview.interface';
import {Album} from '../../interfaces/entities/album.interface';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {getRandomId} from '../../utils/get-random-id';

export const useAlbumFilesEditor = (
  onChangeAlbumCover: (value: string) => void,
  albumImage?: Album['image'],
  defaultFiles: ReadonlyArray<AlbumFilePreview> = [],
  loading?: boolean
): {
  files: ReadonlyArray<AlbumFilePreview>;
  selected: ReadonlyArray<AlbumFile['id']>;
  deleted: ReadonlyArray<AlbumFile['id']>;
  rotated: Record<AlbumFile['id'], number>;
  onChange: (arr: ReadonlyArray<AlbumFilePreview>) => void;
  onClickSelect: (id: AlbumFile['id']) => void;
  onClickRotate: (id: AlbumFile['id']) => void;
  onDeleteSelected: () => void;
  getSelectedFileUrl: () => string | undefined;
  getFilesToUpdate: () => ReadonlyArray<AlbumFilePreview>;
  getFilesToCreate: () => ReadonlyArray<AlbumFilePreview & {file: File}>;
  getFilesToRotate: () => ReadonlyArray<{
    id: AlbumFile['id'];
    angle: number;
  }>;
  onSelectFiles: (event: React.ChangeEvent<HTMLInputElement>) => void;
} => {
  const [files, setFiles] = useState(defaultFiles);
  const [deleted, setDeleted] = useState<ReadonlyArray<AlbumFile['id']>>([]);
  const [selected, setSelected] = useState<ReadonlyArray<AlbumFile['id']>>([]);
  const [created, setCreated] = useState<ReadonlyArray<AlbumFilePreview & {file: File}>>([]);
  const [rotated, setRotated] = useState<Record<AlbumFile['id'], number>>({});

  useEffect(() => {
    setFiles(defaultFiles);
  }, [loading]);

  const onChange = useCallback(
    (arr: ReadonlyArray<AlbumFilePreview>) => {
      setFiles(arr);
      const foundAlbumImage = albumImage && arr.some(({url}) => url === albumImage);
      if (!foundAlbumImage && arr[0]?.url) {
        onChangeAlbumCover(arr[0]?.url);
      }
    },
    [setFiles, onChangeAlbumCover, albumImage]
  );

  const onDeleteSelected = useCallback(() => {
    onChange(files.filter(({id}) => !selected.includes(id)));
    setSelected([]);
    setDeleted([...deleted, ...selected]);
  }, [onChange, files, selected, deleted, setSelected, setDeleted]);

  const onClickSelect = useCallback(
    (id: AlbumFile['id']) => {
      if (selected.includes(id)) {
        setSelected(selected.filter(value => value !== id));
      } else {
        setSelected([...selected, id]);
      }
    },
    [selected, setSelected]
  );

  const onClickRotate = useCallback(
    (id: AlbumFile['id']) => {
      setRotated({...rotated, [id]: (rotated[id] || 0) + 90});
    },
    [rotated, setRotated]
  );

  const getSelectedFileUrl = useCallback(() => {
    return files.find(({id}) => id === selected[0])?.url;
  }, [files, selected]);

  const onSelectFiles = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        const toCreate = Array.from(event.target.files).map(
          value =>
            ({
              id: getRandomId(),
              url: URL.createObjectURL(value),
              description: '',
              file: value,
              type: value.type,
            }) as AlbumFilePreview & {file: File}
        );
        setCreated([...created, ...toCreate]);
        onChange([...files, ...toCreate]);
      }
    },
    [onChange, files, created, setCreated]
  );

  const getFilesToUpdate = useCallback(() => {
    return files.reduce((arr: ReadonlyArray<AlbumFilePreview>, file, index) => {
      if (!created.some(({id}) => file.id === id)) {
        return [...arr, {...file, order: index + 1}];
      }

      return arr;
    }, []);
  }, [files, created]);

  const getFilesToCreate = useCallback(() => {
    return created.reduce((arr: ReadonlyArray<AlbumFilePreview & {file: File} & {angle?: number}>, {id, file}) => {
      const index = files.findIndex(file => file.id === id);

      if (index !== -1) {
        return [...arr, {...files[index], order: index + 1, file, angle: rotated[id]}];
      }
      return arr;
    }, []);
  }, [files, created, rotated]);

  const getFilesToRotate = useCallback(() => {
    const arr = Object.keys(rotated).map(key => ({
      id: key,
      angle: rotated[key],
    }));

    return arr.filter(({id}) => {
      if (created.filter(item => item.id === id)[0]) {
        return false;
      }

      if (deleted.filter(item => item === id)[0]) {
        return false;
      }

      return true;
    });
  }, [rotated, created, deleted]);

  return {
    files,
    selected,
    deleted,
    rotated,
    onChange,
    onClickSelect,
    onSelectFiles,
    onDeleteSelected,
    onClickRotate,
    getSelectedFileUrl,
    getFilesToUpdate,
    getFilesToCreate,
    getFilesToRotate,
  };
};
