import {convertHeicToJpg} from './convert-heic-to-jpg';
import {isHeicConvertibleFile} from './is-file-heic';

export async function convertFileListWithHeic(fileList: FileList): Promise<FileList> {
  const arr = await Promise.all(
    Array.from(fileList).map(async file => {
      if (!isHeicConvertibleFile(file)) {
        return file;
      }

      try {
        return await convertHeicToJpg(file);
      } catch (err) {
        console.error(err);
        return file;
      }
    })
  );

  const dt = new DataTransfer();

  arr.forEach(file => {
    dt.items.add(file);
  });

  return dt.files;
}
