import {CircularProgress} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Patient} from '../../interfaces/entities/patient.interface';
import getName from '../../utils/get-name';
import {AvatarComponent} from '../avatar.component';

export interface CommonGuestbookPostPatientsSelectListProps {
  loading?: boolean;
  loadingElement?: React.ReactElement;
  selected: Patient['id'][];
  onSelect?: (ids: Patient['id'][]) => void;
  onDeselect?: (ids: Patient['id'][]) => void;
  patients: ReadonlyArray<Patient>;
  allOrNone?: boolean;
}

function CommonGuestbookPostPatientsSelectList({
  patients,
  allOrNone,
  selected,
  onSelect,
  onDeselect,
  loading,
  loadingElement = <CircularProgress />,
}: CommonGuestbookPostPatientsSelectListProps) {
  const {t} = useTranslation();

  const onClickSelect = useCallback(
    (id: Patient['id']) => {
      if (onSelect) {
        onSelect([id]);
      }
    },
    [onSelect, selected, patients]
  );

  const onClickDeselect = useCallback(
    (id: Patient['id']) => {
      if (onDeselect) {
        onDeselect([id]);
      }
    },
    [onDeselect, selected, patients]
  );

  const onClickSelectAll = useCallback(() => {
    if (onSelect) {
      onSelect(
        patients.reduce((arr: Patient['id'][], {id}) => {
          if (!selected.includes(id)) {
            arr.push(id);
          }
          return arr;
        }, [])
      );
    }
  }, [onSelect, selected, patients]);

  const onClickDeselectAll = useCallback(() => {
    if (onDeselect) {
      onDeselect(
        patients.reduce((arr: Patient['id'][], {id}) => {
          if (selected.includes(id)) {
            arr.push(id);
          }
          return arr;
        }, [])
      );
    }
  }, [onDeselect, selected, patients]);

  const allSelected = useMemo(() => {
    return !patients.some(({id}) => !selected.includes(id));
  }, [selected, patients]);

  return (
    <List dense>
      {!loading ? (
        <>
          {allOrNone && (
            <Box key={'all'}>
              <ListItem
                sx={{
                  height: '48px',
                }}
                secondaryAction={
                  <FormControlLabel
                    sx={{
                      textDecoration: 'underline',
                      mr: 0,
                    }}
                    control={
                      <Checkbox
                        edge="end"
                        checked={allSelected}
                        inputProps={{'aria-labelledby': 'select-all'}}
                        onClick={!allSelected ? onClickSelectAll : onClickDeselectAll}
                      />
                    }
                    label={'' + (!allSelected ? t('general-select-all.button') : t('general-select-none.button'))}
                    labelPlacement="start"
                  />
                }
                disablePadding
              />
              <Divider />
            </Box>
          )}
          {patients.map(({id, image, firstName, lastName}) => {
            const labelId = `checkbox-list-secondary-label-${id}`;
            const name = getName(firstName, lastName);

            const checked = selected.includes(id);
            return (
              <Box key={id} onClick={() => (!checked ? onClickSelect(id) : onClickDeselect(id))}>
                <ListItem
                  secondaryAction={<Checkbox edge="end" checked={checked} inputProps={{'aria-labelledby': labelId}} />}
                  disablePadding
                >
                  <ListItemButton selected={checked}>
                    <ListItemAvatar>
                      <AvatarComponent name={name} src={image || ''} />
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={name} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Box>
            );
          })}
        </>
      ) : (
        <ListItem disablePadding>{loadingElement}</ListItem>
      )}
    </List>
  );
}

export const CommonGuestbookPostPatientsSelectListComponent = memo(CommonGuestbookPostPatientsSelectList);
