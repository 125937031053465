import {AlertProps} from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DEEP_SPACE_SPARKLE_COLOR} from '../../theme';
import {AlertComponent} from '../alert/alert.component';

interface AdminModeCommonGuestbookBannerProps extends AlertProps {
  onClickSwitch?: () => void;
}

function AdminModeCommonGuestbookBanner({onClickSwitch, ...props}: AdminModeCommonGuestbookBannerProps) {
  const {t} = useTranslation();
  return (
    <AlertComponent
      severity="info"
      {...props}
      sx={() => ({
        py: 0.5,
        '& .MuiAlert-message': {
          width: '100%',
          py: 0,
        },
        ...(props.sx as Record<string, any>),
      })}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={0.5}>
        <Typography sx={{color: DEEP_SPACE_SPARKLE_COLOR, width: '100%'}} variant={'body2'} whiteSpace={'pre-line'}>
          {t('admin-mode-common-guestbook-post.title')}
        </Typography>
        <Button
          variant={'text'}
          color="secondary"
          sx={{
            py: 0.5,
            textTransform: 'none',
            textDecoration: 'underline',
            color: 'inherit',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={onClickSwitch}
        >
          {t('switch-to-ordinary-mode.button')}
        </Button>
      </Stack>
    </AlertComponent>
  );
}

export const AdminModeCommonGuestbookBannerComponent = memo(AdminModeCommonGuestbookBanner);
