import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isDateValid from 'date-fns/isValid';

interface ValidateDateOptions {
  max?: Date;
  min?: Date;
  optional?: boolean;
  requiredError?: string;
  validationError?: string;
  maxError?: string;
  minError?: string;
}

export function validateDate(
  date?: Date | null,
  {optional, max, min, requiredError, validationError, maxError, minError}: ValidateDateOptions = {}
): undefined | string {
  if (!date && optional) {
    return;
  }

  if (!date && !optional) {
    return requiredError || 'required error';
  }

  if (!isDateValid(date ? new Date(date) : date)) {
    return validationError || 'validation error';
  }

  if (max && isAfter(date as Date, max)) {
    return maxError || 'max error';
  }

  if (min && isBefore(date as Date, min)) {
    return minError || 'min error';
  }

  return;
}
