import Button, {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';

const DEFAULT_SX = {
  borderRadius: '50%',
  width: '64px',
  height: '64px',
  minWidth: 'unset',
  padding: 0,
  marginRight: 1,
  marginLeft: 1,
};

function RoundButton(props: ButtonProps) {
  return (
    <Button
      variant="contained"
      sx={
        (theme => ({
          ...DEFAULT_SX,
          [theme.breakpoints.up('sm')]: {
            width: '44px',
            height: '44px',
          },
          [theme.breakpoints.up('md')]: {
            width: '48px',
            height: '48px',
          },
          [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
          },
          [theme.breakpoints.up('lg')]: {
            width: '52px',
            height: '52px',
          },
          ...props.sx,
        })) as ButtonProps['sx']
      }
      {...props}
    />
  );
}

export const RoundButtonComponent = memo(RoundButton);
