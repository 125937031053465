import {useMemo} from 'react';

import {Patient} from '../../interfaces/entities/patient.interface';
import formatDate from '../../utils/format-date';
import getAge from '../../utils/get-age';
import {getCareFacility} from '../../utils/get-care-facility';
import getName from '../../utils/get-name';

export const useFormattedProfile = ({
  birthDate,
  firstName,
  lastName,
  unit,
}: Partial<Patient>): {
  name: string;
  birthDate: string;
  age?: number;
  careFacility: string;
} => {
  const name = useMemo(() => getName(firstName, lastName), [firstName, lastName]);

  const formedBirthDate = useMemo(() => formatDate(birthDate), [birthDate]);

  const age = useMemo(() => (birthDate ? getAge(birthDate) : undefined), [birthDate]);

  const careFacility = useMemo(() => getCareFacility({unit}), [birthDate]);

  return {
    birthDate: formedBirthDate,
    age,
    name,
    careFacility,
  };
};
