export const PATIENT_PAGES_TITLES = {
  PERSONAL: 'web-app-profile-personal.page-title',
  FAMILY: 'web-app-profile-relations.page-title',
  STORY: 'web-app-profile-stories.page-title',
  ALBUM: 'web-app-profile-albums.page-title',
  GUESTBOOK: 'web-app-profile-messages.page-title',
  ACCESSES: 'web-app-profile-accesses.page-title',
  INVITATIONS: 'web-app-profile-invitations.page-title',
};

export enum TABS_TITLES {
  PROFILES = 'profiles',
  RELATIVES = 'relatives',
  STORIES = 'stories',
  ALBUMS = 'albums',
  MESSAGES = 'messages',
  ACCESSES = 'accesses',
  INVITATIONS = 'invitations',
  NEW = 'new',
  EDIT = 'edit',
}

export enum TABS_TRANSLATIONS {
  PERSONAL = 'personal',
  RELATIONS = 'relations',
  STORIES = 'stories',
  ALBUMS = 'albums',
  MESSAGES = 'messages',
  ACCESSES = 'accesses',
  INVITATIONS = 'invitations',
}
