import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Formik, FormikProps} from 'formik';
import {omit} from 'lodash';
import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {SaveAndCancelButtonsComponent} from '../components/buttons/save-and-cancel-buttons.component';
import {Relative} from '../interfaces/entities/relative.interface';
import {AssistiveTextComponent} from './shared/assistive-text.component';
import {DescriptionFieldComponent} from './shared/description-field.component';
import {NameFieldComponent} from './shared/name-field.component';

interface RelativeFormProps extends Partial<Relative> {
  onSave?: (data: Partial<Relative>) => void;
  onCancel?: () => void;
  resetFormAfterCancel?: boolean;
}

function RelativeFormBase({resetFormAfterCancel = true, ...props}: RelativeFormProps) {
  const {t} = useTranslation();

  const onSave = useCallback(
    (updatedData: Partial<Relative>) => {
      const {onSave} = props;
      const data = omit({...props, ...updatedData}, ['onSave', 'onCancel']);

      if (!onSave) {
        return;
      }
      onSave(data);
    },
    [props]
  );

  const onCancel = useCallback(
    (resetForm: () => void) => {
      if (props.onCancel) {
        props.onCancel();
      }
      if (resetFormAfterCancel) {
        resetForm();
      }
    },
    [props.onCancel, resetFormAfterCancel]
  );

  return (
    <Formik<Partial<Relative>>
      initialValues={props || {}}
      onSubmit={onSave}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('required-field-not-filled.error'),
        lastName: Yup.string().required('required-field-not-filled.error'),
        relationName: Yup.string().nullable(true).optional(),
        description: Yup.string().nullable(true).optional(),
      })}
    >
      {(props: FormikProps<Partial<Relative>>) => {
        const {values, touched, errors, handleChange, handleSubmit, resetForm} = props;

        return (
          <Grid container direction="row" justifyContent="flex-start" spacing={3}>
            <Grid item xs={12} lg={9} xl={7}>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <NameFieldComponent
                      id={'firstName'}
                      label={t('relative-edit-first-name.label')}
                      value={values.firstName || ''}
                      handleChange={handleChange}
                      error={errors.firstName}
                      touched={touched.firstName}
                    />
                    <AssistiveTextComponent>{t('relative-edit-first-name.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <NameFieldComponent
                      id={'lastName'}
                      label={t('relative-edit-last-name.label')}
                      value={values.lastName || ''}
                      handleChange={handleChange}
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                    <AssistiveTextComponent>{t('relative-edit-last-name.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box>
                    <NameFieldComponent
                      id={'relationName'}
                      label={t('relative-edit-relationship.label')}
                      value={values.relationName || ''}
                      handleChange={handleChange}
                      error={errors.relationName}
                      touched={touched.relationName}
                    />
                    <AssistiveTextComponent>{t('relative-edit-relationship.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={12}>
                  <Box>
                    <DescriptionFieldComponent
                      id={'description'}
                      label={t('relative-edit-about.label')}
                      value={values.description || ''}
                      handleChange={handleChange}
                      error={errors.description}
                      touched={touched.description}
                      minRows={3}
                    />
                    <AssistiveTextComponent>{t('relative-edit-about.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <SaveAndCancelButtonsComponent
                    onClickCancel={() => onCancel(resetForm)}
                    onClickSave={handleSubmit}
                    sx={theme => ({
                      float: 'right',
                      width: '100%',

                      [theme.breakpoints.up('md')]: {
                        width: '45%',
                      },
                      [theme.breakpoints.up('lg')]: {
                        width: '35%',
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}

export const RelativeForm = memo(RelativeFormBase);
