import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {SlideshowSettings} from '../../interfaces/slideshow-settings.interface';
import {DialogComponent} from './dialog.component';

const MAX_INTERVAL = 30;
const MIN_INTERVAL = 5;
const STEP = 5;

export interface SlideshowSettingsDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (value: SlideshowSettings) => void;
  onClose: () => void;
  defaultSettings: SlideshowSettings;
}

function SlideshowSettingsDialog({
  onCancel,
  onConfirm,
  onClose,
  isOpen,
  defaultSettings,
}: SlideshowSettingsDialogProps) {
  const {t} = useTranslation();

  const [interval, setInterval] = useState(defaultSettings.interval);
  const [loop, setLoop] = useState(defaultSettings.loop);

  useEffect(() => {
    setInterval(defaultSettings.interval);
  }, [isOpen, defaultSettings.interval]);

  useEffect(() => {
    setLoop(defaultSettings.loop);
  }, [isOpen, defaultSettings.loop]);

  const handleChangeSlideshowInterval = useCallback((_event: Event, newValue: number | number[]) => {
    setInterval((newValue as number) * 1000);
  }, []);

  const handleChangeLoop = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setLoop(event.target.checked);
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm({interval, loop});
  }, [onConfirm, interval, loop]);

  const marks = useMemo(() => {
    const arr = [];
    for (let i = MIN_INTERVAL; i <= MAX_INTERVAL; i += STEP) {
      arr.push({
        value: i,
        label: '' + i,
      });
    }
    return arr;
  }, []);

  const renderDialogActions = () => {
    return (
      <Box
        display="flex"
        px={3}
        py={2}
        sx={theme => ({
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
          },
        })}
      >
        <MUIButton
          onClick={handleConfirm}
          disableElevation
          variant="contained"
          sx={{
            minWidth: '150px',
          }}
        >
          {t('general-save.button')}
        </MUIButton>
        <MUIButton
          onClick={onCancel}
          disableElevation
          sx={theme => ({
            minWidth: '80px',
            flexDirection: 'column',
            order: '-1',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
              order: '0',
              ml: 1,
            },
          })}
        >
          {t('general-cancel.button')}
        </MUIButton>
      </Box>
    );
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      title={t('slideshow-settings-dialog.header')}
      description={
        <>
          <Typography gutterBottom>{t('slideshow-settings-interval.label')}</Typography>
          <Slider
            size="small"
            min={MIN_INTERVAL}
            max={MAX_INTERVAL}
            value={interval / 1000}
            onChange={handleChangeSlideshowInterval}
            valueLabelDisplay="auto"
            step={STEP}
            marks={marks}
            sx={theme => ({
              '& .MuiSlider-mark': {
                height: theme.spacing(1),
                backgroundColor: theme.palette.primary.dark,
              },
            })}
          />
          <Typography mt={1} gutterBottom>
            {t('slideshow-settings-loop.label')}
          </Typography>
          <Switch checked={loop} onChange={handleChangeLoop} />
        </>
      }
    >
      {renderDialogActions()}
    </DialogComponent>
  );
}

export const SlideshowSettingsDialogComponent = memo(SlideshowSettingsDialog);
