import {AxiosInstance} from 'axios';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Access, ACCESS_ROLES} from '../interfaces/entities/access.interface';
import {GuestbookPost} from '../interfaces/entities/guestbook-post.interface';
import {Patient} from '../interfaces/entities/patient.interface';
import {Unit} from '../interfaces/entities/unit.interface';
import {ListResponse} from '../interfaces/list-response.interface';
import {User} from '../interfaces/user.interface';
import {UploadsService} from '../services/uploads.service';
import {GuestbookPostsStore} from './guestbook-posts.store';
import {PhotosStore} from './photos.store';
import {BaseStore} from './shared/base.store';

const DELAY_AFTER_READ_COMMON_GUESTBOOK_POST = 1500;

export class PatientsStore extends BaseStore<Patient> {
  roles: Record<Patient['id'], ACCESS_ROLES | undefined> = {};
  loadingRole?: boolean;
  acceptedInvitation?: Access;
  failedAcceptingInvitation?: boolean;

  constructor(
    api: AxiosInstance,
    private readonly photosStore: PhotosStore,
    private readonly guestbookPostsStore: GuestbookPostsStore,
    private readonly uploadsService = new UploadsService(api)
  ) {
    super(api, 'patients');
    makeObservable(this, {
      roles: observable,
      loadingRole: observable,
      acceptedInvitation: observable,
      failedAcceptingInvitation: observable,
      patientsWithNewGuestbookMessages: computed,
      fetchRole: action,
      acceptInvite: action,
    });
  }

  get patientsWithNewGuestbookMessages() {
    if (this.items) {
      return this.items.reduce((counter, item) => {
        if (item.newGuestbookPostsCount) {
          return counter + 1;
        }
        return counter;
      }, 0);
    }

    return 0;
  }

  async update(id: Patient['id'], data: Partial<Patient>, prefix?: string): Promise<Patient | null> {
    const updated = await super.update(id, data, prefix);

    runInAction(() => {
      if (!updated) {
        return;
      }
      this.items = [updated, ...this.items.filter(({id}) => id !== updated.id)];
    });
    return updated;
  }

  create(data: Partial<Patient & {departmentId?: Unit['id']}>): Promise<Patient | null> {
    return super.create(data, 'v2');
  }

  async fetchRole(id: Patient['id']) {
    if (this.roles[id]) {
      return this.roles[id];
    }
    if (this.loadingRole) {
      return;
    }

    runInAction(() => {
      this.loadingRole = true;
    });

    try {
      const {data} = await this.api.get<{role?: ACCESS_ROLES}>(`${this.getUrl()}/${id}/role`);
      runInAction(() => {
        this.roles[id] = data.role;
      });
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      this.loadingRole = false;
    });
  }

  async updateWithPhoto(patientId: Patient['id'], data: Partial<Patient>, file: File, field = 'image') {
    const {url} = await this.uploadsService.upload(`patients/${patientId}`, file);
    await this.update(patientId, {...data, [field]: url});
    await this.photosStore.createByPatientId(patientId, {url});
  }

  async updateWithPhotoUrl(patientId: Patient['id'], data: Partial<Patient>, photo: string) {
    const {url} = await this.uploadsService.uploadByUrl(`patients/${patientId}`, photo);
    await this.update(patientId, {...data, image: url});
    await this.photosStore.createByPatientId(patientId, {url});
  }

  async acceptInvite(invitationKeysId: string) {
    try {
      const {data} = await this.api.post<Access>(`invitations/${invitationKeysId}/accept`);
      runInAction(() => {
        this.roles[data.patient?.id] = data.role;
        this.item = data.patient;
        this.acceptedInvitation = data;
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.failedAcceptingInvitation = true;
      });
    }
  }

  async readGuestbookPost(
    patientId: Patient['id'],
    guestbookPostId: GuestbookPost['id'],
    guestbookPostCommonId?: GuestbookPost['id'],
    user?: User,
    role?: ACCESS_ROLES
  ) {
    const guestbookPost = (this.guestbookPostsStore.itemsByPatientId[patientId] || [])
      .reduce((accumulator: GuestbookPost[], currentValue) => {
        return [...accumulator, currentValue, ...(currentValue.replies || [])];
      }, [])
      .find(item => item.id === guestbookPostId);

    if (guestbookPost?.isNew) {
      runInAction(() => {
        if (this.item?.id === patientId) {
          this.item = {
            ...this.item,
            newGuestbookPostsCount:
              this.item.newGuestbookPostsCount && this.item.newGuestbookPostsCount > 0
                ? this.item.newGuestbookPostsCount - 1
                : 0,
          };
        }

        this.items = this.items.map(item => {
          if (item.id !== patientId) {
            return item;
          }

          return {
            ...item,
            newGuestbookPostsCount:
              item.newGuestbookPostsCount && item.newGuestbookPostsCount > 0 ? item.newGuestbookPostsCount - 1 : 0,
          };
        });
      });
    }

    try {
      await this.guestbookPostsStore.readGuestbookPost(patientId, guestbookPostId, guestbookPostCommonId, user, role);
    } catch (err) {
      console.error(err);
    }

    if (guestbookPost?.isNew && guestbookPostCommonId) {
      setTimeout(async () => {
        let page = 1;
        const arr: Patient[] = [];
        while (page <= this.currentPage) {
          try {
            const {data} = await this.api.get<ListResponse<Patient>>(this.getUrl(), {
              params: {
                page,
                limit: 50,
              },
            });
            arr.push(...data.items);
            page += 1;
          } catch (err) {
            console.error(err);
            break;
          }
        }

        runInAction(() => {
          this.items = this.items.map(item => {
            const updatedItem = arr.find(({id}) => id === item.id);
            if (!updatedItem) {
              return item;
            }

            return {
              ...item,
              newGuestbookPostsCount: updatedItem.newGuestbookPostsCount,
            };
          });
        });
      }, DELAY_AFTER_READ_COMMON_GUESTBOOK_POST);
    }
  }
}
