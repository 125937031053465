import MUIButton, {ButtonProps} from '@mui/material/Button';
import Link from '@mui/material/Link';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DASHBOARD_LINK, LOCALES} from '../../utils/constants';
import {mapVilmerLanguage} from '../../utils/map-vilmer-language';

function DashboardButton(props: ButtonProps<'a'>) {
  const {t, i18n} = useTranslation();

  const url = new URL(DASHBOARD_LINK);
  const vilmerLng = mapVilmerLanguage(i18n.language as LOCALES);
  if (vilmerLng) {
    url.pathname = `/${vilmerLng}${url.pathname}`;
  }

  return (
    <MUIButton
      component={Link}
      href={url.toString()}
      target={'_blank'}
      sx={{
        textAlign: 'center',
        maxWidth: '225px',
        width: 'inherit',
      }}
      disableElevation
      {...props}
    >
      {t('main-nav-dashboard.button')}
    </MUIButton>
  );
}

export const DashboardButtonComponent = memo(DashboardButton);
