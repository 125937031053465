import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import React, {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {MY_PATIENTS_ROUTE} from '../../../configs/routes';
import {APP_TITLE, LOGO_PATH, SUB_NAV_HEIGHT_MOBILE} from '../../../utils/constants';
import {IntercomSupportIconComponent} from '../../icons/intercom-icon.component';

interface MobileNavigationHeaderProps {
  menuOnClick?: () => void;
  supportOnClick?: () => void;
}

function MobileNavigationHeader({menuOnClick, supportOnClick}: MobileNavigationHeaderProps) {
  return (
    <Toolbar
      sx={{
        height: `${SUB_NAV_HEIGHT_MOBILE}px`,
        pl: 3,
        pr: 3,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: '100%',
        }}
      >
        <Grid item>
          <IconButton
            aria-label="Open menu"
            edge="start"
            onClick={menuOnClick}
            sx={{
              p: 1,
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          sx={{
            height: '100%',
          }}
        >
          <Link
            to={MY_PATIENTS_ROUTE.path || ''}
            component={RouterLink}
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={LOGO_PATH}
              alt={APP_TITLE}
              style={{
                height: `30px`,
              }}
            />
          </Link>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Open support chat"
            edge="end"
            onClick={supportOnClick}
            sx={{
              p: 1,
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            <IntercomSupportIconComponent />
          </IconButton>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export const MobileNavigationHeaderComponent = memo(MobileNavigationHeader);
