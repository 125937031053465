import TextField from '@mui/material/TextField';
import React, {memo} from 'react';

import {FieldProps} from '../interfaces/field-props.interface';
import {FieldWrapperComponent} from './field-wrapper.component';

interface DescriptionFieldProps extends FieldProps {
  id?: string;
  value: string;
  minRows?: number;
}

const FIELD_NAME_DEFAULT = 'description';
const FIELD_ID_DEFAULT = `${FIELD_NAME_DEFAULT}-id`;
const MIN_ROWS = 3;

function DescriptionField(props: DescriptionFieldProps) {
  const {
    id = FIELD_ID_DEFAULT,
    name = FIELD_NAME_DEFAULT,
    value,
    error,
    label,
    minRows = MIN_ROWS,
    handleChange,
    handleBlur,
  } = props;

  return (
    <FieldWrapperComponent {...props} name={name}>
      <TextField
        fullWidth
        variant="outlined"
        aria-describedby={error ? `${name}-error-id` : undefined}
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        label={label}
        multiline
        minRows={minRows}
      />
    </FieldWrapperComponent>
  );
}

export const DescriptionFieldComponent = memo(DescriptionField);
