import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import {CommonGuestbookPostLoadingComponent} from '../../components/loading/guestbook/common-guestbook-post-loading.component';
import {LoginComponent} from '../../components/login.component';
import {COMMON_GUESTBOOK_POST_ROUTE} from '../../configs/routes';
import {useStores} from '../../hooks/use-stores';
import {isUserAdmin} from '../../utils/is-user-admin';
import {CommonGuestbookPostContainer} from './common-guestbook-post.container';

function AdminCommonGuestbookPost() {
  const {authStore} = useStores();

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore.fetchMe]);

  if (authStore.loading) {
    return <CommonGuestbookPostLoadingComponent />;
  }

  if (!authStore.me) {
    return <LoginComponent />;
  }

  if (!isUserAdmin(authStore.me) && authStore.loading === false) {
    return <Redirect to={COMMON_GUESTBOOK_POST_ROUTE.path} />;
  }

  return <CommonGuestbookPostContainer adminMode />;
}

export const AdminCommonGuestbookPostContainer = observer(AdminCommonGuestbookPost);
