import orderBy from 'lodash/orderBy';
import {useCallback, useEffect} from 'react';

import {Album} from '../../interfaces/entities/album.interface';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {useStores} from '../use-stores';

export function useAlbumFilesByAlbumId(
  patientId: Patient['id'],
  albumId: Album['id'],
  limit?: number
): {
  items: ReadonlyArray<AlbumFile>;
  hasMore: boolean;
  loadMore: () => Promise<void>;
  loading?: boolean;
  update: (id: AlbumFile['id'], data: Partial<AlbumFile>) => Promise<void>;
  updateMany: (arr: ReadonlyArray<Partial<AlbumFile>>) => Promise<void>;
  deleteMany: (ids: ReadonlyArray<AlbumFile['id']>) => Promise<void>;
  createMany: (arr: ReadonlyArray<Partial<Album & {file: File}>>) => Promise<ReadonlyArray<AlbumFile>>;
  rotateMany: (ids: ReadonlyArray<{id: string; angle: number}>) => Promise<ReadonlyArray<AlbumFile>>;
} {
  const {albumFilesStore: store} = useStores();

  useEffect(() => {
    store.listByAlbumId(patientId, albumId, 1, limit);
  }, [store.listByAlbumId, patientId, albumId, limit]);

  const loadMore = useCallback(() => {
    return store.loadMoreByAlbumId(patientId, albumId, limit);
  }, [store.loadMoreByAlbumId, patientId, albumId]);

  const update = useCallback(
    async (id: AlbumFile['id'], data: Partial<Album>) => {
      await store.updateByAlbumId(patientId, albumId, id, data);
    },
    [store.updateByAlbumId, patientId, albumId]
  );

  const deleteMany = useCallback(
    async (ids: ReadonlyArray<AlbumFile['id']>) => {
      await store.deleteManyByAlbumId(patientId, albumId, ids);
    },
    [store.deleteByPatientId, patientId, albumId]
  );

  const createMany = useCallback(
    async (arr: ReadonlyArray<Partial<Album & {file: File} & {angle?: number}>>) => {
      return store.createManyByAlbumId(patientId, albumId, arr);
    },
    [store.createManyByAlbumId, patientId, albumId]
  );

  const updateMany = useCallback(
    async (arr: ReadonlyArray<Partial<AlbumFile>>) => {
      return store.updateManyByAlbumId(patientId, albumId, arr);
    },
    [store.updateManyByAlbumId, patientId, albumId]
  );

  const rotateMany = useCallback(
    async (arr: ReadonlyArray<{id: string; angle: number}>) => {
      return store.rotateManyByAlbumId(patientId, albumId, arr);
    },
    [store.rotateManyByAlbumId, patientId, albumId]
  );

  return {
    items: orderBy((store.itemsByAlbumId[albumId] || []) as ReadonlyArray<AlbumFile>, ['order'], ['asc']),
    hasMore: store.getHasMoreByAlbumId(patientId, albumId) || false,
    loadMore: loadMore,
    loading: store.loadingByAlbumId[albumId],
    update,
    deleteMany,
    createMany,
    updateMany,
    rotateMany,
  };
}
