import React, {memo} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

import {useTitle} from '../hooks/use-title';

export interface PageTitleProps {
  title: string | ReadonlyArray<string> | undefined;
}

const PageTitle = (props: PageTitleProps) => {
  const {t} = useTranslation();
  const [pageTitle] = useTitle(props.title);

  return (
    <Helmet>
      <title>{t(pageTitle)}</title>
    </Helmet>
  );
};

export const PageTitleComponent = memo(PageTitle);
