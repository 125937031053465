import {CircularProgress} from '@mui/material';
import React, {memo, ReactElement, ReactNode} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface PatientNestedItemsInfiniteScrollProps {
  loadMore: () => Promise<void>;
  dataLength: number;
  hasMore: boolean;
  children: ReactNode;
  limit?: number;
}

function PatientNestedItemsInfiniteScroll({
  loadMore,
  dataLength,
  hasMore,
  children,
}: PatientNestedItemsInfiniteScrollProps): ReactElement {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={loadMore}
      hasMore={hasMore}
      loader={<CircularProgress style={{display: 'table', margin: '0 auto'}} />}
      style={{overflow: undefined}}
    >
      {children}
    </InfiniteScroll>
  );
}

export const PatientNestedItemsInfiniteScrollComponent = memo(PatientNestedItemsInfiniteScroll);
