import {AxiosInstance, AxiosResponse} from 'axios';
import {action, AnnotationsMap, makeObservable, observable, runInAction} from 'mobx';

import {DraftPatient} from '../interfaces/entities/draft-patient.interface';
import {GetManyResponse} from '../interfaces/get-many-response.interface';
import {UploadsService} from '../services/uploads.service';

export class DraftPatientsStore {
  items?: ReadonlyArray<DraftPatient>;
  loadingItems?: boolean;
  loadingDelete?: boolean;
  loadingCreate?: boolean;
  loadingUpdate?: boolean;

  protected readonly annotations: AnnotationsMap<DraftPatientsStore, never> = {
    items: observable,
    loadingItems: observable,
    loadingDelete: observable,
    loadingCreate: observable,
    loadingUpdate: observable,
    fetchItems: action,
    delete: action,
    create: action,
    update: action,
  };

  constructor(
    protected readonly api: AxiosInstance,
    private readonly uploadsService = new UploadsService(api)
  ) {
    makeObservable(this, this.annotations);
  }

  async fetchItems(force = false) {
    if (!force && this.items) {
      return;
    }

    if (this.loadingItems) {
      return;
    }

    runInAction(() => {
      this.loadingItems = true;
    });

    let response: AxiosResponse<GetManyResponse<DraftPatient>>;
    try {
      response = await this.api.get<GetManyResponse<DraftPatient>>('draft-patients');
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      this.items = response?.data?.data;
      this.loadingItems = false;
    });
  }

  async create(data: Partial<DraftPatient>): Promise<DraftPatient | null> {
    if (this.loadingCreate) {
      return null;
    }

    runInAction(() => {
      this.loadingCreate = true;
    });

    try {
      const {data: createdItem} = await this.api.post('draft-patients', data);
      runInAction(() => {
        this.items = [createdItem, ...(this.items || [])];
        this.loadingCreate = false;
      });
      return createdItem;
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.loadingCreate = false;
      });

      return null;
    }
  }

  async delete(id: DraftPatient['id']): Promise<void> {
    runInAction(() => {
      this.loadingDelete = true;
      this.items = this.items?.filter(item => item.id !== id);
    });

    try {
      await this.api.delete(`draft-patients/${id}`);
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      this.loadingDelete = false;
    });
  }

  async update(id: DraftPatient['id'], data: Partial<DraftPatient>): Promise<DraftPatient | null> {
    runInAction(() => {
      this.loadingUpdate = true;
    });

    try {
      const {data: updatedItem} = await this.api.put(`draft-patients/${id}`, data);
      runInAction(() => {
        this.loadingUpdate = false;
        this.items = (this.items || []).reduce((arr: ReadonlyArray<DraftPatient>, item: DraftPatient) => {
          if (item.id === updatedItem.id) {
            return [...arr, {...item, ...updatedItem}];
          }
          return [...arr, item];
        }, []);
      });

      return updatedItem;
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.loadingUpdate = false;
      });
      return null;
    }
  }

  async uploadPhoto(id: DraftPatient['id'], file: File): Promise<string> {
    const {url} = await this.uploadsService.upload(`draft-patients/${id}`, file);

    return url;
  }
}
