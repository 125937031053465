import React, {memo, ReactElement} from 'react';

import {ProfilesListContainer} from '../containers/profiles/profiles-list.container';
import PageProps from '../interfaces/page-props.interface';
import {Page} from './page';

function MyPatients(props: PageProps): ReactElement {
  return (
    <Page {...props}>
      <ProfilesListContainer />
    </Page>
  );
}

export const MyPatientsPage = memo(MyPatients);
