import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, {memo, useEffect, useState} from 'react';

import {WHITE_COLOR} from '../../theme';

interface SlideShowStoryProps {
  text?: string;
  visible?: boolean;
  isPaused?: boolean;
  onClickPlayButton?: () => void;
}

function SlideShowStoryToolbar({text, visible = true, isPaused, onClickPlayButton}: SlideShowStoryProps) {
  const [wasChangedVisible, setWasChangedVisible] = useState(false);

  useEffect(() => {
    if (!wasChangedVisible && visible) {
      setWasChangedVisible(true);
    }
  }, [visible]);

  if (!visible && !wasChangedVisible) {
    return <></>;
  }

  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      width={'100%'}
      sx={theme => ({
        zIndex: theme.zIndex.appBar + 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: text ? 'space-between' : 'center',
        padding: '10px',
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderTop: `1px solid ${WHITE_COLOR}`,
        color: WHITE_COLOR,
        transition: 'opacity 0.3s ease',
        animationDuration: '0.3s',
        animationFillMode: 'forwards',
        animationName: visible ? 'slideIn' : 'slideOut',
        '@keyframes slideIn': {
          '0%': {
            transform: 'translateY(100%)',
          },
          '100%': {
            transform: 'translateY(0)',
          },
        },
        '@keyframes slideOut': {
          '0%': {
            transform: 'translateY(0)',
          },
          '100%': {
            transform: 'translateY(100%)',
          },
        },
      })}
    >
      <IconButton
        onClick={onClickPlayButton}
        sx={{
          p: 0.5,
          fontSize: '30px',
          color: WHITE_COLOR,
          animation: isPaused ? 'play 0.3s linear forwards' : 'pause 0.3s linear forwards',
          '@keyframes pause': {
            '0%': {
              transform: 'scale(1)',
            },
            '50%': {
              transform: 'scale(1.2)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },

          '@keyframes play': {
            '0%': {
              transform: 'scale(1)',
            },
            '50%': {
              transform: 'scale(1.2)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
      >
        {isPaused ? <PlayArrowIcon fontSize="inherit" /> : <PauseIcon fontSize="inherit" />}
      </IconButton>
      {text && (
        <Typography textAlign="center" color={WHITE_COLOR}>
          {text}
        </Typography>
      )}
      <Box></Box>
    </Box>
  );
}

export const SlideShowStoryToolbarComponent = memo(SlideShowStoryToolbar);
