import {Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {memo, useCallback, useState} from 'react';
import Lightbox from 'react-image-lightbox';

import {AvatarComponent, AvatarProps} from './avatar.component';

const IMAGE_PADDING = 50;

function AvatarWithLightbox(props: AvatarProps) {
  const {lightbox} = useStyles();

  const [openLightbox, setOpenLightbox] = useState(false);

  const toggleOpenLightbox = useCallback(() => {
    setOpenLightbox(!openLightbox);
  }, [openLightbox]);

  return (
    <>
      <AvatarComponent
        {...props}
        sx={{cursor: props.src ? 'pointer' : undefined, ...props.sx}}
        onClick={props.src ? toggleOpenLightbox : undefined}
      />

      {props.src && openLightbox && (
        <Lightbox
          reactModalStyle={{overlay: {zIndex: 9999}}}
          wrapperClassName={lightbox}
          mainSrc={props.src}
          onCloseRequest={toggleOpenLightbox}
          imageTitle={
            <Typography variant={'subtitle1'} color={'#FFFFFF'} position={'absolute'} left={'50%'}>
              {props.name}
            </Typography>
          }
        />
      )}
    </>
  );
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    lightbox: {
      '& .ril-caption': {
        bottom: Math.floor(IMAGE_PADDING / 2) - 10,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  })
);

export const AvatarWithLightboxComponent = memo(AvatarWithLightbox);
