import {AxiosInstance} from 'axios';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {Patient} from '../../interfaces/entities/patient.interface';
import {GetManyResponse} from '../../interfaces/get-many-response.interface';
import {getFetchingKey} from '../../utils/get-fetching-key';
import {BaseStore} from './base.store';

export abstract class ProfileBaseStore<T extends {id: string}> extends BaseStore<T> {
  itemsByProfileId: Record<
    string,
    {
      loading?: boolean;
      error?: Error;
      data?: GetManyResponse<T>;
    }
  > = {};

  constructor(
    protected readonly api: AxiosInstance,
    protected entitiesName: string
  ) {
    super(api, entitiesName);
    makeObservable(this, {
      fetchListByProfileId: action,
      itemsByProfileId: observable,
    });
  }

  async fetchListByProfileId(
    profileId: Patient['id'],
    page?: number,
    limit?: number,
    fields?: string[],
    s?: string,
    sort?: string[],
    join?: string[],
    force?: boolean
  ): Promise<void> {
    const fetchingKey = this.getFetchingKey(profileId, page, limit, fields, s, sort, join);

    const {loading, data} = this.itemsByProfileId[fetchingKey] || {};

    if (loading) {
      return;
    }

    if (!force && data) {
      return;
    }

    runInAction(() => {
      this.itemsByProfileId[fetchingKey] = {
        loading: true,
      };
    });

    const searchParams = new URLSearchParams();

    if (page !== undefined) {
      searchParams.append('page', '' + page);
    }

    if (limit !== undefined) {
      searchParams.append('limit', '' + limit);
    }

    if (fields) {
      for (let i = 0; i < fields.length; i++) {
        searchParams.append('fields', fields[i]);
      }
    }

    if (s) {
      searchParams.append('s', s);
    }

    if (join) {
      for (let i = 0; i < join.length; i++) {
        searchParams.append('join', join[i]);
      }
    }

    if (sort) {
      for (let i = 0; i < sort.length; i++) {
        searchParams.append('sort', sort[i]);
      }
    }

    try {
      const {data} = await this.api.get<GetManyResponse<T>>(
        this.getUrlByProfileId(profileId, `?${searchParams.toString()}`)
      );
      runInAction(() => {
        this.itemsByProfileId[fetchingKey] = {
          loading: false,
          data: data,
        };
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.itemsByProfileId[fetchingKey] = {
          loading: false,
          error: err as Error,
        };
      });
    }
  }

  public getPrefixFetchingKey(profileId: Patient['id']): string {
    return `${this.entitiesName}-${profileId}`;
  }

  getFetchingKey(
    profileId: Patient['id'],
    page?: number,
    limit?: number,
    fields?: string[],
    s?: string,
    sort?: string[],
    join?: string[]
  ): string {
    const prefix = this.getPrefixFetchingKey(profileId);
    return getFetchingKey(prefix, page, limit, fields, s, sort, join);
  }

  protected getUrlByProfileId(profileId: string, end?: string): string {
    const patientsUrl = `profiles/${profileId}/${this.entitiesName}`;
    return !end ? patientsUrl : `${patientsUrl}${end}`;
  }

  protected getPrefix(profileId: Patient['id']): string {
    return `patients/${profileId}`;
  }
}
