import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {Patient} from '../../../interfaces/entities/patient.interface';
import getAge from '../../../utils/get-age';
import getName from '../../../utils/get-name';
import {AvatarWithLightboxComponent} from '../../avatar-with-lightbox.component';

function PatientInfo({firstName, lastName, birthDate, image, nickname}: Patient) {
  const {t} = useTranslation();

  const name = getName(firstName, lastName);
  const age = birthDate && getAge(birthDate);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <AvatarWithLightboxComponent name={name} src={image || ''} sx={{height: '98px', width: '98px'}} />
      </Grid>
      <Grid item>
        <Typography variant="caption">{name}</Typography>
      </Grid>
      {nickname && (
        <Grid item>
          <Typography variant="caption">({nickname})</Typography>
        </Grid>
      )}
      {age && (
        <Grid item>
          <Typography variant="overline" sx={{color: 'primary.main'}}>
            {`${age} ${t('persona-age.label')}`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export const PatientInfoComponent = memo(PatientInfo);
