import {AlertProps} from '@mui/material/Alert';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {AlertComponent} from './alert/alert.component';

function AdminMessage(props: AlertProps) {
  const {t} = useTranslation();

  return (
    <AlertComponent severity="info" {...props}>
      {t('memoria-profile-list-admin-warning')}
    </AlertComponent>
  );
}

export const AdminMessageComponent = memo(AdminMessage);
