import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Slider, {SliderProps} from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import React, {memo, useCallback} from 'react';
import Cropper, {CropperProps} from 'react-easy-crop';
import {useTranslation} from 'react-i18next';

import {PLACEHOLDER_AVATAR} from '../utils/constants';
import {UploadButtonComponent} from './buttons/upload-button.component';

const ROTATION_DEFAULT_SLIDER_PROPS: SliderProps = {
  min: 0,
  max: 360,
  step: 1,
  'aria-labelledby': 'Rotation',
};

const ZOOM_DEFAULT_SLIDER_PROPS: SliderProps = {
  min: 1,
  max: 3,
  step: 0.01,
  'aria-labelledby': 'Zoom',
};

export interface PhotoEditorProps extends Partial<CropperProps> {
  columnOriented?: boolean;
  onSelectFiles?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickDeleteButton?: () => void;
  placeHolder?: React.ReactNode;
  uploadButtonText?: string;
}

function PhotoEditor(props: PhotoEditorProps) {
  const {t} = useTranslation();

  const {image, zoom, columnOriented, onSelectFiles, rotation, onClickDeleteButton, placeHolder, uploadButtonText} =
    props;

  const onZoomChange = useCallback(
    (_event: Event, value: number | number[]) => {
      if (props.onZoomChange) {
        props.onZoomChange(Number(value));
      }
    },
    [props.onZoomChange]
  );

  const onRotationChange = useCallback(
    (_event: Event, value: number | number[]) => {
      if (props.onRotationChange) {
        props.onRotationChange(Number(value));
      }
    },
    [props.onRotationChange]
  );

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
      <Grid item {...(columnOriented ? {xs: 12} : {xs: 12, md: 4})}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            minHeight: '250px',
            background: '#fafafa',
          }}
        >
          {image ? (
            <Cropper
              {...(props as CropperProps)}
              style={{
                containerStyle: {
                  borderRadius: '2px',
                  border: 'thin solid rgba(0, 0, 0, 0.6)',
                },
              }}
            />
          ) : (
            <>{placeHolder || <CardMedia image={PLACEHOLDER_AVATAR} sx={{borderRadius: 1, paddingTop: '80%'}} />}</>
          )}
        </Box>
      </Grid>

      <Grid item {...(columnOriented ? {xs: 12} : {xs: 12, md: 8})}>
        <Box p={{xs: 0, md: 1}} pl={{md: 2}} pt={{xs: 1}}>
          {image && (
            <>
              <Grid container direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
                <Grid item xs={4}>
                  <Typography variant="subtitle1">{t('patient-edit-profile-pic-zoom.label')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Slider {...ZOOM_DEFAULT_SLIDER_PROPS} value={zoom} onChange={onZoomChange} />
                </Grid>
              </Grid>

              <Grid container direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
                <Grid item xs={4}>
                  <Typography variant="subtitle1">{t('patient-edit-profile-pic-rotate.label')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Slider {...ROTATION_DEFAULT_SLIDER_PROPS} value={rotation} onChange={onRotationChange} />
                </Grid>
              </Grid>
            </>
          )}

          <Grid
            container
            direction="column"
            spacing={{
              xs: 1,
              sm: 2,
              md: 3,
            }}
            sx={theme => ({
              [theme.breakpoints.up('sm')]: {
                mt: 1,
              },
              [theme.breakpoints.up('md')]: {
                width: '50%',
              },
              [theme.breakpoints.up('lg')]: {
                mt: 3,
                width: '35%',
              },
            })}
          >
            <Grid item>
              <UploadButtonComponent onSelectFiles={onSelectFiles}>{uploadButtonText}</UploadButtonComponent>
            </Grid>
            <Grid item>
              {image && (
                <Button onClick={onClickDeleteButton} disableElevation>
                  <DeleteOutlineOutlinedIcon sx={{paddingRight: '5px'}} />
                  {t('patient-edit-profile-picture.delete')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export const PhotoEditorComponent = memo(PhotoEditor);
