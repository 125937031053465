import SettingsIcon from '@mui/icons-material/Settings';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import Lightbox from 'react-image-lightbox';

import {useSlideshowSettings} from '../../hooks/use-slideshow-settings';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {SlideshowSettings} from '../../interfaces/slideshow-settings.interface';
import {BLACK_COLOR, DEEP_SPACE_SPARKLE_COLOR, WHITE_COLOR} from '../../theme';
import {EMPTY_IMAGE} from '../../utils/constants';
import {getNextIndex} from '../../utils/get-next-index';
import {getPreviousIndex} from '../../utils/get-previous-index';
import {isVideoUrl} from '../../utils/is-video-url';
import {SLIDESHOW_BUTTON_STYLE, SlideshowButtonComponent} from '../buttons/slideshow-button.component';
import {SlideshowSettingsDialogComponent} from '../dialog/slideshow-settings-dialog.component';
import {AlbumFilesListLoadingComponent} from '../loading/album-files/album-files-list-loading.component';
import {PatientNestedItemsInfiniteScrollComponent} from '../patients/patient-nested-items-infinite-scroll.component';
import {SlideShowComponent} from '../slideshow/slideshow.component';
import {AlbumFileCardComponent} from './album-file-card.component';

const IMAGE_PADDING = 50;
const LIGHTBOX_Z_INDEX = 9999;
const LIGHTBOX_VIDEO_Z_INDEX = LIGHTBOX_Z_INDEX + 1;

interface AlbumFilesListComponentProps {
  items?: AlbumFile[];
  hasMore: boolean;
  loadMore: () => Promise<void>;
  loading?: boolean;
  EmptyScreen?: React.ReactNode;
  onMarkFavorite?: (id: AlbumFile['id'], value: boolean) => void;
}

function AlbumFilesListComponentBase({
  items = [],
  loadMore,
  hasMore,
  loading,
  EmptyScreen,
  onMarkFavorite,
}: AlbumFilesListComponentProps) {
  const [indexToShow, setIndexToShow] = useState<number | undefined>();
  const [indexToShowSlideshow, setIndexToShowSlideshow] = useState<number | undefined>();
  const [durations, setDurations] = useState<Record<AlbumFile['id'], number>>({});
  const {lightbox} = useStyles();

  useEffect(() => {
    if (indexToShowSlideshow !== undefined && indexToShow !== undefined) {
      setIndexToShow(undefined);
    }
  }, [indexToShowSlideshow, indexToShow]);

  const {value: slideshowSettings, onChange: onChangeSlideshowSettings} = useSlideshowSettings();
  const [openSlideshowSettings, setOpenSettings] = useState(false);

  const onClickSlideshowSettings = useCallback(() => {
    setOpenSettings(true);
  }, []);

  const onCloseSlideshowSettings = useCallback(() => {
    setOpenSettings(false);
  }, []);

  const onCloseSlideshow = useCallback(() => {
    setIndexToShowSlideshow(undefined);
  }, []);

  const onConfirmSlideshowSettings = useCallback(
    (slideshowSettings: SlideshowSettings) => {
      setOpenSettings(false);
      onChangeSlideshowSettings(slideshowSettings);
    },
    [onChangeSlideshowSettings]
  );

  const onClickOpenStories = useCallback(() => {
    setIndexToShowSlideshow(0);
  }, [setIndexToShowSlideshow]);

  const onAllStoriesEnd = useCallback(() => {
    if (slideshowSettings.loop) {
      return setIndexToShowSlideshow(0);
    }
    setIndexToShowSlideshow(undefined);
  }, [setIndexToShowSlideshow, slideshowSettings.loop]);

  const onLoadedMetadataForVideo = useCallback(
    (id: AlbumFile['id'], event: React.ChangeEvent<HTMLVideoElement>) => {
      if (event.target?.duration) {
        setDurations({...durations, [id]: event.target.duration * 1000});
      }
    },
    [durations]
  );

  const prevAssetToShow = useMemo(() => {
    return indexToShow !== undefined ? getPreviousIndex(indexToShow, items.length) : undefined;
  }, [indexToShow, items.length]);

  const nextAssetToShow = useMemo(() => {
    return indexToShow !== undefined ? getNextIndex(indexToShow, items.length) : undefined;
  }, [indexToShow, items.length]);

  if (loading === false && !items?.length && EmptyScreen) {
    return <>{EmptyScreen}</>;
  }

  return (
    <>
      {(loading !== false || !!items.length) && (
        <Stack direction="row" spacing={1} sx={{marginTop: '20px', marginBottom: '20px'}}>
          <SlideshowButtonComponent
            onClick={onClickOpenStories}
            variant="contained"
            disabled={!!loading}
            sx={theme => ({
              ...SLIDESHOW_BUTTON_STYLE,
              height: 48,
              [theme.breakpoints.down('sm')]: {
                width: '85%',
              },
            })}
          />
          <IconButton
            onClick={onClickSlideshowSettings}
            disabled={!!loading}
            sx={{p: 0.5, fontSize: '30px', color: DEEP_SPACE_SPARKLE_COLOR}}
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      )}

      {loading === false ? (
        <PatientNestedItemsInfiniteScrollComponent dataLength={items.length} hasMore={hasMore} loadMore={loadMore}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
            {items.map((item, index) => (
              <Grid key={item.id || index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <AlbumFileCardComponent
                  {...item}
                  onClick={() => setIndexToShow(index)}
                  onLoadedMetadata={event => onLoadedMetadataForVideo(item.id, event)}
                  onMarkFavorite={onMarkFavorite ? value => onMarkFavorite(item.id, value) : undefined}
                  hideFavorite={!onMarkFavorite}
                />
              </Grid>
            ))}
          </Grid>
        </PatientNestedItemsInfiniteScrollComponent>
      ) : (
        <AlbumFilesListLoadingComponent />
      )}
      {indexToShow !== undefined && (
        <Lightbox
          reactModalStyle={{overlay: {zIndex: LIGHTBOX_Z_INDEX}}}
          wrapperClassName={lightbox}
          prevSrc={prevAssetToShow !== undefined ? items[prevAssetToShow].url : undefined}
          imagePadding={IMAGE_PADDING}
          mainSrc={items[indexToShow].url || EMPTY_IMAGE}
          nextSrc={nextAssetToShow !== undefined ? items[nextAssetToShow].url : undefined}
          imageTitle={
            <Typography
              variant={'subtitle1'}
              color={'#FFFFFF'}
              position={'absolute'}
              left={'50%'}
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {
                  mt: 3.5,
                },
              })}
            >
              {`${indexToShow + 1}/${items.length}`}
            </Typography>
          }
          imageCaption={
            <Typography variant={'body1'} color={'#FFFFFF'}>
              {items[indexToShow].description}
            </Typography>
          }
          onMovePrevRequest={() => {
            setIndexToShow(prevAssetToShow);
          }}
          onMoveNextRequest={() => {
            setIndexToShow(nextAssetToShow);
          }}
          onCloseRequest={() => {
            setIndexToShow(undefined);
          }}
          imageLoadErrorMessage={
            isVideoUrl(items[indexToShow].url || EMPTY_IMAGE) ? (
              <video
                src={items[indexToShow].url}
                controls
                style={{
                  zIndex: LIGHTBOX_VIDEO_Z_INDEX,
                  position: 'absolute',
                  width: '90%',
                  maxHeight: '80%',
                  top: 0,
                  bottom: 0,
                  margin: 'auto 0',
                }}
              />
            ) : undefined
          }
          toolbarButtons={[
            <SlideshowButtonComponent
              key={'slideshow-play'}
              onClick={() => setIndexToShowSlideshow(indexToShow)}
              variant="outlined"
              sx={{
                height: 35,
                marginBottom: 0.75,
                color: WHITE_COLOR,
                opacity: 0.7,
                border: `1px solid ${WHITE_COLOR}`,
                '&:hover': {
                  opacity: 1,
                  border: `1px solid ${WHITE_COLOR}`,
                },
              }}
            />,
          ]}
        />
      )}

      <SlideShowComponent
        open={indexToShowSlideshow !== undefined}
        defaultInterval={slideshowSettings.interval}
        loop={slideshowSettings.loop}
        stories={items.map(item => ({
          ...item,
          duration: durations[item.id],
        }))}
        currentIndex={indexToShowSlideshow}
        onAllStoriesEnd={onAllStoriesEnd}
        onClose={onCloseSlideshow}
      />
      <SlideshowSettingsDialogComponent
        isOpen={openSlideshowSettings}
        onCancel={onCloseSlideshowSettings}
        onClose={onCloseSlideshowSettings}
        onConfirm={onConfirmSlideshowSettings}
        defaultSettings={slideshowSettings}
      />
    </>
  );
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    lightbox: {
      backgroundColor: BLACK_COLOR,
      '& .ril-caption': {
        bottom: Math.floor(IMAGE_PADDING / 2) - 10,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  })
);

export const AlbumFilesListComponent = memo(AlbumFilesListComponentBase);
