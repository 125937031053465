import Grid from '@mui/material/Grid';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

import {BackButtonComponent} from '../../components/buttons/back-button.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {PROFILE_ROUTE_ACCESSES} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {InvitationForm} from '../../forms/invitation.form';
import {useAccessesContacts} from '../../hooks/accesses/use-accesses-contacts';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useFormActions} from '../../hooks/use-form-actions';
import {useStores} from '../../hooks/use-stores';
import {Access} from '../../interfaces/entities/access.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';

function InvitationsNewToPatient({profileId}: ProfilePageProps) {
  const {t} = useTranslation();
  const {invitationsStore: store} = useStores();
  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const [loading, setLoading] = useState(false);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_ACCESSES.path}`;

  const {onCancel, onConfirm} = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Access>) => {
      setLoading(true);
      await store.createByPatientId(profileId, data);
      setLoading(false);

      onConfirm();
    },
    [onConfirm, profileId, setLoading, store.createByPatientId]
  );

  const {items: accessesContacts} = useAccessesContacts(profileId);

  const isEmailUsed = useCallback(
    (value: string) => {
      return accessesContacts.some(({email}) => email === value);
    },
    [accessesContacts]
  );

  const isPhoneUsed = useCallback(
    (value: string) => {
      return accessesContacts.some(({phone}) => {
        if (!phone) {
          return false;
        }

        const formattedPhone = phone.replace(/\s+/g, '').replace(/^\+/, '');
        const formattedValue = value.replace(/\s+/g, '').replace(/^\+/, '');

        return formattedPhone === formattedValue;
      });
    },
    [accessesContacts]
  );

  if (!isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>{t('web-app-profile-invitations.page-title')}</title>
      </Helmet>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <InvitationForm onSave={onSave} onCancel={onCancel} isEmailUsed={isEmailUsed} isPhoneUsed={isPhoneUsed} />
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={loading} />
    </>
  );
}

export const InvitationsNewToPatientContainer = observer(InvitationsNewToPatient);
