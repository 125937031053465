import ChatIcon from '@mui/icons-material/Chat';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {EmptyScreenButtonComponent} from '../buttons/empty-screen-button.component';

interface EmptyListPatientsProps {
  onClick: () => void;
}

const BOX_STYLE = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  background: '#FFFFFF',
};

function EmptyListPatients({onClick}: EmptyListPatientsProps) {
  const {t} = useTranslation();

  return (
    <Grid
      direction="column"
      container
      alignItems="center"
      sx={theme => ({
        [theme.breakpoints.up('sm')]: {
          pt: 6,
        },
      })}
    >
      <Typography
        variant={'h6'}
        pb={4}
        textAlign="center"
        sx={theme => ({
          [theme.breakpoints.up('sm')]: {
            fontSize: '34px',
          },
        })}
      >
        {t('memoria-profile-list-empty.header')}
      </Typography>
      <Typography textAlign="center" maxWidth={700} variant={'body1'} mb={6}>
        {t('memoria-profile-list-empty.body')}
      </Typography>
      <Box
        sx={theme => ({
          [theme.breakpoints.down('sm')]: {
            ...BOX_STYLE,
          },
        })}
      >
        <EmptyScreenButtonComponent
          startIcon={<ChatIcon sx={{pl: '2px'}} />}
          onClick={onClick}
          variant="contained"
          color="secondary"
        >
          {t('memoria-profile-list-empty-contact-support.button')}
        </EmptyScreenButtonComponent>
      </Box>
    </Grid>
  );
}

export const EmptyListPatientsComponent = memo(EmptyListPatients);
