import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import Collapse from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Patient} from '../../interfaces/entities/patient.interface';
import {ErrorWrapperComponent} from '../error-wrapper.component';
import {CommonGuestbookPostPatientsSelectListComponent} from '../guestbook/common-guestbook-post-patients-select-list.component';
import {CommonGuestbookPostTitleSelectedPatientsComponent} from '../guestbook/common-guestbook-post-title-selected-patients.component';
import {BackdropLoadingComponent} from '../loading/backdrop-loading.component';
import {DialogComponent} from './dialog.component';

export interface ConfirmCommonGuestbookPostDialogProps {
  isOpen: boolean;
  onConfirm: (ids: string[]) => void | Promise<void>;
  onClose: () => void;
  loadingConfirm?: boolean;
  patients?: Patient[];
  containsInfo?: boolean | null;
  onChangeContainsInfo?: (value: boolean | null) => void;
}

const YES_VALUE = 'yes';
const NO_VALUE = 'no';

function ConfirmCommonGuestbookPostDialog({
  onClose,
  onConfirm,
  isOpen,
  loadingConfirm,
  patients = [],
  containsInfo,
  onChangeContainsInfo,
}: ConfirmCommonGuestbookPostDialogProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selected, setSelected] = useState(patients.map(({id}) => id));
  const [containsInfoError, setContainsInfoError] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setSelected(patients.map(({id}) => id));
    }
  }, [isOpen]);

  const handleSelect = useCallback(
    (ids: string[]) => {
      setSelected([...selected, ...ids]);
    },
    [selected]
  );

  const handleDeselect = useCallback(
    (ids: string[]) => {
      setSelected(selected.filter(id => !ids.includes(id)));
    },
    [selected]
  );

  const handleSubmitClick = useCallback(() => {
    if (containsInfo === null) {
      return setContainsInfoError(true);
    }

    if (onConfirm) {
      onConfirm(selected);
    }
  }, [onConfirm, selected, containsInfo]);

  const handleChangeContainsInfo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = null;
      if (event.target.value === YES_VALUE) {
        value = true;
      } else if (event.target.value === NO_VALUE) {
        value = false;
      }

      if (onChangeContainsInfo) {
        onChangeContainsInfo(value);
      }
      if (value !== null) {
        setContainsInfoError(false);
      }
    },
    [onChangeContainsInfo]
  );

  const valueContainsInfo = useMemo(() => {
    if (containsInfo) {
      return YES_VALUE;
    }
    if (containsInfo === false) {
      return NO_VALUE;
    }
    return null;
  }, [containsInfo]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={!loadingConfirm ? onClose : undefined}
      title={
        <Typography textAlign={'center'} fontWeight={500} variant="body1">
          {t('common-guestbook-post-confirm-patients.title')}
        </Typography>
      }
      fullScreen={fullScreen}
      maxWidth={'lg'}
      description={
        <>
          <Typography variant="body1">{t('common-guestbook-post-confirm-dialog.description')}</Typography>
          <ErrorWrapperComponent error={containsInfoError}>
            {containsInfoError && (
              <Typography variant="body2" color={'error'} mb={-1} pt={0.5}>
                {t('common-guestbook-post-confirm-dialog-select-yes-no.validation-error', {
                  yes: t('general-yes.button').toUpperCase(),
                  no: t('general-no.button').toUpperCase(),
                })}
              </Typography>
            )}
          </ErrorWrapperComponent>
          <FormControl disabled={loadingConfirm}>
            <RadioGroup
              name="contains-information"
              value={valueContainsInfo}
              onChange={handleChangeContainsInfo}
              row
              sx={{
                textTransform: 'uppercase',
              }}
            >
              <FormControlLabel value={YES_VALUE} control={<Radio />} label={'' + t('general-yes.button')} />
              <FormControlLabel value={NO_VALUE} control={<Radio />} label={'' + t('general-no.button')} />
            </RadioGroup>
          </FormControl>
          <Box sx={{pt: !containsInfo ? 0 : 1, display: 'flex'}}>
            <Collapse in={!!containsInfo} timeout={{enter: 600, exit: 0}}>
              <Box height={!containsInfo ? 20 : '100%'}>
                <Typography variant="h6" color="rgba(0, 0, 0, 0.87)">
                  {t('common-guestbook-post-confirm-dialog-consent.title')}
                </Typography>
                <Typography variant="body1" pt={2} color="rgba(0, 0, 0, 0.87)">
                  {t('common-guestbook-post-confirm-dialog-consent.description')}
                </Typography>
              </Box>
            </Collapse>
          </Box>
          <Box position={'relative'}>
            {patients.length > 0 && (
              <CommonGuestbookPostTitleSelectedPatientsComponent count={selected.length} mb={-0.5} />
            )}
            <CommonGuestbookPostPatientsSelectListComponent
              selected={selected}
              patients={patients}
              onSelect={handleSelect}
              onDeselect={handleDeselect}
            />
            <BackdropLoadingComponent
              open={!!loadingConfirm}
              sx={{
                position: 'absolute',
              }}
            />
          </Box>
        </>
      }
    >
      <Grid
        sx={{
          py: 1.25,
          px: 2,
        }}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          <LoadingButton
            onClick={handleSubmitClick}
            disableElevation
            variant="contained"
            disabled={!selected.length}
            fullWidth
            loadingPosition="end"
            loading={loadingConfirm}
          >
            {t('common-guestbook-post-submit.button')}
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <MUIButton onClick={onClose} disableElevation disabled={loadingConfirm} fullWidth>
            {t('general-cancel.button')}
          </MUIButton>
        </Grid>
      </Grid>
    </DialogComponent>
  );
}

export const ConfirmCommonGuestbookPostDialogComponent = memo(ConfirmCommonGuestbookPostDialog);
