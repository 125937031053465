import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {BackButtonComponent} from '../../components/buttons/back-button.component';
import {SaveAndCancelButtonsComponent} from '../../components/buttons/save-and-cancel-buttons.component';
import {ConfirmLeaveDialogComponent} from '../../components/dialog/confirm-leave-dialog.component';
import {AlbumsOneEditLoadingComponent} from '../../components/loading/albums/albums-one-edit-loading.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {PROFILE_ROUTE_ALBUMS} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {AlbumForm} from '../../forms/album.form';
import {useAlbum} from '../../hooks/albums/use-album';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useFormActions} from '../../hooks/use-form-actions';
import {Album} from '../../interfaces/entities/album.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';
import {AlbumFilesListEditContainer, AlbumFilesListEditRef} from '../album-files/album-files-list-edit.container';

function AlbumsOneEdit({profileId}: ProfilePageProps) {
  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const {t} = useTranslation();

  const {item: defaultItem, fetchLoading, update, updateLoading} = useAlbum(profileId);

  const [item, setItem] = useState(defaultItem);
  const [blockLeave, setBlockLeave] = useState(true);

  useEffect(() => {
    setItem(defaultItem);
  }, [defaultItem]);

  const ref = useRef<AlbumFilesListEditRef>(null);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_ALBUMS.path}/${item?.id}`;

  const {onCancel, onConfirm} = useFormActions(backPath);

  const onSave = useCallback(async () => {
    if (item) {
      await update(
        item,
        ref.current?.save.bind(ref.current, image =>
          update({
            ...item,
            image,
          })
        )
      );
    }
    setBlockLeave(false);
    setTimeout(() => {
      onConfirm();
      setBlockLeave(true);
    }, 500);
  }, [onConfirm, update, item]);

  const onChangeAlbumCover = useCallback(
    async (image: string) => {
      setItem({...item, image} as Album);
    },
    [setItem, item]
  );

  if (fetchLoading) {
    return <AlbumsOneEditLoadingComponent />;
  }

  if (!item || !isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Box>
          <BackButtonComponent path={backPath} />
        </Box>
        <Box>
          <AlbumForm {...item} onChange={setItem} />
        </Box>
        <Box>
          <AlbumFilesListEditContainer
            ref={ref}
            profileId={profileId}
            albumId={item.id}
            albumImage={item.image}
            onChangeAlbumCover={onChangeAlbumCover}
          />
        </Box>
        <Box>
          <SaveAndCancelButtonsComponent
            onClickCancel={onCancel}
            onClickSave={onSave}
            disabledSave={!item.name}
            sx={theme => ({
              pt: 1,
              float: 'right',
              width: '100%',
              mb: 2,
              [theme.breakpoints.up('md')]: {
                width: '45%',
                mb: 0,
              },
              [theme.breakpoints.up('lg')]: {
                width: '35%',
              },
            })}
          />
        </Box>
      </Stack>

      <BackdropLoadingComponent open={!!updateLoading} />

      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-album-warning.body')}
        stayButtonText={t('modal-leave-album-warning-stay.button')}
        leaveButtonText={t('modal-leave-album-warning-leave.button')}
      />
    </>
  );
}

export const AlbumsOneEditContainer = observer(AlbumsOneEdit);
