import AvatarGroup, {AvatarGroupProps} from '@mui/material/AvatarGroup';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {GuestbookLike} from '../../interfaces/entities/guestbook-like.interface';
import {getEmails} from '../../utils/get-emails';
import getName from '../../utils/get-name';
import {getPhones} from '../../utils/get-phones';
import {AvatarComponent} from '../avatar.component';
import {AvatarWithLightboxComponent} from '../avatar-with-lightbox.component';
import {DialogComponent} from '../dialog/dialog.component';
export interface GuestbookFileCardProps extends AvatarGroupProps {
  items: ReadonlyArray<GuestbookLike>;
  size?: 'small' | 'medium' | 'large';
}

const DEFAULT_MAX = 3;
const SX = {
  small: {
    width: 20,
    height: 20,
    fontSize: '10px',
  },
  medium: {
    width: 25,
    height: 25,
    fontSize: '12px',
  },
  large: {
    width: 30,
    height: 30,
    fontSize: '15px',
  },
};

function GuestbookLikes({items, max = DEFAULT_MAX, size = 'medium'}: GuestbookFileCardProps) {
  const {t} = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const onClick = useCallback(() => {
    setShowAll(true);
  }, [setShowAll]);

  const onClose = useCallback(() => {
    setShowAll(false);
  }, []);

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant={size === 'small' ? 'caption' : 'body2'}>{items.length}</Typography>
        </Grid>
        <Grid item>
          <AvatarGroup
            max={max}
            onClick={onClick}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
              '& .MuiAvatarGroup-avatar': SX[size],
            }}
          >
            {items.map(({id, user}) => (
              <AvatarComponent key={id} name={getName(user?.firstName, user?.lastName)} src={user?.image} />
            ))}
          </AvatarGroup>
        </Grid>
      </Grid>

      <DialogComponent isOpen={showAll} onClose={onClose} title={`${t('guestbook-like-list-modal.header')}:`}>
        <List sx={{width: '100%'}}>
          {items.map(({id, user}) => {
            const name = getName(user?.firstName, user?.lastName);
            return (
              <ListItem key={id}>
                <ListItemAvatar>
                  <AvatarWithLightboxComponent key={id} name={name} src={user?.image} />
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={[
                    user.accesses?.[0]?.role && t(`guestbook-post-role-description-${user.accesses[0].role}.label`),
                    user.emails?.[0] ? `<${getEmails(user.emails)}>` : '',
                    user.phones?.[0] ? `<${getPhones(user.phones)}>` : '',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogComponent>
    </>
  );
}

export const GuestbookLikesComponent = memo(GuestbookLikes);
