import {useCallback, useState} from 'react';

export function useDeleteDialog(): {
  onClick: () => void;
  onClose: () => void;
  open: boolean;
} {
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return {open, onClick, onClose};
}
