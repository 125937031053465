// import { useCallback, useEffect, useState } from 'react';
import {useCallback, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';

import {Patient} from '../../interfaces/entities/patient.interface';
import {Relative} from '../../interfaces/entities/relative.interface';
import {usePatientNestedItem} from '../patients/use-patient-nested-item';
import {useStores} from '../use-stores';

export const useRelative = (
  patientId: Patient['id']
): {
  item: Relative | null;
  fetchLoading?: boolean;
  updateLoading?: boolean;
  update: (data: Partial<Relative>) => Promise<void>;
  updateWithPhoto: (data: Partial<Relative>, photo: File) => Promise<void>;
  remove: () => Promise<void>;
} => {
  const {params} = useRouteMatch<{relativeId: string}>();

  const {relativesStore: store} = useStores();

  const {item, loading} = usePatientNestedItem(store, patientId, params.relativeId);

  const [updateLoading, setUpdateLoading] = useState(false);

  const update = useCallback(
    async (data: Partial<Relative>) => {
      setUpdateLoading(true);
      await store.updateByPatientId(patientId, params.relativeId, data);
      setUpdateLoading(false);
    },
    [store.updateByPatientId, setUpdateLoading, patientId, params.relativeId]
  );

  const updateWithPhoto = useCallback(
    async (data: Partial<Relative>, photo: File) => {
      setUpdateLoading(true);
      await store.updateByPatientIdWithPhoto(patientId, params.relativeId, data, photo);
      setUpdateLoading(false);
    },
    [store.updateByPatientIdWithPhoto, setUpdateLoading, patientId, params.relativeId]
  );

  const remove = useCallback(async () => {
    await store.deleteByPatientId(patientId, params.relativeId);
    setUpdateLoading(false);
  }, [store.deleteByPatientId, patientId, params.relativeId]);

  return {
    item,
    fetchLoading: loading,
    updateLoading,
    update,
    updateWithPhoto,
    remove,
  };
};
