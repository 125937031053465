import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {memo, ReactElement, useMemo} from 'react';

import {AlbumFileCardLoadingComponent} from './album-file-card-loading.component';

const ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function AlbumFilesListLoading(): ReactElement {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));

  const count = useMemo(() => {
    if (xs) {
      return 2;
    }

    if (sm) {
      return 4;
    }

    if (md) {
      return 6;
    }

    if (lg) {
      return 8;
    }

    return 12;
  }, [xs, sm, md, lg]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
      {ARRAY.slice(0, count).map((_item, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
          <AlbumFileCardLoadingComponent />
        </Grid>
      ))}
    </Grid>
  );
}

export const AlbumFilesListLoadingComponent = memo(AlbumFilesListLoading);
