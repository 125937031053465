import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import isEqual from 'lodash/isEqual';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {GuestbookFile} from '../../interfaces/entities/guestbook-file.interface';
import {GuestbookPost} from '../../interfaces/entities/guestbook-post.interface';
import {GuestbookPreviewFile} from '../../interfaces/guestbook-preview-file.interface';
import {LIGHT_GRAY_COLOR} from '../../theme';
import {CancelButtonComponent} from '../buttons/cancel-button.component';
import {GuestbookUploadButtonComponent} from '../buttons/guestbook-upload-button.component';
import {ConfirmLeaveDialogComponent} from '../dialog/confirm-leave-dialog.component';
import {GuestbookFileCardComponent} from './guestbook-file-card.component';
import {GuestbookFilesLightboxComponent} from './guestbook-file-lightbox.component';

interface GuestbookPostInputProps extends Partial<GuestbookPost> {
  onSubmit?: (data: Partial<GuestbookPost>) => void;
  onCancel?: () => void;
  blockEmptyLeave?: boolean;
}

const BUTTON_HEIGHT = '36px';

function GuestbookPostInput({
  onSubmit,
  onCancel,
  guestbookFiles: defaultFiles,
  message: defaultMessage,
  id,
  blockEmptyLeave = true,
}: GuestbookPostInputProps) {
  const {t} = useTranslation();

  const [messageText, setMessageText] = useState(defaultMessage || '');
  const [files, setFiles] = useState<ReadonlyArray<GuestbookPreviewFile>>(defaultFiles || []);
  const [indexToShow, setIndexToShow] = useState<number | null>(null);
  const [showConfirmLeaveDialog, setShowConfirmLeaveDialog] = useState<boolean>(false);

  const onChangeMessageText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMessageText(event.target.value);
    },
    [setMessageText]
  );

  const onClickLeave = useCallback(() => {
    setShowConfirmLeaveDialog(false);
    setMessageText('');
    setFiles([]);
    if (onCancel) {
      return onCancel();
    }
  }, [setMessageText, onCancel]);

  const onClickStay = useCallback(() => {
    setShowConfirmLeaveDialog(false);
  }, []);

  const onClickSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit({
        message: messageText,
        guestbookFiles: files as Array<GuestbookFile>,
      });
    }
    setMessageText('');
    setFiles([]);
  }, [messageText, files, setMessageText, setFiles, onSubmit]);

  const onSelectFiles = useCallback(
    (arr: ReadonlyArray<GuestbookPreviewFile>) => {
      setFiles([...files, ...arr]);
    },

    [files, setFiles]
  );

  const onClickFile = useCallback(
    (index: number | string) => {
      setIndexToShow(index as number);
    },
    [setIndexToShow]
  );

  const onClickRemoveFile = useCallback(
    (id: number | string) => {
      setFiles(files.filter((_file, index) => id !== index));
    },
    [files, setFiles]
  );

  const onCloseLightBox = useCallback(() => {
    setIndexToShow(null);
  }, [setIndexToShow]);

  const isPostDisabled = useMemo(() => {
    return !messageText.trim() && !files.length;
  }, [messageText, files.length]);

  const blockLeave = useMemo(() => {
    if (!messageText && !files.length) {
      return blockEmptyLeave;
    }

    return !(defaultMessage === messageText && isEqual(defaultFiles || [], files));
  }, [blockEmptyLeave, defaultMessage, messageText, files, defaultFiles]);

  const showCancelButton = useMemo(() => {
    return !(!defaultMessage && !messageText && !defaultFiles?.length && !files?.length);
  }, [defaultMessage, messageText, files, defaultFiles]);

  const handleCancelPost = useCallback(() => {
    setShowConfirmLeaveDialog(true);
  }, []);

  return (
    <>
      {indexToShow !== null && (
        <GuestbookFilesLightboxComponent files={files} defaultIndex={indexToShow} onClose={onCloseLightBox} />
      )}
      <TextField
        variant="filled"
        multiline
        value={messageText}
        fullWidth
        onChange={onChangeMessageText}
        placeholder={t('guestbook-new-post.label')}
        id={!id ? 'guestbook-new-input' : `guestbook-edit-${id}-input`}
        label={t(id ? 'guestbook-like-edit.tooltip' : 'guestbook-new-post.button')}
      />
      {files.length > 0 && (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} mt={1} mb={2}>
          {files.map((file, index) => (
            <Grid item key={index} xs={6} md={4} lg={3} xl={2}>
              <GuestbookFileCardComponent
                key={index}
                {...file}
                id={index}
                onClose={onClickRemoveFile}
                onClick={onClickFile}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        marginTop={1}
        sx={theme => ({
          marginTop: '4px !important',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
          },
        })}
      >
        <Box
          sx={theme => ({
            opacity: showCancelButton ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            [theme.breakpoints.down('sm')]: {
              order: 1,

              width: '100%',
              mt: 1,
            },
          })}
        >
          {showCancelButton && (
            <CancelButtonComponent
              variant="text"
              onClick={handleCancelPost}
              sx={theme => ({
                height: BUTTON_HEIGHT,
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  border: `1px solid ${LIGHT_GRAY_COLOR}`,
                },
              })}
            />
          )}
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              ml: '0 !important',
            },
          })}
        >
          <Box
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                width: '50%',
                ml: '0 !important',
              },
            })}
          >
            <GuestbookUploadButtonComponent
              fullWidth
              sx={{
                height: BUTTON_HEIGHT,
              }}
              onSelectFiles={onSelectFiles}
            />
          </Box>

          <Button
            variant="contained"
            disabled={isPostDisabled}
            onClick={onClickSubmit}
            sx={theme => ({
              height: BUTTON_HEIGHT,
              [theme.breakpoints.down('sm')]: {
                width: '50%',
              },
            })}
          >
            {id ? t('general-save.button') : t('guestbook-send-post.button')}
          </Button>
        </Stack>
      </Stack>
      <ConfirmLeaveDialogComponent
        open={showConfirmLeaveDialog}
        description={t('modal-leave-post-warning.body')}
        stayButtonText={t('modal-leave-post-warning-continue.button')}
        leaveButtonText={t('modal-leave-post-warning-discard.button')}
        onClickLeave={onClickLeave}
        onClickStay={onClickStay}
        blockLeave={blockLeave}
      />
    </>
  );
}

export const GuestbookPostInputComponent = memo(GuestbookPostInput);
