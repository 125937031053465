import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CreateIcon from '@mui/icons-material/Create';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ImageIcon from '@mui/icons-material/Image';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

import {PatientNavigationItem} from '../interfaces/patient-navigation-item';
import {PATIENT_PAGES_TITLES} from './patient-pages-titles';

export const PATIENT_NAVIGATION_ITEM_PERSONAL: PatientNavigationItem = {
  to: '',
  title: PATIENT_PAGES_TITLES.PERSONAL,
  label: 'minmemoria-profile-menu-front-page',
  exact: true,
  Icon: AccountCircleOutlinedIcon,
  ActiveIcon: AccountCircleIcon,
};

export const PATIENT_NAVIGATION_ITEM_FAMILY_INFO: PatientNavigationItem = {
  to: 'relatives',
  title: PATIENT_PAGES_TITLES.FAMILY,
  label: 'minmemoria-profile-menu-relatives',
  Icon: PeopleOutlinedIcon,
  ActiveIcon: PeopleIcon,
};

export const PATIENT_NAVIGATION_ITEM_LIFE_STORY: PatientNavigationItem = {
  to: 'stories',
  title: PATIENT_PAGES_TITLES.STORY,
  label: 'minmemoria-profile-menu-stories',
  Icon: CreateOutlinedIcon,
  ActiveIcon: CreateIcon,
};

export const PATIENT_NAVIGATION_ITEM_ALBUM_FILES: PatientNavigationItem = {
  to: 'albums',
  title: PATIENT_PAGES_TITLES.ALBUM,
  label: 'minmemoria-profile-menu-albums',
  Icon: ImageOutlinedIcon,
  ActiveIcon: ImageIcon,
};

export const PATIENT_NAVIGATION_ITEM_GUESTBOOK: PatientNavigationItem = {
  to: 'messages',
  title: PATIENT_PAGES_TITLES.GUESTBOOK,
  label: 'minmemoria-side-nav-guestbook.tab',
  Icon: ChatOutlinedIcon,
  ActiveIcon: ChatIcon,
};

export const PATIENT_NAVIGATION_ITEM_ACCESS: PatientNavigationItem = {
  to: 'accesses',
  title: PATIENT_PAGES_TITLES.ACCESSES,
  label: 'minmemoria-profile-menu-access',
  Icon: LockOutlinedIcon,
  ActiveIcon: LockIcon,
};

export const PATIENT_NAVIGATION_ITEM_INVITATIONS = {
  to: 'invitations',
};

const navigationItems: ReadonlyArray<PatientNavigationItem> = [
  PATIENT_NAVIGATION_ITEM_PERSONAL,
  PATIENT_NAVIGATION_ITEM_GUESTBOOK,
  PATIENT_NAVIGATION_ITEM_FAMILY_INFO,
  PATIENT_NAVIGATION_ITEM_ALBUM_FILES,
  PATIENT_NAVIGATION_ITEM_LIFE_STORY,
  PATIENT_NAVIGATION_ITEM_ACCESS,
];

export default navigationItems;
