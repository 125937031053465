// import AddIcon from '@mui/icons-material/Add';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {observer} from 'mobx-react-lite';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';

import {FailedLoadingDataComponent} from '../../components/failed-loading-data.component';
import {GuestbookPostInputComponent} from '../../components/guestbook/guestbook-post-input.component';
import {GuestbookLoadingComponent} from '../../components/loading/guestbook/guestbook-loading.component';
import {PatientNestedItemsInfiniteScrollComponent} from '../../components/patients/patient-nested-items-infinite-scroll.component';
import {usePatientNestedItems} from '../../hooks/patients/use-patient-nested-items';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useStores} from '../../hooks/use-stores';
import {Access} from '../../interfaces/entities/access.interface';
import {GuestbookPost} from '../../interfaces/entities/guestbook-post.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {User} from '../../interfaces/user.interface';
import {DEEP_SPACE_SPARKLE_COLOR} from '../../theme';
import {GuestbookPostCardContainer} from './guestbook-post-card.container';

export const LIMIT = 10;
export const SCROLL_TO_SWITCH_BUTTON = 50;

const BUTTON_STYLE = {
  bgcolor: DEEP_SPACE_SPARKLE_COLOR,
  height: '48px',
  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '50px',
  '&:hover': {
    bgcolor: DEEP_SPACE_SPARKLE_COLOR,
  },
};

interface GuestbookContainerProps {
  profileId: Patient['id'];
}

function Guestbook({profileId}: GuestbookContainerProps): ReactElement {
  const {guestbookPostsStore: store, authStore} = useStores();

  const {items, loadMore, loading, hasMore, errorLoading, reload} = usePatientNestedItems(store, profileId, LIMIT);

  const [showUpButton, setShowUpButton] = useState<boolean>(false);

  const {role} = usePatientRole(profileId);

  const handleScroll = useCallback(() => {
    if (window.scrollY > SCROLL_TO_SWITCH_BUTTON && !showUpButton) {
      setShowUpButton(true);
    } else if (window.scrollY < SCROLL_TO_SWITCH_BUTTON && showUpButton) {
      setShowUpButton(false);
    }
  }, [showUpButton, setShowUpButton]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const onSubmitInput = useCallback(
    (data: Partial<GuestbookPost>) => {
      store.createByPatientId(profileId, {
        ...data,
        guestbookLikes: [],
      } as GuestbookPost);
    },
    [profileId]
  );

  const onClickUp = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  if (loading || !items) {
    return <GuestbookLoadingComponent count={2} />;
  }

  if (loading === false && errorLoading) {
    return <FailedLoadingDataComponent onClick={reload} />;
  }

  return (
    <>
      <Box position="relative">
        {showUpButton && (
          <Button
            onClick={onClickUp}
            variant="contained"
            color={'secondary'}
            sx={theme => ({
              ...BUTTON_STYLE,
              bgcolor: `rgba(69, 94, 100, 0.7)`,
              position: 'fixed',
              top: '110px',
              margin: 'auto',
              marginTop: 0,
              zIndex: theme.zIndex.appBar + 1,
              width: '64px',
              right: '0%',
              left: '0%',
            })}
          >
            <ArrowCircleUpIcon fontSize="large" />
          </Button>
        )}
        <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={5}>
          <Box display="block" sx={{width: '100%'}}>
            <GuestbookPostInputComponent onSubmit={onSubmitInput} blockEmptyLeave={false} />
          </Box>

          <Box width={'100%'}>
            <PatientNestedItemsInfiniteScrollComponent dataLength={items.length} hasMore={hasMore} loadMore={loadMore}>
              <Box width={'100%'}>
                {authStore.me &&
                  store.creating.map((item, index) => (
                    <GuestbookPostCardContainer
                      key={item.id || index}
                      {...item}
                      patientId={profileId}
                      user={{
                        ...(authStore.me as User),
                        accesses: [{role} as Access],
                      }}
                      guestbookLikes={[]}
                      replies={[]}
                      isLoading
                    />
                  ))}

                {items.map((item, index) => (
                  <GuestbookPostCardContainer
                    key={item.id || index}
                    {...(!store.updating[item.id] ? item : store.updating[item.id])}
                    patientId={profileId}
                    isLoading={!!(store.updating[item.id] || store.deleting.includes(item.id))}
                  />
                ))}
              </Box>
            </PatientNestedItemsInfiniteScrollComponent>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
export const GuestbookContainer = observer(Guestbook);
