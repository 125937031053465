import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, {memo} from 'react';

import {Language} from '../../interfaces/language.interface';

interface LocaleSwitcherOptionProps extends Language {
  useFullLabel?: boolean;
}

function LocaleSwitcherOption({useFullLabel, value, shortLabel, label}: LocaleSwitcherOptionProps) {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Box
          style={{
            backgroundImage: `url('/assets/locales/${value}.svg')`,
            backgroundSize: 'cover',
            width: '22px',
            height: '16px',
          }}
        />
      </Grid>
      <Grid item>{(useFullLabel ? label : shortLabel) || value}</Grid>
    </Grid>
  );
}

export const LocaleSwitcherOptionComponent = memo(LocaleSwitcherOption);
