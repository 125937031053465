import TextField from '@mui/material/TextField';
import React, {memo} from 'react';

import {FieldProps} from '../interfaces/field-props.interface';
import {FieldWrapperComponent} from './field-wrapper.component';

const FIELD_NAME_DEFAULT = 'email';
const FIELD_ID_DEFAULT = `${FIELD_NAME_DEFAULT}-id`;

interface EmailFieldProps extends FieldProps {
  id?: string;
  value: string;
}

function EmailField(props: EmailFieldProps) {
  const {id = FIELD_ID_DEFAULT, name = FIELD_NAME_DEFAULT, label, value, error, handleChange, handleBlur} = props;

  return (
    <FieldWrapperComponent {...props} name={name}>
      <TextField
        fullWidth
        variant="outlined"
        aria-describedby={error ? `${name}-error-id` : undefined}
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        label={label}
      />
    </FieldWrapperComponent>
  );
}

export const EmailFieldComponent = memo(EmailField);
