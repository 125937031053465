import Box from '@mui/material/Box';
import Button, {ButtonProps} from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import addSeconds from 'date-fns/addSeconds';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isAfter from 'date-fns/isAfter';
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface ButtonWithTimerProps extends ButtonProps {
  time: number; // seconds
  timerLabel?: string;
}

const STEP = 200; // ms

function ButtonWithTimer({time, onClick, timerLabel, ...props}: ButtonWithTimerProps) {
  const {t} = useTranslation();

  const [disabledTo, setDisabledTo] = useState<Date | null>(null);
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    if (disabledTo && isAfter(now, disabledTo)) {
      return setDisabledTo(null);
    }

    const timer = setTimeout(() => {
      setNow(new Date());
    }, STEP);

    return () => clearTimeout(timer);
  }, [now]);

  const progressValue = useMemo(() => {
    if (!disabledTo) {
      return 0;
    }

    const timeLeft = differenceInMilliseconds(disabledTo, now);
    return Math.floor(100 * (timeLeft / (time * 1000)));
  }, [disabledTo, time, now]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(event);
      }

      setNow(new Date());
      setDisabledTo(addSeconds(new Date(), time));
    },
    [onClick, time]
  );

  const disabled = useMemo(() => progressValue > 0, [progressValue]);

  return (
    <Box>
      <Button disableElevation {...props} onClick={handleClick} disabled={props.disabled || disabled} />
      {disabled && (
        <>
          <LinearProgress variant="determinate" value={progressValue} />
          {timerLabel && (
            <Typography variant="caption" color={'rgba(0, 0, 0, 0.6)'}>
              {t(timerLabel, {
                time: disabledTo ? differenceInSeconds(disabledTo, now) : 0,
              })}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

export const ButtonWithTimerComponent = memo(ButtonWithTimer);
