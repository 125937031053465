import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {memo} from 'react';

import {Patient} from '../../interfaces/entities/patient.interface';
import {CloseButtonComponent} from '../buttons/close-button.component';
import {IconCloseButtonComponent} from '../buttons/icon-close-button.component';
import {ProfileFullInfoComponent} from '../profiles/profile-full-info.component';
import {DialogComponent, DialogProps} from './dialog.component';

type ProfileInfoDialogComponentProps = Patient & Pick<DialogProps, 'isOpen' | 'onClose'>;

function ProfileInfoDialogComponentBase(props: ProfileInfoDialogComponentProps) {
  const {isOpen, onClose, ...profile} = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth={'lg'}
      description={<ProfileFullInfoComponent mobile={fullScreen} {...profile} />}
      CloseButton={!fullScreen ? <CloseButtonComponent /> : <IconCloseButtonComponent />}
    ></DialogComponent>
  );
}

export const ProfileInfoDialogComponent = memo(ProfileInfoDialogComponentBase);
