import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import React, {memo} from 'react';
import {TransitionGroup} from 'react-transition-group';

import {Patient} from '../../interfaces/entities/patient.interface';
import getName from '../../utils/get-name';
import {AvatarComponent} from '../avatar.component';
import {CommonGuestbookPostTitleSelectedPatientsComponent} from './common-guestbook-post-title-selected-patients.component';

export interface CommonGuestbookPostSelectedPatientsProps {
  patients: ReadonlyArray<Patient>;
  onDelete?: (id: Patient['id']) => void;
}

function CommonGuestbookPostSelectedPatients({patients, onDelete}: CommonGuestbookPostSelectedPatientsProps) {
  return (
    <Box>
      {patients.length > 0 && <CommonGuestbookPostTitleSelectedPatientsComponent count={patients.length} pb={0.25} />}
      <Stack component={TransitionGroup} direction="row" spacing={1}>
        {patients.map(({id, image, firstName, lastName}) => {
          const name = getName(firstName, lastName);
          return (
            <Fade key={id}>
              <Chip
                avatar={<AvatarComponent key={id} name={getName(firstName, lastName)} src={image || ''} />}
                label={name}
                variant="outlined"
                color="primary"
                onDelete={onDelete && (() => onDelete(id))}
              />
            </Fade>
          );
        })}
      </Stack>
    </Box>
  );
}

export const CommonGuestbookPostSelectedPatientsComponent = memo(CommonGuestbookPostSelectedPatients);
