import isSameDay from 'date-fns/isSameDay';

import {DraftPatient} from '../interfaces/entities/draft-patient.interface';

export function areDraftPatientsEqual(a: Partial<DraftPatient>, b: Partial<DraftPatient>): boolean {
  if (!a && !b) {
    return true;
  }

  if ((a && !b) || (!a && b)) {
    return false;
  }

  if (a.firstName != b.firstName) {
    return false;
  }

  if (a.lastName != b.lastName) {
    return false;
  }

  if (
    (!a.birthDate && b.birthDate) ||
    (a.birthDate && !b.birthDate) ||
    (a.birthDate && b.birthDate && !isSameDay(new Date(a.birthDate), new Date(b.birthDate)))
  ) {
    return false;
  }

  if (a.image != b.image) {
    return false;
  }

  if (a.birthPlace != b.birthPlace) {
    return false;
  }

  if (a.birthPlace != b.birthPlace) {
    return false;
  }

  if (a.description != b.description) {
    return false;
  }

  if (a.interests != b.interests) {
    return false;
  }

  if (a.habits != b.habits) {
    return false;
  }

  if (a.music != b.music) {
    return false;
  }

  if (a.relax != b.relax) {
    return false;
  }

  if (a.municipalityId != b.municipalityId) {
    return false;
  }

  if (a.institutionId != b.institutionId) {
    return false;
  }

  if (a.step !== b.step) {
    return false;
  }

  return true;
}
