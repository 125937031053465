import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DialogComponent} from './dialog.component';

export interface DeleteDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void | Promise<void>;
}

function LogOutDialog(props: DeleteDialogProps) {
  const {t} = useTranslation();
  const {onCancel, onConfirm, open} = props;

  const renderDialogActions = () => {
    return (
      <Box
        display="flex"
        px={3}
        py={2}
        sx={theme => ({
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
          },
        })}
      >
        <MUIButton
          onClick={() => onConfirm()}
          disableElevation
          variant="contained"
          sx={{
            minWidth: '150px',
          }}
        >
          {t('modal-logout-warning-confirm.button')}
        </MUIButton>
        <MUIButton
          sx={theme => ({
            minWidth: '80px',
            flexDirection: 'column',
            order: '-1',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
              order: '0',
              ml: 1,
            },
          })}
          onClick={onCancel}
          disableElevation
        >
          {t('modal-logout-warning-stay.button')}
        </MUIButton>
      </Box>
    );
  };

  return (
    <DialogComponent
      isOpen={open}
      onClose={onCancel}
      title={t('modal-logout-warning.header')}
      description={t('modal-logout-warning.body')}
    >
      {renderDialogActions()}
    </DialogComponent>
  );
}

export const LogOutDialogComponent = memo(LogOutDialog);
