export function findChildOptions(
  options: {id: string; name: string; parentIds?: string[]}[],
  parentIds: string[]
): {id: string; name: string; parentIds?: string[]}[] {
  return options.filter(option => {
    if (!option.parentIds) {
      return false;
    }

    return option.parentIds.some(parentId => parentIds.includes(parentId));
  });
}
