import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import React, {memo, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Patient} from '../../../interfaces/entities/patient.interface';
import {LoadingProps} from '../../../interfaces/loading-props.interface';
import {NavigationProps} from '../../../interfaces/navigation-props.interface';
import {PatientNavigationItem} from '../../../interfaces/patient-navigation-item';
import {PatientInfoLoadingComponent} from '../../loading/patients/patient-info-loading.component';
import {ListItemComponent} from './list-item.component';
import {PatientInfoComponent} from './patient-info.component';

interface DesktopPatientNavigationProps extends NavigationProps, LoadingProps {
  patient?: Patient;
  items?: ReadonlyArray<PatientNavigationItem>;
}

function DesktopPatientNavigation(props: DesktopPatientNavigationProps) {
  const {items = [], patient, loading} = props;

  const history = useHistory();

  const defaultIndex = items.findIndex(({to}) => to === window.location.pathname);
  const [value, setValue] = useState<number | undefined>(defaultIndex !== -1 ? defaultIndex : 0);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={theme => ({
        position: 'sticky',
        top: theme.spacing(1),
        marginLeft: -2,
        paddingRight: 3,
      })}
      PaperProps={{
        sx: () => ({
          position: 'inherit',
          border: '0',
        }),
      }}
    >
      {patient && !loading ? <PatientInfoComponent {...patient} /> : <PatientInfoLoadingComponent />}

      <List>
        {items.map((item, index) => (
          <ListItemComponent
            key={index}
            {...item}
            isActive={value === index && item.to === history.location.pathname}
            onClick={() => setValue(index)}
          />
        ))}
      </List>
    </Drawer>
  );
}

export const DesktopPatientNavigationComponent = memo(DesktopPatientNavigation);
