import {MouseEvent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {AUTH_REDIRECT_URL_QUERY} from '../utils/constants';
import {useStores} from './use-stores';

export function useLoginButton(): {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
} {
  const {authStore} = useStores();
  const {i18n} = useTranslation();

  const onClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const loginUrl = await authStore.getLoginUrl(i18n.language);
      if (loginUrl) {
        const url = new URL(loginUrl);
        url.searchParams.append(AUTH_REDIRECT_URL_QUERY, encodeURI(window.location.href));
        window.location.replace(url.toString());
      }
    },
    [authStore, i18n?.language]
  );

  return {
    onClick,
  };
}
