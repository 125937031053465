import {useCallback, useEffect, useMemo, useState} from 'react';

import {ALBUM_LIMIT} from '../../containers/albums/albums-one.container';
import {GuestbookFile} from '../../interfaces/entities/guestbook-file.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {useStores} from '../use-stores';

export function useGuestbookFiles(
  profileId: Patient['id'],
  limit = ALBUM_LIMIT,
  s?: string,
  sort = ['guestbookPost.createdAt,DESC', 'createdAt,ASC']
): {
  items: ReadonlyArray<GuestbookFile>;
  hasMore: boolean;
  loadMore: () => Promise<void>;
  loading?: boolean;
  total?: number;
  onClickFavorite: (id: GuestbookFile['id'], value: boolean) => void;
} {
  const [page, setPage] = useState(1);

  const {guestbookFilesStore: store} = useStores();

  useEffect(() => {
    store.fetchListByProfileId(profileId, 1, limit, undefined, s, sort);
  }, [store.fetchListByProfileId, profileId, limit, s, sort]);

  const items = useMemo(() => {
    const arr: GuestbookFile[] = [];
    for (let i = 1; i <= page; i++) {
      const fetchingKey = store.getFetchingKey(profileId, i, limit, undefined, s, sort);

      if (store.itemsByProfileId[fetchingKey]?.data?.data) {
        arr.push(...(store.itemsByProfileId[fetchingKey]?.data?.data as GuestbookFile[]));
      }
    }

    return arr;
  }, [store.itemsByProfileId, profileId, limit, page, s, sort]);

  const hasMore = useMemo(() => {
    const fetchingKey = store.getFetchingKey(profileId, 1, limit, undefined, s, sort);
    return (store.itemsByProfileId[fetchingKey]?.data?.pageCount || 0) > page;
  }, [store.itemsByProfileId, profileId, limit, sort, page]);

  const total = useMemo(() => {
    const fetchingKey = store.getFetchingKey(profileId, 1, limit, undefined, s, sort);

    return store.itemsByProfileId[fetchingKey]?.data?.total || 0;
  }, [store.itemsByProfileId, profileId, limit, s, sort]);

  const loading = useMemo(() => {
    const fetchingKey = store.getFetchingKey(profileId, page, limit, undefined, s, sort);

    return store.itemsByProfileId[fetchingKey]?.loading;
  }, [store.itemsByProfileId, profileId, page, limit, s, sort]);

  const onClickFavorite = useCallback(
    (id: GuestbookFile['id'], value: boolean) => {
      if (value) {
        return store.makeFavorite(profileId, id);
      }

      return store.removeFavorite(profileId, id);
    },
    [profileId]
  );

  const loadMore = useCallback(async () => {
    await store.fetchListByProfileId(profileId, page + 1, limit, undefined, s, sort);
    setPage(page + 1);
  }, [profileId, page, limit, s, sort]);

  return {
    items,
    hasMore,
    loadMore,
    loading,
    total,
    onClickFavorite,
  };
}
