import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import {SxProps, Theme} from '@mui/material/styles';
import React, {memo} from 'react';

import {ErrorWrapperComponent} from '../error-wrapper.component';
import {CancelButtonComponent} from './cancel-button.component';
import {SaveButtonComponent} from './save-button.component';

export interface SaveAndCancelButtonsProps {
  onClickCancel?: () => void;
  onClickSave?: () => void;
  disabledSave?: boolean;
  sx?: SxProps<Theme>;
  saveButtonTitle?: string;
  error?: string;
}

function SaveAndCancelButtons({
  onClickCancel,
  onClickSave,
  disabledSave,
  sx,
  saveButtonTitle,
  error,
}: SaveAndCancelButtonsProps) {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={1} sx={sx}>
      {onClickCancel && (
        <Grid item xs={6} pr={0.5}>
          <CancelButtonComponent fullWidth onClick={onClickCancel} />
        </Grid>
      )}
      {onClickSave && (
        <Grid item xs={6} pl={0.5}>
          <ErrorWrapperComponent error={!!error}>
            <SaveButtonComponent fullWidth onClick={onClickSave} disabled={disabledSave} title={saveButtonTitle} />
          </ErrorWrapperComponent>
        </Grid>
      )}
      {error && (
        <Grid item xs={12}>
          <ErrorWrapperComponent error>
            <FormHelperText sx={{textAlign: 'center', fontWeight: 500}} error>
              {error}
            </FormHelperText>
          </ErrorWrapperComponent>
        </Grid>
      )}
    </Grid>
  );
}

export const SaveAndCancelButtonsComponent = memo(SaveAndCancelButtons);
