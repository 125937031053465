import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {Formik, FormikProps} from 'formik';
import {omit} from 'lodash';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {SaveAndCancelButtonsComponent} from '../components/buttons/save-and-cancel-buttons.component';
import {ContactUsedAlertComponent} from '../components/invitations/contact-used-alert.component';
import {ACCESS_ROLES} from '../interfaces/entities/access.interface';
import {Invitation} from '../interfaces/entities/invitation.interface';
import {checkPhone} from '../utils/check-phone';
import {AssistiveTextComponent} from './shared/assistive-text.component';
import {EmailFieldComponent} from './shared/email-field.component';
import {NameFieldComponent} from './shared/name-field.component';
import {PhoneFieldComponent} from './shared/phone-field.component';
interface InvitationFormProps extends Partial<Invitation> {
  onSave?: (data: Partial<Invitation>) => void;
  onCancel?: () => void;
  isPhoneUsed?: (value: string) => boolean;
  isEmailUsed?: (value: string) => boolean;
}

function InvitationFormBase(props: InvitationFormProps) {
  const {t} = useTranslation();

  const [role, setRole] = useState<ACCESS_ROLES>(props.role || ACCESS_ROLES.MEMBER);

  const [openPhoneUsedAlert, setOpenPhoneUsedAlert] = useState(false);
  const [openEmailUsedAlert, setOpenEmailUsedAlert] = useState(false);

  const handleRoleChange = useCallback(() => {
    setRole(role === ACCESS_ROLES.MEMBER ? ACCESS_ROLES.ADMIN : ACCESS_ROLES.MEMBER);
  }, [setRole, role]);

  const onSave = useCallback(
    (updatedData: Partial<Invitation>) => {
      const {onSave} = props;

      const {isValid, phoneNumber} = checkPhone(updatedData.phone || '');
      const phone = isValid && phoneNumber ? phoneNumber : undefined;
      const data = omit(
        {
          ...props,
          ...updatedData,
          role,
          phone,
          email: updatedData.email || undefined,
        },
        ['onSave', 'onCancel']
      );

      if (!onSave) {
        return;
      }
      onSave(data);
    },
    [props, role]
  );

  const onCancel = useCallback(
    (resetForm: () => void) => {
      resetForm();
      if (props.onCancel) {
        props.onCancel();
      }
    },
    [props.onCancel]
  );

  const onChangePhone = useCallback(
    (event: React.ChangeEvent, callback: (event: React.ChangeEvent<any>) => void) => {
      const value = (event as React.ChangeEvent<HTMLInputElement>).target?.value;

      if (value && props.isPhoneUsed && props.isPhoneUsed(value)) {
        setOpenPhoneUsedAlert(true);
      } else {
        setOpenPhoneUsedAlert(false);
      }

      return callback(event);
    },
    [setOpenPhoneUsedAlert, props.isPhoneUsed]
  );

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent, callback: (event: React.ChangeEvent<any>) => void) => {
      const value = (event as React.ChangeEvent<HTMLInputElement>).target?.value;

      if (value && props.isEmailUsed && props.isEmailUsed(value)) {
        setOpenEmailUsedAlert(true);
      } else {
        setOpenEmailUsedAlert(false);
      }

      return callback(event);
    },
    [setOpenEmailUsedAlert, props.isEmailUsed]
  );

  return (
    <Formik<Partial<Invitation>>
      initialValues={props || {}}
      onSubmit={onSave}
      validationSchema={Yup.object().shape(
        {
          firstName: Yup.string().required('required-field-not-filled.error'),
          lastName: Yup.string().required('required-field-not-filled.error'),
          phone: Yup.string().test('phone', 'access-create-role.error', function (value) {
            const validEmail = Yup.object({
              email: Yup.string().email().required(),
            }).isValidSync({
              email: this.parent.email,
            });

            if (validEmail) {
              return true;
            }

            return !!value && checkPhone(value).isValid;
          }),
          email: Yup.string()
            .email('access-create-role.error')
            .when('phone', {
              is: (phone: string) => !phone || phone.length === 0,
              then: Yup.string().email().required('access-create-role.error'),
              otherwise: Yup.string(),
            }),
        },
        [['email', 'phone']]
      )}
    >
      {(props: FormikProps<Partial<Invitation>>) => {
        const {values, touched, errors, handleChange, handleSubmit, resetForm} = props;

        return (
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={12} lg={9} xl={7}>
              <Grid container direction="row" spacing={1} mb={3}>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>{t('access-create-role-bio.header')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'subtitle1'}>{t('access-create-role-bio.body')}</Typography>
                </Grid>
              </Grid>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <NameFieldComponent
                      id={'firstName'}
                      label={t('first-name.label')}
                      value={values.firstName || ''}
                      handleChange={handleChange}
                      error={errors.firstName}
                      touched={touched.firstName}
                    />
                    <AssistiveTextComponent>{t('relative-edit-first-name.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <NameFieldComponent
                      id={'lastName'}
                      label={t('last-name.label')}
                      value={values.lastName || ''}
                      handleChange={handleChange}
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                    <AssistiveTextComponent>{t('relative-edit-last-name.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={9} xl={7}>
              <Typography variant={'subtitle1'} pb={1.5}>
                {t('access-create-role-credentials.body')}
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <PhoneFieldComponent
                      id={'phone'}
                      label={t('access-create-role-phone.label')}
                      value={values.phone || ''}
                      handleChange={(event: React.ChangeEvent) => {
                        onChangePhone(event, handleChange);
                      }}
                      error={errors.phone}
                      touched={touched.phone}
                    />
                    <AssistiveTextComponent>{t('access-create-role-phone.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <EmailFieldComponent
                      id={'email'}
                      label={t('access-create-role-email.label')}
                      value={values.email || ''}
                      handleChange={(event: React.ChangeEvent) => {
                        onChangeEmail(event, handleChange);
                      }}
                      error={errors.email}
                      touched={touched.email}
                    />
                    <AssistiveTextComponent>{t('access-create-role-email.assistive')}</AssistiveTextComponent>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={9} xl={7}>
              <Grid container direction="row" spacing={1} mb={3}>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>{t('access-create-role-permissions.header')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'subtitle1'}>{t('access-create-role-permissions.body')}</Typography>
                </Grid>
              </Grid>
              <FormControl>
                <RadioGroup name="role" value={role} onChange={handleRoleChange}>
                  <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <FormControlLabel
                          value={ACCESS_ROLES.ADMIN}
                          control={<Radio />}
                          label={'' + t('access-create-role-admin.label')}
                        />
                        <AssistiveTextComponent>{t('access-create-role-admin.assistive')}</AssistiveTextComponent>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <FormControlLabel
                          value={ACCESS_ROLES.MEMBER}
                          control={<Radio />}
                          label={'' + t('access-create-role-member.label')}
                        />
                        <AssistiveTextComponent>{t('access-create-role-member.assistive')}</AssistiveTextComponent>
                      </Box>
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={9} xl={7}>
              <ContactUsedAlertComponent open={openPhoneUsedAlert || openEmailUsedAlert} />
            </Grid>
            <Grid item xs={12}>
              <SaveAndCancelButtonsComponent
                onClickCancel={() => onCancel(resetForm)}
                onClickSave={handleSubmit}
                saveButtonTitle={t('invitation-send.button')}
                disabledSave={openPhoneUsedAlert || openEmailUsedAlert}
                sx={theme => ({
                  float: 'right',
                  width: '100%',

                  [theme.breakpoints.up('lg')]: {
                    width: '35%',
                  },
                })}
              />
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}

export const InvitationForm = memo(InvitationFormBase);
