import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import {observer} from 'mobx-react-lite';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Redirect} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import {AdminMessageComponent} from '../../components/admin-message.component';
import {CommonGuestbookPostButtonComponent} from '../../components/buttons/common-guestbook-post-button.component';
import {ProfilesListLoadingComponent} from '../../components/loading/profiles/profiles-list-loading.component';
import {LoginComponent} from '../../components/login.component';
import {EmptyListPatientsComponent} from '../../components/patients/empty-list-patients.component';
import {PatientCardComponent} from '../../components/patients/patient-card.component';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {usePatients} from '../../hooks/patients/use-patients';
import {useStores} from '../../hooks/use-stores';
import {isUserAdmin} from '../../utils/is-user-admin';

function ProfilesListContainerBase() {
  const {authStore} = useStores();
  const {show} = useIntercom();

  const {items, loadMore, hasMore, loading} = usePatients();

  const isAdmin = authStore.me && isUserAdmin(authStore.me);

  if (loading || authStore.loading !== false) {
    return <ProfilesListLoadingComponent />;
  }

  if (!authStore.me) {
    return <LoginComponent />;
  }

  if (items.length === 1 && !isAdmin) {
    return <Redirect to={`${MY_PATIENTS_ROUTE.path}/${items[0].id}`} />;
  }

  return (
    <>
      {isAdmin && <AdminMessageComponent sx={{mb: 4}} />}
      {(items.length > 1 || isAdmin) && <CommonGuestbookPostButtonComponent admin={isAdmin} />}
      <Box
        id="profiles-list"
        sx={theme => ({
          padding: '40px 0',
          [theme.breakpoints.up('md')]: {
            padding: '50px',
          },
          [theme.breakpoints.up('lg')]: {
            padding: '60px',
          },
        })}
      >
        <InfiniteScroll
          dataLength={items.length}
          next={loadMore}
          hasMore={hasMore}
          loader={<CircularProgress style={{display: 'table', margin: '0 auto'}} />}
          style={{overflow: undefined}}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={3}>
            {items.length ? (
              items.map(item => (
                <Grid key={item.id} item xs={12} sm={4} md={3} lg={2}>
                  <PatientCardComponent {...item} />
                </Grid>
              ))
            ) : (
              <EmptyListPatientsComponent onClick={show} />
            )}
          </Grid>
        </InfiniteScroll>
      </Box>
    </>
  );
}

export const ProfilesListContainer = observer(ProfilesListContainerBase);
