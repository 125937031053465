import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {BackButtonComponent} from '../../components/buttons/back-button.component';
import {DeleteButtonComponent} from '../../components/buttons/delete-button.component';
import {ConfirmLeaveDialogComponent} from '../../components/dialog/confirm-leave-dialog.component';
import {DeleteProfileDialogComponent} from '../../components/dialog/delete-profile-dialog.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {PatientsOneEditLoadingComponent} from '../../components/loading/patients/patients-one-edit-loading.component';
import {PhotoEditorComponent} from '../../components/photo-editor.component';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {PatientForm} from '../../forms/patient.form';
import {usePatient} from '../../hooks/patients/use-patient';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useFormActions} from '../../hooks/use-form-actions';
import {usePhotoEditor} from '../../hooks/use-photo-editor';
import {useStores} from '../../hooks/use-stores';
import {Patient} from '../../interfaces/entities/patient.interface';
import {PROFILE_DELETE_REQUEST_STATUSES} from '../../interfaces/entities/profile-delete-request.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';
import {getDateTime} from '../../utils/get-date-time';
import getName from '../../utils/get-name';

function PatientsOneEdit({profileId}: ProfilePageProps) {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const {profileDeleteRequestsStore, authStore} = useStores();
  const [blockLeave, setBlockLeave] = useState(true);
  const [loadingCreateDeleteRequest, setLoadingCreateDeleteRequest] = useState(false);
  const [deleteRequestChecked, setDeleteRequestChecked] = useState(false);

  const [showDeleteProfileDialog, setShowDeleteProfileDialog] = useState(false);

  const {item, fetchLoading, update, updateLoading, updateWithPhoto} = usePatient(profileId);
  const {isPatientRoleAdmin} = usePatientRole(profileId);

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore.fetchMe]);

  useEffect(() => {
    profileDeleteRequestsStore.fetchAllItemsByProfileId(profileId);
  }, [profileId, profileDeleteRequestsStore.fetchAllItemsByProfileId]);

  const {props: photoEditorProps, isChanged: photoIsChanged, getPhoto} = usePhotoEditor(item?.image);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}`;

  const {onCancel, onConfirm} = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Patient>) => {
      if (!photoIsChanged) {
        await update(data);
      } else {
        const image = await getPhoto();
        if (!image) {
          await update({...data, image: null});
        } else {
          await updateWithPhoto(data, image as File);
        }
      }
      setBlockLeave(false);
      setTimeout(() => {
        onConfirm();
        setBlockLeave(true);
      }, 500);
    },
    [update, updateWithPhoto, photoIsChanged, getPhoto, onConfirm]
  );

  const handleClickDeleteProfile = useCallback(() => {
    setShowDeleteProfileDialog(true);
  }, []);

  const handleCloseDeleteProfileDialog = useCallback(() => {
    setShowDeleteProfileDialog(false);
  }, []);

  const handleConfirmDeleteProfileDialog = useCallback(
    async (reason?: string) => {
      setLoadingCreateDeleteRequest(true);
      const result = await profileDeleteRequestsStore.create(profileId, {
        reason,
      });
      setLoadingCreateDeleteRequest(false);

      if (result) {
        enqueueSnackbar(t('patient-edit-delete.modal.success.notification'), {
          variant: 'success',
        });
        setShowDeleteProfileDialog(false);
      } else {
        enqueueSnackbar(t('patient-edit-delete.modal.error.notification'), {
          variant: 'error',
        });
      }
    },
    [profileId, profileDeleteRequestsStore.create, enqueueSnackbar]
  );

  const handleClickDeleteRequestChecked = useCallback(() => {
    setDeleteRequestChecked(!deleteRequestChecked);
  }, [deleteRequestChecked]);

  const deleteRequestCreated = useMemo(() => {
    if (!profileDeleteRequestsStore.allItemsByProfileId?.[profileId]?.length) {
      return false;
    }
    const lastRequest = profileDeleteRequestsStore.allItemsByProfileId[profileId][0];

    return lastRequest.status === PROFILE_DELETE_REQUEST_STATUSES.UNPROCESSED;
  }, [profileId, profileDeleteRequestsStore.allItemsByProfileId?.[profileId]]);

  if (fetchLoading) {
    return <PatientsOneEditLoadingComponent />;
  }

  if (!item || !isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'}>{t('patient-edit-profile-picture.header')}</Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <PhotoEditorComponent
            {...photoEditorProps}
            uploadButtonText={
              photoEditorProps.image
                ? t('patient-edit-profile-picture.change')
                : t('patient-edit-profile-picture.upload')
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'} pt={1}>
            {t('patient-edit-bio.header')}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <PatientForm {...item} onSave={onSave} onCancel={onCancel} resetFormAfterCancel={false} />
        </Grid>
        <Grid item xs={12} xl={10}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} pt={{xs: 10, lg: 12}}>
            <Typography variant="body2" component={'p'} fontWeight={deleteRequestCreated ? 500 : undefined}>
              {!deleteRequestCreated
                ? t('patient-edit-delete.label')
                : t('patient-edit-delete.already-created.label', {
                    user:
                      profileDeleteRequestsStore.allItemsByProfileId?.[profileId]?.[0]?.createdById === authStore.me?.id
                        ? t('patient-edit-delete.you.label')
                        : getName(
                            profileDeleteRequestsStore.allItemsByProfileId?.[profileId]?.[0]?.createdBy?.firstName,
                            profileDeleteRequestsStore.allItemsByProfileId?.[profileId]?.[0]?.createdBy?.lastName
                          ),
                    datetime: getDateTime(profileDeleteRequestsStore.allItemsByProfileId?.[profileId]?.[0]?.createdAt),
                  })}
            </Typography>
            <DeleteButtonComponent
              color="error"
              sx={theme => ({
                minWidth: '150px',
                color: theme.palette.error.main,
              })}
              disabled={deleteRequestCreated}
              text={t('patient-edit-delete.button')}
              onClick={handleClickDeleteProfile}
            />
          </Stack>
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={!!updateLoading || loadingCreateDeleteRequest} />
      <DeleteProfileDialogComponent
        isOpen={showDeleteProfileDialog}
        onCancel={handleCloseDeleteProfileDialog}
        onConfirm={handleConfirmDeleteProfileDialog}
        onClickChecked={handleClickDeleteRequestChecked}
        checked={deleteRequestChecked}
      />
      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-personlig-warning.body')}
        stayButtonText={t('modal-leave-personlig-warning-stay.button')}
        leaveButtonText={t('modal-leave-personlig-warning-leave.button')}
      />
    </>
  );
}

export const PatientsOneEditContainer = observer(PatientsOneEdit);
