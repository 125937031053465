import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';

function StoryCardLoading(): ReactElement {
  return (
    <Card sx={{height: '100%'}}>
      <CardContent
        sx={{
          pb: '8px !important',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography mb={1} variant={'subtitle1'}>
          <Skeleton width={'150px'} />
        </Typography>
        <Typography variant={'caption'}>
          <Skeleton width={'70px'} />
        </Typography>
        <Typography variant={'body2'} my={2} color={'rgba(0, 0, 0, 0.6)'}>
          <Skeleton width={'100%'} />
        </Typography>
        <CardActions sx={{mt: 'auto', padding: 0}}>
          <Button
            sx={{
              ml: 'auto',
            }}
          >
            <Skeleton width={'150px'} />
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export const StoryCardLoadingComponent = memo(StoryCardLoading);
