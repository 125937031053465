import {Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {observer} from 'mobx-react-lite';
import React, {Ref, useCallback, useImperativeHandle, useState} from 'react';
import Lightbox from 'react-image-lightbox';

import {AlbumFilesEditorComponent} from '../../components/albums/album-files-editor.component';
import {AlbumFilesListLoadingComponent} from '../../components/loading/album-files/album-files-list-loading.component';
import {PatientNestedItemsInfiniteScrollComponent} from '../../components/patients/patient-nested-items-infinite-scroll.component';
import {useAlbumFilesByAlbumId} from '../../hooks/album-files/use-album-files-by-album-id';
import {useAlbumFilesEditor} from '../../hooks/albums/use-album-files-editor';
import {Album} from '../../interfaces/entities/album.interface';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';
import {EMPTY_IMAGE} from '../../utils/constants';

const LIMIT = 25;
const IMAGE_PADDING = 50;

interface AlbumFilesListEditProps extends ProfilePageProps {
  albumId: Album['id'];
  albumImage: Album['image'];
  onChangeAlbumCover: (value: string) => void;
}

export type AlbumFilesListEditRef = {
  save: (updateAlbumCover: (value: string) => Promise<void>) => Promise<void>;
};

function AlbumFilesListEdit(
  {profileId, albumId, onChangeAlbumCover, albumImage}: AlbumFilesListEditProps,
  ref: Ref<AlbumFilesListEditRef>
) {
  const {items, loadMore, loading, hasMore, updateMany, deleteMany, createMany, rotateMany} = useAlbumFilesByAlbumId(
    profileId,
    albumId,
    LIMIT
  );

  const [indexToShow, setIndexToShow] = useState<number | null>(null);

  const {
    files,
    selected,
    rotated,
    deleted,
    onChange: onChangeFiles,
    onClickSelect,
    onDeleteSelected,
    onClickRotate,
    getSelectedFileUrl,
    getFilesToUpdate,
    getFilesToCreate,
    onSelectFiles,
    getFilesToRotate,
  } = useAlbumFilesEditor(onChangeAlbumCover, albumImage, items, loading);

  useImperativeHandle(
    ref,
    () => ({
      async save(updateAlbumCover: (value: string) => Promise<void>) {
        const filesToCreate = getFilesToCreate();
        await updateMany(getFilesToUpdate());
        const createdFiles = await createMany(filesToCreate);
        await deleteMany(deleted);
        const rotatedFiles = await rotateMany(getFilesToRotate());
        const indexFromCreated = filesToCreate.findIndex(({url}) => url && albumImage === url);

        if (indexFromCreated !== -1 && createdFiles?.[indexFromCreated]?.url) {
          await updateAlbumCover(createdFiles[indexFromCreated].url);
        }

        const albumFile = files.filter(({url}) => url === albumImage)[0];
        if (!albumFile) {
          return;
        }

        const rotatedFile = rotatedFiles.filter(({id}) => id === albumFile.id)[0];

        if (rotatedFile?.url) {
          await updateAlbumCover(rotatedFile.url);
        }
      },
    }),
    [files, albumImage, rotated]
  );

  const {lightbox} = useStyles();

  const onClickFile = useCallback(
    (id: AlbumFile['id']) => {
      const index = files.findIndex(file => file.id === id);
      if (index !== -1) {
        setIndexToShow(index);
      }
    },
    [files, setIndexToShow]
  );

  const onMakeAlbumCover = useCallback(() => {
    const url = getSelectedFileUrl();
    if (url) {
      onChangeAlbumCover(url);
    }
  }, [onChangeAlbumCover, getSelectedFileUrl]);

  if (loading) {
    return <AlbumFilesListLoadingComponent />;
  }

  return (
    <PatientNestedItemsInfiniteScrollComponent dataLength={items.length} hasMore={hasMore} loadMore={loadMore}>
      {indexToShow !== null && (
        <Lightbox
          reactModalStyle={{overlay: {zIndex: 9999}}}
          wrapperClassName={lightbox}
          imagePadding={IMAGE_PADDING}
          mainSrc={files[indexToShow].url || EMPTY_IMAGE}
          imageTitle={
            <Typography variant={'subtitle1'} color={'#FFFFFF'} position={'absolute'} left={'50%'}>
              {`${indexToShow + 1}/${files.length}`}
            </Typography>
          }
          imageCaption={
            <Typography variant={'body1'} color={'#FFFFFF'}>
              {files[indexToShow].description}
            </Typography>
          }
          onCloseRequest={() => {
            setIndexToShow(null);
          }}
        />
      )}
      <AlbumFilesEditorComponent
        files={files}
        selected={selected}
        rotated={rotated}
        albumImage={albumImage}
        onChange={onChangeFiles}
        onClickSelect={onClickSelect}
        onClick={onClickFile}
        onDeleteSelected={onDeleteSelected}
        onClickRotate={onClickRotate}
        onMakeAlbumCover={onMakeAlbumCover}
        onSelectFiles={onSelectFiles}
      />
    </PatientNestedItemsInfiniteScrollComponent>
  );
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    lightbox: {
      '& .ril-caption': {
        bottom: Math.floor(IMAGE_PADDING / 2) - 10,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  })
);

export const AlbumFilesListEditContainer = observer(AlbumFilesListEdit, {
  forwardRef: true,
});
