import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {AlertComponent} from '../alert/alert.component';

interface ContactUsedAlertProps {
  open?: boolean;
  onClose?: () => void;
}

function ContactUsedAlert({open, onClose}: ContactUsedAlertProps) {
  const {t} = useTranslation();

  return (
    <Collapse in={open}>
      <AlertComponent
        severity="error"
        action={
          onClose && (
            <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
      >
        {t('access-create-role-contact-exists.alert')}
      </AlertComponent>
    </Collapse>
  );
}

export const ContactUsedAlertComponent = memo(ContactUsedAlert);
