import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {EmptyScreenComponent} from '../../components/empty-screen.component';
import {FailedLoadingDataComponent} from '../../components/failed-loading-data.component';
import {StoriesListLoadingComponent} from '../../components/loading/stories/stories-list-loading.component';
import {PatientNestedItemsInfiniteScrollComponent} from '../../components/patients/patient-nested-items-infinite-scroll.component';
import {PatientNestedItemsWrapperComponent} from '../../components/patients/patient-nested-items-wrapper.component';
import {StoryCardComponent} from '../../components/stories/story-card.component';
import {usePatient} from '../../hooks/patients/use-patient';
import {usePatientNestedItems} from '../../hooks/patients/use-patient-nested-items';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useCreateButton} from '../../hooks/use-create-button';
import {useStores} from '../../hooks/use-stores';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';
import {STORY_CARD_WIDTH} from '../../utils/constants';

const LIMIT = 15;

function StoriesList({profileId}: ProfilePageProps) {
  const {storiesStore: store} = useStores();
  const {items, loading, hasMore, loadMore, errorLoading, reload} = usePatientNestedItems(store, profileId, LIMIT);

  const {t} = useTranslation();

  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const {item} = usePatient(profileId);

  const {onClick: onClickCreate} = useCreateButton();

  const showCreateButton = useMemo(() => {
    return isPatientRoleAdmin && items?.length > 0;
  }, [isPatientRoleAdmin, items?.length]);

  if (loading || !items) {
    return <StoriesListLoadingComponent />;
  }

  if (loading === false && errorLoading) {
    return <FailedLoadingDataComponent onClick={reload} />;
  }

  return (
    <PatientNestedItemsWrapperComponent onClickCreate={showCreateButton ? onClickCreate : undefined}>
      <PatientNestedItemsInfiniteScrollComponent dataLength={items.length} hasMore={hasMore} loadMore={loadMore}>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent={'center'}
          sx={theme => ({
            [theme.breakpoints.up('md')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          {items.length ? (
            items.map((story, index) => (
              <Box
                key={story.id || index}
                mb={3}
                sx={theme => ({
                  width: `${STORY_CARD_WIDTH}px`,
                  [theme.breakpoints.up('md')]: {
                    mr: 2,
                  },
                })}
                display={'block'}
                height={'auto'}
              >
                <StoryCardComponent {...story} />
              </Box>
            ))
          ) : (
            <EmptyScreenComponent
              title={`${t('stories-list-empty.header')}`}
              description={`${t(`stories-list-empty.body`, {
                firstName: item?.firstName,
              })}`}
              icon={<AddIcon sx={{pl: '2px'}} />}
              onClick={onClickCreate}
              text={`${t('stories-list-empty-create-story.button')}`}
              hasContent={isPatientRoleAdmin}
            />
          )}
        </Box>
      </PatientNestedItemsInfiniteScrollComponent>
    </PatientNestedItemsWrapperComponent>
  );
}

export const StoriesListContainer = observer(StoriesList);
