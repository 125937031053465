import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';

function StoriesOneLoading(): ReactElement {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      wrap="nowrap"
      spacing={2}
      sx={{
        paddingBottom: '5px',
      }}
    >
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap" spacing={1}>
          <Grid item>
            <Typography variant={'h5'}>
              <Skeleton
                sx={theme => ({
                  width: '175px',
                  [theme.breakpoints.up('sm')]: {
                    width: '300px',
                  },
                })}
              />
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              fontStyle={'italic'}
              fontWeight={'normal'}
              fontSize={'14px'}
              lineHeight={'20px'}
              letterSpacing={'0.25px'}
            >
              <Skeleton
                sx={theme => ({
                  width: '75px',
                  [theme.breakpoints.up('sm')]: {
                    width: '150px',
                  },
                })}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant={'body2'}>
          <Skeleton
            sx={theme => ({
              width: '75px',
              [theme.breakpoints.up('sm')]: {
                width: '150px',
              },
            })}
          />
        </Typography>
      </Grid>

      <Grid item>
        <Skeleton
          sx={{
            marginBottom: 1,
          }}
        />
        <Skeleton
          sx={{
            marginBottom: 1,
          }}
        />
        <Skeleton
          sx={{
            marginBottom: 1,
          }}
        />

        <Grid container justifyContent="space-around" spacing={2} marginTop={1} marginBottom={2}>
          {[0, 1, 2].map(value => (
            <Grid key={value} item>
              <Skeleton
                variant={'rectangular'}
                sx={theme => ({
                  height: '80px',
                  width: '80px',
                  [theme.breakpoints.up('sm')]: {
                    height: '120px',
                    width: '120px',
                  },
                  [theme.breakpoints.up('md')]: {
                    height: '160px',
                    width: '160px',
                  },
                  [theme.breakpoints.up('lg')]: {
                    height: '220px',
                    width: '220px',
                  },
                  [theme.breakpoints.up('xl')]: {
                    height: '260px',
                    width: '260px',
                  },
                })}
              />
            </Grid>
          ))}
        </Grid>

        <Skeleton
          sx={{
            marginBottom: 1,
          }}
        />
        <Skeleton
          sx={{
            marginBottom: 1,
          }}
        />
        <Skeleton
          sx={{
            marginBottom: 1,
          }}
        />
      </Grid>
    </Grid>
  );
}

export const StoriesOneLoadingComponent = memo(StoriesOneLoading);
