interface FileWithSlice extends File {
  mozSlice?: File['slice'];
  webkitSlice?: File['slice'];
}

export function sliceFile(file: FileWithSlice, start: number, end: number): Blob {
  const slice = file.mozSlice ? file.mozSlice : file.webkitSlice ? file.webkitSlice : file.slice;

  return slice.bind(file)(start, end);
}
