import Grid from '@mui/material/Grid';
import {omit} from 'lodash';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Album} from '../interfaces/entities/album.interface';
import {AssistiveTextComponent} from './shared/assistive-text.component';
import {NameFieldComponent} from './shared/name-field.component';

interface AlbumFormProps extends Partial<Album> {
  onChange?: (data: Album) => void;
}

function AlbumFormBase(props: AlbumFormProps) {
  const {t} = useTranslation();

  const [name, setName] = useState(props.name || '');
  const [description, setDescription] = useState(props.description || '');

  const onChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
      if (props.onChange) {
        props.onChange({
          ...omit(props, 'onChange'),
          name: event.target.value,
          description,
        } as Album);
      }
    },
    [setName, props, description]
  );

  const onChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
      if (props.onChange) {
        props.onChange({
          ...omit(props, 'onChange'),
          description: event.target.value,
          name,
        } as Album);
      }
    },
    [setDescription, props, name]
  );

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NameFieldComponent
          id={'name'}
          label={t('album-edit-name.label')}
          value={name || ''}
          handleChange={onChangeName}
        />
        <AssistiveTextComponent>{t('album-edit-name.assistive')}</AssistiveTextComponent>
      </Grid>
      <Grid item xs={12} sm={6} md={8} lg={9}>
        <NameFieldComponent
          id={'description'}
          label={t('album-edit-description.label')}
          value={description || ''}
          handleChange={onChangeDescription}
        />
        <AssistiveTextComponent>{t('album-edit-description.assistive')}</AssistiveTextComponent>
      </Grid>
    </Grid>
  );
}

export const AlbumForm = memo(AlbumFormBase);
