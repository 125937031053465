import DOMPurify from 'dompurify';

import {YOUTUBE_EMBED_PATH} from './get-youtube-id';

const VIDEO_TAGS = ['iframe'];
const VIDEO_ATTRIBS = ['allow', 'allowfullscreen', 'frameborder', 'scrolling'];

const LINK_ATTRIBS = ['target'];

DOMPurify.addHook('uponSanitizeElement', (node, data) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || '';
    if (!src.startsWith(YOUTUBE_EMBED_PATH)) {
      return node.parentNode?.removeChild(node);
    }
  }
});

export function parseStoryBody(html: string) {
  return DOMPurify.sanitize(html || '', {
    ADD_TAGS: VIDEO_TAGS,
    ADD_ATTR: [...VIDEO_ATTRIBS, ...LINK_ATTRIBS],
  });
}
