import {useCallback, useEffect, useState} from 'react';
import {Area, Point} from 'react-easy-crop/types';

import {PhotoEditorProps} from '../components/photo-editor.component';
import {getCroppedImg} from '../utils/images/get-cropped-img';

const DEFAULT_PROPS: PhotoEditorProps = {
  crop: {x: 0, y: 0},
  zoom: 1,
  rotation: 0,
  aspect: 1 / 1,
};

export function usePhotoEditor(defaultSrc?: string | null): {
  getPhoto: () => Promise<Blob | null> | null;
  props: PhotoEditorProps;
  isChanged?: boolean;
} {
  const [image, setImage] = useState<PhotoEditorProps['image']>(defaultSrc || undefined);

  useEffect(() => {
    setImage(defaultSrc || undefined);
  }, [defaultSrc]);
  const [changedImage, setChangedImage] = useState<boolean>(false);
  const [crop, setCrop] = useState<PhotoEditorProps['crop']>(DEFAULT_PROPS.crop);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | undefined>();
  const [zoom, setZoom] = useState<PhotoEditorProps['zoom']>(DEFAULT_PROPS.zoom);
  const [rotation, setRotation] = useState<PhotoEditorProps['rotation']>(DEFAULT_PROPS.rotation);
  const [aspect, setAspect] = useState<PhotoEditorProps['aspect']>(DEFAULT_PROPS.aspect);

  const onCropChange = useCallback(
    (crop: Point) => {
      setCrop(crop);
    },
    [setCrop]
  );

  const onCropComplete = useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [setCroppedAreaPixels]
  );

  const onZoomChange = useCallback(
    (zoom: number) => {
      setZoom(zoom);
      setChangedImage(true);
    },
    [setZoom, setChangedImage]
  );

  const onRotationChange = useCallback(
    (rotation: number) => {
      setRotation(rotation);
      setChangedImage(true);
    },
    [setRotation, setChangedImage]
  );

  const onClickDeleteButton = useCallback(() => {
    setImage(undefined);
    setChangedImage(true);
    setCrop(DEFAULT_PROPS.crop);
    setZoom(DEFAULT_PROPS.zoom);
    setRotation(DEFAULT_PROPS.rotation);
    setAspect(DEFAULT_PROPS.aspect);
  }, [setImage, setChangedImage, setCrop, setZoom, setRotation, setAspect]);

  const onSelectFiles = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setImage(URL.createObjectURL(event.target.files[0]));
        setChangedImage(true);
        setCrop(DEFAULT_PROPS.crop);
        setZoom(DEFAULT_PROPS.zoom);
        setRotation(DEFAULT_PROPS.rotation);
        setAspect(DEFAULT_PROPS.aspect);
      }
    },
    [setImage, setChangedImage, setCrop, setZoom, setRotation, setAspect]
  );

  const getPhoto = useCallback(() => {
    return changedImage && image && croppedAreaPixels ? getCroppedImg(image, croppedAreaPixels, rotation) : null;
  }, [changedImage, image, croppedAreaPixels]);

  return {
    props: {
      image,
      crop,
      zoom,
      aspect,
      rotation,
      onCropChange,
      onCropComplete,
      onZoomChange,
      onRotationChange,
      onClickDeleteButton,
      onSelectFiles,
    },
    isChanged: changedImage,
    getPhoto,
  };
}
