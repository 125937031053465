import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Button, {ButtonProps} from '@mui/material/Button';
import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {ADMIN_COMMON_GUESTBOOK_POST_ROUTE, COMMON_GUESTBOOK_POST_ROUTE} from '../../configs/routes';

interface CommonGuestbookPostButtonProps extends ButtonProps {
  admin?: boolean;
}

function CommonGuestbookPostButton({admin, ...props}: CommonGuestbookPostButtonProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(admin ? ADMIN_COMMON_GUESTBOOK_POST_ROUTE.path : COMMON_GUESTBOOK_POST_ROUTE.path);
  }, [history, admin]);

  return (
    <Button variant="contained" onClick={onClick} fullWidth {...props} disableElevation>
      <AppRegistrationIcon sx={{mr: 1}} />
      {t('common-guestbook-post.button')}
    </Button>
  );
}

export const CommonGuestbookPostButtonComponent = memo(CommonGuestbookPostButton);
