import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import React, {forwardRef, memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {useProgressiveImage} from '../../hooks/use-progressive-image';
import {Patient} from '../../interfaces/entities/patient.interface';
import {Unit} from '../../interfaces/entities/unit.interface';
import {validateBirthDate} from '../helpers/validate-birth-date';
import {DateField} from './shared/date-field.component';
import {FieldWrapperComponent} from './shared/field-wrapper.component';
import {MultiLinesField} from './shared/multi-lines-field.component';
import {SingleLineField} from './shared/name-field.component';

interface PatientReview extends Patient {
  municipalityId?: Unit['id'] | null;
  institutionId?: Unit['id'] | null;
  departmentId?: Unit['id'] | null;
}

interface PatientReviewFormProps extends PatientReview {
  onChange: (data: Patient) => void;
  municipalities?: ReadonlyArray<Unit>;
  institutions?: ReadonlyArray<Unit>;
  departments?: ReadonlyArray<Unit>;
}

const MIN_ROWS = 3;
const PHOTO_WIDTH = 340;
const PHOTO_HEIGHT = 340;

function PatientReviewFormBase(
  {onChange, municipalities = [], institutions = [], departments = [], ...props}: PatientReviewFormProps,
  ref: React.Ref<FormikProps<PatientReview>>
) {
  const {t} = useTranslation();

  const validateBirthDateField = useCallback(
    (value: Date | null) => {
      return validateBirthDate(t, value);
    },
    [t]
  );

  return (
    <Box>
      <Typography variant="h4" fontSize={'28px'} fontWeight={700} textAlign={'center'} pb={3}>
        {t('profile-review-form.select')}
      </Typography>
      <Divider sx={{mb: 3}} />
      <Formik<PatientReview>
        initialValues={props || {}}
        onSubmit={onChange}
        innerRef={ref as React.Ref<FormikProps<PatientReview>>}
        validationSchema={Yup.object<Record<keyof PatientReview, Yup.AnySchema>>().shape({
          firstName: Yup.string().required('required-field-not-filled.error'),
          lastName: Yup.string().required('required-field-not-filled.error'),
          image: Yup.string().nullable().optional(),
          birthPlace: Yup.string().nullable().optional(),
          description: Yup.string().nullable().optional(),
          interests: Yup.string().nullable().optional(),
          habits: Yup.string().nullable().optional(),
          music: Yup.string().nullable().optional(),
          relax: Yup.string().nullable().optional(),
          municipalityId: Yup.string().nullable().required('required-field-not-filled.error'),
          institutionId: Yup.string().nullable().required('required-field-not-filled.error'),
        })}
      >
        {props => {
          return (
            <Form>
              <Typography variant="h5" color={'primary.dark'} fontWeight={700} pb={1.5}>
                {t('profile-review-name.title')}
              </Typography>
              <Stack direction={{xs: 'column', sm: 'row'}} spacing={{xs: 1, sm: 2, md: 3}}>
                <Box sx={{width: '100%'}}>
                  <Field name="firstName">
                    {(props: FieldProps) => {
                      return (
                        <SingleLineField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('patient-edit-first-name.label')}
                        />
                      );
                    }}
                  </Field>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Field name="lastName">
                    {(props: FieldProps) => {
                      return (
                        <SingleLineField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('patient-edit-last-name.label')}
                        />
                      );
                    }}
                  </Field>
                </Box>
              </Stack>
              {props.values.birthDate && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-birth-date.title')}
                  </Typography>
                  <Field name="birthDate" validate={validateBirthDateField}>
                    {(props: FieldProps) => {
                      return <DateField {...props} readOnly label={t('patient-edit-birth-date.label')} />;
                    }}
                  </Field>
                </>
              )}

              {props.values.image && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-photo-form.title')}
                  </Typography>
                  <Field name="image">
                    {(props: FieldProps) => {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      const [imageSrc, {blur}] = useProgressiveImage(props.field.value, {
                        width: PHOTO_WIDTH,
                        height: PHOTO_HEIGHT,
                      });
                      return (
                        <CardMedia
                          image={imageSrc}
                          sx={theme => ({
                            margin: '0 auto',
                            borderRadius: 1,
                            backgroundSize: 'contain',
                            filter: blur ? 'blur(20px)' : 'none',
                            transition: blur ? 'none' : 'filter 0.3s ease-out',
                            width: `${PHOTO_WIDTH * 0.65}px`,
                            height: `${PHOTO_HEIGHT * 0.65}px`,
                            [theme.breakpoints.up('sm')]: {
                              width: `${PHOTO_WIDTH}px`,
                              height: `${PHOTO_HEIGHT}px`,
                            },
                          })}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.birthPlace && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-home-place.title')}
                  </Typography>
                  <Field name="birthPlace">
                    {(props: FieldProps) => {
                      return (
                        <SingleLineField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('persona-edit-home-place.label')}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.description && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-about-form.title', {
                      firstName: props.values.firstName,
                    })}
                  </Typography>
                  <Field name="description">
                    {(props: FieldProps) => {
                      return (
                        <MultiLinesField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('patient-edit-about.label')}
                          minRows={MIN_ROWS}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.interests && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-interests-form.title')}
                  </Typography>
                  <Field name="interests">
                    {(props: FieldProps) => {
                      return (
                        <MultiLinesField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('patient-edit-interests.label')}
                          minRows={MIN_ROWS}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.habits && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-habits-form.title')}
                  </Typography>
                  <Field name="habits">
                    {(props: FieldProps) => {
                      return (
                        <MultiLinesField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('patient-edit-habits.label')}
                          minRows={MIN_ROWS}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.music && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-music-form.title')}
                  </Typography>
                  <Field name="music">
                    {(props: FieldProps) => {
                      return (
                        <MultiLinesField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('patient-edit-music.label')}
                          minRows={MIN_ROWS}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.relax && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-relax-form.title')}
                  </Typography>
                  <Field name="relax">
                    {(props: FieldProps) => {
                      return (
                        <MultiLinesField
                          {...props}
                          InputProps={{
                            readOnly: true,
                          }}
                          label={t('patient-edit-relax.label')}
                          minRows={MIN_ROWS}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.municipalityId && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-municipality-form.title')}
                  </Typography>
                  <Field name="municipalityId">
                    {(props: FieldProps) => {
                      return (
                        <Autocomplete
                          autoHighlight
                          getOptionLabel={option => option.name}
                          options={municipalities}
                          renderInput={params => (
                            <FieldWrapperComponent
                              {...props}
                              meta={{
                                ...props.meta,
                                touched: !!props.meta.error,
                              }}
                            >
                              <TextField
                                error={!!props.meta.error}
                                {...params}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </FieldWrapperComponent>
                          )}
                          {...props.field}
                          onChange={(_event, newValue) => {
                            return props.form.setFieldValue(props.field.name, (newValue as Unit | null)?.id);
                          }}
                          value={municipalities.find(({id}) => props.field.value === id)}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.institutionId && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-institution-form.title')}
                  </Typography>
                  <Field name="institutionId">
                    {(props: FieldProps) => {
                      return (
                        <Autocomplete
                          autoHighlight
                          getOptionLabel={option => option.name}
                          options={institutions}
                          renderInput={params => (
                            <FieldWrapperComponent
                              {...props}
                              meta={{
                                ...props.meta,
                                touched: !!props.meta.error,
                              }}
                            >
                              <TextField
                                error={!!props.meta.error}
                                {...params}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </FieldWrapperComponent>
                          )}
                          {...props.field}
                          onChange={(_event, newValue) => {
                            return props.form.setFieldValue(props.field.name, (newValue as Unit | null)?.id);
                          }}
                          value={institutions.find(({id}) => props.field.value === id)}
                        />
                      );
                    }}
                  </Field>
                </>
              )}

              {props.values.departmentId && (
                <>
                  <Typography variant="h5" color={'primary.dark'} fontWeight={700} pt={2.5} pb={1.5}>
                    {t('profile-review-department-form.title')}
                  </Typography>
                  <Field name="departmentId">
                    {(props: FieldProps) => {
                      return (
                        <Autocomplete
                          autoHighlight
                          getOptionLabel={option => option.name}
                          options={departments}
                          renderInput={params => (
                            <FieldWrapperComponent
                              {...props}
                              meta={{
                                ...props.meta,
                                touched: !!props.meta.error,
                              }}
                            >
                              <TextField
                                error={!!props.meta.error}
                                {...params}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </FieldWrapperComponent>
                          )}
                          {...props.field}
                          onChange={(_event, newValue) => {
                            return props.form.setFieldValue(props.field.name, (newValue as Unit | null)?.id);
                          }}
                          value={departments.find(({id}) => props.field.value === id)}
                        />
                      );
                    }}
                  </Field>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export const PatientReviewForm = memo(forwardRef(PatientReviewFormBase));
