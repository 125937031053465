import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import {Theme} from '@mui/material/styles';
import React, {memo, ReactNode, useMemo} from 'react';
import {NavLink, useRouteMatch} from 'react-router-dom';

import {SubNavItem} from '../../../interfaces/sub-nav-item.interface';

interface MobileNavigationMenuListItemProps extends Pick<SubNavItem, 'to' | 'href' | 'target'> {
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

function MobileNavigationMenuListItem(props: MobileNavigationMenuListItemProps) {
  const {path} = useRouteMatch();

  const sx = useMemo(
    () => (theme: Theme) => ({
      color: 'rgba(0, 0, 0, 0.6) ',
      '& svg': {
        fill: '#000000',
        fillOpacity: 0.87,
      },
      ...(props.to === path || props.href === path
        ? {
            background: `${theme.palette.primary.light} !important`,
            color: `${theme.palette.primary.main} !important`,
            '& svg': {
              fill: theme.palette.primary.main,
              fillOpacity: 1,
            },
          }
        : {}),
    }),
    [path, props.to, props.href]
  );

  if (props.to) {
    return (
      <ListItemButton to={props.to} onClick={props.onClick} component={NavLink} sx={sx}>
        {props.children}
      </ListItemButton>
    );
  }

  if (props.href) {
    return (
      <ListItemButton href={props.href} target={props.target} onClick={props.onClick} component={Link} sx={sx}>
        {props.children}
      </ListItemButton>
    );
  }

  return (
    <ListItemButton onClick={props.onClick} sx={sx}>
      {props.children}
    </ListItemButton>
  );
}

export const MobileNavigationMenuListItemComponent = memo(MobileNavigationMenuListItem);
