import Grid from '@mui/material/Grid';
import React, {memo, ReactElement} from 'react';

import {ProfileCardLoadingComponent} from './profile-card-loading.component';

const ARRAY = [1, 2, 3, 4, 5];

function ProfilesListLoading(): ReactElement {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      {ARRAY.map(item => (
        <Grid key={item} item xs={12} sm={4} md={3} lg={2}>
          <ProfileCardLoadingComponent />
        </Grid>
      ))}
    </Grid>
  );
}

export const ProfilesListLoadingComponent = memo(ProfilesListLoading);
