import {AxiosInstance} from 'axios';

import {Patient} from '../interfaces/entities/patient.interface';
import {Story} from '../interfaces/entities/story.interface';
import {UploadsService} from '../services/uploads.service';
import {PatientBaseStore} from './shared/patient-base.store';

export class StoriesStore extends PatientBaseStore<Story> {
  constructor(
    api: AxiosInstance,
    private readonly uploadsService = new UploadsService(api)
  ) {
    super(api, 'stories');
  }

  async uploadByPatientId(patientId: Patient['id'], file: File) {
    const {url} = await this.uploadsService.upload(`patients/${patientId}/${this.entitiesName}`, file);

    return url;
  }
}
