import AddIcon from '@mui/icons-material/Add';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';

import {AlbumsOneComponent} from '../../components/albums/albums-one.component';
import {EmptyScreenComponent} from '../../components/empty-screen.component';
import {PROFILE_ROUTE_ALBUMS} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {useAlbumFilesByAlbumId} from '../../hooks/album-files/use-album-files-by-album-id';
import {useAlbum} from '../../hooks/albums/use-album';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useEditButton} from '../../hooks/use-edit-button';
import {useGoToOrBack} from '../../hooks/use-go-to-or-back';
import {useStores} from '../../hooks/use-stores';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';

export const ALBUM_LIMIT = 12;

function AlbumsOne({profileId}: ProfilePageProps) {
  const {params} = useRouteMatch<{albumId: string}>();
  const {t} = useTranslation();

  const {albumFilesStore} = useStores();

  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const {onClick: onClickEdit} = useEditButton();

  const [loadingDelete, setLoadingDelete] = useState(false);

  const {item, fetchLoading, remove} = useAlbum(profileId);

  const {items, loadMore, loading, hasMore} = useAlbumFilesByAlbumId(profileId, params.albumId, ALBUM_LIMIT);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_ALBUMS.path}`;

  const {go: goToList} = useGoToOrBack(backPath);

  const onConfirmDelete = useCallback(async () => {
    setLoadingDelete(true);
    if (remove) {
      await remove();
    }
    setLoadingDelete(false);
    goToList();
  }, [remove, goToList]);

  const handleMarkFavorite = useCallback(
    (id: AlbumFile['id'], value: boolean) => {
      albumFilesStore.makeFavorite(profileId, params.albumId, id, value);
    },
    [albumFilesStore.makeFavorite, profileId, params.albumId]
  );

  return (
    <AlbumsOneComponent
      profileId={profileId}
      name={item?.name}
      showDeleteButton={isPatientRoleAdmin}
      showEditButton={isPatientRoleAdmin}
      onEdit={onClickEdit}
      onDelete={onConfirmDelete}
      description={item?.description}
      loading={fetchLoading}
      files={items as AlbumFile[]}
      loadingFiles={loading}
      hasMoreFiles={hasMore}
      loadMoreFiles={loadMore}
      loadingDelete={loadingDelete}
      onMarkFavorite={handleMarkFavorite}
      EmptyScreen={
        <EmptyScreenComponent
          title={t('photos-list-empty.header')}
          description={t('photos-list-empty.body')}
          text={`${t('photos-list-empty-add-photos.button')}`}
          hasContent={isPatientRoleAdmin}
          onClick={onClickEdit}
          icon={<AddIcon sx={{pl: '2px'}} />}
        />
      }
    />
  );
}

export const AlbumsOneContainer = observer(AlbumsOne);
