import {AxiosInstance} from 'axios';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {GuestbookFile} from '../interfaces/entities/guestbook-file.interface';
import {Patient} from '../interfaces/entities/patient.interface';
import {GetManyResponse} from '../interfaces/get-many-response.interface';
import {ProfileBaseStore} from './shared/profile-base.store';

const FAVORITES_LIMIT = 100;
export class GuestbookFilesStore extends ProfileBaseStore<GuestbookFile> {
  allFavoritesByProfileId: Record<string, ReadonlyArray<GuestbookFile>> = {};
  loadingAllFavoritesByProfileId: Record<string, boolean> = {};

  constructor(api: AxiosInstance) {
    super(api, 'guestbook-files');
    makeObservable(this, {
      allFavoritesByProfileId: observable,
      loadingAllFavoritesByProfileId: observable,
      resetByProfileId: action,
      makeFavorite: action,
      removeFavorite: action,
      fetchAllFavorite: action,
    });
  }

  public resetByProfileId(profileId: Patient['id']) {
    const prefix = this.getPrefixFetchingKey(profileId);
    for (const key in this.itemsByProfileId) {
      if (key.startsWith(prefix)) {
        runInAction(() => {
          delete this.itemsByProfileId[key];
        });
      }
    }
  }

  async makeFavorite(profileId: Patient['id'], id: GuestbookFile['id']) {
    let data: GuestbookFile;
    try {
      const response = await this.api.patch<GuestbookFile>(`${this.getUrlByProfileId(profileId)}/${id}`, {
        albumIsFavorite: true,
      });
      data = response.data;
    } catch (err) {
      console.error(err);
      return;
    }

    const prefix = this.getPrefixFetchingKey(profileId);

    for (const key in this.itemsByProfileId) {
      if (this.itemsByProfileId[key]?.data?.data && key.startsWith(prefix)) {
        runInAction(() => {
          (this.itemsByProfileId[key].data as GetManyResponse<GuestbookFile>).data = (
            this.itemsByProfileId[key].data as GetManyResponse<GuestbookFile>
          ).data.map(item => {
            if (item.id !== id) {
              return item;
            }

            return {...item, albumIsFavorite: true};
          });
        });
      }
    }

    runInAction(() => {
      if (data && this.allFavoritesByProfileId[profileId]) {
        this.allFavoritesByProfileId[profileId] = [data, ...this.allFavoritesByProfileId[profileId]];
      }
    });
  }

  async removeFavorite(profileId: Patient['id'], id: GuestbookFile['id']) {
    runInAction(() => {
      if (this.allFavoritesByProfileId[profileId]) {
        this.allFavoritesByProfileId[profileId] = this.allFavoritesByProfileId[profileId].filter(
          item => item.id !== id
        );
      }
    });
    try {
      await this.api.patch<GuestbookFile>(`${this.getUrlByProfileId(profileId)}/${id}`, {
        albumIsFavorite: false,
      });
    } catch (err) {
      console.error(err);
      return;
    }

    const prefix = this.getPrefixFetchingKey(profileId);

    for (const key in this.itemsByProfileId) {
      if (this.itemsByProfileId[key]?.data?.data && key.startsWith(prefix)) {
        runInAction(() => {
          (this.itemsByProfileId[key].data as GetManyResponse<GuestbookFile>).data = (
            this.itemsByProfileId[key].data as GetManyResponse<GuestbookFile>
          ).data.map(item => {
            if (item.id !== id) {
              return item;
            }

            return {...item, albumIsFavorite: false};
          });
        });
      }
    }
  }

  async fetchAllFavorite(profileId: Patient['id'], force = false) {
    if (!force && this.allFavoritesByProfileId[profileId]) {
      return;
    }

    if (this.loadingAllFavoritesByProfileId[profileId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllFavoritesByProfileId[profileId] = true;
    });
    let more = true;
    let page = 1;
    const s = JSON.stringify({albumIsFavorite: true});
    const arr: GuestbookFile[] = [];
    while (more) {
      const searchParams = new URLSearchParams();

      searchParams.append('page', '' + FAVORITES_LIMIT);
      searchParams.append('page', '' + page);
      searchParams.append('s', s);

      const {data} = await this.api.get<GetManyResponse<GuestbookFile>>(
        this.getUrlByProfileId(profileId, `?${searchParams.toString()}`)
      );

      if (!data) {
        break;
      }

      if (data.data) {
        arr.push(...data.data);
      }
      more = (data.pageCount || 0) > page;
      page += 1;
    }

    runInAction(() => {
      this.allFavoritesByProfileId[profileId] = arr;
      this.loadingAllFavoritesByProfileId[profileId] = false;
    });
  }
}
