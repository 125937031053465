import Button, {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';

import {DEEP_SPACE_SPARKLE_COLOR} from '../../theme';

const BUTTON_STYLE = {
  bgcolor: DEEP_SPACE_SPARKLE_COLOR,
  height: '48px',
  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '50px',
  '&:hover': {
    bgcolor: DEEP_SPACE_SPARKLE_COLOR,
  },
};

function EmptyScreenButton(props: ButtonProps) {
  return (
    <Button
      sx={{
        ...BUTTON_STYLE,
      }}
      disableElevation
      {...props}
    >
      {props.children}
    </Button>
  );
}

export const EmptyScreenButtonComponent = memo(EmptyScreenButton);
