import CloseIcon from '@mui/icons-material/Close';
import Backdrop, {BackdropProps} from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {useWindowSize} from '@react-hook/window-size/throttled';
import React, {memo, useCallback, useEffect, useMemo} from 'react';
import Stories from 'react-insta-stories';
import {ReactInstaStoriesProps} from 'react-insta-stories/dist/interfaces';

import {SlideShowStoryComponent} from './ slideshow-story.component';

interface SlideShowProps extends ReactInstaStoriesProps {
  open: BackdropProps['open'];
  backdropSx?: BackdropProps['sx'];
  onClose?: () => void;
  stories: {url: string; description?: string; duration?: number}[];
}

const DEFAULT_INTERVAL = 10 * 1000; // 10 sec

function SlideShow({open, backdropSx, onClose, stories: items, ...props}: SlideShowProps) {
  const stories: ReactInstaStoriesProps['stories'] = useMemo(() => {
    return (items || []).map(({url, description, duration}, index) => ({
      duration,
      content: ({action, isPaused}) => {
        return (
          <SlideShowStoryComponent
            url={url}
            titleTop={`${index + 1}/${items.length}`}
            titleBottom={description}
            isPaused={isPaused}
            onClickPlayButton={() => {
              action(isPaused ? 'play' : 'pause');
            }}
          />
        );
      },
    }));
  }, [items]);

  const onClickAwayStories = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const escFunction = useCallback(
    (event: any) => {
      if (event.key === 'Escape') {
        onClickAwayStories();
      }
    },
    [onClickAwayStories]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const [widthWindow, heightWindow] = useWindowSize();

  return (
    <Backdrop open={open} sx={{zIndex: theme => theme.zIndex.drawer + 10, ...backdropSx}}>
      {open && (
        <Box width={'100%'} height={'100%'}>
          <Stories
            width={widthWindow}
            height={heightWindow}
            loop
            keyboardNavigation
            {...props}
            stories={stories}
            defaultInterval={props.defaultInterval || DEFAULT_INTERVAL}
          />
        </Box>
      )}
      <IconButton
        aria-label="close"
        onClick={onClickAwayStories}
        size={'large'}
        sx={theme => ({
          position: 'absolute',
          right: 5,
          top: 5,
          color: theme.palette.secondary.light,
          zIndex: theme.zIndex.modal + 1,
          '& svg': {
            width: theme.spacing(4.5),
            height: theme.spacing(4.5),
          },
        })}
      >
        <CloseIcon />
      </IconButton>
    </Backdrop>
  );
}

export const SlideShowComponent = memo(SlideShow);
