import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {MINMEMORIA_HOME_URL, WEBSITE_NAME} from '../utils/constants';
import {SignInButtonComponent} from './buttons/sign-in-button.component';

const IMAGE_WIDTH_MOBILE = '72.5%';
const IMAGE_WIDTH_TABLET_VERTICAL = '50%';
const IMAGE_WIDTH_ALL = '40%';

function Login() {
  const {t} = useTranslation();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletVertical = useMediaQuery(theme.breakpoints.only('sm'));

  const getImageWidth = useCallback(() => {
    if (mobileView) {
      return IMAGE_WIDTH_MOBILE;
    }

    if (tabletVertical) {
      return IMAGE_WIDTH_TABLET_VERTICAL;
    }

    return IMAGE_WIDTH_ALL;
  }, [mobileView, tabletVertical]);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
      sx={theme => ({
        mt: 5.5,
        px: 1,
        [theme.breakpoints.up('sm')]: {
          mt: 13.5,
        },
        [theme.breakpoints.up('md')]: {
          mt: 4.75,
        },
        [theme.breakpoints.up('lg')]: {
          mt: 2.25,
        },
      })}
    >
      <img src={'/assets/login-phone-laptop.png'} style={{width: getImageWidth(), maxWidth: '400px'}} />
      <Box
        sx={theme => ({
          width: '100%',
          maxWidth: 'sm',
          pt: 1,
          [theme.breakpoints.only('sm')]: {
            maxWidth: '65%',
          },
          [theme.breakpoints.up('sm')]: {
            pt: 1.5,
          },
          [theme.breakpoints.up('md')]: {
            maxWidth: '550px',
            pt: 2,
          },
        })}
      >
        <Typography
          variant="h5"
          width={'100%'}
          sx={theme => ({
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '28px',
            fontWeight: 700,
            [theme.breakpoints.up('md')]: {
              fontSize: '32px',
            },
          })}
        >
          {t('sign-in.title1')}
        </Typography>
        <Typography
          variant="h5"
          width={'100%'}
          sx={theme => ({
            color: theme.palette.primary.main,
            fontSize: '28px',
            fontWeight: 700,
            mt: 1.5,
            [theme.breakpoints.up('md')]: {
              fontSize: '32px',
              mt: 1.75,
            },
          })}
        >
          {t('sign-in.title2')}
        </Typography>
        <Typography
          variant="body1"
          mt={3}
          sx={theme => ({
            fontSize: '18px',
            [theme.breakpoints.up('sm')]: {
              fontSize: '20px',
            },
          })}
        >
          {t('sign-in.description')}
        </Typography>
      </Box>
      <SignInButtonComponent fullWidth sx={{maxWidth: '350px', textTransform: 'none'}} />
      <Box>
        <Typography textAlign="center" variant={'body1'} mb={0.25}>
          {t('app-not-logged-in-learn-more.header')}
        </Typography>
        <Typography textAlign="center" variant={'body1'}>
          {t('app-not-logged-in-learn-more.body')}
          <Link ml={1} target="_blank" underline="none" href={MINMEMORIA_HOME_URL}>
            {WEBSITE_NAME}
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
}

export const LoginComponent = memo(Login);
