import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import {FormControlProps} from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {SxProps, Theme, useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import uniqBy from 'lodash/uniqBy';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {BackButtonComponent} from '../../components/buttons/back-button.component';
import {CancelButtonComponent} from '../../components/buttons/cancel-button.component';
import {GuestbookUploadButtonComponent} from '../../components/buttons/guestbook-upload-button.component';
import {ConfirmCommonGuestbookPostDialogComponent} from '../../components/dialog/confirm-common-guestbook-post-dialog.component';
import {ConfirmLeaveDialogComponent} from '../../components/dialog/confirm-leave-dialog.component';
import {AdminModeCommonGuestbookBannerComponent} from '../../components/guestbook/admin-mode-coomon-guestbook-post-banner.component';
import {CommonGuestbookPostFilesComponent} from '../../components/guestbook/common-guestbook-post-files.component';
import {CommonGuestbookPostInputComponent} from '../../components/guestbook/common-guestbook-post-message-input.component';
import {CommonGuestbookPostPatientsSelectListComponent} from '../../components/guestbook/common-guestbook-post-patients-select-list.component';
import {CommonGuestbookPostSelectedPatientsComponent} from '../../components/guestbook/common-guestbook-post-selected-patients.component';
import {GuestbookFilesLightboxComponent} from '../../components/guestbook/guestbook-file-lightbox.component';
import {OrdinaryModeCommonGuestbookBannerComponent} from '../../components/guestbook/ordinary-mode-coomon-guestbook-post-banner.component';
import {CommonGuestbookPostLoadingComponent} from '../../components/loading/guestbook/common-guestbook-post-loading.component';
import {LoginComponent} from '../../components/login.component';
import {MultipleSelectChipComponent} from '../../components/multiple-select-chip.component';
import {ADMIN_COMMON_GUESTBOOK_POST_ROUTE, COMMON_GUESTBOOK_POST_ROUTE, MY_PATIENTS_ROUTE} from '../../configs/routes';
import {usePatientsByFilters} from '../../hooks/patients/use-all-patients-by-filters';
import {useFormActions} from '../../hooks/use-form-actions';
import {useQuery} from '../../hooks/use-query';
import {useStores} from '../../hooks/use-stores';
import {GuestbookFile} from '../../interfaces/entities/guestbook-file.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {GuestbookPreviewFile} from '../../interfaces/guestbook-preview-file.interface';
import {PatientsFilterOption} from '../../interfaces/patients-filters.interface';
import {BLACK_COLOR, LIGHT_GRAY_COLOR, ORANGE_COLOR} from '../../theme';
import {
  DEPARTMENT_IDS_URL_QUERY,
  INSTITUTION_IDS_URL_QUERY,
  MUNICIPALITY_IDS_URL_QUERY,
  PATIENT_IDS_URL_QUERY,
} from '../../utils/constants';
import {findChildOptions} from '../../utils/find-child-options';
import {getGroupsAndOptionsForDepartmentsFilters} from '../../utils/get-groups-and-options-for-departments-filters';
import {getGroupsAndOptionsForFilters} from '../../utils/get-groups-and-options-for-filters';
import {getRandomId} from '../../utils/get-random-id';
import {isUserAdmin} from '../../utils/is-user-admin';

const FILTER_FORM_CONTROL_PROPS: FormControlProps = {
  sx: theme => ({
    m: 1,
    width: 300,
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: 225,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(2)})`,
    },
  }),
};

const BOX_SHADOW_SECTION = `0 0 20px ${LIGHT_GRAY_COLOR}`;

const SECTION_STYLES: SxProps<Theme> = {
  boxShadow: BOX_SHADOW_SECTION,
};

const LOADING_CIRCULAR_SIZE = 28;

interface CommonGuestbookPostContainerProps {
  adminMode?: boolean;
}

function CommonGuestbookPost({adminMode}: CommonGuestbookPostContainerProps) {
  const {t} = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up('lg'));

  const {authStore, guestbookPostsStore: store, v2PatientsStore: patientsStore} = useStores();
  const [selectedMunicipalities, setSelectedMunicipalities] = useState<PatientsFilterOption['id'][]>([]);

  const [selectedInstitutions, setSelectedInstitutions] = useState<PatientsFilterOption['id'][]>([]);

  const [selectedDepartments, setSelectedDepartments] = useState<PatientsFilterOption['id'][]>([]);

  const [selectedPatients, setSelectedPatients] = useState<Patient[]>([]);

  const [message, setMessage] = useState('');

  const [files, setFiles] = useState<(GuestbookPreviewFile & {id: string})[]>([]);

  const [indexToShow, setIndexToShow] = useState<number | null>(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [loadingPublish, setLoadingPublish] = useState<boolean>(false);

  const [blockLeave, setBlockLeave] = useState(true);

  const [containsInfo, setContainsInfo] = useState<boolean | null>(null);

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore.fetchMe]);

  useEffect(() => {
    patientsStore.fetchFilters(adminMode);
  }, [patientsStore.fetchFilters, adminMode]);

  const {onCancel} = useFormActions(MY_PATIENTS_ROUTE.path);

  const availableInstitutions = useMemo(() => {
    return selectedMunicipalities.length
      ? findChildOptions(patientsStore.filters?.institutions || [], selectedMunicipalities)
      : patientsStore.filters?.institutions || [];
  }, [patientsStore.filters?.institutions, selectedMunicipalities]);

  const availableDepartments = useMemo(() => {
    if (selectedInstitutions.length) {
      return findChildOptions(patientsStore.filters?.departments || [], selectedInstitutions);
    }

    return (patientsStore.filters?.departments || []).filter(({parentIds}) => {
      if (!parentIds) {
        return false;
      }

      return parentIds.some(parentId => availableInstitutions.some(({id}) => id === parentId));
    });
  }, [patientsStore.filters?.departments, availableInstitutions, selectedInstitutions]);

  const handleSelectFiles = useCallback(
    (arr: ReadonlyArray<GuestbookPreviewFile>) => {
      setFiles([...arr.map(item => ({...item, id: getRandomId()})), ...files]);
    },

    [files, setFiles]
  );

  const handleRemoveFile = useCallback(
    (id: string) => {
      setFiles(files.filter(file => file.id !== id));
    },

    [files, setFiles]
  );

  const handleClickFile = useCallback(
    (id: string) => {
      const index = files.findIndex(file => file.id === id);
      setIndexToShow(index === -1 ? null : index);
    },
    [files]
  );

  const onCloseLightBox = useCallback(() => {
    setIndexToShow(null);
  }, [setIndexToShow]);

  const onCloseConfirmDialog = useCallback(() => {
    setShowConfirmDialog(false);
  }, [setShowConfirmDialog]);

  const updateQueryParams = useCallback(
    (paramName: string, value: string[]) => {
      query.delete(paramName);
      if (value.length) {
        query.append(paramName, value.join(','));
      }
      history.replace({
        search: query.toString(),
      });
    },
    [query, history]
  );

  const handleChangeSelectedDepartments = useCallback(
    (value: PatientsFilterOption['id'][]) => {
      setSelectedDepartments(value);
      updateQueryParams(DEPARTMENT_IDS_URL_QUERY, value);
    },
    [updateQueryParams]
  );

  const handleChangeSelectedInstitutions = useCallback(
    (value: PatientsFilterOption['id'][]) => {
      setSelectedInstitutions(value);
      updateQueryParams(INSTITUTION_IDS_URL_QUERY, value);
      if (value.length) {
        handleChangeSelectedDepartments(
          availableDepartments
            .filter(({id, parentIds}) => {
              if (!parentIds) {
                return false;
              }

              if (!selectedDepartments.includes(id)) {
                return false;
              }

              return parentIds.some(parentId => value.includes(parentId));
            })
            .map(({id}) => id)
        );
      } else {
        handleChangeSelectedDepartments([]);
      }
    },
    [handleChangeSelectedDepartments, availableDepartments, selectedDepartments]
  );

  const handleChangeSelectedMunicipalities = useCallback(
    (value: PatientsFilterOption['id'][]) => {
      setSelectedMunicipalities(value);
      updateQueryParams(MUNICIPALITY_IDS_URL_QUERY, value);
      if (value.length) {
        handleChangeSelectedInstitutions(
          availableInstitutions
            .filter(({id, parentIds}) => {
              if (!parentIds) {
                return false;
              }

              if (!selectedInstitutions.includes(id)) {
                return false;
              }

              return parentIds.some(parentId => value.includes(parentId));
            })
            .map(({id}) => id)
        );
      } else {
        handleChangeSelectedInstitutions([]);
      }
    },
    [handleChangeSelectedInstitutions, availableInstitutions, selectedInstitutions]
  );

  const handleClearAllFilters = useCallback(() => {
    handleChangeSelectedDepartments([]);
    handleChangeSelectedInstitutions([]);
    handleChangeSelectedMunicipalities([]);
  }, [handleChangeSelectedDepartments, handleChangeSelectedInstitutions, handleChangeSelectedMunicipalities]);

  const {institutionGroups, institutionOptions} = useMemo(() => {
    const {groups: institutionGroups, options: institutionOptions} = getGroupsAndOptionsForFilters(
      patientsStore.filters?.municipalities || [],
      availableInstitutions,
      t('common-guestbook-post-institution-not-found-municipality.group')
    );
    return {institutionGroups, institutionOptions};
  }, [availableInstitutions, patientsStore.filters?.municipalities, selectedMunicipalities, t]);

  const {departmentGroups, departmentOptions} = useMemo(() => {
    const {groups: departmentGroups, options: departmentOptions} = getGroupsAndOptionsForDepartmentsFilters(
      patientsStore.filters?.municipalities || [],
      patientsStore.filters?.institutions || [],
      availableDepartments || [],
      t('common-guestbook-post-institution-not-found-institution.group')
    );
    return {departmentGroups, departmentOptions};
  }, [availableDepartments, patientsStore.filters?.institutions, selectedInstitutions, t]);

  const isAdmin = useMemo(() => {
    return authStore.me && isUserAdmin(authStore.me);
  }, [authStore.me]);

  useEffect(() => {
    if (patientsStore.loadingFilters !== false || !patientsStore.filters) {
      return;
    }

    const municipalities = patientsStore.filters.municipalities;
    const institutions = patientsStore.filters.institutions;
    const departments = patientsStore.filters.departments;

    const municipalitiesFromQuery = (query.get(MUNICIPALITY_IDS_URL_QUERY) || '').split(',').filter(Boolean);
    const institutionsFromQuery = (query.get(INSTITUTION_IDS_URL_QUERY) || '').split(',').filter(Boolean);

    const departmentsFromQuery = (query.get(DEPARTMENT_IDS_URL_QUERY) || '').split(',').filter(Boolean);

    const municipalitiesToSelect = municipalitiesFromQuery.filter(id => municipalities.some(item => item.id === id));

    handleChangeSelectedMunicipalities(municipalitiesToSelect);

    const institutionsToSelect = institutionsFromQuery.filter(id =>
      findChildOptions(institutions, municipalitiesToSelect).some(item => item.id === id)
    );

    handleChangeSelectedInstitutions(institutionsToSelect);

    const departmentsToSelect = departmentsFromQuery.filter(id =>
      findChildOptions(departments, institutionsToSelect).some(item => item.id === id)
    );
    handleChangeSelectedDepartments(departmentsToSelect);
  }, [
    patientsStore.loadingFilters,
    patientsStore.filters?.municipalities,
    patientsStore.filters?.institutions,
    patientsStore.filters?.departments,
  ]);

  useEffect(() => {
    if (
      patientsStore.loadingFilters === false &&
      patientsStore.filters &&
      patientsStore.filters.municipalities.length === 1 &&
      !query.get(MUNICIPALITY_IDS_URL_QUERY)
    ) {
      handleChangeSelectedMunicipalities([patientsStore.filters.municipalities[0].id]);
    }
  }, [patientsStore.loadingFilters, patientsStore.filters?.municipalities]);

  useEffect(() => {
    if (
      availableInstitutions.length === 1 &&
      selectedMunicipalities.length === 1 &&
      !query.get(INSTITUTION_IDS_URL_QUERY)
    ) {
      handleChangeSelectedInstitutions([availableInstitutions[0].id]);
    }
  }, [availableInstitutions, selectedMunicipalities]);

  useEffect(() => {
    if (
      availableDepartments.length === 1 &&
      selectedInstitutions.length === 1 &&
      selectedMunicipalities.length === 1 &&
      !query.get(DEPARTMENT_IDS_URL_QUERY)
    ) {
      handleChangeSelectedInstitutions([availableDepartments[0].id]);
    }
  }, [availableDepartments, selectedMunicipalities, selectedInstitutions]);

  const {items: patients, loading: loadingPatients} = usePatientsByFilters(
    selectedDepartments.length
      ? selectedDepartments
      : selectedInstitutions.length
        ? selectedInstitutions
        : selectedMunicipalities,
    adminMode
  );

  useEffect(() => {
    if (loadingPatients !== false || !patients) {
      return;
    }

    const patientsFromQuery = (query.get(PATIENT_IDS_URL_QUERY) || '').split(',').filter(Boolean);

    const patientsToSelect = patients.filter(
      patient =>
        patientsFromQuery.includes(patient.id) &&
        !selectedPatients.some(selectedPatient => selectedPatient.id === patient.id)
    );

    if (patientsToSelect.length) {
      const arr = uniqBy([...selectedPatients, ...patientsToSelect], 'id');
      setSelectedPatients(arr);
    }
  }, [loadingPatients, patients, selectedPatients]);

  const handleSelectPatients = useCallback(
    (ids: Patient['id'][]) => {
      const arr = uniqBy([...(patients || []).filter(({id}) => ids.includes(id)), ...selectedPatients], 'id');
      setSelectedPatients(arr);
      updateQueryParams(
        PATIENT_IDS_URL_QUERY,
        arr.map(({id}) => id)
      );
    },
    [selectedPatients, updateQueryParams, patients]
  );

  const handleDeselectPatients = useCallback(
    (ids: Patient['id'][]) => {
      const arr = selectedPatients.filter(({id}) => !ids.includes(id));
      setSelectedPatients(arr);
      updateQueryParams(
        PATIENT_IDS_URL_QUERY,
        arr.map(({id}) => id)
      );
    },
    [selectedPatients, updateQueryParams]
  );

  const handleDeselectOnePatient = useCallback(
    (id: Patient['id']) => {
      handleDeselectPatients([id]);
    },
    [handleDeselectPatients]
  );

  const handleClickSend = useCallback(() => {
    setShowConfirmDialog(true);
  }, []);

  const handleSubmit = useCallback(
    async (ids: string[]) => {
      setBlockLeave(false);
      setTimeout(async () => {
        setLoadingPublish(true);
        await store.createCommon(
          {
            message: message,
            guestbookFiles: files as GuestbookFile[],
            patients: ids,
          },
          adminMode
        );
        setLoadingPublish(false);
        setShowConfirmDialog(false);
        setMessage('');
        setFiles([]);
        setContainsInfo(null);
        history.push(MY_PATIENTS_ROUTE.path);
      }, 500);
    },
    [store.createCommon, message, files, history, adminMode]
  );

  const selectedPatientIds = useMemo(() => {
    return selectedPatients.map(({id}) => id);
  }, [selectedPatients]);

  const onClickSelectAllPatients = useCallback(() => {
    handleSelectPatients(
      (patients || []).reduce((arr: Patient['id'][], {id}) => {
        if (!selectedPatientIds.includes(id)) {
          arr.push(id);
        }
        return arr;
      }, [])
    );
  }, [handleSelectPatients, selectedPatientIds, patients]);

  const onClickDeselectAllPatients = useCallback(() => {
    handleDeselectPatients(
      (patients || []).reduce((arr: Patient['id'][], {id}) => {
        if (selectedPatientIds.includes(id)) {
          arr.push(id);
        }
        return arr;
      }, [])
    );
  }, [handleDeselectPatients, selectedPatientIds, patients]);

  const handleSwitchToOrdinaryMode = useCallback(() => {
    history.push(COMMON_GUESTBOOK_POST_ROUTE.path);
  }, [history]);

  const handleSwitchToAdminMode = useCallback(() => {
    history.push(ADMIN_COMMON_GUESTBOOK_POST_ROUTE.path);
  }, [history]);

  const allSelected = useMemo(() => {
    if (!patients) {
      return false;
    }
    return !patients.some(({id}) => !selectedPatientIds.includes(id));
  }, [selectedPatientIds, patients]);

  const disabledSendButton = useMemo(() => {
    return !selectedPatients.length || (!message && !files.length);
  }, [selectedPatients, files, message]);

  const showSelectOneFilter = useMemo(() => {
    return adminMode && !selectedMunicipalities.length && !selectedInstitutions.length && !selectedDepartments.length;
  }, [adminMode, selectedMunicipalities.length, selectedInstitutions.length, selectedDepartments.length]);

  if (authStore.loading) {
    return <CommonGuestbookPostLoadingComponent />;
  }

  if (!authStore.me) {
    return <LoginComponent />;
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          pb: 1,
        }}
      >
        <BackButtonComponent path={MY_PATIENTS_ROUTE.path} />
        <CancelButtonComponent variant="contained" onClick={onCancel} endIcon={<CancelOutlinedIcon />} />
      </Stack>
      {adminMode && isAdmin && (
        <AdminModeCommonGuestbookBannerComponent sx={{my: 2}} onClickSwitch={handleSwitchToOrdinaryMode} />
      )}
      {!adminMode && isAdmin && (
        <OrdinaryModeCommonGuestbookBannerComponent sx={{my: 2}} onClickSwitch={handleSwitchToAdminMode} />
      )}
      <Typography
        variant="h6"
        sx={{
          pb: 0.5,
        }}
      >
        {t('common-guestbook-post.title')}
      </Typography>
      <Typography variant="subtitle1" pb={0.75}>
        {t('common-guestbook-post.description')}
      </Typography>

      <Box
        sx={theme => ({
          minHeight: selectedPatients.length > 0 ? theme.spacing(4) : 0,
          position: 'relative',
        })}
      >
        <Box
          sx={{
            overflowX: 'auto',
          }}
        >
          <CommonGuestbookPostSelectedPatientsComponent
            patients={selectedPatients}
            onDelete={handleDeselectOnePatient}
          />
        </Box>

        <Box
          sx={theme => ({
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: theme.spacing(3.5), // Adjust the width of the fade as needed
            background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
          })}
        />
      </Box>

      <Box
        position={'relative'}
        mt={0.75}
        sx={theme => ({
          boxShadow: BOX_SHADOW_SECTION,
          px: 2.5,
          py: 1.5,
          [theme.breakpoints.down('lg')]: {
            px: 2,
            py: 1.25,
          },
          [theme.breakpoints.down('md')]: {
            px: 1.75,
            py: 1.25,
          },
          [theme.breakpoints.down('sm')]: {
            px: 1.25,
            py: 1,
          },
        })}
      >
        <Typography variant="body1" fontWeight={500} pt={1} pb={2}>
          {t('guestbook-new-post.button')}
        </Typography>
        <CommonGuestbookPostInputComponent message={message} onChange={setMessage} user={authStore.me} />
        <Box position={'relative'} pt={files.length > 0 ? 1 : 0}>
          <Box
            sx={theme => ({
              maxHeight: 350,
              pb: files.length > 0 ? 3.5 : 0,

              [theme.breakpoints.down('lg')]: {
                maxHeight: 325,
              },
              [theme.breakpoints.down('md')]: {
                maxHeight: 275,
              },
              [theme.breakpoints.up('lg')]: {
                pb: files.length <= 4 ? 1 : 3,
              },
              [theme.breakpoints.only('md')]: {
                pb: files.length <= 3 ? 1 : 3,
              },
              [theme.breakpoints.only('sm')]: {
                pb: files.length <= 2 ? 1 : 3,
              },
              [theme.breakpoints.only('xs')]: {
                pb: files.length <= 2 ? 1 : 3,
              },
            })}
            overflow={'auto'}
          >
            <CommonGuestbookPostFilesComponent files={files} onDelete={handleRemoveFile} onClick={handleClickFile} />
          </Box>
          <Box
            sx={theme => ({
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              height: theme.spacing(3.5),
              background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
              [theme.breakpoints.up('lg')]: {
                display: files.length <= 4 ? 'none' : undefined,
              },
              [theme.breakpoints.only('md')]: {
                display: files.length <= 3 ? 'none' : undefined,
              },
              [theme.breakpoints.only('sm')]: {
                display: files.length <= 2 ? 'none' : undefined,
              },
              [theme.breakpoints.only('xs')]: {
                display: files.length <= 2 ? 'none' : undefined,
              },
            })}
          />
        </Box>
        <Grid
          container
          rowSpacing={1.25}
          columnSpacing={{sm: 1.5, lg: 2.5}}
          sx={theme => ({
            pt: 2,
            [theme.breakpoints.down('sm')]: {
              pt: 1.25,
            },
          })}
        >
          <Grid item xs={12} sm={6}>
            <GuestbookUploadButtonComponent
              onSelectFiles={handleSelectFiles}
              variant={'outlined'}
              startIcon={<ImageOutlinedIcon sx={{color: ORANGE_COLOR}} />}
              sx={{
                color: BLACK_COLOR,
                textTransform: 'none',
                borderRadius: '5px',
                boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.30)',
                '&:hover': {
                  backgroundColor: LIGHT_GRAY_COLOR,
                  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.30)',
                },
              }}
            >
              {t('common-guestbook-post-upload.button')}
            </GuestbookUploadButtonComponent>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SendIcon />}
              disabled={disabledSendButton}
              onClick={handleClickSend}
              sx={theme => ({
                borderRadius: '5px',
                boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.30)',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.30)',
                },
              })}
            >
              {t('guestbook-send-post.button')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}></Stack>

      <Typography
        variant="h6"
        sx={{
          pt: 2,
          pb: 0.5,
        }}
      >
        {t('common-guestbook-post-select-patient.title')}
      </Typography>
      <Typography variant="subtitle1" pb={0.75}>
        {t('common-guestbook-post-select-patient.description')}
      </Typography>
      <Box sx={SECTION_STYLES} position={'relative'}>
        {adminMode && (
          <Stack
            direction={{xs: 'column', sm: 'row'}}
            justifyContent={patientsStore.loadingFilters === false ? 'space-between' : 'center'}
            alignItems={{xs: 'stretch', sm: 'center'}}
            spacing={{xs: 1, sm: 1.4, md: 2}}
          >
            {patientsStore.loadingFilters === false ? (
              <>
                <Stack
                  direction={{xs: 'column', sm: 'row'}}
                  justifyContent="flex-start"
                  alignItems={{xs: 'stretch', sm: 'center'}}
                  spacing={{xs: 0, sm: -1, md: -0.25, lg: 2}}
                >
                  <MultipleSelectChipComponent
                    label={t('common-guestbook-post-municipality.select')}
                    options={patientsStore.filters?.municipalities || []}
                    formControlProps={FILTER_FORM_CONTROL_PROPS}
                    value={selectedMunicipalities}
                    onChange={handleChangeSelectedMunicipalities}
                    allOrNone={(patientsStore.filters?.municipalities || []).length > 1}
                  />
                  {availableInstitutions.length > 0 && (
                    <MultipleSelectChipComponent
                      label={t('common-guestbook-post-institution.select')}
                      groups={institutionGroups}
                      options={institutionOptions}
                      formControlProps={FILTER_FORM_CONTROL_PROPS}
                      value={selectedInstitutions}
                      onChange={handleChangeSelectedInstitutions}
                      allOrNone={availableInstitutions.length > 1}
                    />
                  )}
                  {availableDepartments.length > 0 && (
                    <MultipleSelectChipComponent
                      label={t('common-guestbook-post-department.select')}
                      groups={departmentGroups}
                      options={departmentOptions}
                      formControlProps={FILTER_FORM_CONTROL_PROPS}
                      value={selectedDepartments}
                      onChange={handleChangeSelectedDepartments}
                      allOrNone={availableDepartments.length > 1}
                    />
                  )}

                  {(selectedMunicipalities.length > 0 ||
                    selectedInstitutions.length > 0 ||
                    selectedDepartments.length > 0) && (
                    <Button
                      variant="text"
                      onClick={handleClearAllFilters}
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {`${t('common-guestbook-post-clear-all-filters.button')}`}
                    </Button>
                  )}
                </Stack>
                {desktopView && !showSelectOneFilter && (
                  <FormControlLabel
                    sx={{
                      textDecoration: 'underline',
                      pr: 2,
                    }}
                    control={
                      <Checkbox
                        edge="end"
                        checked={allSelected}
                        inputProps={{'aria-labelledby': 'select-all'}}
                        onClick={!allSelected ? onClickSelectAllPatients : onClickDeselectAllPatients}
                      />
                    }
                    label={'' + (!allSelected ? t('general-select-all.button') : t('general-select-none.button'))}
                    labelPlacement="start"
                  />
                )}
              </>
            ) : (
              <CircularProgress size={LOADING_CIRCULAR_SIZE} />
            )}
          </Stack>
        )}
        <Box maxHeight={300} overflow={'auto'} pb={1}>
          {!showSelectOneFilter ? (
            <CommonGuestbookPostPatientsSelectListComponent
              selected={selectedPatientIds}
              patients={patients || []}
              allOrNone={!adminMode}
              loadingElement={
                <CircularProgress
                  sx={{
                    m: 'auto',
                    mb: 1,
                  }}
                  size={LOADING_CIRCULAR_SIZE}
                />
              }
              onSelect={handleSelectPatients}
              onDeselect={handleDeselectPatients}
              loading={loadingPatients !== false}
            />
          ) : (
            <Typography variant="body2" textAlign={'center'} fontStyle={'italic'}>
              {t('admin-mode-common-guestbook-post-select-filter')}
            </Typography>
          )}
        </Box>
        <Box
          sx={theme => ({
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: theme.spacing(3.5),
            width: '100%', // Adjust the width of the fade as needed
            background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
          })}
        />
      </Box>
      {indexToShow !== null && (
        <GuestbookFilesLightboxComponent files={files} defaultIndex={indexToShow} onClose={onCloseLightBox} />
      )}

      <ConfirmCommonGuestbookPostDialogComponent
        isOpen={showConfirmDialog}
        onClose={onCloseConfirmDialog}
        onConfirm={handleSubmit}
        patients={selectedPatients}
        loadingConfirm={loadingPublish}
        containsInfo={containsInfo}
        onChangeContainsInfo={setContainsInfo}
      />

      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-post-warning.body')}
        stayButtonText={t('modal-leave-post-warning-continue.button')}
        leaveButtonText={t('modal-leave-post-warning-discard.button')}
      />
    </>
  );
}

export const CommonGuestbookPostContainer = observer(CommonGuestbookPost);
