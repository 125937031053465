import TextField, {TextFieldProps} from '@mui/material/TextField';
import {FieldProps} from 'formik';
import React, {memo} from 'react';

import {FieldWrapperComponent, FieldWrapperProps} from './field-wrapper.component';

const DEFAULT_MIN_ROWS = 3;

interface MultiLinesFieldProps extends FieldProps, Pick<FieldWrapperProps, 'assistiveText' | 'assistiveTextPosition'> {
  label?: string;
  minRows?: number;
  InputProps?: TextFieldProps['InputProps'];
  placeholder?: TextFieldProps['placeholder'];
}

function MultiLinesFieldBase({minRows = DEFAULT_MIN_ROWS, InputProps, placeholder, ...props}: MultiLinesFieldProps) {
  return (
    <FieldWrapperComponent {...props}>
      <TextField
        error={props.meta.touched && !!props.meta.error}
        fullWidth
        variant="outlined"
        label={props.label}
        multiline
        minRows={minRows}
        InputProps={InputProps}
        placeholder={placeholder}
        {...props.field}
      />
    </FieldWrapperComponent>
  );
}

export const MultiLinesField = memo(MultiLinesFieldBase);
