import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {BackButtonComponent} from '../../components/buttons/back-button.component';
import {ConfirmLeaveDialogComponent} from '../../components/dialog/confirm-leave-dialog.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {PhotoEditorComponent} from '../../components/photo-editor.component';
import {PROFILE_ROUTE_RELATIVES} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {RelativeForm} from '../../forms/relative.form';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useFormActions} from '../../hooks/use-form-actions';
import {usePhotoEditor} from '../../hooks/use-photo-editor';
import {useStores} from '../../hooks/use-stores';
import {Relative} from '../../interfaces/entities/relative.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';

function RelativesNew({profileId}: ProfilePageProps) {
  const {t} = useTranslation();
  const {relativesStore} = useStores();
  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const [loading, setLoading] = useState(false);
  const [blockLeave, setBlockLeave] = useState<boolean>(true);
  const {props: photoEditorProps, getPhoto, isChanged: photoIsChanged} = usePhotoEditor();

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_RELATIVES.path}`;

  const {onCancel, onConfirm} = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Relative>) => {
      setLoading(true);
      if (!photoIsChanged) {
        await relativesStore.createByPatientId(profileId, data);
      } else {
        const image = await getPhoto();
        if (!image) {
          await relativesStore.createByPatientId(profileId, {
            ...data,
            image: null,
          });
        } else {
          await relativesStore.createByPatientIdWithPhoto(profileId, data, image as File);
        }
      }
      setLoading(false);
      setBlockLeave(false);
      setTimeout(() => {
        onConfirm();
        setBlockLeave(true);
      }, 500);
    },
    [
      onConfirm,
      profileId,
      setLoading,
      relativesStore.createByPatientId,
      relativesStore.createByPatientIdWithPhoto,
      photoIsChanged,
      getPhoto,
    ]
  );

  if (!isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'}>{t('relative-edit-profile-picture.header')}</Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <PhotoEditorComponent {...photoEditorProps} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'} pt={1}>
            {t('relative-edit-bio.header')}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <RelativeForm onSave={onSave} onCancel={onCancel} resetFormAfterCancel={false} />
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={loading} />
      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-relative-warning.body')}
        stayButtonText={t('modal-leave-relative-warning-stay.button')}
        leaveButtonText={t('modal-leave-relative-warning-leave.button')}
      />
    </>
  );
}

export const RelativesNewContainer = observer(RelativesNew);
