import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {useFormattedProfile} from '../../hooks/patients/use-formatted-profile';
import {useProgressiveImage} from '../../hooks/use-progressive-image';
import {Patient} from '../../interfaces/entities/patient.interface';
import {BLACK_COLOR} from '../../theme';
import {ImageComponent} from '../image.component';
import {
  DESKTOP_IMAGE_HEIGHT,
  DESKTOP_IMAGE_WIDTH,
  MOBILE_IMAGE_HEIGHT,
  MOBILE_IMAGE_WIDTH,
} from './profile-short-info.component';

interface ProfileFullInfoComponentProps extends Patient {
  mobile?: boolean;
}

function ProfileFullInfoComponentBase({mobile = false, image, ...profile}: ProfileFullInfoComponentProps) {
  const {t} = useTranslation();

  const {name, birthDate, age, careFacility} = useFormattedProfile(profile);

  const [imageWidth, imageHeight] = useMemo(() => {
    return mobile ? [MOBILE_IMAGE_WIDTH, MOBILE_IMAGE_HEIGHT] : [DESKTOP_IMAGE_WIDTH, DESKTOP_IMAGE_HEIGHT];
  }, [mobile]);

  const [imageSrc, {blur}] = useProgressiveImage(image, {
    height: imageHeight,
    width: imageWidth,
  });

  return (
    <Box>
      <Grid container spacing={{xs: 1, md: 2}}>
        <Grid item>
          <Box
            position="relative"
            sx={{
              filter: blur ? 'blur(20px)' : 'none',
              transition: blur ? 'none' : 'filter 0.3s ease-out',
              width: imageWidth,
              height: imageHeight,
            }}
          >
            <ImageComponent
              image={imageSrc}
              imageFullScreen={image}
              title={name}
              cardMediaSx={{
                pt: `${100 * (Math.round((imageHeight / imageWidth) * 100) / 100)}%`,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs container justifyContent="center" direction="column" spacing={{xs: 1, sm: 1.5, md: 2.5}}>
          <Grid item>
            <Typography variant={!mobile ? 'h4' : 'h6'} color={BLACK_COLOR}>
              {`${name}${profile.nickname ? ` (${profile.nickname})` : ''}`}
            </Typography>
          </Grid>
          <Grid item container rowSpacing={{xs: 1, md: 1.5}} columnSpacing={{xs: 1.5, md: 2}}>
            {!!birthDate && (
              <>
                <Grid item xs={6} sm={5} md={4} lg={2}>
                  <Typography variant={!mobile ? 'body1' : 'body2'}>{t('birth-date.label')}:</Typography>
                </Grid>
                <Grid item xs={6} sm={7} md={8} lg={10}>
                  <Typography variant={!mobile ? 'body1' : 'body2'}>{`${birthDate} (${`${age}  ${t(
                    'persona-age.label'
                  )}`})`}</Typography>
                </Grid>
              </>
            )}
            {profile.birthPlace && (
              <>
                <Grid item xs={6} sm={5} md={4} lg={2}>
                  <Typography variant={!mobile ? 'body1' : 'body2'}>{t('birth-place.label')}:</Typography>
                </Grid>
                <Grid item xs={6} sm={7} md={8} lg={10}>
                  <Typography variant={!mobile ? 'body1' : 'body2'}>{profile.birthPlace}</Typography>
                </Grid>
              </>
            )}
            {!!careFacility && (
              <>
                <Grid item xs={6} sm={5} md={4} lg={2}>
                  <Typography variant={!mobile ? 'body1' : 'body2'}>{t('personal.affiliation')}:</Typography>
                </Grid>
                <Grid item xs={6} sm={7} md={8} lg={10}>
                  <Typography variant={!mobile ? 'body1' : 'body2'}>{careFacility}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {profile.description && (
        <>
          <Grid item>
            <Typography
              variant={!mobile ? 'h5' : 'subtitle1'}
              pt={{xs: 2, md: 4}}
              pb={{xs: 0.5, md: 1}}
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {
                  fontWeight: 500,
                },
              })}
            >
              {t('persona-description.header', {firstName: profile.firstName})}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={!mobile ? 'body1' : 'body2'} whiteSpace={'pre-line'}>
              {profile.description}
            </Typography>
          </Grid>
        </>
      )}
      {profile.interests && (
        <>
          <Typography
            variant={!mobile ? 'h5' : 'subtitle1'}
            pt={{xs: 2, md: 4}}
            pb={{xs: 0.5, md: 1}}
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                fontWeight: 500,
              },
            })}
          >
            {t('persona-interests.header')}:
          </Typography>
          <Typography variant={!mobile ? 'body1' : 'body2'} whiteSpace={'pre-line'}>
            {profile.interests}
          </Typography>
        </>
      )}
      {profile.habits && (
        <>
          <Typography
            variant={!mobile ? 'h5' : 'subtitle1'}
            pt={{xs: 2, md: 4}}
            pb={{xs: 0.5, md: 1}}
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                fontWeight: 500,
              },
            })}
          >
            {t('persona-habits.header')}:
          </Typography>
          <Typography variant={!mobile ? 'body1' : 'body2'} whiteSpace={'pre-line'}>
            {profile.habits}
          </Typography>
        </>
      )}
      {profile.music && (
        <>
          <Typography
            variant={!mobile ? 'h5' : 'subtitle1'}
            pt={{xs: 2, md: 4}}
            pb={{xs: 0.5, md: 1}}
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                fontWeight: 500,
              },
            })}
          >
            {t('persona-music.header')}:
          </Typography>
          <Typography variant={!mobile ? 'body1' : 'body2'} whiteSpace={'pre-line'}>
            {profile.music}
          </Typography>
        </>
      )}

      {profile.relax && (
        <>
          <Typography
            variant={!mobile ? 'h5' : 'subtitle1'}
            pt={{xs: 2, md: 4}}
            pb={{xs: 0.5, md: 1}}
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                fontWeight: 500,
              },
            })}
          >
            {t('persona-relax.header')}:
          </Typography>
          <Typography variant={!mobile ? 'body1' : 'body2'} whiteSpace={'pre-line'}>
            {profile.relax}
          </Typography>
        </>
      )}
    </Box>
  );
}

export const ProfileFullInfoComponent = memo(ProfileFullInfoComponentBase);
