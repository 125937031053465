import {AccessesListContainer} from '../containers/accesses/accesses-list.container';
import {AlbumsListContainer} from '../containers/albums/albums-list.container';
import {AlbumsNewContainer} from '../containers/albums/albums-new.container';
import {AlbumsOneContainer} from '../containers/albums/albums-one.container';
import {AlbumsOneEditContainer} from '../containers/albums/albums-one-edit.container';
import {FavoritesAlbumContainer} from '../containers/albums/favorites-album.container';
import {GuestbookAlbumContainer} from '../containers/albums/guestbook-album.container';
import {GuestbookAlbumEditContainer} from '../containers/albums/guestbook-album-edit.container';
import {GuestbookContainer} from '../containers/guestbook/guestbook.container';
import {InvitationsNewToPatientContainer} from '../containers/invitations/invitations-new-to-patient.container';
import {PatientsOneEditContainer} from '../containers/patients/patients-one-edit.container';
import {RelativesListContainer} from '../containers/relatives/relatives-list.container';
import {RelativesNewContainer} from '../containers/relatives/relatives-new.container';
import {RelativesOneContainer} from '../containers/relatives/relatives-one.container';
import {RelativesOneEditContainer} from '../containers/relatives/relatives-one-edit.container';
import {StoriesListContainer} from '../containers/stories/stories-list.container';
import {StoriesNewContainer} from '../containers/stories/stories-new.container';
import {StoriesOneContainer} from '../containers/stories/stories-one.container';
import {StoriesOneEditContainer} from '../containers/stories/stories-one-edit.container';
import {ProfileRouteItem} from '../interfaces/profile-route-item.interface';
import {FAVORITES_FOLDER_ID, GUESTBOOK_ALBUM_ID} from '../utils/constants';
import {
  PROFILE_NAVIGATION_ITEM_ACCESS,
  PROFILE_NAVIGATION_ITEM_ALBUM_FILES,
  PROFILE_NAVIGATION_ITEM_HOME,
  PROFILE_NAVIGATION_ITEM_INVITATIONS,
  PROFILE_NAVIGATION_ITEM_RELATIVES,
  PROFILE_NAVIGATION_ITEM_STORIES,
} from './profile-navigation';

export const EDIT_PATH = 'edit';
export const CREATE_PATH = 'new';

export const PROFILE_ROUTE: ProfileRouteItem = {
  key: 'router-patient',
  title: PROFILE_NAVIGATION_ITEM_HOME.label,
  tooltip: PROFILE_NAVIGATION_ITEM_HOME.label,
  path: `${PROFILE_NAVIGATION_ITEM_HOME.to}`,
  component: GuestbookContainer,
  exact: true,
};

export const PROFILE_ROUTE_EDIT: ProfileRouteItem = {
  key: 'router-patient-edit',
  title: PROFILE_NAVIGATION_ITEM_HOME.label,
  tooltip: PROFILE_NAVIGATION_ITEM_HOME.label,
  path: `${PROFILE_ROUTE.path}${EDIT_PATH}`,
  component: PatientsOneEditContainer,
  exact: true,
};

export const PROFILE_ROUTE_RELATIVES: ProfileRouteItem = {
  key: 'router-patient-relatives',
  title: PROFILE_NAVIGATION_ITEM_RELATIVES.label,
  tooltip: PROFILE_NAVIGATION_ITEM_RELATIVES.label,
  path: `${PROFILE_NAVIGATION_ITEM_RELATIVES.to}`,
  component: RelativesListContainer,
  exact: true,
};

export const PROFILE_ROUTE_RELATIVE_CREATE: ProfileRouteItem = {
  key: 'router-patient-relatives-new',
  title: 'relative profile',
  tooltip: 'relative profile',
  path: `${PROFILE_ROUTE_RELATIVES.path}/${CREATE_PATH}`,
  component: RelativesNewContainer,
  exact: true,
};

export const PROFILE_ROUTE_RELATIVE: ProfileRouteItem = {
  key: 'router-patient-relative-info',
  title: 'relative profile',
  tooltip: 'relative profile',
  path: `${PROFILE_ROUTE_RELATIVES.path}/:relativeId`,
  component: RelativesOneContainer,
  exact: true,
};

export const PROFILE_ROUTE_RELATIVE_EDIT: ProfileRouteItem = {
  key: 'router-patient-relative-one-edit',
  title: 'relative profile',
  tooltip: 'relative profile',
  path: `${PROFILE_ROUTE_RELATIVES.path}/:relativeId/${EDIT_PATH}`,
  component: RelativesOneEditContainer,
  exact: true,
};

export const PROFILE_ROUTE_STORIES: ProfileRouteItem = {
  key: 'router-patient-stories',
  title: PROFILE_NAVIGATION_ITEM_STORIES.label,
  tooltip: PROFILE_NAVIGATION_ITEM_STORIES.label,
  path: `${PROFILE_NAVIGATION_ITEM_STORIES.to}`,
  component: StoriesListContainer,
  exact: true,
};

export const PROFILE_ROUTE_STORY: ProfileRouteItem = {
  key: 'router-persona-story-info',
  title: 'story',
  tooltip: 'story',
  path: `${PROFILE_ROUTE_STORIES.path}/:storyId`,
  component: StoriesOneContainer,
  exact: true,
};

export const PROFILE_ROUTE_STORY_CREATE: ProfileRouteItem = {
  key: 'router-profile-stories-new',
  title: 'story profile',
  tooltip: 'story profile',
  path: `${PROFILE_ROUTE_STORIES.path}/${CREATE_PATH}`,
  component: StoriesNewContainer,
  exact: true,
};

export const PROFILE_ROUTE_STORY_EDIT: ProfileRouteItem = {
  key: 'router-profile-stories-one-edit',
  title: 'story profile',
  tooltip: 'story profile',
  path: `${PROFILE_ROUTE_STORIES.path}/:storyId/${EDIT_PATH}`,
  component: StoriesOneEditContainer,
  exact: true,
};

export const PROFILE_ROUTE_ALBUMS: ProfileRouteItem = {
  key: 'router-profile-albums',
  title: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  tooltip: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  path: `${PROFILE_NAVIGATION_ITEM_ALBUM_FILES.to}`,
  component: AlbumsListContainer,
  exact: true,
};

export const PROFILE_ROUTE_GUESTBOOK_ALBUM: ProfileRouteItem = {
  key: 'router-profile-guestbook-album',
  title: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  tooltip: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  path: `${PROFILE_ROUTE_ALBUMS.path}/${GUESTBOOK_ALBUM_ID}`,
  component: GuestbookAlbumContainer,
  exact: true,
};

export const PROFILE_ROUTE_GUESTBOOK_ALBUM_EDIT: ProfileRouteItem = {
  key: 'router-profile-album',
  title: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  tooltip: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  path: `${PROFILE_ROUTE_ALBUMS.path}/${GUESTBOOK_ALBUM_ID}/${EDIT_PATH}`,
  component: GuestbookAlbumEditContainer,
  exact: true,
};

export const PROFILE_ROUTE_FAVORITES_ALBUM: ProfileRouteItem = {
  key: 'router-profile-favorites-album',
  title: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  tooltip: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  path: `${PROFILE_ROUTE_ALBUMS.path}/${FAVORITES_FOLDER_ID}`,
  component: FavoritesAlbumContainer,
  exact: true,
};

export const PROFILE_ROUTE_ALBUM: ProfileRouteItem = {
  key: 'router-profile-album',
  title: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  tooltip: PROFILE_NAVIGATION_ITEM_ALBUM_FILES.label,
  path: `${PROFILE_ROUTE_ALBUMS.path}/:albumId`,
  component: AlbumsOneContainer,
  exact: true,
};

export const PROFILE_ROUTE_ALBUM_CREATE: ProfileRouteItem = {
  key: 'router-profile-albums-new',
  title: 'album profile',
  tooltip: 'album profile',
  path: `${PROFILE_ROUTE_ALBUMS.path}/${CREATE_PATH}`,
  component: AlbumsNewContainer,
  exact: true,
};

export const PROFILE_ROUTE_ALBUM_EDIT: ProfileRouteItem = {
  key: 'router-profile-albums-one-edit',
  title: 'album profile',
  tooltip: 'album profile',
  path: `${PROFILE_ROUTE_ALBUMS.path}/:albumId/${EDIT_PATH}`,
  component: AlbumsOneEditContainer,
  exact: true,
};

export const PROFILE_ROUTE_ACCESSES: ProfileRouteItem = {
  key: 'router-profile-accesses',
  title: PROFILE_NAVIGATION_ITEM_ACCESS.label,
  tooltip: PROFILE_NAVIGATION_ITEM_ACCESS.label,
  path: `${PROFILE_NAVIGATION_ITEM_ACCESS.to}`,
  component: AccessesListContainer,
  exact: true,
};

export const PROFILE_ROUTE_INVITATION_CREATE: ProfileRouteItem = {
  key: 'router-profile-invitations-new',
  title: 'invitation profile',
  tooltip: 'invitation profile',
  path: `${PROFILE_NAVIGATION_ITEM_INVITATIONS.to}/${CREATE_PATH}`,
  component: InvitationsNewToPatientContainer,
  exact: true,
};

export const profileRoutes: ReadonlyArray<ProfileRouteItem> = [
  PROFILE_ROUTE,
  PROFILE_ROUTE_EDIT,
  PROFILE_ROUTE_RELATIVES,
  PROFILE_ROUTE_RELATIVE_CREATE,
  PROFILE_ROUTE_RELATIVE,
  PROFILE_ROUTE_RELATIVE_EDIT,
  PROFILE_ROUTE_STORIES,
  PROFILE_ROUTE_STORY_CREATE,
  PROFILE_ROUTE_STORY,
  PROFILE_ROUTE_STORY_EDIT,
  PROFILE_ROUTE_ALBUMS,
  PROFILE_ROUTE_ALBUM_CREATE,
  PROFILE_ROUTE_GUESTBOOK_ALBUM,
  PROFILE_ROUTE_GUESTBOOK_ALBUM_EDIT,
  PROFILE_ROUTE_FAVORITES_ALBUM,
  PROFILE_ROUTE_ALBUM,
  PROFILE_ROUTE_ALBUM_EDIT,
  PROFILE_ROUTE_ACCESSES,
  PROFILE_ROUTE_INVITATION_CREATE,
];
