import {useEffect} from 'react';

import {DraftPatient} from '../../interfaces/entities/draft-patient.interface';
import {DraftPatientsStore} from '../../stores/draft-patients.store';
import {useStores} from '../use-stores';

export const useDraftPatients = (): {
  items?: ReadonlyArray<DraftPatient>;
  loadingItems?: boolean;
  delete: (id: DraftPatient['id']) => Promise<void>;
  loadingDelete?: boolean;
  create: (data: Partial<DraftPatient>) => Promise<DraftPatient | null>;
  loadingCreate?: boolean;
  update: (id: DraftPatient['id'], data: Partial<DraftPatient>) => Promise<DraftPatient | null>;
  loadingUpdate?: boolean;
  uploadPhoto: DraftPatientsStore['uploadPhoto'];
} => {
  const {draftPatientsStore: store} = useStores();

  useEffect(() => {
    store.fetchItems();
  }, [store.fetchItems]);

  return {
    items: store.items,
    loadingItems: store.loadingItems,
    delete: store.delete.bind(store),
    loadingDelete: store.loadingDelete,
    create: store.create.bind(store),
    loadingCreate: store.loadingCreate,
    update: store.update.bind(store),
    loadingUpdate: store.loadingUpdate,
    uploadPhoto: store.uploadPhoto.bind(store),
  };
};
