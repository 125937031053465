import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {memo, useMemo} from 'react';

import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {EMPTY_IMAGE} from '../../utils/constants';
import {addQualityToImageURL} from '../../utils/images/add-quality-to-image-url';
import {isVideoUrl} from '../../utils/is-video-url';
import {StarCheckboxComponent} from '../star-checkbox.component';

const IMAGE_QUALITY = 'auto';
const IMAGE_HEIGHT = 300;

export interface AlbumFileCardProps extends AlbumFile {
  onClick?: () => void;
  onMarkFavorite?: (value: boolean) => void;
  hideFavorite?: boolean;
  onLoadedMetadata?: (event: React.ChangeEvent<HTMLVideoElement>) => void;
}

function AlbumFileCard({
  url,
  description,
  isFavorite,
  onClick,
  onMarkFavorite,
  hideFavorite,
  onLoadedMetadata,
}: AlbumFileCardProps) {
  const imageUrl = useMemo(() => {
    return url ? addQualityToImageURL(url, IMAGE_QUALITY, IMAGE_HEIGHT) : EMPTY_IMAGE;
  }, [url]);

  return (
    <Card
      sx={{
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia
          image={imageUrl}
          sx={{
            paddingTop: '74%',
            backgroundSize: 'contain',
            position: 'relative',
          }}
        >
          {isVideoUrl(imageUrl) && (
            <video
              src={imageUrl}
              muted
              autoPlay
              onLoadedMetadata={onLoadedMetadata}
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                maxHeight: '100%',
              }}
            />
          )}
        </CardMedia>
      </CardActionArea>
      <CardContent sx={theme => ({padding: `${theme.spacing(1)} !important`})}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
          <Grid item xs>
            <Typography
              gutterBottom
              variant="caption"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {description}
            </Typography>
          </Grid>
          {!hideFavorite && (
            <Grid item>
              <StarCheckboxComponent checked={!!isFavorite} onChange={onMarkFavorite} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export const AlbumFileCardComponent = memo(AlbumFileCard);
