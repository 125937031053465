import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import React, {memo, useCallback, useState} from 'react';

import {getRandomId} from '../utils/get-random-id';

interface StarCheckboxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

function StarCheckbox({checked: defaultChecked, onChange, name, id = getRandomId('checkbox')}: StarCheckboxProps) {
  const {input, label} = useStyles();

  const [checked, setChecked] = useState(defaultChecked || false);

  const toggleChecked = useCallback(
    (event: any) => {
      event.stopPropagation();
      const newValue = !checked;
      setChecked(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [setChecked, checked, onChange]
  );

  return (
    <>
      <input className={input} type="checkbox" id={id} name={name} checked={checked} onChange={toggleChecked} />
      <label className={label} htmlFor={id}>
        {checked ? (
          <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 8.70807L8.332 7.55938L12 0L15.668 7.55938L24 8.70807L17.936 14.5314L19.416 22.8L12 18.8385L4.584 22.798L6.064 14.5294L0 8.70807Z"
              fill="#FFF500"
            />
          </svg>
        ) : (
          <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.18214 9.41669L8.46857 8.55001L8.99826 8.47698L9.23168 7.99593L12 2.2907L14.7683 7.99593L15.0017 8.47698L15.5314 8.55001L21.8183 9.41675L17.2434 13.8102L16.8574 14.1808L16.9516 14.7076L18.0683 20.9464L12.4712 17.9565L12.0001 17.7049L11.529 17.9564L5.93158 20.9449L7.04836 14.7056L7.14266 14.1787L6.75653 13.808L2.18214 9.41669Z"
              stroke="#FFF500"
              strokeWidth="2"
            />
          </svg>
        )}
      </label>
    </>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  input: {
    display: 'none',
  },
  label: {
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

export const StarCheckboxComponent = memo(StarCheckbox);
