import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useFormattedProfile} from '../../hooks/patients/use-formatted-profile';
import {useProgressiveImage} from '../../hooks/use-progressive-image';
import {Patient} from '../../interfaces/entities/patient.interface';
import {AvatarComponent} from '../avatar.component';
import {ImageComponent} from '../image.component';
import {ResponsiveEllipsisComponent} from '../responsive-ellipsis.component';
interface ProfileShortInfoComponentProps extends Patient {
  EditButton?: React.ReactNode;
  onClickShowMore?: () => void;
  mobile?: boolean;
  isExpanded?: boolean;
  onClickExpanded?: () => void;
}

export const DESKTOP_IMAGE_WIDTH = 255;
export const DESKTOP_IMAGE_HEIGHT = 263;

export const MOBILE_IMAGE_WIDTH = 138;
export const MOBILE_IMAGE_HEIGHT = 142;

const DESCRIPTION_MAX_LINES = 2;

function ProfileShortInfoComponentBase({
  EditButton,
  onClickShowMore,
  mobile = false,
  isExpanded = false,
  onClickExpanded,
  image,
  ...profile
}: ProfileShortInfoComponentProps) {
  const {t} = useTranslation();
  const bornLabelRef = useRef<HTMLDivElement>(null);

  const [labelMinWidth, setLabelMinWidth] = useState(0);

  const {name, birthDate, age} = useFormattedProfile(profile);

  const [imageWidth, imageHeight] = useMemo(() => {
    return mobile ? [MOBILE_IMAGE_WIDTH, MOBILE_IMAGE_HEIGHT] : [DESKTOP_IMAGE_WIDTH, DESKTOP_IMAGE_HEIGHT];
  }, [mobile]);

  const [imageSrc, {blur}] = useProgressiveImage(image, {
    width: imageWidth,
    height: imageHeight,
  });

  useEffect(() => {
    const newMinWith = bornLabelRef.current?.offsetWidth || 0;

    if (labelMinWidth !== newMinWith) {
      setLabelMinWidth(newMinWith);
    }
  }, [bornLabelRef.current, labelMinWidth]);

  if (mobile) {
    return (
      <Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <AvatarComponent
            src={imageSrc}
            name={name}
            sx={{
              filter: blur ? 'blur(20px)' : 'none',
              transition: blur ? 'none' : 'filter 0.3s ease-out',
              width: mobile ? '48px' : '40px',
              height: mobile ? '48px' : '40px',
            }}
          />
          {EditButton}
        </Stack>
        <Stack sx={{pt: 0.5}} direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography whiteSpace={'normal'} fontWeight={500} variant={!mobile ? 'h6' : 'subtitle1'}>
            {`${name}${profile.nickname ? ` (${profile.nickname})` : ''}`}
          </Typography>
          <Button
            id="profile-read-more"
            size="small"
            sx={{
              textTransform: 'none',
              py: 0.75,
              mt: 1,
            }}
            variant="contained"
            onClick={onClickShowMore}
          >
            {t('read-more.button')}
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Accordion
      expanded={isExpanded}
      onChange={onClickExpanded}
      sx={{
        boxShadow: 'none',
        minHeight: 'auto',
        '& .Mui-expanded': {minHeight: 'auto!important'},
      }}
    >
      <AccordionSummary
        sx={{
          px: 0,
          m: 0,
          '& .MuiAccordionSummary-content': {m: 0},
          '& .Mui-expanded': {m: `0!important`, minHeight: 'auto'},
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Fade in={!isExpanded}>
          <Stack direction="row" alignItems="center" spacing={{xs: 1, md: 2}}>
            <AvatarComponent
              src={imageSrc}
              name={name}
              sx={{
                filter: blur ? 'blur(20px)' : 'none',
                transition: blur ? 'none' : 'filter 0.3s ease-out',
                width: mobile ? '48px' : '40px',
                height: mobile ? '48px' : '40px',
              }}
            />
            <Typography whiteSpace={'normal'} fontWeight={500} variant={!mobile ? 'h6' : 'subtitle1'}>
              {`${name}${profile.nickname ? ` (${profile.nickname})` : ''}`}
            </Typography>
          </Stack>
        </Fade>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={{xs: 1, md: 2}}>
          <Grid item>
            <Box
              position="relative"
              sx={{
                filter: blur ? 'blur(20px)' : 'none',
                transition: blur ? 'none' : 'filter 0.3s ease-out',
                width: imageWidth,
                height: imageHeight,
                m: 'auto',
              }}
            >
              <ImageComponent
                image={imageSrc}
                imageFullScreen={image}
                title={name}
                cardMediaSx={{
                  pt: `${100 * (Math.round((imageHeight / imageWidth) * 100) / 100)}%`,
                }}
              />
            </Box>
            {mobile && (
              <Button
                id="profile-read-more"
                size="small"
                fullWidth
                endIcon={<VisibilityIcon />}
                sx={{
                  py: 0.75,
                  mt: 1,
                }}
                variant="outlined"
                onClick={onClickShowMore}
              >
                {t('read-more.button')}
              </Button>
            )}
          </Grid>
          <Grid item xs container columnSpacing={{xs: 1, sm: 2, md: 3}}>
            <Grid item xs container direction="column" spacing={{xs: 1, sm: 1.5, md: 2.5}}>
              <Grid item>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{xs: 0.5}}>
                  <Typography variant={!mobile ? 'h4' : 'h6'}>
                    {`${name}${profile.nickname ? ` (${profile.nickname})` : ''}`}
                  </Typography>
                  {mobile && EditButton}
                </Stack>
              </Grid>
              <Grid item>
                {!!birthDate && (
                  <Stack spacing={{xs: 1, md: 2}} direction="row" alignItems="center">
                    <Typography ref={bornLabelRef} minWidth={labelMinWidth} variant={!mobile ? 'body1' : 'body2'}>
                      {t('birth-date.label')}:
                    </Typography>

                    <Typography variant={!mobile ? 'body1' : 'body2'}>{`${birthDate} (${`${age}  ${t(
                      'persona-age.label'
                    )}`})`}</Typography>
                  </Stack>
                )}
              </Grid>
              {profile.birthPlace && (
                <Grid item>
                  <Stack spacing={{xs: 1, md: 2}} direction="row" alignItems="center">
                    <Typography ref={bornLabelRef} minWidth={labelMinWidth} variant={!mobile ? 'body1' : 'body2'}>
                      {t('birth-place.label')}:
                    </Typography>

                    <Typography variant={!mobile ? 'body1' : 'body2'}>{profile.birthPlace}</Typography>
                  </Stack>
                </Grid>
              )}
              {profile.description && (
                <>
                  <Grid item>
                    <Typography
                      variant={!mobile ? 'h5' : 'subtitle1'}
                      pt={{xs: 1, md: 2}}
                      sx={theme => ({
                        [theme.breakpoints.down('sm')]: {
                          fontWeight: 500,
                        },
                      })}
                    >
                      {t('persona-description.header', {firstName: profile.firstName})}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component={'div'} variant={!mobile ? 'body1' : 'body2'} whiteSpace={'pre-line'} mt={-1}>
                      <ResponsiveEllipsisComponent text={profile.description} maxLine={DESCRIPTION_MAX_LINES} />
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item>
              <Grid
                sx={{
                  height: '100%',
                }}
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                {!mobile && <Grid item>{EditButton}</Grid>}
                <Grid item>
                  {!mobile && (
                    <Button id="profile-read-more" variant="contained" onClick={onClickShowMore}>
                      {t('read-more.button')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export const ProfileShortInfoComponent = memo(ProfileShortInfoComponentBase);
