import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';

import {AvatarComponent} from '../../avatar.component';

function GuestbookPostMessageLoading() {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <Skeleton variant="circular">
              <AvatarComponent />
            </Skeleton>
          </Grid>
          <Grid item>
            <Typography variant={'subtitle1'} color={'rgba(0, 0, 0, 0.87)'} mb={0.5}>
              <Skeleton width={'200px'} />
            </Typography>
            <Typography variant={'body2'} color={'rgba(0, 0, 0, 0.6)'}>
              <Skeleton width={'140px'} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant={'caption'} color={'rgba(0, 0, 0, 0.87)'}>
          <Skeleton width={'100px'} />
        </Typography>
      </Grid>
    </Grid>
  );
}

export const GuestbookPostMessageLoadingComponent = memo(GuestbookPostMessageLoading);
