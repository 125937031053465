import uniq from 'lodash/uniq';

export function getAllAvailableValuesForMultiSelect(
  options: {id: string; name: string}[],
  groups: {groupName: string; options: {id: string; name: string}[]}[]
): string[] {
  const values: string[] = [];
  const n = groups.length;
  for (let i = 0; i < n; i++) {
    const m = groups[i].options.length;
    for (let j = 0; j < m; j++) {
      const option = groups[i].options[j];
      values.push(option.id);
    }
  }

  const m = options.length;
  for (let i = 0; i < m; i++) {
    const option = options[i];
    values.push(option.id);
  }

  return uniq(values);
}
