import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {HeaderComponent, HeaderProps} from '../components/header/header.component';
import {useCountryCode} from '../hooks/use-country-code';
import {useStores} from '../hooks/use-stores';
import {LOCALES} from '../utils/constants';

function Header(props: Omit<HeaderProps, 'onClickLogin'>) {
  const {i18n} = useTranslation();

  const {authStore, usersStore} = useStores();

  const {loading: loadingCountry, countryCode} = useCountryCode();

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore]);

  const logged = !!authStore.me;

  useEffect(() => {
    if (authStore.loading !== false) {
      return;
    }

    if (!authStore.me?.language && loadingCountry === false && countryCode?.toLowerCase() === 'se') {
      i18n.changeLanguage(LOCALES.sv);
    } else if (authStore.me?.language && i18n.language !== authStore.me?.language) {
      i18n.changeLanguage(authStore.me.language);
    }
  }, [authStore.me, authStore.loading, loadingCountry, countryCode, i18n.language]);

  const onChangeLanguage = useCallback(
    (language: LOCALES) => {
      if (logged) {
        usersStore.updateLanguage(language);
      }
    },
    [logged, usersStore.updateLanguage]
  );

  return (
    <HeaderComponent {...props} loadingLogin={authStore.loading} logged={logged} onChangeLanguage={onChangeLanguage} />
  );
}

export const HeaderContainer = observer(Header);
