import AppBar from '@mui/material/AppBar';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo} from 'react';

import {SUB_NAV_ITEM_CREATE_PATIENT, SUB_NAV_ITEM_MY_PATIENTS, SUB_NAV_ITEM_SUPPORT} from '../../configs/sub-nav-items';
import {usePatients} from '../../hooks/patients/use-patients';
import {useAllUnits} from '../../hooks/units/use-all-units';
import {useStores} from '../../hooks/use-stores';
import {Unit} from '../../interfaces/entities/unit.interface';
import {SubNavItem} from '../../interfaces/sub-nav-item.interface';
import {DEPARTMENT_UNIT_TYPE} from '../../stores/units.store';
import {isUserAdmin} from '../../utils/is-user-admin';
import {DesktopNavigationComponent} from './desktop-navigation.component';
import {MobileNavigationComponent} from './mobile-navigation/mobile-navigation.component';

function Navigation() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const {authStore} = useStores();

  const {items, loading, patientsWithNewGuestbookPosts} = usePatients();
  const {items: units = [], loading: loadingUnits} = useAllUnits();

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore.fetchMe]);

  const departments: Unit[] = useMemo(() => {
    const n = units.length;
    if (loadingUnits !== false || !n) {
      return [];
    }

    const arr: Unit[] = [];
    for (let i = 0; i < n; i++) {
      if (units[i].type === DEPARTMENT_UNIT_TYPE) {
        arr.push(units[i]);
      }
    }

    return arr;
  }, [loadingUnits, units]);

  const isAdmin = useMemo(() => {
    return authStore.me && isUserAdmin(authStore.me);
  }, [authStore.me]);

  const subNavItems = useMemo(() => {
    const arr: SubNavItem[] = [SUB_NAV_ITEM_SUPPORT];

    if (departments.length > 0) {
      arr.unshift(SUB_NAV_ITEM_CREATE_PATIENT);
    }

    if (loading == false && (items.length > 1 || isAdmin)) {
      arr.unshift({
        ...SUB_NAV_ITEM_MY_PATIENTS,
        counter: patientsWithNewGuestbookPosts,
      });
    }

    return arr;
  }, [loading, items.length, patientsWithNewGuestbookPosts, departments]);

  return (
    <AppBar
      color={'inherit'}
      elevation={0}
      position={'relative'}
      sx={theme => ({
        zIndex: theme.zIndex.appBar + 1,
      })}
    >
      <DesktopNavigationComponent open={!mobileView} items={subNavItems} />
      <MobileNavigationComponent open={mobileView} items={subNavItems} logged={!!authStore.me} />
    </AppBar>
  );
}

export const NavigationComponent = observer(Navigation);
