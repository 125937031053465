import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {TABS_TITLES, TABS_TRANSLATIONS} from '../configs/patient-pages-titles';

const DEFAULT_PAGE_TITLE = 'MinMemoria';

export const useTitle = (
  title?: string | ReadonlyArray<string>
): [title: string, setTitle: (title: string) => string] => {
  let pageTitle;
  const history = useHistory();
  const pathPartsArr = history.location.pathname.split('/').filter(Boolean);
  let tab = pathPartsArr.pop();

  const findTitleByKeyword = useCallback(
    (title: ReadonlyArray<string>, keyword: string) => {
      return title.find(t => t.split('.')[0].split('-').pop() === keyword);
    },
    [history]
  );

  if (tab === TABS_TITLES.EDIT) {
    tab = pathPartsArr[pathPartsArr.length - 2];
  }

  if (tab === TABS_TITLES.NEW) {
    tab = pathPartsArr[pathPartsArr.length - 1];
  }

  if (parseInt(tab || '') || tab?.includes('-')) {
    const pathPartsArr = history.location.pathname.split('/').filter(Boolean);
    tab = pathPartsArr[pathPartsArr.length - 2];
  }

  if (tab && title && Array.isArray(title)) {
    const titlesObj: Record<string, string> = {
      [TABS_TITLES.PROFILES]: findTitleByKeyword(title, TABS_TRANSLATIONS.PERSONAL) || DEFAULT_PAGE_TITLE,
      [TABS_TITLES.RELATIVES]: findTitleByKeyword(title, TABS_TRANSLATIONS.RELATIONS) || DEFAULT_PAGE_TITLE,
      [TABS_TITLES.STORIES]: findTitleByKeyword(title, TABS_TRANSLATIONS.STORIES) || DEFAULT_PAGE_TITLE,
      [TABS_TITLES.ALBUMS]: findTitleByKeyword(title, TABS_TRANSLATIONS.ALBUMS) || DEFAULT_PAGE_TITLE,
      [TABS_TITLES.MESSAGES]: findTitleByKeyword(title, TABS_TRANSLATIONS.MESSAGES) || DEFAULT_PAGE_TITLE,
      [TABS_TITLES.ACCESSES]: findTitleByKeyword(title, TABS_TRANSLATIONS.ACCESSES) || DEFAULT_PAGE_TITLE,
    };
    pageTitle = titlesObj[tab];
  } else {
    pageTitle = DEFAULT_PAGE_TITLE;
  }

  const setTitle = useCallback(
    (title: string) => {
      return title;
    },
    [history]
  );

  return [pageTitle, setTitle];
};
