import CloseIcon from '@mui/icons-material/Close';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import NativeSelect from '@mui/material/NativeSelect';
import {omit} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';

import {AlbumFileCardComponent} from '../../components/album-files/album-file-card.component';
import {UploadButtonComponent} from '../../components/buttons/upload-button.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {ImagesDialogLoadingComponent} from '../../components/loading/images-dialog-loading.component';
import {useAlbumFilesByAlbumId} from '../../hooks/album-files/use-album-files-by-album-id';
import {usePatientNestedItems} from '../../hooks/patients/use-patient-nested-items';
import {useFavoriteFiles} from '../../hooks/use-favorite-files';
import {useStores} from '../../hooks/use-stores';
import {Album} from '../../interfaces/entities/album.interface';
import {FAVORITES_FOLDER_ID, FAVORITES_FOLDER_NAME} from '../../utils/constants';
import {getAlbumTitleTranslationKey} from '../../utils/get-album-title-translation-key';

interface StoriesOneImagesDialogProps extends DialogProps {
  onSave: (value: string) => void;
}

const LIMIT = 100;

function StoriesOneImagesDialog(props: StoriesOneImagesDialogProps) {
  const {params} = useRouteMatch<{id: string}>();
  const {t} = useTranslation();

  const [albumId, setAlbumId] = useState<Album['id']>(FAVORITES_FOLDER_ID);
  const [uploadLoading, setUploadLoading] = useState(false);

  const {albumsStore, storiesStore: storiesStore} = useStores();
  const {items: albums} = usePatientNestedItems(albumsStore, params.id, LIMIT);

  const {items: photos, loading: loadingPhotos} = useAlbumFilesByAlbumId(params.id, albumId, LIMIT);
  const {items: favoritesPhotos, loading: loadingFavorites} = useFavoriteFiles(params.id);

  const onChangeAlbumId = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setAlbumId(event.target.value);
    },
    [setAlbumId]
  );

  const onSelectFiles = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setUploadLoading(true);
        const url = await storiesStore.uploadByPatientId(params.id, event.target.files[0]);
        setUploadLoading(false);
        props.onSave(url);
      }
    },
    [params.id, storiesStore.uploadByPatientId, props.onSave, setUploadLoading]
  );

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose({}, 'escapeKeyDown');
    }
  }, [props.onClose]);

  const isFavorites = albumId === FAVORITES_FOLDER_ID;

  const photosToShow = (!isFavorites ? photos : favoritesPhotos) || [];

  return (
    <Dialog fullWidth maxWidth={'xl'} aria-labelledby="images-dialog-title" {...omit(props, 'onSave', 'patientId')}>
      <DialogTitle id="images-dialog-title">
        {t('stories-edit-add-picture.header')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={theme => ({
            position: 'absolute',
            right: 1,
            top: 1,
            color: theme.palette.secondary.main,
          })}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            p: '0 0 20px 0',
            m: '0 0 25px 0',
            borderBottom: '1px dotted #666',
          }}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Typography>{t('stories-edit-add-picture-select-album.label')}</Typography>
              <NativeSelect
                onChange={onChangeAlbumId}
                value={albumId}
                name="album"
                inputProps={{'aria-label': 'album'}}
              >
                <option key={FAVORITES_FOLDER_ID} value={FAVORITES_FOLDER_ID}>
                  {t(getAlbumTitleTranslationKey(FAVORITES_FOLDER_NAME), FAVORITES_FOLDER_NAME)}
                </option>
                {albums.map(({id, name}, index) => (
                  <option key={id || index} value={id || ''}>
                    {t(getAlbumTitleTranslationKey(name || ''), name)}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UploadButtonComponent onSelectFiles={onSelectFiles}>
                {t('stories-edit-add-picture-upload-new.label')}
              </UploadButtonComponent>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            p: '0 0 20px 0',
          }}
        >
          {loadingPhotos || loadingFavorites ? (
            <ImagesDialogLoadingComponent />
          ) : (
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
              {photosToShow.map((photo, index) => (
                <Grid key={photo.id || index} item xs={6} sm={4} md={3} lg={2}>
                  <Box
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <AlbumFileCardComponent {...photo} onClick={() => props.onSave(photo.url)} hideFavorite />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <BackdropLoadingComponent
          sx={{
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: theme => theme.zIndex.drawer + 10,
          }}
          open={uploadLoading}
        />
      </DialogContent>
    </Dialog>
  );
}

export const StoriesOneImagesDialogContainer = observer(StoriesOneImagesDialog);
