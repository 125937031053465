import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {SubNavItem} from '../../../interfaces/sub-nav-item.interface';
import {MobileNavigationMenuListItemComponent} from './mobile-navigation-menu-list-item.component';

function MobileNavigationMenuItem(props: SubNavItem) {
  const {t} = useTranslation();

  const {Icon, label} = props;

  return (
    <MobileNavigationMenuListItemComponent {...props}>
      {Icon && (
        <ListItemIcon
          sx={{
            minWidth: '54px',
            height: '20px',
          }}
        >
          <Icon />
        </ListItemIcon>
      )}
      {label && (
        <ListItemText>
          <Typography variant="subtitle2">{t(label)}</Typography>
        </ListItemText>
      )}
    </MobileNavigationMenuListItemComponent>
  );
}

export const MobileNavigationMenuItemComponent = memo(MobileNavigationMenuItem);
