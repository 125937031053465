import {useEffect, useMemo, useState} from 'react';

import {getDefaultImageTransformations} from '../utils/get-default-image-transformations';
import {addTransformationsToImageURL} from '../utils/images/add-transformations-to-image-url';

interface UseProgressiveImageOptions {
  height?: number;
  width?: number;
  face?: boolean;
  defaultUrl?: string;
  imageTransformations?: string[];
  lowQuality?: number;
  highQuality?: number;
}

const DEFAULT_HIGHT_QUALITY = 100;
const DEFAULT_LOW_QUALITY = 10;

export function useProgressiveImage(
  imageUrl?: string | null,
  {
    height,
    width,
    face,
    defaultUrl,
    imageTransformations,
    lowQuality = DEFAULT_LOW_QUALITY,
    highQuality = DEFAULT_HIGHT_QUALITY,
  }: UseProgressiveImageOptions = {}
): [string, {blur: boolean}] {
  const defaultImageTransformations = imageTransformations || getDefaultImageTransformations(height, width, face);

  const lowQualitySrc = useMemo(
    () => addTransformationsToImageURL(imageUrl || '', [`q_${lowQuality}`, ...defaultImageTransformations]),
    [imageUrl, lowQuality, defaultImageTransformations]
  );

  const highQualitySrc = useMemo(
    () => addTransformationsToImageURL(imageUrl || '', [`q_${highQuality}`, ...defaultImageTransformations]),
    [imageUrl, highQuality, defaultImageTransformations]
  );

  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    if (!imageUrl) {
      return;
    }
    // Reset the state to low quality whenever the image URL changes
    setSrc(lowQualitySrc);

    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
    img.onerror = () => {
      if (defaultUrl) {
        setSrc(defaultUrl);
      }
    };
  }, [imageUrl, lowQualitySrc, highQualitySrc, defaultUrl]);

  const blur = useMemo(() => {
    if (!imageUrl) {
      return false;
    }
    const img = new Image();
    img.src = highQualitySrc;
    if (img.complete) {
      return false;
    }

    return src === lowQualitySrc && src !== highQualitySrc;
  }, [src, imageUrl, lowQualitySrc, highQualitySrc]);

  return [
    imageUrl ? src : '',
    {
      blur,
    },
  ];
}
