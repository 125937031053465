import Badge from '@mui/material/Badge';
import MuiTab, {TabProps as MuiTabProps} from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import {PatientNavigationItem} from '../../../interfaces/patient-navigation-item';

interface MobilePatientNavigationTabProps
  extends PatientNavigationItem,
    Omit<MuiTabProps, 'label' | 'title' | 'onClick'> {
  isActive?: boolean;
  onClick?: (tabIndex: MuiTabProps['tabIndex']) => void;
}

function MobilePatientNavigationTab(props: MobilePatientNavigationTabProps) {
  const {t} = useTranslation();

  const {Icon, ActiveIcon, label = '', to, tabIndex, onClick: onClickFromProps, isActive, counter} = props;

  const onClick = () => {
    if (onClickFromProps) {
      onClickFromProps(tabIndex);
    }
  };

  return (
    <MuiTab
      tabIndex={tabIndex}
      onClick={onClick}
      to={to}
      exact
      component={NavLink}
      icon={
        <Badge badgeContent={counter} color="primary">
          {!isActive || !ActiveIcon ? (
            <Icon sx={{height: '20px', color: 'rgba(0, 0, 0, 0.87)'}} />
          ) : (
            <ActiveIcon sx={{height: '20px', color: 'rgba(0, 0, 0, 0.87)'}} />
          )}
        </Badge>
      }
      label={<Typography variant="caption">{t(label)}</Typography>}
      sx={theme =>
        !isActive
          ? {
              opacity: 1,
              color: 'rgba(0, 0, 0, 0.6)',
            }
          : {
              opacity: 1,
              color: `${theme.palette.primary.main} !important`,
              '& svg': {
                fill: theme.palette.primary.main,
                fillOpacity: 1,
              },
            }
      }
    />
  );
}

export const MobilePatientNavigationTabComponent = memo(MobilePatientNavigationTab);
