import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PROFILE_ROUTE_ALBUMS} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {useDeleteDialog} from '../../hooks/use-delete-dialog';
import {Album} from '../../interfaces/entities/album.interface';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {BLACK_COLOR} from '../../theme';
import {getAlbumTitleTranslationKey} from '../../utils/get-album-title-translation-key';
import {getDateTime} from '../../utils/get-date-time';
import {AlbumFilesListComponent} from '../album-files/album-files-list.component';
import {BackAndManageButtonsComponent} from '../buttons/back-and-manage-buttons.component';
import {BackdropLoadingComponent} from '../loading/backdrop-loading.component';
import {AlbumDeleteDialogComponent} from './album-delete-dialog.component';

interface AlbumsOneComponentProps extends Partial<Album> {
  profileId: string;
  loading?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  files?: AlbumFile[];
  loadingFiles?: boolean;
  hasMoreFiles: boolean;
  loadMoreFiles: () => Promise<void>;
  onEdit?: () => void;
  onDelete?: () => void;
  EmptyScreen?: React.ReactNode;
  loadingDelete?: boolean;
  onMarkFavorite?: (id: AlbumFile['id'], value: boolean) => void;
}

function AlbumsOneComponentBase({
  profileId,
  showEditButton = false,
  showDeleteButton = false,
  loadingDelete = false,
  files,
  loading,
  loadingFiles,
  onEdit,
  onDelete,
  name,
  updatedAt,
  description,
  hasMoreFiles,
  loadMoreFiles,
  EmptyScreen,
  onMarkFavorite,
}: AlbumsOneComponentProps) {
  const {t} = useTranslation();
  const theme = useTheme();

  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const {onClick: onClickDelete, onClose: onCloseDeleteDialog, open: openDeleteDialog} = useDeleteDialog();

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_ALBUMS.path}`;

  const translatedName = useMemo(() => {
    return t(getAlbumTitleTranslationKey(name || ''), name);
  }, [t, name]);

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" wrap="nowrap" spacing={2}>
        <Grid item>
          <BackAndManageButtonsComponent
            onClickEdit={showEditButton ? onEdit : undefined}
            onClickDelete={showDeleteButton ? onClickDelete : undefined}
            backPath={backPath}
            editText={t('edit-album.button')}
            deleteText={t('delete-album.button')}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap" spacing={1}>
            <Grid item>
              <Typography variant={'h5'}>
                {loading === false ? (
                  translatedName
                ) : (
                  <Skeleton
                    sx={theme => ({
                      width: '175px',
                      [theme.breakpoints.up('sm')]: {
                        width: '300px',
                      },
                    })}
                  />
                )}
              </Typography>
            </Grid>
            {updatedAt && (
              <Grid item>
                <Typography
                  fontStyle={'italic'}
                  fontWeight={'normal'}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  letterSpacing={'0.25px'}
                  color={BLACK_COLOR}
                >{`${t('story-view-changed-date.label')}: ${getDateTime(updatedAt)}`}</Typography>
              </Grid>
            )}
            {loading !== false && (
              <Grid item>
                <Typography
                  fontStyle={'italic'}
                  fontWeight={'normal'}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  letterSpacing={'0.25px'}
                  color={BLACK_COLOR}
                >
                  <Skeleton
                    sx={theme => ({
                      width: '75px',
                      [theme.breakpoints.up('sm')]: {
                        width: '150px',
                      },
                    })}
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {(!!description || loading !== false) && (
          <Grid item>
            <Typography variant={'body2'}>
              {loading === false ? (
                description
              ) : (
                <>
                  <Skeleton
                    sx={theme => ({
                      width: '100%',
                      [theme.breakpoints.up('sm')]: {
                        width: '75%',
                      },
                    })}
                  />
                  {mobileView && (
                    <>
                      <Skeleton
                        sx={{
                          width: '100%',
                          marginTop: 1,
                        }}
                      />
                      <Skeleton
                        sx={{
                          width: '100%',
                          marginTop: 1,
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <AlbumFilesListComponent
            loading={loadingFiles}
            items={files}
            hasMore={hasMoreFiles}
            loadMore={loadMoreFiles}
            EmptyScreen={EmptyScreen}
            onMarkFavorite={onMarkFavorite}
          />
        </Grid>
      </Grid>
      <AlbumDeleteDialogComponent open={openDeleteDialog} onCancel={onCloseDeleteDialog} onConfirm={onDelete} />
      <BackdropLoadingComponent open={loadingDelete} />
    </>
  );
}

export const AlbumsOneComponent = memo(AlbumsOneComponentBase);
