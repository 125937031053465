import MUIButton from '@mui/material/Button';
import React, {memo, MouseEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {useLoginButton} from '../../hooks/use-login-button';
import {useStores} from '../../hooks/use-stores';
import {LogOutDialogComponent} from '../dialog/logout-dialog.component';

type ButtonType = 'text' | 'outlined' | 'contained' | undefined;

interface LoginButtonProps {
  isLogged?: boolean;
  type?: ButtonType;
  showWarningBeforeLogOut?: boolean;
  loginButtonText?: string;
}

function LoginButton({isLogged, type, loginButtonText, showWarningBeforeLogOut}: LoginButtonProps) {
  const [showWarningDialog, setWarningDialog] = useState<boolean>(false);
  const {t} = useTranslation();
  const {authStore} = useStores();
  const history = useHistory();

  const {onClick: handleLoginOnClick} = useLoginButton();

  const loggedOut = () => {
    authStore.logout();
    history.push(MY_PATIENTS_ROUTE.path);
    history.go(0);
  };

  const handleCancel = useCallback(() => {
    setWarningDialog(false);
  }, [showWarningDialog]);

  const handleConfirm = useCallback(() => {
    setWarningDialog(false);
    loggedOut();
  }, [showWarningDialog]);

  const handleLogoutOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (showWarningBeforeLogOut) {
      setWarningDialog(true);
    } else {
      await loggedOut();
    }
  };

  return (
    <>
      <MUIButton
        sx={{
          textAlign: 'center',
          maxWidth: '225px',
          width: 'inherit',
        }}
        onClick={isLogged ? handleLogoutOnClick : handleLoginOnClick}
        disableElevation
        variant={type}
      >
        {isLogged ? t('main-nav-logout.button') : t(loginButtonText || 'login')}
      </MUIButton>
      <LogOutDialogComponent open={showWarningDialog} onCancel={handleCancel} onConfirm={handleConfirm} />
    </>
  );
}

export const LoginButtonComponent = memo(LoginButton);
