export function getFetchingKey(
  prefix?: string,
  page?: number,
  limit?: number,
  fields?: string[],
  s?: string,
  sort?: string[],
  join?: string[]
): string {
  const arr = [];

  if (s) {
    arr.push(`s-${s}`);
  }

  if (fields?.length) {
    arr.push(`fields-${[...fields].sort().join('-')}`);
  }

  if (sort?.length) {
    arr.push(`sort-${sort.join('-')}`);
  }

  if (join?.length) {
    arr.push(`join-${[...join].sort().join('-')}`);
  }

  if (page) {
    arr.push(`page-${page}`);
  }

  if (limit) {
    arr.push(`limit-${limit}`);
  }

  const key = arr.join('-');

  return !prefix ? key : `${prefix}-${key}`;
}
