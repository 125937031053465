import {useEffect, useState} from 'react';

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const updateWindowDimensions = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Set initial window dimensions
    updateWindowDimensions();

    // Event listener for window resize
    window.addEventListener('resize', updateWindowDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []); // Empty dependency array means this effect will run once, similar to componentDidMount

  return windowDimensions;
}
