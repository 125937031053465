import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DeleteDialogComponent, DeleteDialogProps} from '../dialog/delete-dialog.component';

export type GuestbookReplyDeleteDialog = Pick<DeleteDialogProps, 'open' | 'onCancel' | 'onConfirm'>;

function GuestbookReplyDeleteDialog(props: GuestbookReplyDeleteDialog) {
  const {t} = useTranslation();
  return (
    <DeleteDialogComponent
      {...props}
      title={t('guestbook-comment-delete-modal.header')}
      contentText={t('guestbook-comment-delete-modal.body')}
    />
  );
}

export const GuestbookReplyDeleteDialogComponent = memo(GuestbookReplyDeleteDialog);
