import heic2any from 'heic2any';

const HEIC_TO_JPEG_QUALITY = 0.5;
export async function convertHeicToJpg(file: File): Promise<File> {
  const blob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: HEIC_TO_JPEG_QUALITY,
  });
  return new File([blob as Blob], file.name, {
    ...file,
  });
}
