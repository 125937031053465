import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {memo, useEffect, useRef, useState} from 'react';
import {Story} from 'react-insta-stories/dist/interfaces';

import {WHITE_COLOR} from '../../theme';
import {isVideoUrl} from '../../utils/is-video-url';
import {SlideShowStoryToolbarComponent} from './ slideshow-story-toolbar.component';

const TOOLBAR_HIDE_TIMEOUT = 3000;

interface SlideShowStoryProps extends Pick<Story, 'url'> {
  titleTop?: string;
  titleBottom?: string;
  isPaused?: boolean;
  onClickPlayButton?: () => void;
}

function SlideShowStory({url, titleTop, titleBottom, isPaused, onClickPlayButton}: SlideShowStoryProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [toolbarVisible, setToolbarVisible] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    const hideToolbar = () => {
      setToolbarVisible(false);
    };

    const showToolbar = () => {
      setToolbarVisible(true);
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(hideToolbar, TOOLBAR_HIDE_TIMEOUT); // Hide after 3 seconds
    };

    const handleMouseMove = () => {
      if (!toolbarVisible) {
        showToolbar();
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef?.current?.paused && !isPaused) {
      videoRef.current.play();
      return;
    }

    if (videoRef?.current && !videoRef.current.paused && isPaused) {
      videoRef.current.pause();
      return;
    }
  }, [videoRef, isPaused]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${url})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        margin: '0 auto',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {titleTop && (
        <Typography
          p={2.5}
          textAlign="center"
          color={WHITE_COLOR}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          sx={{
            backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%)',
          }}
        >
          {titleTop}
        </Typography>
      )}
      {url && isVideoUrl(url) && (
        <video
          ref={videoRef}
          src={url}
          autoPlay
          style={{
            position: 'absolute',
            width: '100%',
            maxHeight: '100%',
            top: 0,
            bottom: 0,
            margin: 'auto 0',
          }}
        ></video>
      )}
      <SlideShowStoryToolbarComponent
        visible={mobileView || toolbarVisible}
        isPaused={isPaused}
        text={titleBottom}
        onClickPlayButton={onClickPlayButton}
      />
    </Box>
  );
}

export const SlideShowStoryComponent = memo(SlideShowStory);
