import {User} from '../interfaces/user.interface';
import {formatPhone} from './format-phone';

export function getPhones(phones: User['phones']): string {
  return (phones || []).reduce((str, {phone}, index, arr) => {
    const formattedPhone = formatPhone(phone);
    if (index < arr.length - 1) {
      return `${str}${formattedPhone} | `;
    }

    return `${str}${formattedPhone}`;
  }, '');
}
