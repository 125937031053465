import {AxiosInstance} from 'axios';
import {action, AnnotationsMap, makeObservable, observable, runInAction} from 'mobx';

import {Unit} from '../interfaces/entities/unit.interface';
import {GetManyResponse} from '../interfaces/get-many-response.interface';

const FETCH_UNITS_LIMIT = 100;

const PARENTS_UNIT_DEPTH = 5;

export const INSTITUTION_UNIT_TYPE = 'Institusjon';
export const MUNICIPALITY_UNIT_TYPE = 'Kommune';
export const PATIENT_UNIT_TYPE = 'Pasient/hjemmeboende';
export const DEPARTMENT_UNIT_TYPE = 'Avdeling';

export class UnitsStore {
  allInstitutions?: ReadonlyArray<Unit>;
  municipalities?: ReadonlyArray<Unit>;
  allUnits?: ReadonlyArray<Unit>;
  units: Record<string, GetManyResponse<Unit>> = {};
  unit: Record<string, Unit> = {};

  loadingAllUnits?: boolean;
  loadingUnits: Record<string, boolean> = {};
  loadingUnit: Record<string, boolean> = {};

  protected readonly annotations: AnnotationsMap<UnitsStore, never> = {
    allInstitutions: observable,
    municipalities: observable,
    allUnits: observable,
    units: observable,
    loadingAllUnits: observable,
    fetchAllUnits: action,
    fetchUnit: action,
    fetchUnits: action,
  };

  constructor(protected readonly api: AxiosInstance) {
    makeObservable(this, this.annotations);
  }

  async fetchAllUnits(force = false) {
    if (!force && this.allUnits) {
      return;
    }

    if (this.loadingAllUnits) {
      return;
    }

    runInAction(() => {
      this.loadingAllUnits = true;
    });
    let more = true;
    let page = 1;
    const arr: Unit[] = [];
    while (more) {
      const join: string[] = [];
      for (let i = 0; i < PARENTS_UNIT_DEPTH; i++) {
        let joinItem = 'parents';
        for (let j = 0; j < i; j++) {
          joinItem += '.parents';
        }

        join.push(joinItem);
      }

      const data = await this.fetchUnits(undefined, page, FETCH_UNITS_LIMIT, ['name,ASC'], join);

      if (!data) {
        break;
      }

      if (data.data) {
        arr.push(...data.data);
      }
      more = (data.pageCount || 0) > page;
      page += 1;
    }

    runInAction(() => {
      this.allUnits = arr;
      this.loadingAllUnits = false;
    });
  }

  async fetchUnits(
    search?: Record<string, any>,
    page?: number,
    limit?: number,
    sort?: string[],
    join?: string[],
    force?: boolean
  ): Promise<GetManyResponse<Unit> | undefined> {
    const key = this.getFetchKeyFetchMany(search, page, limit, join, sort);
    if (!force && this.units[key]) {
      return this.units[key];
    }

    if (this.loadingUnits[key]) {
      return;
    }

    runInAction(() => {
      this.loadingUnits[key] = true;
    });

    const searchParams = new URLSearchParams();
    if (search) {
      searchParams.append('s', JSON.stringify(search));
    }

    if (page) {
      searchParams.append('page', '' + page);
    }

    if (limit) {
      searchParams.append('limit', '' + limit);
    }

    if (sort) {
      for (const sortItem of sort) {
        searchParams.append('sort', sortItem);
      }
    }

    if (join) {
      for (const joinItem of join) {
        searchParams.append('join', joinItem);
      }
    }

    let data: GetManyResponse<Unit> | undefined = undefined;
    try {
      const response = await this.api.get<GetManyResponse<Unit>>(`units?${searchParams.toString()}`);
      data = response.data;
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      if (data) {
        this.units[key] = data;
      }

      this.loadingUnits[key] = false;
    });

    return data;
  }

  async fetchUnit(id: Unit['id'], join?: string[], force?: boolean): Promise<Unit | undefined> {
    const key = this.getFetchKeyFetchOne(id, join);
    if (!force && this.unit[key]) {
      return this.unit[key];
    }

    if (this.loadingUnit[key]) {
      return;
    }

    runInAction(() => {
      this.loadingUnit[key] = true;
    });

    const searchParams = new URLSearchParams();

    if (join) {
      for (const joinItem of join) {
        searchParams.append('join', joinItem);
      }
    }

    let data: Unit | undefined = undefined;
    try {
      const response = await this.api.get<Unit>(`units/${id}?${searchParams.toString()}`);
      data = response.data;
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      if (data) {
        this.unit[key] = data;
      }

      this.loadingUnit[key] = false;
    });

    return data;
  }

  private getFetchKeyFetchMany(
    search?: Record<string, any>,
    page?: number,
    limit?: number,
    join?: string[],
    sort?: string[]
  ): string {
    const keys: string[] = [];

    if (search) {
      keys.push(`search-${JSON.stringify(search)}`);
    }

    if (page) {
      keys.push(`page-${page}`);
    }

    if (limit) {
      keys.push(`limit-${limit}`);
    }

    if (join) {
      keys.push(`join-${join.join('&')}`);
    }

    if (sort) {
      keys.push(`sort-${sort.join('&')}`);
    }

    return keys.join('|');
  }

  private getFetchKeyFetchOne(id: Unit['id'], join?: string[]): string {
    const keys: string[] = [];

    keys.push(`id-${id}`);

    if (join) {
      keys.push(`join-${join.join('&')}`);
    }

    return keys.join('|');
  }
}
