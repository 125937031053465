import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import React, {forwardRef, memo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {Patient} from '../../interfaces/entities/patient.interface';
import {MultiLinesField} from './shared/multi-lines-field.component';

type PatientHabits = Pick<Patient, 'habits'>;

interface PatientHabitsFormProps extends PatientHabits {
  onChange: (data: PatientHabits) => void;
}

const MIN_ROWS = 5;

function PatientHabitsFormBase({onChange, ...props}: PatientHabitsFormProps, ref: React.Ref<FormikProps<Patient>>) {
  const {t} = useTranslation();

  return (
    <Formik<PatientHabits>
      initialValues={props || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientHabits>>}
      validationSchema={Yup.object<Record<keyof PatientHabits, Yup.AnySchema>>().shape({
        habits: Yup.string().nullable().optional(),
      })}
    >
      {() => (
        <Form>
          <Field name="habits">
            {(props: FieldProps) => {
              return (
                <MultiLinesField
                  {...props}
                  label={t('patient-edit-habits.label')}
                  assistiveText={t('patient-edit-habits.assistive')}
                  assistiveTextPosition={'top'}
                  minRows={MIN_ROWS}
                />
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientHabitsForm = memo(forwardRef(PatientHabitsFormBase));
