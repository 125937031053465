import MUIButton, {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';

function Button(props: ButtonProps) {
  return (
    <MUIButton
      {...props}
      sx={{
        textAlign: 'center',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
        ...props.sx,
      }}
    >
      {props.children}
    </MUIButton>
  );
}

export const ButtonComponent = memo(Button);
