import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import React, {forwardRef, memo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {Patient} from '../../interfaces/entities/patient.interface';
import {Unit} from '../../interfaces/entities/unit.interface';
import {findUnitOptionsByParentId} from '../../utils/find-unit-options-by-parent-id';
import {FieldWrapperComponent} from './shared/field-wrapper.component';

interface PatientMunicipalityInstitution {
  municipalityId: Unit['id'] | null;
  institutionId: Unit['id'] | null;
}

interface PatientMunicipalityInstitutionFormProps extends PatientMunicipalityInstitution {
  onChange: (data: PatientMunicipalityInstitution) => void;
  municipalities: Unit[];
  institutions: Unit[];
}

function PatientMunicipalityInstitutionFormBase(
  {onChange, municipalities, institutions: allInstitutions, ...props}: PatientMunicipalityInstitutionFormProps,
  ref: React.Ref<FormikProps<Patient>>
) {
  const {t} = useTranslation();

  return (
    <Formik<PatientMunicipalityInstitution>
      initialValues={props}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientMunicipalityInstitution>>}
      validationSchema={Yup.object<Record<keyof PatientMunicipalityInstitution, Yup.AnySchema>>().shape({
        municipalityId: Yup.string().nullable().required('required-field-not-filled.error'),
        institutionId: Yup.string().nullable().required('required-field-not-filled.error'),
      })}
    >
      {() => {
        return (
          <Form>
            <Field name="municipalityId">
              {(props: FieldProps) => {
                return (
                  <Autocomplete
                    autoHighlight
                    getOptionLabel={option => option.name}
                    options={municipalities}
                    renderInput={params => (
                      <FieldWrapperComponent
                        {...props}
                        meta={{
                          ...props.meta,
                          touched: !!props.meta.error,
                        }}
                      >
                        <TextField
                          error={!!props.meta.error}
                          {...params}
                          label={t('profile-municipality-form.select')}
                        />
                      </FieldWrapperComponent>
                    )}
                    {...props.field}
                    onChange={(_event, newValue) => {
                      const options = findUnitOptionsByParentId(allInstitutions, (newValue as Unit | null)?.id);
                      props.form.setFieldValue('institutionId', options.length === 1 ? options[0].id : null, false);

                      return props.form.setFieldValue(props.field.name, (newValue as Unit | null)?.id);
                    }}
                    value={municipalities.find(({id}) => props.field.value === id)}
                  />
                );
              }}
            </Field>
            <Field name="institutionId">
              {(props: FieldProps) => {
                const options = findUnitOptionsByParentId(allInstitutions, props.form.values.municipalityId);

                const value = props.field.value ? options.find(({id}) => props.field.value === id) : null;

                if (props.field.value && !value) {
                  props.form.setFieldValue(props.field.name, null);
                }
                return (
                  <Autocomplete
                    sx={{py: 2}}
                    autoHighlight
                    getOptionLabel={option => option.name}
                    options={options}
                    renderInput={params => (
                      <FieldWrapperComponent
                        {...props}
                        meta={{
                          ...props.meta,
                          touched: !!props.meta.error,
                        }}
                      >
                        <TextField
                          error={!!props.meta.error}
                          {...params}
                          label={t('profile-institution-form.select')}
                        />
                      </FieldWrapperComponent>
                    )}
                    {...props.field}
                    onChange={(_event, newValue) => {
                      return props.form.setFieldValue(props.field.name, (newValue as Unit | null)?.id);
                    }}
                    value={options.find(({id}) => props.field.value === id) || null}
                  />
                );
              }}
            </Field>
          </Form>
        );
      }}
    </Formik>
  );
}

export const PatientMunicipalityInstitutionForm = memo(forwardRef(PatientMunicipalityInstitutionFormBase));
