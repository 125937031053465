import orderBy from 'lodash/orderBy';

const DEFAULT_NOT_FOUND_PARENT_GROUP_NAME = 'No Available Groups';

export function getGroupsAndOptionsForDepartmentsFilters<T extends {id: string; name: string; parentIds?: string[]}>(
  municipalities: T[],
  institutions: T[],
  departments: T[],
  notFoundGroupName = DEFAULT_NOT_FOUND_PARENT_GROUP_NAME
) {
  const groupsObj: Record<string, {group: T; options: T[]}> = {};
  const itemOptions: T[] = [];
  const notFoundParentOptions: T[] = [];

  const n = departments.length;
  for (let i = 0; i < n; i++) {
    const item = departments[i];
    if (!item) {
      continue;
    }
    const m = (item.parentIds || []).length;
    if (m === 0) {
      itemOptions.push(item);
      continue;
    }

    for (let j = 0; j < m; j++) {
      const parentId = item.parentIds?.[j];

      if (!parentId) {
        continue;
      }

      const parent = institutions.find(({id}) => id === parentId);

      if (!parent && !notFoundParentOptions.some(({id}) => id === item.id)) {
        notFoundParentOptions.push(item);
        continue;
      }

      if (!parent) {
        continue;
      }

      if (!groupsObj[parentId]) {
        groupsObj[parentId] = {
          group: parent,
          options: [],
        };
      }

      if (!groupsObj[parentId].options.some(({id}) => id === item.id)) {
        groupsObj[parentId].options.push(item);
      }
    }
  }
  const itemGroups: {
    groupName: string;
    options: T[];
  }[] = [];

  for (const key in groupsObj) {
    const municipalityParents = municipalities.filter(({id}) => (groupsObj[key].group.parentIds || []).includes(id));
    itemGroups.push({
      groupName: [
        ...orderBy(municipalityParents, ['name'], ['asc']).map(({name}) => name),
        groupsObj[key].group.name,
      ].join(', '),
      options: orderBy(groupsObj[key].options, ['name'], ['asc']),
    });
  }
  return {
    groups: notFoundParentOptions.length
      ? [
          ...orderBy(itemGroups, ['groupName'], ['asc']),
          {
            groupName: notFoundGroupName,
            options: orderBy(notFoundParentOptions, ['name'], ['asc']),
          },
        ]
      : orderBy(itemGroups, ['groupName'], ['asc']),
    options: orderBy(itemOptions, ['name'], ['asc']),
  };
}
