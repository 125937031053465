import 'react-phone-input-2/lib/material.css';

import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import {LOCALES} from '../../utils/constants';
import {FieldProps} from '../interfaces/field-props.interface';
import {FieldWrapperComponent} from './field-wrapper.component';

const FIELD_NAME_DEFAULT = 'phone';
const FIELD_ID_DEFAULT = `${FIELD_NAME_DEFAULT}-id`;

const LANGUAGE_PHONE_CODE_MAPPER: {[key in LOCALES]?: string} = {
  [LOCALES.nb]: 'no',
  [LOCALES.sv]: 'se',
  [LOCALES.da]: 'dk',
};

interface PhoneFieldProps extends FieldProps {
  id?: string;
  value: string;
}

function PhoneField(props: PhoneFieldProps) {
  const {i18n} = useTranslation();

  const {id = FIELD_ID_DEFAULT, name = FIELD_NAME_DEFAULT, label, value, error, handleChange, handleBlur} = props;

  const {container, containerFocused} = useStyles();

  const [isFocused, setIsFocused] = useState(false);

  const onChange = useCallback(
    (_value: string, _data: any, event: React.ChangeEvent<HTMLInputElement>) => {
      if (handleChange) {
        handleChange(event);
      }
    },
    [handleChange]
  );

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(false);
      if (handleBlur) {
        handleBlur(event);
      }
    },
    [setIsFocused, handleBlur]
  );

  return (
    <FieldWrapperComponent {...props} name={name}>
      <PhoneInput
        inputProps={{
          id,
          name: 'phone',
          required: true,
          error,
          label,
          autoFocus: false,
        }}
        inputStyle={{
          width: '100%',
        }}
        containerClass={isFocused ? containerFocused : container}
        country={LANGUAGE_PHONE_CODE_MAPPER[i18n.language as LOCALES] || LANGUAGE_PHONE_CODE_MAPPER.nb}
        onlyCountries={['no', 'se', 'dk']}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        specialLabel={label}
      />
    </FieldWrapperComponent>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    '&.react-tel-input .special-label': {
      fontSize: '12px',
      top: '-9px',
    },
  },
  containerFocused: {
    '&.react-tel-input .form-control': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
      boxShadow: 'none',
    },
    '&.react-tel-input .special-label': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      fontWeight: 'normal',
      fontSize: '12px',
      top: '-9px',
    },
  },
}));

export const PhoneFieldComponent = memo(PhoneField);
