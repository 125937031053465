import {useEffect} from 'react';

import {BaseEntity} from '../../interfaces/entities/base-entity.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {PatientBaseStore} from '../../stores/shared/patient-base.store';

export function usePatientNestedItem<T extends BaseEntity>(
  store: PatientBaseStore<T>,
  patientId: Patient['id'],
  id: T['id']
): {
  item: T | null;
  loading?: boolean;
} {
  useEffect(() => {
    store.oneByPatientId(patientId, id);
  }, [store.oneByPatientId, patientId, id]);

  return {
    item: store.itemByPatientId[patientId],
    loading: store.loadingOneByPatientId[patientId],
  };
}
