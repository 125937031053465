import Grid from '@mui/material/Grid';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {BackButtonComponent} from '../../components/buttons/back-button.component';
import {ConfirmLeaveDialogComponent} from '../../components/dialog/confirm-leave-dialog.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {StoriesOneLoadingComponent} from '../../components/loading/stories/stories-one-loading.component';
import {PROFILE_ROUTE_STORIES} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {StoryForm} from '../../forms/story.form';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useStory} from '../../hooks/stories/use-story';
import {useFormActions} from '../../hooks/use-form-actions';
import {Story} from '../../interfaces/entities/story.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';

function StoriesOneEdit({profileId}: ProfilePageProps) {
  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const {t} = useTranslation();
  const [blockLeave, setBlockLeave] = useState<boolean>(true);
  const {item, fetchLoading, update, updateLoading} = useStory(profileId);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_STORIES.path}`;

  const {onCancel, onConfirm} = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Story>) => {
      await update(data);
      setBlockLeave(false);
      setTimeout(() => {
        onConfirm();
        setBlockLeave(true);
      }, 500);
    },
    [onConfirm, update]
  );

  if (fetchLoading) {
    return <StoriesOneLoadingComponent />;
  }

  if (!item || !isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <StoryForm {...item} onSave={onSave} onCancel={onCancel} resetFormAfterCancel={false} />
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={!!updateLoading} />
      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-story-warning.body')}
        stayButtonText={t('modal-leave-story-warning-stay.button')}
        leaveButtonText={t('modal-leave-story-warning-leave.button')}
      />
    </>
  );
}

export const StoriesOneEditContainer = observer(StoriesOneEdit);
