import React, {memo, useCallback, useState} from 'react';

import {getRandomId} from '../utils/get-random-id';

interface CheckboxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

function Checkbox({name, id = getRandomId('checkbox'), checked, disabled, onChange: onChangeProps}: CheckboxProps) {
  const [value, setValue] = useState(!!checked);

  const onChange = useCallback(() => {
    setValue(!value);
    if (onChangeProps) {
      onChangeProps(!value);
    }
  }, [value, setValue, onChangeProps]);

  return (
    <>
      <input
        type="checkbox"
        id={id}
        name={name}
        disabled={disabled}
        checked={value}
        onChange={onChange}
        style={{
          display: 'none',
        }}
      />
      <label
        htmlFor={id}
        style={{
          width: '23px',
          height: '23px',
          background: '#FFFFFF',
          border: '2px solid #E5E5E5',
          borderRadius: '6px',
          cursor: 'pointer',
          display: 'inline-block',
          position: 'relative',
          userSelect: 'none',
        }}
      >
        {value && (
          <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <path
              d="M1 7L6.625 13L16 1"
              stroke="#626262"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </label>
    </>
  );
}

export const CheckboxComponent = memo(Checkbox);
