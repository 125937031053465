import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DialogComponent} from './dialog.component';

export interface DeleteDialogProps {
  title: string;
  contentText: string;
  open: boolean;
  onCancel: () => void;
  onConfirm?: () => void | Promise<void>;
}

function DeleteDialog(props: DeleteDialogProps) {
  const {t} = useTranslation();
  const {contentText, title, open, onCancel, onConfirm} = props;

  const renderDialogActions = () => {
    return (
      <Box
        display="flex"
        px={3}
        py={2}
        sx={theme => ({
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
          },
        })}
      >
        <Button
          onClick={onConfirm}
          variant={'contained'}
          autoFocus
          sx={{
            minWidth: '150px',
          }}
        >
          {t('general-delete.button')}
        </Button>
        <Button
          autoFocus
          onClick={onCancel}
          sx={theme => ({
            minWidth: '80px',
            flexDirection: 'column',
            order: '-1',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
              order: '0',
              ml: 1,
            },
          })}
        >
          {t('general-cancel.button')}
        </Button>
      </Box>
    );
  };

  return (
    <DialogComponent isOpen={open} onClose={onCancel} title={title} description={contentText}>
      {renderDialogActions()}
    </DialogComponent>
  );
}

export const DeleteDialogComponent = memo(DeleteDialog);
