import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SortEnd, SortStart} from 'react-sortable-hoc';

import {AlbumFileForm} from '../../forms/album-file.form';
import {AlbumFilePreview} from '../../interfaces/album-file-preview.interface';
import {Album} from '../../interfaces/entities/album.interface';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {arrayMoveImmutable} from '../../utils/array-move';
import {UploadButtonComponent} from '../buttons/upload-button.component';
import {SortableListComponent} from '../sortable-list/sortable-list.component';
import {AlbumEditSelectedButtonComponent} from './album-edit-selected-button.component';

interface AlbumFilesEditorProps {
  files: ReadonlyArray<AlbumFilePreview>;
  selected: ReadonlyArray<AlbumFile['id']>;
  rotated: Record<AlbumFile['id'], number>;
  albumImage: Album['image'];
  onClickSelect: (id: AlbumFile['id']) => void;
  onClickRotate?: (id: AlbumFile['id']) => void;
  onChange: (items: ReadonlyArray<AlbumFilePreview>) => void;
  onClick?: (id: AlbumFile['id']) => void;
  onDeleteSelected?: () => void;
  onMakeAlbumCover: () => void;
  onSelectFiles: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function AlbumFilesEditor({
  files,
  selected,
  rotated,
  albumImage,
  onClickSelect,
  onClickRotate,
  onChange,
  onClick,
  onDeleteSelected,
  onMakeAlbumCover,
  onSelectFiles,
}: AlbumFilesEditorProps) {
  const [sortingIndex, setSortingIndex] = useState<number>();
  const {t} = useTranslation();

  const onSortStart = useCallback(
    ({index}: SortStart) => {
      setSortingIndex(index);
    },
    [setSortingIndex]
  );

  const onSortEnd = useCallback(
    ({oldIndex, newIndex}: SortEnd) => {
      setSortingIndex(undefined);
      onChange(arrayMoveImmutable(files, oldIndex, newIndex));
    },
    [files, setSortingIndex, onChange]
  );

  const onChangeOne = useCallback(
    (id: AlbumFile['id'], data: Partial<AlbumFile>) => {
      onChange(
        files.map(file => {
          if (file.id === id) {
            return data as AlbumFile;
          }

          return file;
        })
      );
    },
    [files, onChange]
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          mb: 1,
        }}
      >
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <UploadButtonComponent
            inputProps={{
              accept: 'video/*,image/*,.heic,.heif',
              multiple: true,
            }}
            onSelectFiles={onSelectFiles}
          >
            {t('album-edit-upload.button')}
          </UploadButtonComponent>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AlbumEditSelectedButtonComponent
            disabled={!selected.length}
            disabledButtons={{makeAlbumCover: selected.length !== 1}}
            onDeleteSelected={onDeleteSelected}
            onMakeAlbumCover={onMakeAlbumCover}
          />
        </Grid>
      </Grid>
      <Typography fontStyle="italic" mb={4}>
        {t('album-edit-photo-order-how-to.body')}
      </Typography>

      <SortableListComponent
        axis="xy"
        useWindowAsScrollContainer={true}
        onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        pressDelay={200}
        sortingIndex={sortingIndex}
      >
        {files.map((file, index) => (
          <AlbumFileForm
            key={file.id || index}
            file={file}
            onChange={onChangeOne}
            onClickSelect={onClickSelect}
            onClickRotate={onClickRotate}
            onClick={onClick}
            isAlbumCover={albumImage === file.url}
            rotation={rotated[file.id]}
          />
        ))}
      </SortableListComponent>
    </>
  );
}

export const AlbumFilesEditorComponent = memo(AlbumFilesEditor);
