import {useEffect} from 'react';

import {useStores} from './use-stores';

export const useCountryCode = (): {
  loading: boolean;
  countryCode?: string;
} => {
  const {authStore} = useStores();

  useEffect(() => {
    authStore.fetchCountryCode();
  }, []);

  return {
    countryCode: authStore.countryCode,
    loading: authStore.loadingCountryCode,
  };
};
