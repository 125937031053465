import Typography, {TypographyProps} from '@mui/material/Typography';
import React, {memo} from 'react';

function AssistiveText({sx, ...props}: TypographyProps) {
  return (
    <Typography
      variant="body1"
      sx={{
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '12px',
        paddingLeft: '1rem',
        ...sx,
      }}
      {...props}
    />
  );
}

export const AssistiveTextComponent = memo(AssistiveText);
