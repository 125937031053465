import MuiAvatar, {AvatarProps as MuiAvatarProps} from '@mui/material/Avatar';
import {Theme, useTheme} from '@mui/material/styles';
import Color from 'color';
import React, {CSSProperties, memo, useMemo} from 'react';
import stc from 'string-to-color';

import getFirstLettersName from '../utils/get-first-letters-name';
import {getRandomId} from '../utils/get-random-id';
import {addQualityToImageURL} from '../utils/images/add-quality-to-image-url';

const IMAGE_QUALITY = 'auto';
const IMAGE_HEIGHT = 200;

export interface AvatarProps extends MuiAvatarProps {
  name?: string;
}

const getEmptyAvatarStyle: (theme: Theme, name?: string) => CSSProperties = (theme: Theme, name = getRandomId()) => {
  const backgroundColor = Color(stc(name));
  const color = backgroundColor.isDark() ? theme.palette.secondary.light : theme.palette.secondary.dark;

  return {
    color: color,
    fontWeight: 500,
    backgroundColor: backgroundColor.hex(),
  };
};

function Avatar(props: AvatarProps) {
  const theme = useTheme();

  const {name} = props;

  const src = useMemo(() => {
    return props.src && addQualityToImageURL(props.src, IMAGE_QUALITY, IMAGE_HEIGHT);
  }, [props.src]);

  return src ? (
    <MuiAvatar {...props} src={src} alt={name} />
  ) : (
    <MuiAvatar {...props} alt={name} style={getEmptyAvatarStyle(theme, name)}>
      {getFirstLettersName(name, 2)}
    </MuiAvatar>
  );
}

export const AvatarComponent = memo(Avatar);
