import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DeleteDialogComponent, DeleteDialogProps} from '../dialog/delete-dialog.component';

export type StoryDeleteDialog = Pick<DeleteDialogProps, 'open' | 'onCancel' | 'onConfirm'>;

function InvitationDeleteDialog(props: StoryDeleteDialog) {
  const {t} = useTranslation();
  return (
    <DeleteDialogComponent
      {...props}
      title={t('access-list-delete-pending-invite-modal.header')}
      contentText={t('access-list-delete-pending-invite-modal.body')}
    />
  );
}

export const InvitationDeleteDialogComponent = memo(InvitationDeleteDialog);
