import {addSearchParam} from '../add-search-param';
import {getRandomId} from '../get-random-id';
import {isBlobUrl} from './is-blob-url';

export function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'Anonymous');
    image.src = isBlobUrl(url) ? url : addSearchParam(url, 'no_cache_key', getRandomId());
  });
}
