import {useCallback, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';

import {Album} from '../../interfaces/entities/album.interface';
import {AlbumFile} from '../../interfaces/entities/album-file.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {usePatientNestedItem} from '../patients/use-patient-nested-item';
import {useStores} from '../use-stores';

export const useAlbum = (
  patientId: Patient['id']
): {
  item: Album | null;
  fetchLoading?: boolean;
  updateLoading?: boolean;
  update: (data: Partial<Album>, saveAlbumFiles?: () => Promise<void>) => Promise<void>;
  updateWithAlbumFiles: (data: Partial<Album>, albumFiles: ReadonlyArray<Partial<AlbumFile>>) => Promise<void>;
  remove: () => Promise<void>;
} => {
  const {params} = useRouteMatch<{albumId: string}>();

  const {albumsStore: store, albumFilesStore} = useStores();

  const {item, loading} = usePatientNestedItem(store, patientId, params.albumId);

  const [updateLoading, setUpdateLoading] = useState(false);

  const update = useCallback(
    async (data: Partial<Album>, saveAlbumFiles?: () => Promise<void>) => {
      setUpdateLoading(true);

      await store.updateByPatientId(patientId, params.albumId, data);
      if (saveAlbumFiles) {
        await saveAlbumFiles();
      }

      setUpdateLoading(false);
    },
    [store.updateByPatientId, setUpdateLoading, patientId, params.albumId]
  );

  const updateWithAlbumFiles = useCallback(
    async (data: Partial<Album>, albumFiles: ReadonlyArray<Partial<AlbumFile>>) => {
      setUpdateLoading(true);
      await store.updateByPatientId(patientId, params.albumId, data);
      await Promise.all(
        albumFiles.map(async (albumFile, index) => {
          if (!albumFile.id) {
            return;
          }
          return albumFilesStore.updateByAlbumId(patientId, params.albumId, albumFile.id, {
            ...albumFile,
            order: index + 1,
          });
        })
      );
      setUpdateLoading(false);
    },
    [store.updateByPatientId, setUpdateLoading, patientId, params.albumId]
  );

  const remove = useCallback(async () => {
    await store.deleteByPatientId(patientId, params.albumId);
    setUpdateLoading(false);
  }, [store.deleteByPatientId, patientId, params.albumId]);

  return {
    item,
    fetchLoading: loading,
    updateLoading,
    update,
    updateWithAlbumFiles,
    remove,
  };
};
