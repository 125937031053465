import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';

import {PatientsOneLoadingComponent} from '../components/loading/patients/patients-one-loading.component';
import {PatientNavigationComponent} from '../components/patient-navigation/patient-navigation.component';
import {MY_PATIENTS_ROUTE} from '../configs/routes';
import {useStores} from '../hooks/use-stores';
import PageProps from '../interfaces/page-props.interface';
import {DRAWER_WIDTH} from '../utils/constants';
import {Page} from './page';

function AcceptInvitation(props: PageProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const {params} = useRouteMatch<{invitationKeysId: string}>();

  const {authStore, patientsStore} = useStores();

  useEffect(() => {
    if (!params.invitationKeysId || !authStore.me) {
      return;
    }

    patientsStore.acceptInvite(params.invitationKeysId);
  }, [params.invitationKeysId, authStore.me]);

  useEffect(() => {
    if (patientsStore.acceptedInvitation) {
      history.push(`${MY_PATIENTS_ROUTE.path}/${patientsStore.acceptedInvitation.patient?.id}`);
    }
  }, [patientsStore.acceptedInvitation, history]);

  useEffect(() => {
    if (patientsStore.failedAcceptingInvitation) {
      window.location.replace('/');
    }
  }, [patientsStore.failedAcceptingInvitation]);

  return (
    <Page {...props}>
      <Box display={'flex'}>
        <PatientNavigationComponent patientId={'id'} />
        <Box
          width={mobile ? '100%' : `calc(100% - ${DRAWER_WIDTH}px)`}
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              mb: 6,
            },
          })}
        >
          <PatientsOneLoadingComponent />;
        </Box>
      </Box>
    </Page>
  );
}

export const AcceptInvitationPage = observer(AcceptInvitation);
