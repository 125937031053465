export function areAllOptionsSelected(
  value: string[],
  options: {id: string; name: string}[],
  groups: {groupName: string; options: {id: string; name: string}[]}[]
): boolean {
  const n = groups.length;
  for (let i = 0; i < n; i++) {
    const m = groups[i].options.length;
    for (let j = 0; j < m; j++) {
      const option = groups[i].options[j];
      if (!value.includes(option.id)) {
        return false;
      }
    }
  }

  const m = options.length;
  for (let i = 0; i < m; i++) {
    const option = options[i];
    if (!value.includes(option.id)) {
      return false;
    }
  }

  return true;
}
