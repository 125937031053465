import {Unit} from '../interfaces/entities/unit.interface';
import {DEPARTMENT_UNIT_TYPE, INSTITUTION_UNIT_TYPE, MUNICIPALITY_UNIT_TYPE} from '../stores/units.store';

function findUnitIdWithType(unit: Unit, targetType: string): Unit['id'] | undefined {
  if (unit.type === targetType) {
    return unit.id;
  }
  if (unit.parents) {
    for (const parent of unit.parents) {
      if (parent.type === targetType) {
        return parent.id;
      }
      const foundUnit = findUnitIdWithType(parent, targetType);
      if (foundUnit) {
        return foundUnit;
      }
    }
  }
  return undefined;
}

export function findDepartmentInstitutionMunicipality(units: ReadonlyArray<Unit>): {
  departmentId?: Unit['id'];
  institutionId?: Unit['id'];
  municipalityId?: Unit['id'];
} {
  let institutionId;
  let municipalityId;
  let departmentId;

  for (const unit of units) {
    if (!departmentId && unit.type === DEPARTMENT_UNIT_TYPE) {
      departmentId = unit.id;
    }

    if (departmentId) {
      institutionId = findUnitIdWithType(unit, INSTITUTION_UNIT_TYPE);
    }

    if (departmentId && institutionId) {
      municipalityId = findUnitIdWithType(unit, MUNICIPALITY_UNIT_TYPE);
    }

    if (departmentId && institutionId && municipalityId) {
      break;
    }
  }

  if (!departmentId && !institutionId && !municipalityId) {
    for (const unit of units) {
      institutionId = findUnitIdWithType(unit, INSTITUTION_UNIT_TYPE);
      if (institutionId) {
        municipalityId = findUnitIdWithType(unit, MUNICIPALITY_UNIT_TYPE);
      }

      if (institutionId && municipalityId) {
        break;
      }
    }
  }

  if (!institutionId && !municipalityId) {
    for (const unit of units) {
      municipalityId = findUnitIdWithType(unit, MUNICIPALITY_UNIT_TYPE);

      if (municipalityId) {
        break;
      }
    }
  }

  return {departmentId, institutionId, municipalityId};
}
