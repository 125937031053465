import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {EDIT_PATH} from '../configs/profile-routes';

export function useEditButton(): {
  onClick: () => void;
} {
  const history = useHistory();
  const onClick = useCallback(() => {
    const newPath = history.location.pathname + (history.location.pathname.endsWith('/') ? EDIT_PATH : `/${EDIT_PATH}`);
    history.push(newPath);
  }, [history, history.location.pathname]);

  return {
    onClick,
  };
}
