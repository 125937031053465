import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';

function PatientsNewLoading(): ReactElement {
  return (
    <Box
      sx={theme => ({
        maxWidth: theme.breakpoints.values.md,
        margin: '0 auto',
      })}
    >
      <Typography variant="h4" pt={5} pb={3}>
        <Skeleton
          sx={theme => ({
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              maxWidth: '450px',
            },
          })}
        />
      </Typography>
      <Skeleton
        sx={{
          height: '30px',
          width: '100%',
          mb: 2,
        }}
      />
      <Skeleton
        sx={{
          height: '30px',
          width: '100%',
          mb: 2,
        }}
      />
      <Skeleton
        sx={{
          height: '30px',
          width: '100%',
          mb: 2,
        }}
      />
      <Skeleton
        sx={{
          height: '30px',
          width: '100%',
          mb: 2,
        }}
      />
    </Box>
  );
}

export const PatientsNewLoadingComponent = memo(PatientsNewLoading);
