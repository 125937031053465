import {useEffect} from 'react';

import {AccessContact} from '../../interfaces/access-contact.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {useStores} from '../use-stores';

export const useAccessesContacts = (
  patientId: Patient['id']
): {
  items: ReadonlyArray<AccessContact>;
} => {
  const {accessesStore: store} = useStores();

  useEffect(() => {
    store.fetchAccessContacts(patientId);
  }, [patientId]);

  return {
    items: store.contactsByPatientId[patientId] || [],
  };
};
