import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';
import {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';

import {RoundButtonComponent} from './round-button.component';

function RoundForwardButton(props: ButtonProps) {
  return (
    <RoundButtonComponent {...props}>
      <ArrowForwardIosRounded />
    </RoundButtonComponent>
  );
}

export const RoundForwardButtonComponent = memo(RoundForwardButton);
