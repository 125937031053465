import axios, {AxiosError} from 'axios';
import axiosRetry, {exponentialDelay} from 'axios-retry';
import Cookies from 'js-cookie';

import {AUTH_TOKEN_KEY} from '../utils/constants';

const API_URL = process.env.REACT_APP_API_URL || '/api';
const DELAY_FACTOR = 500; // ms
const api = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

api.interceptors.request.use(config => {
  if (!config.headers) {
    config.headers = {};
  }

  const token: string | null | undefined = Cookies.get(AUTH_TOKEN_KEY);

  if (!config.headers.Authorization && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosRetry(api, {
  retries: 3,
  retryDelay: (retryNumber: number, err: AxiosError) =>
    // @ts-ignore
    exponentialDelay(retryNumber, err, DELAY_FACTOR),
});

export default api;
