import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Redirect, Route, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import {EditButtonComponent} from '../components/buttons/edit-button.component';
import {ProfileInfoDialogComponent} from '../components/dialog/profile-info-dialog.component';
import {ProfileShortInfoLoadingComponent} from '../components/loading/profiles/profile-short-info-loading.component';
import {PageTitleComponent} from '../components/page-title.component';
import {ProfileNavigationComponent} from '../components/profile-navigation/profile-navigation.component';
import {ProfileShortInfoComponent} from '../components/profiles/profile-short-info.component';
import {profileRoutes} from '../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../configs/routes';
import {usePatient} from '../hooks/patients/use-patient';
import {usePatientRole} from '../hooks/patients/use-patient-role';
import {useEditButton} from '../hooks/use-edit-button';
import PageProps from '../interfaces/page-props.interface';
import {ProfileRouteItem} from '../interfaces/profile-route-item.interface';
import {DEEP_SPACE_SPARKLE_COLOR} from '../theme';
import {Page} from './page';

const INTERCOM_PROFILE_ROLE = 'Profile role';

function ProfilePageBase(props: PageProps) {
  const {path, params} = useRouteMatch<{id: string}>();
  const location = useLocation();
  const {update} = useIntercom();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isExpandedProfile, setIsExpandedProfile] = useState(false);

  const {item, fetchLoading} = usePatient(params.id);
  const {isPatientRoleAdmin, role} = usePatientRole(params.id);

  const [openFull, setOpenFull] = useState(false);

  const {onClick: onClickEdit} = useEditButton();

  const handleClickShowMore = useCallback(() => {
    setOpenFull(true);
  }, []);

  const handleCloseFullDialog = useCallback(() => {
    setOpenFull(false);
  }, []);

  const handleIsExpandedChange = useCallback(() => {
    setIsExpandedProfile(!isExpandedProfile);
  }, [isExpandedProfile]);

  const showProfileEditButton = useMemo(() => {
    const pathname = location.pathname.replace(/\/$/, '');
    return isPatientRoleAdmin && pathname === `${MY_PATIENTS_ROUTE.path}/${params.id}`;
  }, [isPatientRoleAdmin, location.pathname]);

  useEffect(() => {
    const pathname = location.pathname.replace(/\/$/, '');
    if (pathname === `${MY_PATIENTS_ROUTE.path}/${params.id}`) {
      return setIsExpandedProfile(true);
    }

    setIsExpandedProfile(false);
  }, [location.pathname]);

  useEffect(() => {
    if (role) {
      update({
        customAttributes: {
          [INTERCOM_PROFILE_ROLE]: role,
        },
      });
    }
  }, [params.id, role, update]);

  if (fetchLoading === false && !item) {
    return <Redirect to={MY_PATIENTS_ROUTE.path} />;
  }

  return (
    <Page {...props}>
      <Container
        maxWidth="xl"
        sx={theme => ({
          mt: -1.25,
          [theme.breakpoints.down('sm')]: {
            px: 0,
          },
        })}
      >
        {item ? (
          <ProfileShortInfoComponent
            {...item}
            isExpanded={isExpandedProfile}
            onClickExpanded={handleIsExpandedChange}
            mobile={mobile}
            onClickShowMore={handleClickShowMore}
            key={item.id}
            EditButton={
              showProfileEditButton ? (
                <EditButtonComponent
                  variant="text"
                  color="secondary"
                  sx={{
                    color: DEEP_SPACE_SPARKLE_COLOR,
                  }}
                  id="edit-profile-button"
                  onClick={onClickEdit}
                  iconOnly={mobile}
                />
              ) : undefined
            }
          />
        ) : (
          <ProfileShortInfoLoadingComponent
            mobile={mobile}
            isExpanded={isExpandedProfile}
            onClickExpanded={handleIsExpandedChange}
          />
        )}
        <ProfileNavigationComponent loading={fetchLoading} profileId={params.id} profile={item || undefined} />
        <Switch>
          {profileRoutes.map((route: ProfileRouteItem) => (
            <Route
              key={`${route.key}`}
              path={`${path}/${route.path}`}
              render={() => (
                <>
                  <PageTitleComponent title={props.title} />
                  <Box maxWidth={'xl'} margin={'auto'} pt={{xs: 1.75, md: 4}}>
                    {route.component && <route.component profileId={params.id} />}
                  </Box>
                </>
              )}
              exact={route.exact}
            />
          ))}
          <Route path="*" render={() => <Redirect to={`${MY_PATIENTS_ROUTE.path}/${params.id}`} />} />
        </Switch>
        {item && <ProfileInfoDialogComponent isOpen={openFull} onClose={handleCloseFullDialog} {...item} />}
      </Container>
    </Page>
  );
}

export const ProfilePage = observer(ProfilePageBase);
