import CloseIcon from '@mui/icons-material/Close';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import AppBar, {AppBarProps} from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {BLACK_COLOR, WHITE_COLOR} from '../theme';
import {LOGO_WITHOUT_TEXT_PATH} from '../utils/constants';

export interface InstallWidgetProps extends AppBarProps {
  onClickClose?: () => void;
  onClickInstall?: () => void;
}

const InstallWidget = ({onClickClose, onClickInstall, ...appBarProps}: InstallWidgetProps) => {
  const {t} = useTranslation();

  return (
    <AppBar
      {...appBarProps}
      sx={{
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        backgroundColor: WHITE_COLOR,
        color: BLACK_COLOR,
        ...appBarProps.sx,
      }}
    >
      <Toolbar sx={{py: 1.5, px: 1.25}}>
        <IconButton
          onClick={onClickClose}
          color="inherit"
          sx={{
            pr: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
        <ListItem>
          <ListItemAvatar sx={{display: 'flex'}}>
            <img height={48} src={LOGO_WITHOUT_TEXT_PATH} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="subtitle2" color={'rgba(0, 0, 0, 0.87)'} fontWeight={600}>
                {t('logo-primary.title')}
              </Typography>
            }
            secondary={
              <Typography variant="body2" color={'rgba(0, 0, 0, 0.87)'}>
                {t('logo-secondary.title')}
              </Typography>
            }
          />
        </ListItem>

        <Box sx={{flexGrow: 1}} />
        <Button
          variant="contained"
          disableElevation
          sx={{
            py: 1.5,
          }}
          startIcon={<InstallMobileIcon />}
          onClick={onClickInstall}
        >
          {t('install.button')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export const InstallWidgetComponent = memo(InstallWidget);
