import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import {ImageComponent} from '../../image.component';

function RelativesOneLoading(): ReactElement {
  const {t} = useTranslation();

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" wrap={'nowrap'} spacing={3}>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              component={'div'}
              sx={{
                maxWidth: '100%',
              }}
            >
              <ImageComponent />
            </Skeleton>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <Typography variant={'h4'} paddingLeft={'6px'} paddingBottom={4}>
              <Skeleton
                sx={theme => ({
                  width: '100%',
                  [theme.breakpoints.up('sm')]: {
                    maxWidth: '350px',
                  },
                })}
              />
            </Typography>
            <table
              style={{
                overflow: 'hidden',
                textAlign: 'left',
              }}
            >
              <tbody>
                <tr
                  style={{
                    verticalAlign: 'top',
                  }}
                >
                  <th
                    style={{
                      padding: '6px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography variant={'body1'}>{t('relative-relationship.label')}:</Typography>
                  </th>
                  <td style={{padding: '6px', width: '90%'}}>
                    <Typography variant={'body1'}>
                      <Skeleton
                        sx={theme => ({
                          width: '100%',
                          [theme.breakpoints.up('sm')]: {
                            width: '200px',
                          },
                        })}
                      />
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <table
          style={{
            overflow: 'hidden',
            textAlign: 'left',
            width: '100%',
          }}
        >
          <tbody>
            <tr
              style={{
                verticalAlign: 'top',
              }}
            >
              <th
                style={{
                  padding: '0px 10px 24px 0px',
                  textAlign: 'left',
                }}
              >
                <Typography variant={'h5'}>{`${t('persona-description.header')} :`}</Typography>
              </th>
            </tr>
            <tr>
              <td style={{padding: '0px 10px 24px 0px'}}>
                <Typography variant={'body1'} paddingBottom={1}>
                  <Skeleton
                    sx={{
                      width: '100%',
                    }}
                  />
                </Typography>
                <Typography variant={'body1'} paddingBottom={1}>
                  <Skeleton
                    sx={{
                      width: '100%',
                    }}
                  />
                </Typography>
                <Typography variant={'body1'}>
                  <Skeleton
                    sx={{
                      width: '100%',
                    }}
                  />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}

export const RelativesOneLoadingComponent = memo(RelativesOneLoading);
