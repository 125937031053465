import React, {memo, ReactElement} from 'react';

import {CommonGuestbookPostContainer} from '../containers/guestbook/common-guestbook-post.container';
import PageProps from '../interfaces/page-props.interface';
import {Page} from './page';

function CommonGuestbookPost(props: PageProps): ReactElement {
  return (
    <Page {...props}>
      <CommonGuestbookPostContainer />
    </Page>
  );
}

export const CommonGuestbookPostPage = memo(CommonGuestbookPost);
