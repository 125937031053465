import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CreateIcon from '@mui/icons-material/Create';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ImageIcon from '@mui/icons-material/Image';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

import {ProfileNavigationItem} from '../interfaces/profile-navigation-item';

export const PROFILE_NAVIGATION_ITEM_HOME: ProfileNavigationItem = {
  to: '',
  label: 'minmemoria-profile-menu-front-page',
  Icon: AccountCircleOutlinedIcon,
  ActiveIcon: AccountCircleIcon,
};

export const PROFILE_NAVIGATION_ITEM_RELATIVES: ProfileNavigationItem = {
  to: 'relatives',
  label: 'minmemoria-profile-menu-relatives',
  Icon: PeopleOutlinedIcon,
  ActiveIcon: PeopleIcon,
};

export const PROFILE_NAVIGATION_ITEM_STORIES: ProfileNavigationItem = {
  to: 'stories',
  label: 'minmemoria-profile-menu-stories',
  Icon: CreateOutlinedIcon,
  ActiveIcon: CreateIcon,
};

export const PROFILE_NAVIGATION_ITEM_ALBUM_FILES: ProfileNavigationItem = {
  to: 'albums',
  label: 'minmemoria-profile-menu-albums',
  Icon: ImageOutlinedIcon,
  ActiveIcon: ImageIcon,
};

export const PROFILE_NAVIGATION_ITEM_ACCESS: ProfileNavigationItem = {
  to: 'accesses',
  label: 'minmemoria-profile-menu-access',
  Icon: LockOutlinedIcon,
  ActiveIcon: LockIcon,
};

export const PROFILE_NAVIGATION_ITEM_INVITATIONS = {
  to: 'invitations',
};

const navigationItems: ReadonlyArray<ProfileNavigationItem> = [
  PROFILE_NAVIGATION_ITEM_HOME,
  PROFILE_NAVIGATION_ITEM_RELATIVES,
  PROFILE_NAVIGATION_ITEM_STORIES,
  PROFILE_NAVIGATION_ITEM_ALBUM_FILES,
  PROFILE_NAVIGATION_ITEM_ACCESS,
];

export default navigationItems;
