import AddIcon from '@mui/icons-material/Add';
import Button, {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

interface CreateButtonProps extends ButtonProps {
  title?: string;
}

function CreateButton(props: CreateButtonProps) {
  const {t} = useTranslation();

  return (
    <Button variant="contained" {...props} disableElevation>
      <AddIcon sx={{paddingRight: '5px'}} />
      {t(props.title || 'general-create.button')}
    </Button>
  );
}

export const CreateButtonComponent = memo(CreateButton);
