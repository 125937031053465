import Grid from '@mui/material/Grid';
import React, {memo} from 'react';
import {SortableContainer} from 'react-sortable-hoc';

import {ALBUM_FILE_SORTABLE_ANIMATION, SortableItemComponent} from './sortable-item.component';

export interface SortableListProps {
  children: ReadonlyArray<React.ReactNode>;
  sortingIndex?: number;
}

function SortableList({children, sortingIndex}: SortableListProps) {
  return (
    <Grid container direction="row" justifyItems="flex-start" alignItems="stretch" spacing={2}>
      {children.map((child, index) => {
        return (
          <SortableItemComponent
            key={index}
            index={index}
            animation={
              sortingIndex !== undefined
                ? sortingIndex !== index && index % 2 === 0
                  ? ALBUM_FILE_SORTABLE_ANIMATION.EVEN
                  : ALBUM_FILE_SORTABLE_ANIMATION.ODD
                : undefined
            }
          >
            {child}
          </SortableItemComponent>
        );
      })}
    </Grid>
  );
}

export const SortableListComponent = memo(SortableContainer<SortableListProps>(SortableList));
