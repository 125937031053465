import {Area} from 'react-easy-crop/types';

import {createImage} from './create-image';
import {getRadianAngle} from './get-radian-angle';

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop?: Area,
  rotation = 0,
  backgroundColor = '#fafafa'
): Promise<Blob | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  image.width = image.width / 2;
  image.height = image.height / 2;

  const safeArea = Math.max(image.width, image.height) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width, safeArea / 2 - image.height);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop?.width || image.width;
  canvas.height = pixelCrop?.height || image.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width - (pixelCrop?.x || 0)),
    Math.round(0 - safeArea / 2 + image.height - (pixelCrop?.y || 0))
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      // resolve(URL.createObjectURL(file));
      resolve(file);
    }, 'image/jpeg');
  });
}
