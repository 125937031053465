import {AxiosInstance} from 'axios';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {Patient} from '../interfaces/entities/patient.interface';
import {ProfileDeleteRequest} from '../interfaces/entities/profile-delete-request.interface';
import {GetManyResponse} from '../interfaces/get-many-response.interface';

const FETCH_ALL_BY_FILTERS_LIMIT = 100;
const FETCH_ALL_BY_FILTERS_SORT = 'createdAt,DESC';

export class ProfileDeleteRequestsStore {
  allItemsByProfileId: Record<Patient['id'], ProfileDeleteRequest[]> = {};
  loadingAllItemsByProfileId: Record<Patient['id'], boolean> = {};
  errorLoadingAllItemsByProfileId: Record<Patient['id'], Error | undefined> = {};

  constructor(
    protected readonly api: AxiosInstance,
    protected readonly entitiesName: string = `delete-requests`
  ) {
    makeObservable(this, {
      allItemsByProfileId: observable,
      loadingAllItemsByProfileId: observable,
      errorLoadingAllItemsByProfileId: observable,

      fetchAllItemsByProfileId: action,
    });
  }

  async fetchAllItemsByProfileId(profileId: Patient['id'], force = false) {
    if (!force && this.allItemsByProfileId[profileId]) {
      return;
    }

    if (this.loadingAllItemsByProfileId[profileId]) {
      return;
    }

    const searchParams = new URLSearchParams();

    searchParams.append('limit', '' + FETCH_ALL_BY_FILTERS_LIMIT);
    searchParams.append('sort', FETCH_ALL_BY_FILTERS_SORT);

    const join: string[] = ['createdBy', 'createdBy.phones', 'createdBy.emails'];
    for (const joinItem of join) {
      searchParams.append('join', joinItem);
    }

    runInAction(() => {
      this.loadingAllItemsByProfileId[profileId] = true;
    });

    let more = true;
    let page = 1;
    const arr: ProfileDeleteRequest[] = [];
    while (more) {
      searchParams.set('page', '' + page);
      try {
        const {data} = await this.api.get<GetManyResponse<ProfileDeleteRequest>>(
          `profiles/${profileId}/${this.entitiesName}?${searchParams.toString()}`
        );

        if (!data) {
          break;
        }
        if (data.data) {
          arr.push(...data.data);
        }
        more = (data.pageCount || 0) > page;
        page += 1;
      } catch (err) {
        runInAction(() => {
          this.loadingAllItemsByProfileId[profileId] = false;
          this.errorLoadingAllItemsByProfileId[profileId] = err as Error;
        });
        return;
      }
    }

    runInAction(() => {
      this.allItemsByProfileId[profileId] = arr;
      this.loadingAllItemsByProfileId[profileId] = false;
    });
  }

  async create(profileId: Patient['id'], data: Partial<ProfileDeleteRequest>) {
    try {
      const {data: createdItem} = await this.api.post(`profiles/${profileId}/${this.entitiesName}`, data);
      runInAction(() => {
        this.allItemsByProfileId[profileId] = [createdItem, ...(this.allItemsByProfileId[profileId] || [])];
      });
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  }
}
