import {LOCALES} from '../utils/constants';
import {Access} from './entities/access.interface';
import {Email} from './entities/email.interface';
import {Phone} from './entities/phone.interface';
import {VilmerBaseEntity} from './entities/vilmer-base.interface';

export enum USER_ROLES {
  ADMIN = 'admin',
  NURSE = 'nurse',
  RESIDENT = 'resident',
  RELATIVE = 'relative',
  EVERYDAY_FRIEND = 'everyday-friend',
}

export interface User extends VilmerBaseEntity {
  id: string;
  firstName: string;
  lastName: string;
  image?: string;
  isEnabled: boolean;
  language?: LOCALES;
  accesses?: ReadonlyArray<Access>;
  emails?: ReadonlyArray<Email>;
  phones?: ReadonlyArray<Phone>;
  role?: USER_ROLES | null;
}
