import Grid from '@mui/material/Grid';
import formatISO from 'date-fns/formatISO';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {SaveAndCancelButtonsComponent} from '../components/buttons/save-and-cancel-buttons.component';
import {Patient} from '../interfaces/entities/patient.interface';
import {validateBirthDate} from './helpers/validate-birth-date';
import {DateField} from './patient/shared/date-field.component';
import {MultiLinesField} from './patient/shared/multi-lines-field.component';
import {SingleLineField} from './patient/shared/name-field.component';
import {FormWrapperComponent} from './shared/form-wrapper.component';

interface PatientFormProps extends Patient {
  onSave?: (data: Partial<Patient>) => void;
  onCancel?: () => void;
  resetFormAfterCancel?: boolean;
}

function PatientFormBase({resetFormAfterCancel = true, ...props}: PatientFormProps) {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onSave = useCallback(
    (updatedData: Partial<Patient>) => {
      const {onSave} = props;
      const data = omit(
        {
          ...props,
          ...updatedData,
          birthDate:
            updatedData.birthDate &&
            formatISO(new Date(updatedData.birthDate), {
              representation: 'date',
            }),
        },
        ['onSave', 'onCancel', 'resetFormAfterCancel']
      );

      if (!onSave) {
        return;
      }
      setIsLoading(true);
      onSave(data);
      setIsLoading(false);
    },
    [props, setIsLoading]
  );

  const onCancel = useCallback(
    (resetForm: () => void) => {
      if (props.onCancel) {
        props.onCancel();
      }
      if (resetFormAfterCancel) {
        resetForm();
      }
    },
    [props.onCancel, resetFormAfterCancel]
  );

  const validateBirthDateField = useCallback(
    (value: Date | null) => {
      return validateBirthDate(t, value);
    },
    [t]
  );

  return (
    <FormWrapperComponent isLoading={isLoading}>
      <Formik<Partial<Patient>>
        initialValues={props || {}}
        onSubmit={onSave}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('required-field-not-filled.error'),
          lastName: Yup.string().required('required-field-not-filled.error'),
          nickname: Yup.string().nullable(true).optional(),
          description: Yup.string().nullable(true).optional(),
          interests: Yup.string().nullable(true).optional(),
          habits: Yup.string().nullable(true).optional(),
          music: Yup.string().nullable(true).optional(),
          relax: Yup.string().nullable(true).optional(),
        })}
      >
        {({errors, isSubmitting, touched, resetForm, handleSubmit}: FormikProps<Partial<Patient>>) => {
          return (
            <Form>
              <Grid container direction="row" justifyContent="flex-start" spacing={3}>
                <Grid item xs={12} lg={9} xl={7}>
                  <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={12} sm={6}>
                      <Field name="firstName">
                        {(props: FieldProps) => {
                          return (
                            <SingleLineField
                              {...props}
                              label={t('patient-edit-first-name.label')}
                              assistiveText={t('patient-edit-first-name.assistive')}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="lastName">
                        {(props: FieldProps) => {
                          return (
                            <SingleLineField
                              {...props}
                              label={t('patient-edit-last-name.label')}
                              assistiveText={t('patient-edit-last-name.assistive')}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="nickname">
                        {(props: FieldProps) => {
                          return (
                            <SingleLineField
                              {...props}
                              label={t('patient-edit-nickname.label')}
                              assistiveText={t('patient-edit-nickname.assistive')}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="birthDate" validate={validateBirthDateField}>
                        {(props: FieldProps) => {
                          return (
                            <DateField
                              {...props}
                              assistiveText={t('patient-edit-birth-date.assistive')}
                              label={t('patient-edit-birth-date.label')}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="birthPlace">
                        {(props: FieldProps) => {
                          return (
                            <SingleLineField
                              {...props}
                              label={t('persona-edit-home-place.label')}
                              assistiveText={t('home-place.assistive')}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={12}>
                      <Field name="description">
                        {(props: FieldProps) => {
                          return (
                            <MultiLinesField
                              {...props}
                              label={t('patient-edit-about.label')}
                              assistiveText={t('patient-edit-about.assistive')}
                              minRows={3}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="interests">
                        {(props: FieldProps) => {
                          return (
                            <MultiLinesField
                              {...props}
                              label={t('patient-edit-interests.label')}
                              assistiveText={t('patient-edit-interests.assistive')}
                              minRows={2}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="habits">
                        {(props: FieldProps) => {
                          return (
                            <MultiLinesField
                              {...props}
                              label={t('patient-edit-habits.label')}
                              assistiveText={t('patient-edit-habits.assistive')}
                              minRows={2}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="music">
                        {(props: FieldProps) => {
                          return (
                            <MultiLinesField
                              {...props}
                              label={t('patient-edit-music.label')}
                              assistiveText={t('patient-edit-music.assistive')}
                              minRows={2}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="relax">
                        {(props: FieldProps) => {
                          return (
                            <MultiLinesField
                              {...props}
                              label={t('patient-edit-relax.label')}
                              assistiveText={t('patient-edit-relax.assistive')}
                              minRows={2}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <SaveAndCancelButtonsComponent
                        onClickCancel={() => onCancel(resetForm)}
                        onClickSave={handleSubmit}
                        sx={theme => ({
                          float: 'right',
                          width: '100%',

                          [theme.breakpoints.up('md')]: {
                            width: '45%',
                          },
                          [theme.breakpoints.up('lg')]: {
                            width: '35%',
                          },
                        })}
                        error={
                          !isSubmitting && !isEmpty(touched) && !isEmpty(errors)
                            ? t('patient-edit-general-validation-error')
                            : undefined
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FormWrapperComponent>
  );
}

export const PatientForm = memo(PatientFormBase);
