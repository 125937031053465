import {AxiosInstance} from 'axios';
import uniqBy from 'lodash/uniqBy';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {Album} from '../interfaces/entities/album.interface';
import {AlbumFile} from '../interfaces/entities/album-file.interface';
import {Patient} from '../interfaces/entities/patient.interface';
import {ListResponse} from '../interfaces/list-response.interface';
import {UploadsService} from '../services/uploads.service';
import {isVideoType} from '../utils/is-video-type';
import {AlbumsStore} from './albums.store';
import {PatientBaseStore} from './shared/patient-base.store';

const FAVORITES_LIMIT = 100;

export class AlbumFilesStore extends PatientBaseStore<AlbumFile> {
  /**
   * List of items by albumId
   */
  itemsByAlbumId: Record<Album['id'], ReadonlyArray<AlbumFile>> = {};

  /**
   * Flag of loading list by albumId
   */
  loadingByAlbumId: Record<Album['id'], boolean> = {};

  /**
   * Number of total pages for list by albumId
   */
  totalPagesByAlbumId: Record<Album['id'], number> = {};
  /**
   * Number of current page for list by albumId
   */
  currentPageByAlbumId: Record<Album['id'], number> = {};

  allFavoritesByProfileId: Record<string, ReadonlyArray<AlbumFile>> = {};
  loadingAllFavoritesByProfileId: Record<string, boolean> = {};

  /**
   *
   * @param api AxiosInstance
   * @param entitiesName string. Plural name of entity, it is using for REST API url
   */
  constructor(
    api: AxiosInstance,
    private readonly albumStore: AlbumsStore,
    private readonly uploadsService = new UploadsService(api)
  ) {
    super(api, 'files');
    makeObservable(this, {
      listByAlbumId: action,
      loadMoreByAlbumId: action,
      makeFavorite: action,
      removeFavorite: action,
      fetchAllFavorite: action,
      itemsByAlbumId: observable,
      loadingByAlbumId: observable,
      totalPagesByAlbumId: observable,
      currentPageByAlbumId: observable,
      allFavoritesByProfileId: observable,
      loadingAllFavoritesByProfileId: observable,
    });
  }

  /**
   * Async function to fetch list of items
   * @param page number. Page number of fetching list
   * @param limit number. limit of number items per page
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   */
  async listByAlbumId(patientId: Patient['id'], albumId: Album['id'], page = 1, limit = 50) {
    const currentPage = this.currentPageByAlbumId[albumId] || 0;
    if (currentPage >= page) {
      return;
    }

    runInAction(() => {
      this.loadingByAlbumId[albumId] = true;
    });
    try {
      const {data} = await this.api.get<ListResponse<AlbumFile>>(this.getUrlByAlbumId(patientId, albumId), {
        params: {
          page,
          limit,
        },
      });
      runInAction(() => {
        this.itemsByAlbumId[albumId] = data.items;
        this.totalPagesByAlbumId[albumId] = data.meta.totalPages || 0;
        this.currentPageByAlbumId[albumId] = data.meta.currentPage;
      });
    } catch (err) {
      console.error(err);
    }
    runInAction(() => {
      this.loadingByAlbumId[albumId] = false;
    });
  }

  /**
   * Async function to load more items
   * @param limit number. limit of number items per page
   * @param prefix string. Prefix is using for setting string at the begin of REST API url
   */
  async loadMoreByAlbumId(patientId: Patient['id'], albumId: Album['id'], limit = 50) {
    try {
      const {data} = await this.api.get<ListResponse<AlbumFile>>(this.getUrlByAlbumId(patientId, albumId), {
        params: {
          page: (this.currentPageByAlbumId[albumId] || 0) + 1,
          limit,
        },
      });
      runInAction(() => {
        this.itemsByAlbumId[albumId] = uniqBy([...(this.itemsByAlbumId[albumId] || []), ...data.items], 'id');
        this.totalPagesByAlbumId[albumId] = data.meta.totalPages || 0;
        this.currentPageByAlbumId[albumId] = data.meta.currentPage;
      });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Function to check 'does it have more items' by album id
   * @param _patientId string
   * @param albumId string
   * @returns boolean
   */
  getHasMoreByAlbumId(_patientId: Patient['id'], albumId: Album['id']) {
    const currentPage = this.currentPageByAlbumId[albumId] || 0;
    const totalPages = this.totalPagesByAlbumId[albumId] || 0;
    return !currentPage || currentPage < totalPages;
  }

  async updateByAlbumId(
    patientId: Patient['id'],
    albumId: Album['id'],
    albumFileId: AlbumFile['id'],
    data: Partial<AlbumFile>
  ) {
    const items = this.itemsByAlbumId[albumId] || [];
    const item = items.find(({id}) => id === albumFileId);
    if (!item) {
      return;
    }

    const temporaryItem = {...item, ...data};

    runInAction(() => {
      this.itemsByAlbumId[albumId] = items.reduce((arr: ReadonlyArray<AlbumFile>, item: AlbumFile) => {
        if (item.id === albumFileId) {
          return [...arr, temporaryItem];
        }
        return [...arr, item];
      }, []);
    });

    const {data: updatedData} = await this.api.put(`${this.getUrlByAlbumId(patientId, albumId)}/${albumFileId}`, data);

    if (!updatedData) {
      return;
    }

    runInAction(() => {
      this.itemsByAlbumId[albumId] = items.reduce((arr: ReadonlyArray<AlbumFile>, item: AlbumFile) => {
        if (item.id === albumFileId) {
          return [...arr, updatedData];
        }
        return [...arr, item];
      }, []);

      const existsInFavorites = (this.allFavoritesByProfileId[patientId] || []).some(({id}) => id === updatedData.id);
      if (updatedData.isFavorite && !existsInFavorites) {
        this.allFavoritesByProfileId[patientId] = [...(this.allFavoritesByProfileId[patientId] || []), updatedData];
      } else if (!updatedData.isFavorite && existsInFavorites) {
        this.allFavoritesByProfileId[patientId] = (this.allFavoritesByProfileId[patientId] || []).filter(
          ({id}) => id !== updatedData.id
        );
      }
    });
  }

  async createManyByAlbumId(
    patientId: Patient['id'],
    albumId: Album['id'],
    arr: ReadonlyArray<Partial<AlbumFile & {file: File} & {angle?: number}>>
  ) {
    const created = await Promise.all(
      arr.map(async data => {
        const {url} = data.file
          ? await this.uploadsService.upload(
              `patients/${patientId}/albums/${albumId}`,
              data.file,
              data.file?.type && isVideoType(data.file?.type) ? {type: 'video'} : undefined
            )
          : {url: undefined};

        const response = await this.api.post<AlbumFile>(this.getUrlByAlbumId(patientId, albumId), {
          ...data,
          url,
        });

        if (data.angle) {
          try {
            const simpleAngle = data.angle % 360;
            if (!simpleAngle) {
              return;
            }
            const {data: rotateData} = await this.api.post(
              `${this.getUrlByAlbumId(patientId, albumId)}/${response.data.id}/rotate/${simpleAngle}`
            );

            return rotateData;
          } catch (err) {
            console.error(err);
          }
        }

        return response.data;
      })
    );

    runInAction(() => {
      this.itemsByAlbumId[albumId] = [...(this.itemsByAlbumId[albumId] || []), ...created];

      const favorites = created.filter(({isFavorite}) => isFavorite);

      this.allFavoritesByProfileId[patientId] = [...(this.allFavoritesByProfileId[patientId] || []), ...favorites];

      this.albumStore.itemsByPatientId[patientId] = (this.albumStore.itemsByPatientId[patientId] || []).map(album => {
        if (album.id === albumId) {
          return {
            ...album,
            filesCount: (album.filesCount || 0) + created.length,
          };
        }

        return album;
      });
    });

    return created;
  }

  async updateManyByAlbumId(
    patientId: Patient['id'],
    albumId: Album['id'],
    dataArr: ReadonlyArray<Partial<AlbumFile>>
  ) {
    const updated = await Promise.all(
      dataArr.map(async data => {
        try {
          const {data: updatedData} = await this.api.put(
            `${this.getUrlByAlbumId(patientId, albumId)}/${data.id}`,
            data
          );

          return updatedData;
        } catch (err) {
          console.error(err);
        }
      })
    );

    runInAction(() => {
      this.itemsByAlbumId[albumId] = (this.itemsByAlbumId[albumId] || []).reduce((arr: Array<AlbumFile>, item) => {
        const updatedData = updated.find(({id}) => item.id === id);
        if (updatedData) {
          arr.push(updatedData);
        } else {
          arr.push(item);
        }
        return arr;
      }, []);

      updated.forEach(updatedData => {
        const existsInFavorites = (this.allFavoritesByProfileId[patientId] || []).some(({id}) => id === updatedData.id);
        if (updatedData.isFavorite && !existsInFavorites) {
          this.allFavoritesByProfileId[patientId] = [...(this.allFavoritesByProfileId[patientId] || []), updatedData];
        } else if (!updatedData.isFavorite && existsInFavorites) {
          this.allFavoritesByProfileId[patientId] = (this.allFavoritesByProfileId[patientId] || []).filter(
            ({id}) => id !== updatedData.id
          );
        }
      });
    });
  }

  async deleteByAlbumId(patientId: Patient['id'], albumId: Album['id'], albumFileId: AlbumFile['id']) {
    const items = this.itemsByAlbumId[albumId] || [];
    const item = items.find(({id}) => id === albumFileId);
    if (!item) {
      return;
    }

    await this.api.delete(`${this.getUrlByAlbumId(patientId, albumId)}/${albumFileId}`);

    runInAction(() => {
      this.itemsByAlbumId[albumId] = items.filter(({id}) => id !== albumFileId);

      const existsInFavorites = (this.allFavoritesByProfileId[patientId] || []).some(({id}) => id === albumFileId);

      if (existsInFavorites) {
        this.allFavoritesByProfileId[patientId] = (this.allFavoritesByProfileId[patientId] || []).filter(
          ({id}) => id !== albumFileId
        );
      }
    });
  }

  async deleteManyByAlbumId(
    patientId: Patient['id'],
    albumId: Album['id'],
    albumFileIds: ReadonlyArray<AlbumFile['id']>
  ) {
    const items = this.itemsByAlbumId[albumId] || [];
    const itemsToDelete = items.filter(({id}) => albumFileIds.includes(id));
    if (!itemsToDelete.length) {
      return;
    }

    await Promise.all(
      itemsToDelete.map(({id}) => this.api.delete(`${this.getUrlByAlbumId(patientId, albumId)}/${id}`))
    );

    runInAction(() => {
      this.itemsByAlbumId[albumId] = items.filter(({id}) => !albumFileIds.includes(id));

      const existsInFavorites = (this.allFavoritesByProfileId[patientId] || []).filter(({id}) =>
        albumFileIds.includes(id)
      );

      if (existsInFavorites.length) {
        this.allFavoritesByProfileId[patientId] = (this.allFavoritesByProfileId[patientId] || []).filter(
          ({id}) => !albumFileIds.includes(id)
        );
      }

      this.albumStore.itemsByPatientId[patientId] = (this.albumStore.itemsByPatientId[patientId] || []).map(album => {
        if (album.id === albumId) {
          return {
            ...album,
            filesCount: (album.filesCount || 0) - itemsToDelete.length,
          };
        }

        return album;
      });
    });
  }

  async rotateManyByAlbumId(
    patientId: Patient['id'],
    albumId: Album['id'],
    rotated: ReadonlyArray<{id: AlbumFile['id']; angle: number}>
  ) {
    const updated = await Promise.all(
      rotated.map(async ({id, angle}) => {
        try {
          const simpleAngle = angle % 360;
          if (!simpleAngle) {
            return;
          }
          const {data: updatedData} = await this.api.post(
            `${this.getUrlByAlbumId(patientId, albumId)}/${id}/rotate/${simpleAngle}`
          );

          return updatedData;
        } catch (err) {
          console.error(err);
        }
      })
    );

    runInAction(() => {
      this.itemsByAlbumId[albumId] = (this.itemsByAlbumId[albumId] || []).reduce((arr: Array<AlbumFile>, item) => {
        const updatedData = updated.find(updatedItem => updatedItem && item.id === updatedItem.id);
        if (updatedData) {
          arr.push(updatedData);
        } else {
          arr.push(item);
        }
        return arr;
      }, []);
    });

    return updated;
  }

  async fetchAllFavorite(profileId: Patient['id'], force = false) {
    if (!force && this.allFavoritesByProfileId[profileId]) {
      return;
    }

    if (this.loadingAllFavoritesByProfileId[profileId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllFavoritesByProfileId[profileId] = true;
    });
    let more = true;
    let page = 1;
    const arr: AlbumFile[] = [];
    while (more) {
      const {data} = await this.api.get<ListResponse<AlbumFile>>(`patients/${profileId}/album-files`, {
        params: {
          page,
          limit: FAVORITES_LIMIT,
          isFavorite: true,
        },
      });

      if (!data) {
        break;
      }

      if (data.items) {
        arr.push(...data.items);
      }
      more = (data.meta.totalPages || 0) > page;
      page += 1;
    }

    runInAction(() => {
      this.allFavoritesByProfileId[profileId] = arr;
      this.loadingAllFavoritesByProfileId[profileId] = false;
    });
  }

  async makeFavorite(patientId: Patient['id'], albumId: Album['id'], albumFileId: AlbumFile['id'], value: boolean) {
    const items = this.itemsByAlbumId[albumId] || [];
    const item = items.find(({id}) => id === albumFileId);
    if (!item) {
      return;
    }

    await this.api.put(`${this.getUrlByAlbumId(patientId, albumId)}/${albumFileId}`, {
      ...item,
      isFavorite: value,
    });

    runInAction(() => {
      this.itemsByAlbumId[albumId] = items.map(item => {
        if (item.id === albumFileId) {
          item.isFavorite = value;
        }

        return item;
      });

      this.allFavoritesByProfileId[patientId] = value
        ? [...(this.allFavoritesByProfileId[patientId] || []), item]
        : (this.allFavoritesByProfileId[patientId] || []).filter(({id}) => id !== albumFileId);
    });
  }

  async removeFavorite(patientId: Patient['id'], albumFileId: AlbumFile['id']) {
    const item = (this.allFavoritesByProfileId[patientId] || []).find(({id}) => id === albumFileId);
    if (!item) {
      return;
    }

    const albumId = item.album?.id;

    runInAction(async () => {
      this.allFavoritesByProfileId[patientId] = (this.allFavoritesByProfileId[patientId] || []).filter(
        ({id}) => id !== albumFileId
      );

      await this.api.put(
        albumId
          ? `${this.getUrlByAlbumId(patientId, albumId)}/${albumFileId}`
          : `${this.getUrlByPatientId(patientId)}/${albumFileId}`,
        {
          ...item,
          isFavorite: false,
        }
      );

      if (albumId && this.itemsByAlbumId[albumId]) {
        this.itemsByAlbumId[albumId] = this.itemsByAlbumId[albumId].map(item => {
          if (item.id === albumFileId) {
            item.isFavorite = false;
          }

          return item;
        });
      }
    });
  }

  /**
   * Function to define REST API url for entity by album id
   * @param patientId
   * @param albumId
   * @returns string. /patient/:patientId/albums/:albumId/files
   */
  protected getUrlByAlbumId(patientId: Patient['id'], albumId: Album['id']): string {
    return `${this.getPrefix(patientId)}/albums/${albumId}/${this.entitiesName}`;
  }
}
