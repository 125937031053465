import {useCallback, useEffect} from 'react';

import {Patient} from '../../interfaces/entities/patient.interface';
import {useStores} from '../use-stores';

export const usePatients = (): {
  items: ReadonlyArray<Patient>;
  hasMore: boolean;
  loadMore: (limit?: number, prefix?: string | undefined) => Promise<void>;
  loading?: boolean;
  patientsWithNewGuestbookPosts: number;
} => {
  const {patientsStore: store} = useStores();

  useEffect(() => {
    store.list();
  }, [store.list]);

  const loadMore = useCallback(() => {
    return store.loadMore();
  }, [store, store.loadMore]);

  return {
    items: store.items,
    hasMore: store.hasMore,
    loadMore: loadMore,
    loading: store.loading,
    patientsWithNewGuestbookPosts: store.patientsWithNewGuestbookMessages,
  };
};
