import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import React, {memo} from 'react';

import {DEEP_SPACE_SPARKLE_COLOR} from '../../theme';

function IconCloseButton(props: IconButtonProps) {
  return (
    <IconButton
      sx={{
        color: DEEP_SPACE_SPARKLE_COLOR,
      }}
      {...props}
    >
      <CancelOutlinedIcon />
    </IconButton>
  );
}

export const IconCloseButtonComponent = memo(IconCloseButton);
