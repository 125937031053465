import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import React, {forwardRef, memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {InputFileComponent} from '../../components/input-file.component';
import {useProgressiveImage} from '../../hooks/use-progressive-image';
import {Patient} from '../../interfaces/entities/patient.interface';

type PatientPhoto = Pick<Patient, 'image' | 'firstName'>;

interface PatientNameFormProps extends PatientPhoto {
  onChange: (data: PatientPhoto) => void;
  upload: (file: File) => Promise<string>;
}

const UPLOAD_COMPONENTS_WIDTH = 340;

function PatientPhotoFormBase(
  {onChange, upload, ...props}: PatientNameFormProps,
  ref: React.Ref<FormikProps<Patient>>
) {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const onSelectFiles = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (value: string) => void) => {
      if (!event.target.files?.[0]) {
        return;
      }
      setLoading(true);
      const url = await upload(event.target.files[0]);
      setFieldValue(url);
      setLoading(false);
    },
    [upload]
  );

  return (
    <Formik<PatientPhoto>
      initialValues={(props as PatientPhoto) || {}}
      onSubmit={onChange}
      innerRef={ref as React.Ref<FormikProps<PatientPhoto>>}
    >
      {() => (
        <Form>
          <Field name="image">
            {(props: FieldProps) => {
              const selectImageId = `${props.field.name}-select-photo-id`;
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [imageSrc, {blur}] = useProgressiveImage(props.field.value, {
                width: UPLOAD_COMPONENTS_WIDTH,
                height: UPLOAD_COMPONENTS_WIDTH,
              });

              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {!loading && props.field.value && (
                    <CardMedia
                      image={imageSrc}
                      sx={theme => ({
                        borderRadius: 1,
                        backgroundSize: 'contain',
                        filter: blur ? 'blur(20px)' : 'none',
                        transition: blur ? 'none' : 'filter 0.3s ease-out',
                        width: `${UPLOAD_COMPONENTS_WIDTH * 0.65}px`,
                        height: `${UPLOAD_COMPONENTS_WIDTH * 0.65}px`,
                        [theme.breakpoints.up('sm')]: {
                          width: `${UPLOAD_COMPONENTS_WIDTH}px`,
                          height: `${UPLOAD_COMPONENTS_WIDTH}px`,
                        },
                      })}
                    />
                  )}
                  {loading && (
                    <Box
                      sx={theme => ({
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.secondary.light,
                        borderRadius: 1,
                        mx: 'auto',
                        '&:hover': {
                          transform: 'scale(1.005)',
                        },
                        width: `${UPLOAD_COMPONENTS_WIDTH * 0.65}px`,
                        height: `${UPLOAD_COMPONENTS_WIDTH * 0.65}px`,
                        [theme.breakpoints.up('sm')]: {
                          width: `${UPLOAD_COMPONENTS_WIDTH}px`,
                          height: `${UPLOAD_COMPONENTS_WIDTH}px`,
                        },
                      })}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  <InputFileComponent
                    inputProps={{accept: 'image/*,.heic,.heif'}}
                    id={selectImageId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onSelectFiles(event, props.form.setFieldValue.bind(props.form, props.field.name))
                    }
                  />
                  <FormLabel htmlFor={selectImageId}>
                    {!loading && !props.field.value && (
                      <Box
                        sx={theme => ({
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: theme.palette.secondary.light,
                          borderRadius: '50%',
                          cursor: 'pointer',
                          mx: 'auto',
                          '&:hover': {
                            transform: 'scale(1.005)',
                          },
                          width: `${UPLOAD_COMPONENTS_WIDTH * 0.65}px`,
                          height: `${UPLOAD_COMPONENTS_WIDTH * 0.65}px`,
                          [theme.breakpoints.up('sm')]: {
                            width: `${UPLOAD_COMPONENTS_WIDTH}px`,
                            height: `${UPLOAD_COMPONENTS_WIDTH}px`,
                          },
                        })}
                      >
                        <CloudUploadIcon
                          sx={theme => ({
                            color: theme.palette.secondary.main,
                            height: '100px',
                            width: '100px',
                          })}
                        />
                      </Box>
                    )}

                    <Button
                      component="span"
                      sx={theme => ({
                        width: `${UPLOAD_COMPONENTS_WIDTH}px`,
                        mx: 'auto',
                        mt: 1.25,
                        height: '32px',
                        [theme.breakpoints.up('sm')]: {
                          mt: 3,
                          height: '38px',
                        },
                      })}
                      variant="contained"
                    >
                      {!loading && props.field.value
                        ? t('patient-edit-profile-picture.change')
                        : t('patient-edit-profile-picture.upload')}
                    </Button>
                  </FormLabel>
                </Box>
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
}

export const PatientPhotoForm = memo(forwardRef(PatientPhotoFormBase));
