import {Patient} from '../interfaces/entities/patient.interface';

export function getCareFacility({unit}: Pick<Patient, 'unit'>): string {
  return unit?.parents
    ? unit.parents.reduce((str, item) => {
        if (!str && item?.name) {
          return item.name;
        }

        if (item?.name) {
          return `${str}, ${item?.name}`;
        }

        return str;
      }, '')
    : '';
}
