import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {PROFILE_ROUTE_RELATIVES} from '../../configs/profile-routes';
import {Relative} from '../../interfaces/entities/relative.interface';
import {BLACK_COLOR} from '../../theme';
import getName from '../../utils/get-name';
import {AvatarComponent} from '../avatar.component';

function RelativeCard(props: Relative) {
  const {id, firstName, lastName, relationName, image} = props;
  const name = getName(firstName, lastName);

  return (
    <Link underline="none" to={`${PROFILE_ROUTE_RELATIVES.path}/${id}`} component={RouterLink}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <AvatarComponent
            name={name}
            src={image || ''}
            sx={theme => ({
              height: '166px',
              width: '166px',
              fontSize: theme.typography.h2.fontSize,
              fontWeight: theme.typography.h2.fontWeight,
              fontStyle: theme.typography.h2.fontStyle,
              lineHeight: theme.typography.h2.lineHeight,
              letterSpacing: theme.typography.h2.letterSpacing,
            })}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1" color={BLACK_COLOR} textAlign="center" paddingTop={2}>
            {name}
          </Typography>
        </Grid>
        {relationName && (
          <Grid item>
            <Typography
              variant="body2"
              color={'primary'}
              textAlign="center"
              paddingTop={1}
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {relationName}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Link>
  );
}

export const RelativeCardComponent = memo(RelativeCard);
