import {AxiosInstance} from 'axios';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {ACCESS_ROLES} from '../interfaces/entities/access.interface';
import {CommonGuestbookPost} from '../interfaces/entities/common-guestbook-post.interface';
import {GuestbookFile} from '../interfaces/entities/guestbook-file.interface';
import {GuestbookLike} from '../interfaces/entities/guestbook-like.interface';
import {GuestbookPost} from '../interfaces/entities/guestbook-post.interface';
import {GuestbookPostReadBy} from '../interfaces/entities/guestbook-post-read-by.interface';
import {GuestbookReply} from '../interfaces/entities/guestbook-reply.interface';
import {Patient} from '../interfaces/entities/patient.interface';
import {GuestbookPreviewFile} from '../interfaces/guestbook-preview-file.interface';
import {User} from '../interfaces/user.interface';
import {UploadsService} from '../services/uploads.service';
import {getRandomId} from '../utils/get-random-id';
import {isVideoType} from '../utils/is-video-type';
import {GuestbookFilesStore} from './guestbook-files.store';
import {PatientBaseStore} from './shared/patient-base.store';

export class GuestbookPostsStore extends PatientBaseStore<GuestbookPost> {
  creating: Array<GuestbookPost> = [];
  updating: Record<GuestbookPost['id'], GuestbookPost> = {};
  deleting: Array<GuestbookPost['id']> = [];

  creatingReplies: Record<GuestbookPost['id'], Array<GuestbookReply>> = {};
  updatingReplies: Record<GuestbookPost['id'], Record<GuestbookReply['id'], GuestbookReply>> = {};
  deletingReplies: Record<GuestbookPost['id'], Array<GuestbookReply['id']>> = {};

  constructor(
    api: AxiosInstance,
    private readonly guestbookFilesStore: GuestbookFilesStore,
    private readonly uploadsService = new UploadsService(api)
  ) {
    super(api, 'guestbook-posts');
    makeObservable(this, {
      creating: observable,
      updating: observable,
      deleting: observable,
      creatingReplies: observable,
      updatingReplies: observable,
      deletingReplies: observable,
      readGuestbookPost: action,
    });
  }

  getLikes(patientId: string): Record<GuestbookPost['id'], Record<User['id'], GuestbookLike['id']>> {
    const obj: Record<GuestbookPost['id'], Record<User['id'], GuestbookLike['id']>> = {};

    const n = (this.itemsByPatientId[patientId] || []).length;
    for (let i = 0; i < n; i++) {
      const {id: guestbookPostId, guestbookLikes = []} = this.itemsByPatientId[patientId][i];
      if (!obj[guestbookPostId]) {
        obj[guestbookPostId] = {};
      }

      const m = guestbookLikes.length;
      for (let j = 0; j < m; j++) {
        const {id: likeId, user} = guestbookLikes[j];
        obj[guestbookPostId][user.id] = likeId;
      }
    }

    return obj;
  }

  getReplyLikes(
    patientId: string,
    parentId: GuestbookPost['id']
  ): Record<GuestbookPost['id'], Record<User['id'], GuestbookLike['id']>> {
    const obj: Record<GuestbookPost['id'], Record<User['id'], GuestbookLike['id']>> = {};

    const parent = (this.itemsByPatientId[patientId] || []).find(({id}) => id === parentId);

    if (!parent) {
      return {};
    }

    const n = parent.replies.length;
    for (let i = 0; i < n; i++) {
      const {id: guestbookPostId, guestbookLikes = []} = parent.replies[i];
      if (!obj[guestbookPostId]) {
        obj[guestbookPostId] = {};
      }

      const m = guestbookLikes.length;
      for (let j = 0; j < m; j++) {
        const {id: likeId, user} = guestbookLikes[j];
        obj[guestbookPostId][user.id] = likeId;
      }
    }

    return obj;
  }

  async addLike(patientId: string, guestbookPostId: string) {
    const {data} = await this.api.post<GuestbookLike>(
      `${this.getUrlByPatientId(patientId)}/${guestbookPostId}/guestbook-likes`,
      {}
    );

    runInAction(() => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).reduce(
        (arr: Array<GuestbookPost>, item: GuestbookPost) => {
          if (item.id === guestbookPostId) {
            arr.push({
              ...item,
              guestbookLikes: [...item.guestbookLikes, data],
            });
          } else {
            arr.push(item);
          }
          return arr;
        },
        []
      );
    });
  }

  async addLikeToReply(patientId: string, guestbookPostId: string, guestbookReplyId: string) {
    const {data} = await this.api.post<GuestbookLike>(
      `${this.getUrlByPatientId(patientId)}/${guestbookReplyId}/guestbook-likes`,
      {}
    );

    runInAction(() => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).reduce(
        (arr: Array<GuestbookPost>, item: GuestbookPost) => {
          if (item.id === guestbookPostId) {
            arr.push({
              ...item,
              replies: item.replies.reduce((arr: Array<GuestbookReply>, reply: GuestbookReply) => {
                if (reply.id === guestbookReplyId) {
                  arr.push({
                    ...reply,
                    guestbookLikes: [...reply.guestbookLikes, data],
                  });
                } else {
                  arr.push(reply);
                }
                return arr;
              }, []),
            });
          } else {
            arr.push(item);
          }
          return arr;
        },
        []
      );
    });
  }

  async removeLike(patientId: string, guestbookPostId: string, likeId: string) {
    await this.api.delete(`${this.getUrlByPatientId(patientId)}/${guestbookPostId}/guestbook-likes/${likeId}`);
    runInAction(() => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).reduce(
        (arr: Array<GuestbookPost>, item) => {
          if (item.id === guestbookPostId) {
            arr.push({
              ...item,
              guestbookLikes: item.guestbookLikes.filter(({id}) => id !== likeId),
            });
          } else {
            arr.push(item);
          }
          return arr;
        },
        []
      );
    });
  }

  async removeLikeFromReply(patientId: string, guestbookPostId: string, guestbookReplyId: string, likeId: string) {
    await this.api.delete(`${this.getUrlByPatientId(patientId)}/${guestbookReplyId}/guestbook-likes/${likeId}`);

    runInAction(() => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).reduce(
        (arr: Array<GuestbookPost>, item: GuestbookPost) => {
          if (item.id === guestbookPostId) {
            arr.push({
              ...item,
              replies: item.replies.reduce((arr: Array<GuestbookReply>, reply: GuestbookReply) => {
                if (reply.id === guestbookReplyId) {
                  arr.push({
                    ...reply,
                    guestbookLikes: reply.guestbookLikes.filter(({id}) => id !== likeId),
                  });
                } else {
                  arr.push(reply);
                }
                return arr;
              }, []),
            });
          } else {
            arr.push(item);
          }
          return arr;
        },
        []
      );
    });
  }

  createByPatientId = async (patientId: Patient['id'], data: GuestbookPost) => {
    const now = new Date();
    const temporaryId = getRandomId();

    runInAction(async () => {
      this.creating = [
        {
          ...data,
          id: temporaryId,
          createdAt: now,
          updatedAt: now,
          guestbookLikes: [],
          replies: [],
        },
        ...this.creating,
      ];
    });

    const {data: createdData} = await this.api.post<GuestbookPost>(this.getUrlByPatientId(patientId), {
      message: data.message,
    });

    const uploadResults = await Promise.allSettled(
      (data.guestbookFiles as ReadonlyArray<GuestbookPreviewFile>).reduce(
        (arr: Array<Promise<GuestbookFile>>, {file, type}) => {
          if (file) {
            arr.push(
              this.uploadGuestbookFile(patientId, createdData.id, {
                file,
                video: !!(type && isVideoType(type)),
              })
            );
          }
          return arr;
        },
        []
      )
    );

    const guestbookFiles = uploadResults.reduce(
      (arr: Array<GuestbookFile>, {status, value}: {status: string; value?: GuestbookFile}) => {
        if (status === 'fulfilled' && value) {
          arr.push(value);
        }
        return arr;
      },
      []
    );

    runInAction(async () => {
      this.itemsByPatientId[patientId] = [
        {...createdData, guestbookFiles},
        ...(this.itemsByPatientId[patientId] || []),
      ];
      this.creating = this.creating.filter(({id}) => id !== temporaryId);
    });

    this.guestbookFilesStore.resetByProfileId(patientId);

    return this.itemByPatientId[patientId];
  };

  createReplyByPatientId = async (
    patientId: Patient['id'],
    guestbookPostId: GuestbookPost['id'],
    data: GuestbookReply
  ) => {
    const now = new Date();
    const temporaryId = getRandomId();

    runInAction(async () => {
      this.creatingReplies[guestbookPostId] = [
        ...(this.creatingReplies[guestbookPostId] || []),
        {
          ...data,
          id: temporaryId,
          createdAt: now,
          updatedAt: now,
          guestbookLikes: [],
          replies: [],
          parent: {id: guestbookPostId} as GuestbookPost,
        },
      ];
    });

    const {data: createdData} = await this.api.post<GuestbookReply>(this.getUrlByPatientId(patientId), {
      message: data.message,
      parentId: guestbookPostId,
    });

    const uploadResults = await Promise.allSettled(
      (data.guestbookFiles as ReadonlyArray<GuestbookPreviewFile>).reduce(
        (arr: Array<Promise<GuestbookFile>>, {file, type}) => {
          if (file) {
            arr.push(
              this.uploadGuestbookFile(patientId, createdData.id, {
                file,
                video: !!(type && isVideoType(type)),
              })
            );
          }
          return arr;
        },
        []
      )
    );

    const guestbookFiles = uploadResults.reduce(
      (arr: Array<GuestbookFile>, {status, value}: {status: string; value?: GuestbookFile}) => {
        if (status === 'fulfilled' && value) {
          arr.push(value);
        }
        return arr;
      },
      []
    );

    runInAction(async () => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).map(item => {
        if (item.id === guestbookPostId) {
          return {
            ...item,
            replies: [...item.replies, {...createdData, guestbookFiles}],
          };
        }

        return item;
      });
      this.creatingReplies[guestbookPostId] = this.creatingReplies[guestbookPostId].filter(
        ({id}) => id !== temporaryId
      );
    });

    this.guestbookFilesStore.resetByProfileId(patientId);

    return createdData;
  };

  updateByPatientId = async (patientId: Patient['id'], guestbookPostId: GuestbookPost['id'], data: GuestbookPost) => {
    const beforeUpdateData = (this.itemsByPatientId[patientId] || []).find(({id}) => id === guestbookPostId);

    if (!beforeUpdateData) {
      return null;
    }

    runInAction(async () => {
      this.updating[guestbookPostId] = {...beforeUpdateData, ...data};
    });

    const {data: updatedData} = await this.api.put<GuestbookPost>(
      `${this.getUrlByPatientId(patientId)}/${guestbookPostId}`,
      {
        message: data.message,
      }
    );

    const uploadResults = await Promise.allSettled(
      (data.guestbookFiles as ReadonlyArray<GuestbookPreviewFile>).reduce(
        (arr: Array<Promise<GuestbookFile>>, {file, type}) => {
          if (file) {
            arr.push(
              this.uploadGuestbookFile(patientId, updatedData.id, {
                file,
                video: !!(type && isVideoType(type)),
              })
            );
          }
          return arr;
        },
        []
      )
    );

    const guestbookFiles = uploadResults
      .reduce((arr: Array<GuestbookFile>, {status, value}: {status: string; value?: GuestbookFile}) => {
        if (status === 'fulfilled' && value) {
          arr.push(value);
        }
        return arr;
      }, [])
      .concat(data.guestbookFiles?.filter(({id}) => id) || []);

    const guestbookFilesToDelete = beforeUpdateData.guestbookFiles.filter(
      ({id}) => !guestbookFiles.some(file => file.id === id)
    );

    await Promise.all(
      guestbookFilesToDelete.map(async ({id}) => {
        try {
          await this.api.delete<GuestbookPost>(
            `${this.getUrlByPatientId(patientId)}/${guestbookPostId}/guestbook-files/${id}`
          );
        } catch (err) {
          console.error(err);
        }
      })
    );

    runInAction(() => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).map(item => {
        if (item.id === guestbookPostId) {
          return {...updatedData, guestbookFiles};
        }

        return item;
      });

      delete this.updating[guestbookPostId];
    });

    this.guestbookFilesStore.resetByProfileId(patientId);

    return updatedData;
  };

  updateReplyByPatientId = async (
    patientId: Patient['id'],
    guestbookPostId: GuestbookPost['id'],
    guestbookReplyId: GuestbookReply['id'],
    data: GuestbookPost
  ) => {
    const beforeUpdateData = (this.itemsByPatientId[patientId] || [])
      .find(({id}) => id === guestbookPostId)
      ?.replies.find(({id}) => id === guestbookReplyId);

    if (!beforeUpdateData) {
      return null;
    }

    runInAction(async () => {
      this.updatingReplies[guestbookPostId] = {
        ...this.updatingReplies[guestbookPostId],
        [guestbookReplyId]: {...beforeUpdateData, ...data},
      };
    });

    const {data: updatedData} = await this.api.put<GuestbookReply>(
      `${this.getUrlByPatientId(patientId)}/${guestbookReplyId}`,
      {
        message: data.message,
      }
    );

    const uploadResults = await Promise.allSettled(
      (data.guestbookFiles as ReadonlyArray<GuestbookPreviewFile>).reduce(
        (arr: Array<Promise<GuestbookFile>>, {file, type}) => {
          if (file) {
            arr.push(
              this.uploadGuestbookFile(patientId, updatedData.id, {
                file,
                video: !!(type && isVideoType(type)),
              })
            );
          }
          return arr;
        },
        []
      )
    );

    const guestbookFiles = uploadResults
      .reduce((arr: Array<GuestbookFile>, {status, value}: {status: string; value?: GuestbookFile}) => {
        if (status === 'fulfilled' && value) {
          arr.push(value);
        }
        return arr;
      }, [])
      .concat(data.guestbookFiles?.filter(({id}) => id) || []);

    const guestbookFilesToDelete = beforeUpdateData.guestbookFiles.filter(
      ({id}) => !guestbookFiles.some(file => file.id === id)
    );

    await Promise.all(
      guestbookFilesToDelete.map(async ({id}) => {
        try {
          await this.api.delete<GuestbookPost>(
            `${this.getUrlByPatientId(patientId)}/${guestbookReplyId}/guestbook-files/${id}`
          );
        } catch (err) {
          console.error(err);
        }
      })
    );

    runInAction(() => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).map(item => {
        if (item.id === guestbookPostId) {
          return {
            ...item,
            replies: item.replies.map(reply => {
              if (reply.id === guestbookReplyId) {
                return {...updatedData, guestbookFiles};
              }

              return reply;
            }),
          };
        }

        return item;
      });

      delete this.updatingReplies[guestbookPostId][guestbookReplyId];
    });

    return updatedData;
  };

  async deleteByPatientId(patientId: Patient['id'], id: GuestbookPost['id']): Promise<void> {
    runInAction(() => {
      this.deleting = [...this.deleting, id];
    });
    await super.deleteByPatientId(patientId, id);
    runInAction(() => {
      this.deleting = this.deleting.filter(deletingId => deletingId !== id);
    });

    this.guestbookFilesStore.resetByProfileId(patientId);
  }

  async deleteReplyByPatientId(
    patientId: Patient['id'],
    guestbookPostId: GuestbookPost['id'],
    id: GuestbookPost['id']
  ): Promise<void> {
    runInAction(() => {
      this.deletingReplies[guestbookPostId] = [...(this.deletingReplies[guestbookPostId] || []), id];
    });
    await super.deleteByPatientId(patientId, id);
    runInAction(() => {
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).map(item => {
        if (item.id === guestbookPostId) {
          return {
            ...item,
            replies: item.replies.filter(reply => reply.id !== id),
          };
        }

        return item;
      });
      this.deletingReplies[guestbookPostId] = this.deletingReplies[guestbookPostId].filter(
        deletingId => deletingId !== id
      );
    });

    this.guestbookFilesStore.resetByProfileId(patientId);
  }

  createCommon = async (
    data: Pick<GuestbookPost, 'message' | 'guestbookFiles'> & {
      patients: ReadonlyArray<Patient['id']>;
    },
    admin?: boolean
  ) => {
    const {data: createdData} = await this.api.post<CommonGuestbookPost>(
      `${admin ? 'admin/' : ''}guestbook-posts/common`,
      {
        message: data.message,
        patients: data.patients,
      }
    );

    const uploadResults = await Promise.allSettled(
      (data.guestbookFiles as ReadonlyArray<GuestbookPreviewFile>).reduce(
        (arr: Array<Promise<GuestbookFile[]>>, {file, type}) => {
          if (file) {
            arr.push(
              this.uploadCommonGuestbookFile(createdData.id, {
                file,
                video: !!(type && isVideoType(type)),
              })
            );
          }
          return arr;
        },
        []
      )
    );

    const guestbookFiles: (GuestbookFile & {
      guestbookPost?: GuestbookPost;
    })[] = uploadResults.reduce(
      (arr: Array<GuestbookFile>, {status, value}: {status: string; value?: GuestbookFile[]}) => {
        if (status === 'fulfilled' && value) {
          arr.push(...value);
        }
        return arr;
      },
      []
    );

    runInAction(async () => {
      const n = createdData.guestbookPosts.length;
      for (let i = 0; i < n; i++) {
        const guestbookPost = createdData.guestbookPosts[i];
        if (!guestbookPost.patient || !this.itemsByPatientId[guestbookPost.patient.id]) {
          continue;
        }

        this.itemsByPatientId[guestbookPost.patient.id] = [
          {
            ...createdData.guestbookPosts[i],
            guestbookFiles: guestbookFiles.filter(
              guestbookFile => guestbookFile.guestbookPost?.id === guestbookPost.id
            ),
          },
          ...(this.itemsByPatientId[guestbookPost.patient.id] || []),
        ];
      }
    });

    for (let i = 0; i < data.patients.length; i++) {
      this.guestbookFilesStore.resetByProfileId(data.patients[i]);
    }
  };

  async readGuestbookPost(
    patientId: Patient['id'],
    id: GuestbookPost['id'],
    guestbookPostCommonId?: GuestbookPost['id'],
    user?: User,
    role?: ACCESS_ROLES
  ) {
    try {
      const {data} = await this.api.post<GuestbookPostReadBy>(`${this.getUrlByPatientId(patientId)}/${id}/read`, {});

      const newReadBy = {
        ...data,
        user: {...user, access: {role}},
      } as GuestbookPostReadBy;

      if (!guestbookPostCommonId) {
        runInAction(() => {
          this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).map((item: GuestbookPost) => ({
            ...item,
            ...(item.id === id
              ? {
                  isNew: false,
                  readByMe: data,
                  readBy: [...(item.readBy || []), newReadBy],
                }
              : {}),
            replies: (item.replies || []).map((reply: GuestbookReply) => ({
              ...reply,
              ...(reply.id === id
                ? {
                    isNew: false,
                    readByMe: data,
                    readBy: [...(reply.readBy || []), newReadBy],
                  }
                : {}),
            })),
          }));
        });
      } else {
        runInAction(() => {
          for (patientId of Object.keys(this.itemsByPatientId)) {
            this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).map((item: GuestbookPost) => ({
              ...item,
              ...(item.commonId === guestbookPostCommonId
                ? {
                    isNew: false,
                    readByMe: data,
                    readBy: [...(item.readBy || []), newReadBy],
                  }
                : {}),
            }));
          }
        });
      }
    } catch (err) {
      console.error(err);
      return;
    }
  }

  private async uploadGuestbookFile(
    patientId: Patient['id'],
    guestbookPostId: GuestbookPost['id'],
    {file, video}: {file: File; video?: boolean}
  ): Promise<GuestbookFile> {
    const {url} = await this.uploadsService.upload(
      `patients/${patientId}/guestbook-posts/${guestbookPostId}`,
      file,
      video ? {type: 'video'} : undefined
    );
    const {data} = await this.api.post<GuestbookFile>(
      `${this.getUrlByPatientId(patientId)}/${guestbookPostId}/guestbook-files`,
      {
        url,
      }
    );

    return data;
  }

  private async uploadCommonGuestbookFile(
    id: CommonGuestbookPost['id'],
    {file, video}: {file: File; video?: boolean}
  ): Promise<GuestbookFile[]> {
    const {url} = await this.uploadsService.upload(
      `guestbook-posts/common/${id}`,
      file,
      video ? {type: 'video'} : undefined
    );

    const {data} = await this.api.post<GuestbookFile[]>(`guestbook-posts/common/${id}/guestbook-files`, {
      url,
    });

    return data;
  }
}
