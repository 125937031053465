import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Button, {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

interface DeleteButtonProps extends ButtonProps {
  text?: string;
}

function DeleteButton({text, ...props}: DeleteButtonProps) {
  const {t} = useTranslation();

  return (
    <Button variant="outlined" {...props} disableElevation>
      <DeleteOutlineIcon sx={{paddingRight: '5px'}} />
      {text || t('general-delete.button')}
    </Button>
  );
}

export const DeleteButtonComponent = memo(DeleteButton);
