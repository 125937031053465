import {AxiosInstance} from 'axios';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {Patient} from '../interfaces/entities/patient.interface';
import {GetManyResponse} from '../interfaces/get-many-response.interface';
import {PatientsFilters} from '../interfaces/patients-filters.interface';
import {getUnitParentsJoin} from '../utils/get-unit-parents-join';

const DEFAULT_FETCH_ALL_BY_FILTERS_KEY = 'fetch-all-key';

const FETCH_ALL_BY_FILTERS_LIMIT = 100;
const ADMIN_FETCH_ALL_BY_FILTERS_LIMIT = 10000;
const FETCH_ALL_BY_FILTERS_SORT = 'firstName,ASC';

export class V2PatientsStore {
  filters?: PatientsFilters;
  loadingFilters?: boolean;

  allItemsByFilters: Record<string, Patient[]> = {};
  loadingAllItemsByFilters: Record<string, boolean> = {};
  errorLoadingAllItemsByFilters: Record<Patient['id'], Error | undefined> = {};

  constructor(
    protected readonly api: AxiosInstance,
    protected readonly entitiesName: string = `v2/patients`,
    protected readonly adminEntitiesName: string = `v2/admin/patients`
  ) {
    makeObservable(this, {
      allItemsByFilters: observable,
      loadingAllItemsByFilters: observable,
      errorLoadingAllItemsByFilters: observable,
      filters: observable,
      loadingFilters: observable,

      fetchFilters: action,
    });
  }

  async fetchFilters(admin = false, force = false): Promise<void> {
    if (this.loadingFilters) {
      return;
    }

    if (!force && this.filters) {
      return;
    }

    runInAction(() => {
      this.loadingFilters = true;
    });
    try {
      const {data} = await this.api.get<PatientsFilters>(
        `${admin ? this.adminEntitiesName : this.entitiesName}/filters`
      );
      runInAction(() => {
        this.filters = data;
      });
    } catch (err) {
      console.error(err);
    }
    runInAction(() => {
      this.loadingFilters = false;
    });
  }

  async fetchAllItemsByFilters(
    search?: Record<string, string>,
    key = DEFAULT_FETCH_ALL_BY_FILTERS_KEY,
    admin = false,
    force = false
  ) {
    if (!force && this.allItemsByFilters[key]) {
      return;
    }

    if (this.loadingAllItemsByFilters[key]) {
      return;
    }

    const searchParams = new URLSearchParams();
    if (search) {
      searchParams.append('s', JSON.stringify(search));
    }

    searchParams.append('limit', '' + (admin ? ADMIN_FETCH_ALL_BY_FILTERS_LIMIT : FETCH_ALL_BY_FILTERS_LIMIT));
    searchParams.append('sort', FETCH_ALL_BY_FILTERS_SORT);

    const join: string[] = getUnitParentsJoin('unit');
    for (const joinItem of join) {
      searchParams.append('join', joinItem);
    }

    runInAction(() => {
      this.loadingAllItemsByFilters[key] = true;
    });

    let more = true;
    let page = 1;
    const arr: Patient[] = [];
    while (more) {
      searchParams.set('page', '' + page);
      const {data} = await this.api.get<GetManyResponse<Patient>>(
        `${admin ? this.adminEntitiesName : this.entitiesName}?${searchParams.toString()}`
      );

      if (!data) {
        break;
      }

      if (data.data) {
        arr.push(...data.data);
      }
      more = (data.pageCount || 0) > page;
      page += 1;
    }

    runInAction(() => {
      this.allItemsByFilters[key] = arr;
      this.loadingAllItemsByFilters[key] = false;
    });
  }
}
