import {User} from '../user.interface';
import {BaseEntity} from './base-entity.interface';
import {Patient} from './patient.interface';

export enum ACCESS_ROLES {
  MEMBER = 'member',
  ADMIN = 'admin',
}

export interface Access extends BaseEntity {
  role: ACCESS_ROLES;
  user: User;
  userId: User['id'];
  patient: Patient;
  invitation?: Record<string, any>;
}
