import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Theme} from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import React, {memo, useCallback, useMemo, useRef} from 'react';

import {GuestbookPreviewFile} from '../../interfaces/guestbook-preview-file.interface';
import {EMPTY_IMAGE} from '../../utils/constants';
import {addQualityToImageURL} from '../../utils/images/add-quality-to-image-url';
import {isVideoType} from '../../utils/is-video-type';
import {isVideoUrl} from '../../utils/is-video-url';

const IMAGE_QUALITY = 'auto';
const IMAGE_HEIGHT = 500;

export interface GuestbookFileCardProps extends Partial<Omit<GuestbookPreviewFile, 'id'>> {
  id: number | string;
  onClick?: (id: number | string) => void;
  onClose?: (id: number | string) => void;
  height?: string;
}

function GuestbookFileCard({id, url = EMPTY_IMAGE, type, onClose, onClick, height = '75%'}: GuestbookFileCardProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const onClickClose = useCallback(() => {
    if (onClose && id !== undefined && id !== null) {
      onClose(id);
    }
  }, [onClose, id]);

  const video = useMemo(() => {
    return (type && isVideoType(type)) || isVideoUrl(url);
  }, [url, type]);

  const imageUrl = useMemo(() => {
    if (video) {
      return url;
    }
    return url ? addQualityToImageURL(url, IMAGE_QUALITY, IMAGE_HEIGHT) : EMPTY_IMAGE;
  }, [url, video]);

  const onClickCard = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!video && onClick && id !== undefined && id !== null) {
        onClick(id);
      }

      if (videoRef?.current && event.target !== videoRef.current) {
        return videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
      }
    },
    [onClick, id, video, videoRef]
  );

  return (
    <Card sx={{position: 'relative', overflow: 'visible'}}>
      <CardActionArea onClick={onClickCard}>
        {video ? (
          <CardMedia
            image={url}
            sx={{
              pt: height,
              position: 'relative',
              backgroundSize: 'contain',
            }}
          >
            <video
              ref={videoRef}
              src={url}
              controls
              style={{
                position: 'absolute',
                width: '100%',
                top: '50%',
                transform: 'translate(0,-50%)',
                maxHeight: '100%',
              }}
            ></video>
          </CardMedia>
        ) : (
          <CardMedia
            image={imageUrl}
            sx={{
              pt: height,
              backgroundSize: 'contain',
            }}
          />
        )}
      </CardActionArea>

      {onClose && (
        <IconButton
          aria-label="delete"
          onClick={onClickClose}
          sx={(theme: Theme) => ({
            position: 'absolute',
            zIndex: theme.zIndex.drawer + 2,
            top: '-10px',
            right: '-10px',
            backgroundColor: 'secondary.dark',
            color: 'secondary.light',
            padding: '2px',
            transition: 'transform .2s',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0, 0.6)',
              color: 'secondary.light',
              transform: 'scale(1.1)',
            },
          })}
        >
          <DeleteForeverIcon />
        </IconButton>
      )}
    </Card>
  );
}

export const GuestbookFileCardComponent = memo(GuestbookFileCard);
