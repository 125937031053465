export function toCamelCase(str: string): string {
  // Split the string by dots
  const parts = str.split('.');

  // Capitalize the first letter of each part except the first part
  const camelCaseParts = parts.map((part, index) => {
    if (index === 0) {
      return part;
    }
    return part.charAt(0).toUpperCase() + part.slice(1);
  });

  // Join the parts together
  const camelCaseStr = camelCaseParts.join('');

  return camelCaseStr;
}
