import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import omit from 'lodash/omit';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {AccessCardComponent} from '../../components/accesses/access-card.component';
import {AccessDepartmentCardComponent} from '../../components/accesses/access-department-card.component';
import {GiveAccessToNursesButtonComponent} from '../../components/buttons/give-access-to-nurses-button.component';
import {ConfirmGiveAccessToDepartmentComponent} from '../../components/dialog/confirm-give-access-to-department.component';
import {EmptyScreenComponent} from '../../components/empty-screen.component';
import {FailedLoadingDataComponent} from '../../components/failed-loading-data.component';
import {InvitationCardComponent} from '../../components/invitations/invitation-card.component';
import {AccessesListLoadingComponent} from '../../components/loading/accesses/accesses-list-loading.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {PatientNestedItemsInfiniteScrollComponent} from '../../components/patients/patient-nested-items-infinite-scroll.component';
import {PatientNestedItemsWrapperComponent} from '../../components/patients/patient-nested-items-wrapper.component';
import {PATIENT_NAVIGATION_ITEM_INVITATIONS} from '../../configs/patient-navigation';
import {CREATE_PATH} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {usePatient} from '../../hooks/patients/use-patient';
import {usePatientNestedItems} from '../../hooks/patients/use-patient-nested-items';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useUnit} from '../../hooks/units/use-unit';
import {useStores} from '../../hooks/use-stores';
import {Access} from '../../interfaces/entities/access.interface';
import {Invitation, INVITATION_STATUSES} from '../../interfaces/entities/invitation.interface';
import {Unit} from '../../interfaces/entities/unit.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';
import {User} from '../../interfaces/user.interface';
import {DEPARTMENT_UNIT_TYPE} from '../../stores/units.store';
import {findUnitsByType} from '../../utils/find-units-in-parents';

const LIMIT = 30;

function AccessesList({profileId}: ProfilePageProps) {
  const {accessesStore, invitationsStore, authStore, usersStore} = useStores();
  const history = useHistory();

  const {t} = useTranslation();

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    accessesStore.fetchAllItemsByPatientId(profileId);
  }, [profileId]);

  const accesses = accessesStore.allItemsByPatientId[profileId] || [];
  const loadingAccesses = accessesStore.loadingAllItemsByPatientId[profileId];
  const errorLoadingAccesses = accessesStore.loadingAllItemsByPatientId[profileId];
  const reloadAccesses = useCallback(
    () => accessesStore.fetchAllItemsByPatientId(profileId, true),
    [profileId, accessesStore.fetchAllItemsByPatientId]
  );

  const {
    items: invitations,
    loading: loadingInvitations,
    hasMore: hasMoreInvitations,
    loadMore: loadMoreInvitations,
    errorLoading: errorLoadingInvitations,
    reload: reloadInvitations,
  } = usePatientNestedItems(invitationsStore, profileId, LIMIT);

  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const {item: patient} = usePatient(profileId);

  const {item: unit, fetchLoading: loadingUnit} = useUnit(patient?.unitId);

  const [giveAccessToDepartmentId, setGiveAccessToDepartmentId] = useState<Unit['id'] | null>(null);
  const [loading, setLoading] = useState(false);
  const [nursesIdsToRemoveAccess, setNursesIdsToRemoveAccess] = useState<User['id'][]>([]);
  const [nursesToCreateAccess, setNursesToCreateAccess] = useState<Record<User['id'], Access['role']>>({});

  const onClickCreate = useCallback(() => {
    history.push(`${MY_PATIENTS_ROUTE.path}/${profileId}/${PATIENT_NAVIGATION_ITEM_INVITATIONS.to}/${CREATE_PATH}`);
  }, [history, profileId]);

  const pendingInvitations = useMemo(() => {
    return invitations.filter(({status}) => status === INVITATION_STATUSES.PENDING);
  }, [invitations]);

  const onDeleteInvitation = useCallback(
    (id: Invitation['id']) => {
      invitationsStore.deleteByPatientId(profileId, id);
    },
    [profileId, invitationsStore.deleteByPatientId]
  );

  const onResendInvitation = useCallback(
    async (id: Invitation['id']) => {
      try {
        await invitationsStore.resendByPatientId(profileId, id);
        enqueueSnackbar(t('access-list-pending-invite-resend-success.notification'), {variant: 'success'});
      } catch (_err) {
        enqueueSnackbar(t('access-list-pending-invite-resend-error.notification'), {variant: 'error'});
      }
    },
    [profileId, invitationsStore.resendByPatientId, enqueueSnackbar, t]
  );

  const clickGiveAccessDepartment = useCallback((id: Unit['id']) => {
    setGiveAccessToDepartmentId(id);
  }, []);

  const closeGiveAccessDialog = useCallback(() => {
    setGiveAccessToDepartmentId(null);
    setNursesToCreateAccess({});
    setNursesIdsToRemoveAccess([]);
  }, []);

  const confirmGiveAccessDepartment = useCallback(async () => {
    setGiveAccessToDepartmentId(null);

    setLoading(true);
    const accessIdsToRemove = accesses.reduce((arr: Access['id'][], {id, userId}) => {
      if (nursesIdsToRemoveAccess.includes(userId) && !arr.includes(id)) {
        arr.push(id);
      }

      return arr;
    }, []);

    await Promise.all(accessIdsToRemove.map(id => accessesStore.deleteAccessesByPatientId(profileId, id)));
    const nursesToCreateAccessArr = Object.entries(nursesToCreateAccess);
    if (giveAccessToDepartmentId && nursesToCreateAccessArr.length) {
      await accessesStore.createManyAccessesByPatientAndDepartmentId(
        profileId,
        giveAccessToDepartmentId,
        nursesToCreateAccessArr.map(([userId, role]) => ({
          userId,
          role,
        }))
      );
    }
    setLoading(false);
    reloadAccesses();
  }, [
    profileId,
    accessesStore.createManyAccessesByPatientAndDepartmentId,
    accessesStore.deleteAccessesByPatientId,
    reloadAccesses,
    authStore.me,
    nursesToCreateAccess,
    nursesIdsToRemoveAccess,
    accesses,
    giveAccessToDepartmentId,
  ]);

  const onClickRemoveNurse = useCallback(
    (id: User['id']) => {
      setNursesIdsToRemoveAccess([...nursesIdsToRemoveAccess, id]);
      setNursesToCreateAccess(omit(nursesToCreateAccess, id));
    },
    [nursesIdsToRemoveAccess, nursesToCreateAccess]
  );

  const onClickSelectNurse = useCallback(
    (id: User['id'], role: Access['role']) => {
      setNursesToCreateAccess({...nursesToCreateAccess, [id]: role});
      setNursesIdsToRemoveAccess(nursesIdsToRemoveAccess.filter(item => item !== id));
    },
    [nursesToCreateAccess, nursesIdsToRemoveAccess]
  );

  const departments: Unit[] = useMemo(() => {
    if (loadingUnit !== false || !unit) {
      return [];
    }

    return findUnitsByType([unit], DEPARTMENT_UNIT_TYPE);
  }, [loadingUnit, unit]);

  useEffect(() => {
    const n = departments.length;
    for (let i = 0; i < n; i++) {
      usersStore.fetchAllNursesFromDepartment(departments[i].id);
    }
  }, [departments, usersStore.fetchAllNursesFromDepartment]);

  useEffect(() => {
    if (patient?.unitId) {
      usersStore.fetchAllDeactivatedUsersFromUnit(patient.unitId);
    }
  }, [patient?.unitId, usersStore.fetchAllDeactivatedUsersFromUnit]);

  useEffect(() => {
    const n = departments.length;
    for (let i = 0; i < n; i++) {
      accessesStore.fetchAllItemsByPatientAndDepartmentId(profileId, departments[i].id);
    }
  }, [profileId, departments, accessesStore.fetchAllItemsByPatientAndDepartmentId]);

  const nurses = useMemo(() => {
    if (!authStore.me || !patient?.unitId) {
      return [];
    }

    const deactivatedUsers = usersStore.allDeactivatedUsersByUnitId[patient.unitId] || [];

    return giveAccessToDepartmentId
      ? (usersStore.allNursesByDepartmentId[giveAccessToDepartmentId] || []).filter(
          item => !deactivatedUsers.some(({id}) => id === item.id) && item.id !== authStore.me?.id
        )
      : [];
  }, [
    giveAccessToDepartmentId,
    patient?.unitId,
    usersStore.allNursesByDepartmentId[giveAccessToDepartmentId || ''],
    usersStore.allDeactivatedUsersByUnitId[patient?.unitId || ''],
    authStore.me,
  ]);

  const nursesAccesses = useMemo(() => {
    if (!giveAccessToDepartmentId) {
      return {};
    }

    const accessesToDepartment =
      accessesStore.allItemsByPatientAndDepartmentId[
        accessesStore.getKeyForPatientAndDepartment(profileId, giveAccessToDepartmentId)
      ] || [];

    const obj: Record<User['id'], Access['role']> = {};

    const n = nurses.length;
    for (let i = 0; i < n; i++) {
      const nurse = nurses[i];

      const role =
        nursesToCreateAccess[nurse.id] ||
        (!nursesIdsToRemoveAccess.includes(nurse.id)
          ? accessesToDepartment.find(({userId}) => userId === nurse.id)?.role
          : undefined);

      if (role) {
        obj[nurse.id] = role;
      }
    }

    return obj;
  }, [giveAccessToDepartmentId, nurses, profileId, nursesIdsToRemoveAccess, nursesToCreateAccess]);

  const onClickRemoveAllNurses = useCallback(() => {
    setNursesIdsToRemoveAccess(nurses.map(({id}) => id));
    setNursesToCreateAccess({});
  }, [nurses]);

  const onClickSelectAllNurses = useCallback(
    (role: Access['role']) => {
      setNursesToCreateAccess(
        nurses.reduce((obj: Record<User['id'], Access['role']>, {id}) => {
          obj[id] = role;
          return obj;
        }, {})
      );
      setNursesIdsToRemoveAccess([]);
    },
    [nurses]
  );

  if (!loadingInvitations && !loadingAccesses && !accesses.length && !invitations.length) {
    return (
      <EmptyScreenComponent
        title={`${t('access-list-empty.header')}`}
        description={`${t('access-list-empty.body', {
          firstName: patient?.firstName,
        })}`}
        icon={<AddIcon sx={{pl: '2px'}} />}
        onClick={onClickCreate}
        text={`${t('access-list-empty-create-invite.button')}`}
        hasContent={isPatientRoleAdmin}
      />
    );
  }

  return (
    <>
      <PatientNestedItemsWrapperComponent
        onClickCreate={isPatientRoleAdmin ? onClickCreate : undefined}
        titleCreateButton="access-list-create-role.button"
      >
        {loadingUnit === false && departments.length > 0 && (
          <>
            {departments.length === 1 && authStore.me && isPatientRoleAdmin && (
              <GiveAccessToNursesButtonComponent
                sx={theme => ({
                  ml: 'auto',
                  mt: -2,
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
                onClick={() => clickGiveAccessDepartment(departments[0].id)}
                loading={
                  !!(
                    giveAccessToDepartmentId &&
                    (usersStore.loadingAllNursesByDepartmentId[departments[0].id] ||
                      accessesStore.loadingAllItemsByPatientAndDepartmentId[
                        accessesStore.getKeyForPatientAndDepartment(profileId, departments[0].id)
                      ])
                  )
                }
              />
            )}
            <Typography variant="h6" mb={1.5}>
              {departments.length > 1 ? t('access-list-departments.header') : t('access-list-department.header')}:
            </Typography>
            <List>
              {departments.map(item => (
                <Box key={item.id}>
                  <ListItem>
                    <AccessDepartmentCardComponent
                      {...item}
                      onClickGiveAccess={departments.length > 1 ? () => clickGiveAccessDepartment(item.id) : undefined}
                      loadingGiveAccessButton={
                        giveAccessToDepartmentId === item.id &&
                        (usersStore.loadingAllNursesByDepartmentId[item.id] ||
                          accessesStore.loadingAllItemsByPatientAndDepartmentId[
                            accessesStore.getKeyForPatientAndDepartment(profileId, item.id)
                          ])
                      }
                    />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </Box>
              ))}
            </List>
          </>
        )}

        {!loadingAccesses ? (
          <>
            {accesses.length && (
              <Typography variant="h6" mt={departments.length ? 3 : undefined} mb={1.5}>
                {t('access-list-user-access.header')}:
              </Typography>
            )}
            <List>
              {accesses.map((item, index) => (
                <Box key={index}>
                  <ListItem>
                    <AccessCardComponent {...item} />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </Box>
              ))}
            </List>
          </>
        ) : (
          <AccessesListLoadingComponent />
        )}
        {!loadingAccesses && errorLoadingAccesses && (
          <>
            <Typography variant="h6" mb={1.5}>
              {t('access-list-user-access.header')}:
            </Typography>
            <FailedLoadingDataComponent onClick={reloadAccesses} />
          </>
        )}
        {!loadingInvitations ? (
          <PatientNestedItemsInfiniteScrollComponent
            dataLength={invitations.length}
            hasMore={hasMoreInvitations}
            loadMore={loadMoreInvitations}
          >
            {pendingInvitations.length ? (
              <Typography variant="h6" mt={6} mb={1.5}>
                {t('access-list-user-access-pending.header')}:
              </Typography>
            ) : (
              <></>
            )}
            <List>
              {pendingInvitations.map((item, index) => (
                <Box key={item.id || index} position="relative">
                  <ListItem>
                    <InvitationCardComponent
                      {...item}
                      onClickResend={isPatientRoleAdmin ? onResendInvitation : undefined}
                      onClickDelete={isPatientRoleAdmin ? onDeleteInvitation : undefined}
                    />
                    <BackdropLoadingComponent
                      open={invitationsStore.isDeletingByPatientId(profileId, item.id)}
                      sx={{
                        position: 'absolute',
                      }}
                    />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </Box>
              ))}
            </List>
          </PatientNestedItemsInfiniteScrollComponent>
        ) : (
          <AccessesListLoadingComponent />
        )}
        {loadingInvitations === false && errorLoadingInvitations && (
          <>
            <Typography variant="h6" mt={6} mb={1.5}>
              {t('access-list-user-access-pending.header')}:
            </Typography>
            <FailedLoadingDataComponent onClick={reloadInvitations} />
          </>
        )}
      </PatientNestedItemsWrapperComponent>
      <ConfirmGiveAccessToDepartmentComponent
        isOpen={
          !!(
            giveAccessToDepartmentId &&
            !usersStore.loadingAllNursesByDepartmentId[giveAccessToDepartmentId] &&
            !accessesStore.loadingAllItemsByPatientAndDepartmentId[
              accessesStore.getKeyForPatientAndDepartment(profileId, giveAccessToDepartmentId)
            ]
          )
        }
        departmentName={departments.map(({name}) => name).join(', ')}
        onConfirm={confirmGiveAccessDepartment}
        onClose={closeGiveAccessDialog}
        nurses={nurses}
        nursesAccesses={nursesAccesses}
        onClickRemove={onClickRemoveNurse}
        onClickRemoveAll={onClickRemoveAllNurses}
        onClickSelect={onClickSelectNurse}
        onClickSelectAll={onClickSelectAllNurses}
      />
      <BackdropLoadingComponent open={loading} />
    </>
  );
}
export const AccessesListContainer = observer(AccessesList);
