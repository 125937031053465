import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, {memo} from 'react';
import {SortableElement} from 'react-sortable-hoc';

export enum ALBUM_FILE_SORTABLE_ANIMATION {
  ODD = 'odd',
  EVEN = 'even',
}

export interface SortableItemProps {
  animation?: ALBUM_FILE_SORTABLE_ANIMATION;
  children: React.ReactNode;
}

function SortableItem({animation, children}: SortableItemProps) {
  const sx = animation
    ? {
        animationDelay: `-.${('0' + (Math.random() * (75 - 5) + 5)).slice(-2)}s`,
        animationDuration: `.${Math.floor(Math.random() * (33 - 22) + 22)}s`,
        animationName: 'keyframeSortable',
        animationIterationCount: 'infinite',
        transformOrigin: '50% 10%',
        '@keyframes keyframeSortable': {
          '0%': {
            transform: animation === 'even' ? 'rotate(-1deg)' : 'rotate(1deg)',
            animationTimingFunction: 'ease-in',
          },

          '50%': {
            transform: animation === 'even' ? 'rotate(1.5deg)' : 'rotate(-1.5deg)',
            animationTimingFunction: 'ease-out',
          },
        },
      }
    : {};

  return (
    <Grid item xs={6} md={4} lg={3} xl={2}>
      <Box position={'relative'} sx={sx}>
        {children}
        <Backdrop
          open={!!animation}
          sx={theme => ({
            zIndex: theme.zIndex.drawer + 1,
            background: 'rgba(255,255,255,0.2)',
            position: 'absolute',
          })}
        />
      </Box>
    </Grid>
  );
}

export const SortableItemComponent = memo(SortableElement<SortableItemProps>(SortableItem));
