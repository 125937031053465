export function arrayMoveMutable(array: Array<unknown>, fromIndex: number, toIndex: number): void {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable<ValueType>(
  array: ReadonlyArray<ValueType>,
  fromIndex: number,
  toIndex: number
): ReadonlyArray<ValueType> {
  array = [...array];
  arrayMoveMutable(array as Array<unknown>, fromIndex, toIndex);
  return array;
}
