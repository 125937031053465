import orderBy from 'lodash/orderBy';

import {Unit} from '../interfaces/entities/unit.interface';

export function findUnitsByType(units: Unit[], targetType: string): Unit[] {
  const results: Unit[] = [];

  function recursiveSearch(currUnit: Unit) {
    if (currUnit.type === targetType && !results.some(({id}) => id === currUnit.id)) {
      results.push(currUnit);
    }

    if (!currUnit.parents) {
      return;
    }

    for (const parent of currUnit.parents) {
      recursiveSearch(parent);
    }
  }

  for (const unit of units) {
    recursiveSearch(unit);
  }

  return orderBy(results, ['name'], ['asc']);
}
