import {checkPhone} from './check-phone';

const formatterObj: Record<string, (value: string) => string> = {
  '+47': value => value.replace(/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/, '+$1$2 $3$4$5 $6$7 $8$9$10'),

  '+45': value => value.replace(/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/, '+$1$2 $3$4-$5$6-$7$8-$9$10'),

  '+46': value => value.replace(/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/, '+$1$2 $3$4-$5$6$7-$8$9$10$11'),

  '+380': (value: string) =>
    value.replace(/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/, '+$1$2 ($3$4$5) $6$7$8-$9$10-$11$12'),
};

export function formatPhone(phone: string): string {
  const {isValid, countryCode} = checkPhone(phone.startsWith('+') ? phone : `+${phone}`);

  if (!isValid || !countryCode) {
    return phone;
  }

  const formatter = formatterObj[countryCode];

  return formatter ? formatter(phone) : phone;
}
