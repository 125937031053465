import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import {ButtonComponent} from '../../button.component';
import {ImageComponent} from '../../image.component';

function PatientsOneLoading(): ReactElement {
  const {t} = useTranslation();

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" wrap={'nowrap'} spacing={3}>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <ButtonComponent variant="contained" disabled disableElevation>
              <EditIcon
                sx={{
                  paddingRight: '5px',
                }}
              />
              {t('edit')}
            </ButtonComponent>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              component={'div'}
              sx={{
                maxWidth: '100%',
              }}
            >
              <ImageComponent />
            </Skeleton>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <Typography variant={'h4'} paddingBottom={4}>
              <Skeleton
                sx={theme => ({
                  width: '100%',
                  [theme.breakpoints.up('sm')]: {
                    maxWidth: '350px',
                  },
                })}
              />
            </Typography>
            <table>
              <tbody>
                <tr>
                  <th
                    style={{
                      padding: '6px',
                      textAlign: 'left',
                      minWidth: '120px',
                    }}
                  >
                    <Typography variant={'body1'}>{t('birth-date.label')}:</Typography>
                  </th>
                  <td style={{padding: '6px', width: '90%'}}>
                    <Typography variant={'body1'}>
                      <Skeleton
                        sx={theme => ({
                          width: '100%',
                          [theme.breakpoints.up('sm')]: {
                            maxWidth: '200px',
                          },
                        })}
                      />
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <th
                    style={{
                      padding: '6px',
                      textAlign: 'left',
                      minWidth: '120px',
                    }}
                  >
                    <Typography variant={'body1'}>{t('persona-years-old.label')}:</Typography>
                  </th>
                  <td style={{padding: '6px'}}>
                    <Typography variant={'body1'}>
                      <Skeleton
                        sx={theme => ({
                          width: '100%',
                          [theme.breakpoints.up('sm')]: {
                            maxWidth: '200px',
                          },
                        })}
                      />
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <th
                    style={{
                      padding: '6px',
                      textAlign: 'left',
                      minWidth: '120px',
                    }}
                  >
                    <Typography variant={'body1'}>{t('birth-place.label')}:</Typography>
                  </th>
                  <td style={{padding: '6px', width: '90%'}}>
                    <Typography variant={'body1'}>
                      <Skeleton
                        sx={theme => ({
                          width: '100%',
                          [theme.breakpoints.up('sm')]: {
                            maxWidth: '200px',
                          },
                        })}
                      />
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <th
                    style={{
                      padding: '6px',
                      textAlign: 'left',
                      minWidth: '120px',
                    }}
                  >
                    <Typography variant={'body1'}>{t('personal.affiliation')}:</Typography>
                  </th>
                  <td style={{padding: '6px', width: '90%'}}>
                    <Typography variant={'body1'}>
                      <Skeleton
                        sx={theme => ({
                          width: '100%',
                          [theme.breakpoints.up('sm')]: {
                            maxWidth: '200px',
                          },
                        })}
                      />
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <table style={{width: '100%'}}>
          <tbody>
            <>
              <tr>
                <th
                  style={{
                    padding: '0px 10px 24px 0px',
                    textAlign: 'left',
                    minWidth: '120px',
                  }}
                >
                  <Typography variant={'h5'}>{`${t('persona-description.header')}:`}</Typography>
                </th>
              </tr>
              <tr>
                <td style={{padding: '0px 10px 24px 0px'}}>
                  <Typography variant={'body1'}>
                    <Skeleton />
                    <Skeleton sx={{marginTop: 1}} />
                    <Skeleton sx={{marginTop: 1}} />
                    <Skeleton sx={{marginTop: 1}} />
                  </Typography>
                </td>
              </tr>
            </>

            <>
              <tr>
                <th
                  style={{
                    padding: '0px 10px 24px 0px',
                    textAlign: 'left',
                    minWidth: '120px',
                  }}
                >
                  <Typography variant={'h5'}>{t('persona-interests.header')}:</Typography>
                </th>
              </tr>
              <tr>
                <td style={{padding: '0px 10px 24px 0px'}}>
                  <Typography variant={'body1'}>
                    <Skeleton sx={{marginTop: 1}} />
                    <Skeleton sx={{marginTop: 1}} />
                  </Typography>
                </td>
              </tr>
            </>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}

export const PatientsOneLoadingComponent = memo(PatientsOneLoading);
