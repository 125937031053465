import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';

import {GuestbookPostMessageLoadingComponent} from './guestbook-post-message-loading.component';

function GuestbookPostLoading(): ReactElement {
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <Grid item>
        <GuestbookPostMessageLoadingComponent />
      </Grid>

      <Grid item>
        <Typography variant={'body1'} margin={'0 auto'} width={'100%'} mb={0.5}>
          <Skeleton />
        </Typography>

        <Typography variant={'body1'} margin={'0 auto'} width={'75%'}>
          <Skeleton />
        </Typography>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
}

export const GuestbookPostLoadingComponent = memo(GuestbookPostLoading);
