import RotateRightIcon from '@mui/icons-material/RotateRight';
import Button, {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';

import {WHITE_COLOR} from '../../theme';

interface BackButtonProps extends ButtonProps {
  path?: string;
}

function RotateRightButton(props: BackButtonProps) {
  return (
    <Button
      variant="contained"
      sx={{
        p: 0,
        minWidth: 0,
        background: WHITE_COLOR,
        '&:hover': {
          background: WHITE_COLOR,
        },
      }}
      {...props}
    >
      <RotateRightIcon color="primary" />
    </Button>
  );
}

export const RotateRightButtonComponent = memo(RotateRightButton);
