import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {omit} from 'lodash';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {getYouTubeId} from '../utils/get-youtube-id';
import {CancelButtonComponent} from './buttons/cancel-button.component';

interface YoutubeDialogProps extends DialogProps {
  onSave: (value: string) => void;
}

function YoutubeDialog(props: YoutubeDialogProps) {
  const {t} = useTranslation();

  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);
  const [helperText, setHelperText] = useState(undefined);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      if (touched && !getYouTubeId(event.target.value)) {
        setHelperText(t('stories-edit-add-youtube-link.error'));
      }
    },
    [setValue, setHelperText, touched]
  );

  const onSubmit = useCallback(() => {
    if (!props.onSave || !value) {
      return;
    }

    const isYoutubeLink = !!getYouTubeId(value);

    if (isYoutubeLink) {
      return props.onSave(value);
    }

    setTouched(true);
    setHelperText(t('stories-edit-add-youtube-link.error'));
  }, [value, props.onSave, setTouched, setHelperText]);

  return (
    <Dialog fullWidth maxWidth={'sm'} aria-labelledby="youtube-video-form-title" {...omit(props, 'onSave')}>
      <DialogTitle id="youtube-video-form-title">{t('stories-edit-add-youtube-link.header')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('stories-edit-add-youtube-link.body')}</DialogContentText>
        <TextField
          defaultValue={value}
          onChange={onChange}
          autoFocus
          helperText={helperText}
          margin="dense"
          id="youtube-link"
          type="url"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <CancelButtonComponent onClick={props.onClose as () => void} />
        <Button variant="contained" disabled={!value} onClick={onSubmit}>
          {t('general-save.button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const YoutubeDialogComponent = memo(YoutubeDialog);
