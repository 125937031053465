import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DeleteDialogComponent, DeleteDialogProps} from '../dialog/delete-dialog.component';

export type AlbumDeleteDialog = Pick<DeleteDialogProps, 'open' | 'onCancel' | 'onConfirm'>;

function AlbumDeleteDialog(props: AlbumDeleteDialog) {
  const {t} = useTranslation();
  return (
    <DeleteDialogComponent
      {...props}
      title={t('album-delete-warning-modal.header')}
      contentText={t('album-delete-warning-modal.body')}
    />
  );
}

export const AlbumDeleteDialogComponent = memo(AlbumDeleteDialog);
