import React, {memo, useCallback, useMemo, useState} from 'react';
import Lightbox from 'react-image-lightbox';

import {GuestbookPreviewFile} from '../../interfaces/guestbook-preview-file.interface';
import {EMPTY_IMAGE} from '../../utils/constants';
import {getNextIndex} from '../../utils/get-next-index';
import {getPreviousIndex} from '../../utils/get-previous-index';
import {isVideoType} from '../../utils/is-video-type';
import {isVideoUrl} from '../../utils/is-video-url';

export interface GuestbookFilesLightboxProps {
  defaultIndex: number;
  files: ReadonlyArray<Partial<GuestbookPreviewFile>>;
  onClose: () => void;
}

function GuestbookFilesLightbox({defaultIndex, files, onClose}: GuestbookFilesLightboxProps) {
  const [index, setIndex] = useState(defaultIndex);

  const prevIndex = useMemo(() => {
    let previousIndex = getPreviousIndex(index, files.length);
    const {type, url} = files[previousIndex];
    let video = (type && isVideoType(type)) || (url && isVideoUrl(url));

    while (video) {
      previousIndex = getPreviousIndex(previousIndex, files.length);
      const file = files[previousIndex];
      video = (file.type && isVideoType(file.type)) || (file.url && isVideoUrl(file.url));
    }

    return previousIndex;
  }, [index, files.length]);

  const nextIndex = useMemo(() => {
    let nextIndex = getNextIndex(index, files.length);
    const {type, url} = files[nextIndex];
    let video = (type && isVideoType(type)) || (url && isVideoUrl(url));

    while (video) {
      nextIndex = getNextIndex(nextIndex, files.length);
      const file = files[nextIndex];
      video = (file.type && isVideoType(file.type)) || (file.url && isVideoUrl(file.url));
    }

    return nextIndex;
  }, [index, files.length]);

  const onMovePrevRequest = useCallback(() => {
    setIndex(prevIndex);
  }, [prevIndex, setIndex]);

  const onMoveNextRequest = useCallback(() => {
    setIndex(nextIndex);
  }, [nextIndex, setIndex]);

  return (
    <Lightbox
      reactModalStyle={{overlay: {zIndex: 9999}}}
      prevSrc={files[prevIndex].url}
      mainSrc={files[index].url || EMPTY_IMAGE}
      nextSrc={files[nextIndex].url}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
      onCloseRequest={onClose}
    />
  );
}

export const GuestbookFilesLightboxComponent = memo(GuestbookFilesLightbox);
