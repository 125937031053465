import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {BackButtonComponent} from '../../components/buttons/back-button.component';
import {ConfirmLeaveDialogComponent} from '../../components/dialog/confirm-leave-dialog.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {RelativeOneEditLoadingComponent} from '../../components/loading/relatives/relative-one-edit-loading.component';
import {PhotoEditorComponent} from '../../components/photo-editor.component';
import {PROFILE_ROUTE_RELATIVES} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {RelativeForm} from '../../forms/relative.form';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useRelative} from '../../hooks/relatives/use-relative';
import {useFormActions} from '../../hooks/use-form-actions';
import {usePhotoEditor} from '../../hooks/use-photo-editor';
import {Relative} from '../../interfaces/entities/relative.interface';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';

function RelativesOneEdit({profileId}: ProfilePageProps) {
  const {t} = useTranslation();

  const {item, fetchLoading, update, updateWithPhoto, updateLoading} = useRelative(profileId);
  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const [blockLeave, setBlockLeave] = useState<boolean>(true);

  const {props: photoEditorProps, isChanged: photoIsChanged, getPhoto} = usePhotoEditor(item?.image);

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_RELATIVES.path}/${item?.id || ''}`;

  const {onCancel, onConfirm} = useFormActions(backPath);

  const onSave = useCallback(
    async (data: Partial<Relative>) => {
      if (!photoIsChanged) {
        await update(data);
      } else {
        const image = await getPhoto();
        if (!image) {
          await update({...data, image: null});
        } else {
          await updateWithPhoto(data, image as File);
        }
      }
      setBlockLeave(false);
      setTimeout(() => {
        onConfirm();
        setBlockLeave(true);
      }, 500);
    },
    [update, updateWithPhoto, photoIsChanged, getPhoto, onConfirm]
  );

  if (fetchLoading) {
    return <RelativeOneEditLoadingComponent />;
  }

  if (!item || !isPatientRoleAdmin) {
    return <></>;
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <BackButtonComponent path={backPath} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'}>{t('relative-edit-profile-picture.header')}</Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <PhotoEditorComponent {...photoEditorProps} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'} pt={1}>
            {t('relative-edit-bio.header')}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={10}>
          <RelativeForm {...item} onSave={onSave} onCancel={onCancel} resetFormAfterCancel={false} />
        </Grid>
      </Grid>
      <BackdropLoadingComponent open={!!updateLoading} />
      <ConfirmLeaveDialogComponent
        blockLeave={blockLeave}
        description={t('modal-leave-relative-warning.body')}
        stayButtonText={t('modal-leave-relative-warning-stay.button')}
        leaveButtonText={t('modal-leave-relative-warning-leave.button')}
      />
    </>
  );
}

export const RelativesOneEditContainer = observer(RelativesOneEdit);
