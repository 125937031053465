import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {BackAndManageButtonsComponent} from '../../components/buttons/back-and-manage-buttons.component';
import {StoriesOneLoadingComponent} from '../../components/loading/stories/stories-one-loading.component';
import {StoryDeleteDialogComponent} from '../../components/stories/story-delete-dialog.component';
import {PROFILE_ROUTE_STORIES} from '../../configs/profile-routes';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useStory} from '../../hooks/stories/use-story';
import {useDeleteDialog} from '../../hooks/use-delete-dialog';
import {useEditButton} from '../../hooks/use-edit-button';
import {useGoToOrBack} from '../../hooks/use-go-to-or-back';
import {ProfilePageProps} from '../../interfaces/profile-page-props.interface';
import {BLACK_COLOR} from '../../theme';
import getDate from '../../utils/get-date';
import {getDateTime} from '../../utils/get-date-time';
import {parseStoryBody} from '../../utils/parse-story-body';

function StoriesOne({profileId}: ProfilePageProps) {
  const {isPatientRoleAdmin} = usePatientRole(profileId);
  const {onClick: onClickEdit} = useEditButton();
  const {fetchLoading, item, remove} = useStory(profileId);
  const {onClick: onClickDelete, onClose: onCloseDeleteDialog, open: openDeleteDialog} = useDeleteDialog();

  const {t} = useTranslation();

  const backPath = `${MY_PATIENTS_ROUTE.path}/${profileId}/${PROFILE_ROUTE_STORIES.path}`;

  const {go: goToList} = useGoToOrBack(backPath);

  const onConfirmDelete = useCallback(async () => {
    await remove();
    goToList();
  }, [remove, goToList]);

  if (fetchLoading || !item) {
    return <StoriesOneLoadingComponent />;
  }

  const {name, date, updatedAt, body} = item;

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        spacing={2}
        sx={{
          paddingBottom: '5px',
        }}
      >
        <Grid item>
          <BackAndManageButtonsComponent
            onClickEdit={isPatientRoleAdmin ? onClickEdit : undefined}
            onClickDelete={isPatientRoleAdmin ? onClickDelete : undefined}
            backPath={backPath}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            sx={{paddingBottom: '20px'}}
          >
            <Grid item xs={12} sm={9}>
              <Typography variant={'h5'}>{name}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              {updatedAt && (
                <Typography
                  fontStyle={'italic'}
                  fontWeight={'normal'}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  letterSpacing={'0.25px'}
                  color={BLACK_COLOR}
                  textAlign={'end'}
                >
                  {`${t('story-view-changed-date.label')}: ${getDateTime(updatedAt)}`}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!!date && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant={'body2'}>{`${t('story-view-when-it-happened.label')}: ${getDate(date)}`}</Typography>
          </Grid>
        )}
        {!!body && (
          <Grid item>
            <Box
              sx={{
                '& img': {
                  verticalAlign: 'baseline',
                },
              }}
              dangerouslySetInnerHTML={{__html: parseStoryBody(body)}}
            />
          </Grid>
        )}
      </Grid>
      <StoryDeleteDialogComponent open={openDeleteDialog} onCancel={onCloseDeleteDialog} onConfirm={onConfirmDelete} />
    </>
  );
}

export const StoriesOneContainer = observer(StoriesOne);
