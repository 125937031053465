import {Unit} from '../interfaces/entities/unit.interface';
import {PATIENT_UNIT_TYPE} from '../stores/units.store';

export function formatUnitToIntercom(unit: Unit): string {
  if (unit.type === PATIENT_UNIT_TYPE) {
    return `Patient, ID: ${unit.vilmerId}`;
  }
  return `Unit: ${unit.name}, ID: ${unit.vilmerId}`;
}

export function formatUnitWithParentsToIntercom(unit: Unit): string {
  const unitStr = `${formatUnitToIntercom(unit)}`;

  const parentsStr = (unit.parents || []).map(parent => formatUnitToIntercom(parent)).join(', ');

  return parentsStr ? `${unitStr}, Parents: ${parentsStr}` : unitStr;
}

export function formatUnitsToIntercom(units: Unit[]): string {
  if (units.length === 1) {
    return formatUnitWithParentsToIntercom(units[0]);
  }

  return units.reduce((currentStr, unit, index) => {
    let str = `${currentStr}`;

    str += `<${formatUnitWithParentsToIntercom(unit)}>`;

    if (index < units.length - 1) {
      str += ' | ';
    } else {
      str += '.';
    }

    return str;
  }, '');
}
