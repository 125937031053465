import {AxiosInstance} from 'axios';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {Patient} from '../../interfaces/entities/patient.interface';
import {ListResponse} from '../../interfaces/list-response.interface';
import {getRandomId} from '../../utils/get-random-id';
import {BaseStore} from './base.store';

/**
 * Abstract class for patient nested entities
 */
export abstract class PatientBaseStore<T extends {id: string}> extends BaseStore<T> {
  /**
   * List of items by patientIdId
   */
  itemsByPatientId: Record<Patient['id'], ReadonlyArray<T>> = {};

  /**
   * Current item
   */
  itemByPatientId: Record<Patient['id'], T | null> = {};

  /**
   * Flag of loading list by patientIdId
   */
  loadingByPatientId: Record<Patient['id'], boolean> = {};

  /**
   * Flag of loading list by patientIdId
   */
  loadingOneByPatientId: Record<Patient['id'], boolean> = {};

  /**
   * Number of total pages for list by patientIdId
   */
  totalPagesByPatientId: Record<Patient['id'], number> = {};
  /**
   * Number of current page for list by patientIdId
   */
  currentPageByPatientId: Record<Patient['id'], number> = {};

  /**
   * Error during loading by patientId
   */
  errorLoadingByPatientId: Record<Patient['id'], Error | undefined> = {};

  constructor(
    protected readonly api: AxiosInstance,
    protected entitiesName: string
  ) {
    super(api, entitiesName);
    makeObservable(this, {
      listByPatientId: action,
      loadMoreByPatientId: action,
      createByPatientId: action,
      updateByPatientId: action,
      deleteByPatientId: action,
      itemsByPatientId: observable,
      itemByPatientId: observable,
      loadingByPatientId: observable,
      loadingOneByPatientId: observable,
      totalPagesByPatientId: observable,
      currentPageByPatientId: observable,
      errorLoadingByPatientId: observable,
    });
  }

  /**
   * Async function to fetch list of patient nested items by patient id
   * @param patientId string
   * @param page number
   * @param limit number. Default 50
   */
  async listByPatientId(patientId: Patient['id'], page = 1, limit = 50, end?: string, force?: boolean): Promise<void> {
    const currentPage = this.currentPageByPatientId[patientId] || 0;
    if (!force && currentPage >= page) {
      return;
    }

    runInAction(() => {
      this.loadingByPatientId[patientId] = true;
      this.errorLoadingByPatientId[patientId] = undefined;
    });

    try {
      const {data} = await this.api.get<ListResponse<T>>(this.getUrlByPatientId(patientId, end), {
        params: {
          page,
          limit,
        },
      });
      runInAction(() => {
        this.itemsByPatientId[patientId] = data.items;
        this.totalPagesByPatientId[patientId] = data.meta.totalPages || 0;
        this.currentPageByPatientId[patientId] = data.meta.currentPage;
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.errorLoadingByPatientId[patientId] = err as Error;
      });
    }
    runInAction(() => {
      this.loadingByPatientId[patientId] = false;
    });
  }

  /**
   * Async function to fetch list of patient nested items by patient id
   * @param patientId string
   * @param limit number. Default 50
   */
  async loadMoreByPatientId(patientId: Patient['id'], limit = 50, end?: string): Promise<void> {
    try {
      const {data} = await this.api.get<ListResponse<T>>(this.getUrlByPatientId(patientId, end), {
        params: {
          page: (this.currentPageByPatientId[patientId] || 0) + 1,
          limit,
        },
      });
      runInAction(() => {
        this.itemsByPatientId[patientId] = [...(this.itemsByPatientId[patientId] || []), ...data.items];
        this.totalPagesByPatientId[patientId] = data.meta.totalPages || 0;
        this.currentPageByPatientId[patientId] = data.meta.currentPage;
      });
    } catch (err) {
      console.error(err);
    }
  }

  getHasMoreByPatientId(patientId: Patient['id']) {
    const currentPage = this.currentPageByPatientId[patientId] || 0;
    const totalPages = this.totalPagesByPatientId[patientId] || 0;
    return !currentPage || currentPage < totalPages;
  }

  /**
   * Async function to get one item  by patient id and item id
   * @param patientId string
   * @param id string. id of entity
   * @param force boolean. Flag to force fetch item from REST API, default false
   * @returns
   */
  async oneByPatientId(patientId: Patient['id'], id: T['id'], force = false): Promise<void | T> {
    if (!id) {
      return;
    }

    const item = this.itemByPatientId[patientId];
    if (!force && item?.id === id) {
      return item;
    }

    const foundItem = (this.itemsByPatientId[patientId] || []).filter(item => item.id === id)[0];

    if (!force && foundItem) {
      return runInAction(() => {
        this.itemByPatientId[patientId] = foundItem;
        this.loadingOneByPatientId[patientId] = false;
      });
    }

    runInAction(() => {
      this.loadingOneByPatientId[patientId] = true;
    });

    try {
      const {data} = await this.api.get<T>(`${this.getUrlByPatientId(patientId)}/${id}`);
      runInAction(() => {
        this.itemByPatientId[patientId] = data;
      });
    } catch (err) {
      console.error(err);
    }

    runInAction(() => {
      this.loadingOneByPatientId[patientId] = false;
    });
  }

  /**
   * Async function to create patient nested item by patient id
   * @param patientId string
   * @param data Partial<T>
   */
  async createByPatientId(patientId: Patient['id'], data: Partial<T>): Promise<T | null> {
    const temporaryItem: T = {...data, id: getRandomId()} as T;

    runInAction(() => {
      this.itemByPatientId[patientId] = temporaryItem;
      this.itemsByPatientId[patientId] = [temporaryItem, ...(this.itemsByPatientId[patientId] || [])];
    });

    try {
      const {data: createdItem} = await this.api.post(this.getUrlByPatientId(patientId), data);
      runInAction(() => {
        this.itemByPatientId[patientId] = createdItem;
        this.itemsByPatientId[patientId] = this.itemsByPatientId[patientId].reduce((arr: ReadonlyArray<T>, item: T) => {
          if (item.id === temporaryItem.id) {
            return [...arr, {...item, ...createdItem}];
          }
          return [...arr, item];
        }, []);
      });
    } catch (err) {
      console.error(err);
    }

    return this.itemByPatientId[patientId];
  }

  /**
   * Async function to update patient nested item by patient id, item id
   * @param id string. item id
   * @param patientId string
   * @param data Partial<T>
   */
  async updateByPatientId(patientId: Patient['id'], id: T['id'], data: Partial<T>): Promise<T | null> {
    const temporaryItem: T = {...data, id} as T;

    runInAction(() => {
      this.itemByPatientId[patientId] = temporaryItem;
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).reduce(
        (arr: ReadonlyArray<T>, item: T) => {
          if (item.id === temporaryItem.id) {
            return [...arr, {...item, ...temporaryItem}];
          }
          return [...arr, item];
        },
        []
      );
    });

    try {
      const {data: updatedItem} = await this.api.put(`${this.getUrlByPatientId(patientId)}/${id}`, data);
      runInAction(() => {
        this.itemByPatientId[patientId] = updatedItem;
        this.itemsByPatientId[patientId] = this.itemsByPatientId[patientId].reduce((arr: ReadonlyArray<T>, item: T) => {
          if (item.id === temporaryItem.id) {
            return [...arr, {...item, ...updatedItem}];
          }
          return [...arr, item];
        }, []);
      });
    } catch (err) {
      console.error(err);
    }
    return this.itemByPatientId[patientId];
  }

  /**
   * Async function to delete patient nested item by patient id, item id
   * @param id string. item id
   * @param patientId string
   */
  async deleteByPatientId(patientId: Patient['id'], id: T['id']): Promise<void> {
    runInAction(() => {
      this.itemByPatientId[patientId] = null;
      this.itemsByPatientId[patientId] = (this.itemsByPatientId[patientId] || []).filter(item => item.id !== id);
    });

    try {
      await this.api.delete(`${this.getUrlByPatientId(patientId)}/${id}`);
    } catch (err) {
      console.error(err);
    }
  }

  protected getUrlByPatientId(patientId: string, end?: string): string {
    const patientsUrl = `patients/${patientId}/${this.entitiesName}`;
    return !end ? patientsUrl : `${patientsUrl}${end}`;
  }

  protected getPrefix(patientId: Patient['id']): string {
    return `patients/${patientId}`;
  }
}
