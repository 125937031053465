import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Button, {ButtonProps} from '@mui/material/Button';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {DEEP_SPACE_SPARKLE_COLOR} from '../../theme';

function CloseButton(props: ButtonProps) {
  const {t} = useTranslation();

  return (
    <Button
      variant="outlined"
      sx={{
        color: DEEP_SPACE_SPARKLE_COLOR,
      }}
      color="secondary"
      startIcon={<CancelOutlinedIcon />}
      {...props}
    >
      {t('close.button')}
    </Button>
  );
}

export const CloseButtonComponent = memo(CloseButton);
