import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import {FormikProps} from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {DraftPatientExistsComponent} from '../../components/draft-patients/draft-patient-exists.component';
import {BackdropLoadingComponent} from '../../components/loading/backdrop-loading.component';
import {PatientsNewLoadingComponent} from '../../components/loading/patients/patients-new-loading.component';
import {LoginComponent} from '../../components/login.component';
import {PatientCreatedDepartmentAccessComponent} from '../../components/patients/patient-created-department-access.component';
import {PatientCreatedSuccessInvitedDepartmentComponent} from '../../components/patients/patient-created-success-invited-department.component';
import {PatientFormWrapperComponent} from '../../components/patients/patient-form-wrapper.component';
import {MultiStepsForm} from '../../forms/multi-steps.form';
import {PatientBirthDateForm} from '../../forms/patient/patient-birth-date.form';
import {PatientBirthPlaceForm} from '../../forms/patient/patient-birth-place.form';
import {PatientDepartmentForm} from '../../forms/patient/patient-department.form';
import {PatientDescriptionForm} from '../../forms/patient/patient-description.form';
import {PatientHabitsForm} from '../../forms/patient/patient-habits.form';
import {PatientInterestsForm} from '../../forms/patient/patient-interests.form';
import {PatientMunicipalityInstitutionForm} from '../../forms/patient/patient-municipality-institution.form';
import {PatientMusicForm} from '../../forms/patient/patient-music.form';
import {PatientNameForm} from '../../forms/patient/patient-name.form';
import {PatientPhotoForm} from '../../forms/patient/patient-photo.form';
import {PatientRelaxForm} from '../../forms/patient/patient-relax.form';
import {PatientReviewForm} from '../../forms/patient/patient-review.form';
import {useDraftPatients} from '../../hooks/draft-patients/use-draft-patients';
import {useAllUnits} from '../../hooks/units/use-all-units';
import {useStores} from '../../hooks/use-stores';
import {Access, ACCESS_ROLES} from '../../interfaces/entities/access.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {Unit} from '../../interfaces/entities/unit.interface';
import {User} from '../../interfaces/user.interface';
import {DEPARTMENT_UNIT_TYPE, INSTITUTION_UNIT_TYPE, MUNICIPALITY_UNIT_TYPE} from '../../stores/units.store';
import {areDraftPatientsEqual} from '../../utils/are-draft-patients-equal';
import {findDepartmentInstitutionMunicipality} from '../../utils/find-department-institution-municipality';
import {findUnitOptionsByParentId} from '../../utils/find-unit-options-by-parent-id';
import {findUnitsByType} from '../../utils/find-units-in-parents';
import {isDataObjectEmpty} from '../../utils/is-data-obj-empty';

const DRAFT_PATIENT_INTERVAL = 3000;

const STEPS: {
  title?: string;
  form: React.FC<
    Patient & {
      ref?: React.Ref<FormikProps<Patient>>;
      onChange: (value: Partial<Patient>) => void;
      upload: (file: File) => Promise<string>;
    }
  >;
}[] = [
  {title: 'profile-name-form.title', form: PatientNameForm},
  {title: 'profile-birth-date-form.title', form: PatientBirthDateForm},
  {title: 'profile-photo-form.title', form: PatientPhotoForm},
  {title: 'profile-birth-place-form.title', form: PatientBirthPlaceForm},
  {title: 'profile-about-form.title', form: PatientDescriptionForm},
  {title: 'profile-interests-form.title', form: PatientInterestsForm},
  {title: 'profile-habits-form.title', form: PatientHabitsForm},
  {title: 'profile-music-form.title', form: PatientMusicForm},
  {title: 'profile-relax-form.title', form: PatientRelaxForm},
];

function PatientsNew() {
  const {authStore, patientsStore, usersStore, accessesStore} = useStores();

  const {t} = useTranslation();

  const {items: units = [], loading: loadingUnits} = useAllUnits();

  const {
    items: [draftPatient] = [],
    loadingItems: loadingDraftItems,
    delete: deleteDraftPatients,
    loadingDelete: loadingDeleteDraftItem,
    create: createDraftPatients,
    update: updateDraftPatients,
    uploadPhoto: uploadPhotoForDraftPatient,
  } = useDraftPatients();

  const refSteps = useRef<Record<number, FormikProps<Patient>>>({});

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [departmentId, setDepartmentId] = useState<Unit['id'] | null>(null);
  const [institutionId, setInstitutionId] = useState<Unit['id'] | null>(null);
  const [municipalityId, setMunicipalityId] = useState<Unit['id'] | null>(null);

  const [wasDepartmentChanged, setWasDepartmentChanged] = useState(false);
  const [wasInstitutionChanged, setWasInstitutionChanged] = useState(false);
  const [wasMunicipalityChanged, setWasMunicipalityChanged] = useState(false);
  const [nursesToCreateAccess, setNursesToCreateAccess] = useState<Record<User['id'], Access['role']>>({});
  const [data, setData] = useState<Patient>({
    firstName: '',
    lastName: '',
    birthDate: null,
  } as Patient);
  const [createdPatient, setCreatedPatient] = useState<Patient | null>(null);
  const [continueDraftPatient, setContinueDraftPatient] = useState(false);
  const [showSuccessInvitedDepartment, setShowSuccessInvitedDepartment] = useState(false);

  const nurses = useMemo(() => {
    return departmentId ? usersStore.allNursesByDepartmentId[departmentId] : [];
  }, [departmentId, usersStore.allNursesByDepartmentId[departmentId || '']]);

  useEffect(() => {
    if (loadingUnits === false && units) {
      const result = findDepartmentInstitutionMunicipality(units);

      if (!municipalityId && result.municipalityId) {
        setMunicipalityId(result.municipalityId);
      }
      if (!institutionId && result.institutionId) {
        setInstitutionId(result.institutionId);
      }
      if (!departmentId && result.departmentId) {
        setDepartmentId(result.departmentId);
      }
    }
  }, [loadingUnits, units]);

  useEffect(() => {
    const syncDraftPatient = async () => {
      if (
        !createdPatient &&
        !draftPatient &&
        (!isDataObjectEmpty(omit(refSteps?.current?.[activeStep]?.values || {}, ['upload'])) ||
          (municipalityId && wasMunicipalityChanged) ||
          (institutionId && wasInstitutionChanged) ||
          (departmentId && wasDepartmentChanged))
      ) {
        setContinueDraftPatient(true);
        return createDraftPatients({
          ...refSteps?.current?.[activeStep]?.values,
          municipalityId,
          institutionId,
          departmentId,
          step: activeStep,
        });
      }

      if (
        draftPatient &&
        refSteps?.current?.[activeStep]?.values &&
        isDataObjectEmpty(omit(refSteps?.current?.[activeStep]?.values, ['upload'])) &&
        !municipalityId &&
        !institutionId &&
        !departmentId &&
        activeStep === 0
      ) {
        return deleteDraftPatients(draftPatient.id);
      }

      const actualMunicipalityId =
        (refSteps?.current?.[activeStep]?.values as {municipalityId?: string})?.municipalityId || municipalityId;

      const actualInstitutionId =
        (refSteps?.current?.[activeStep]?.values as {institutionId?: string})?.institutionId || institutionId;

      const actualDepartmentId =
        (
          refSteps?.current?.[activeStep]?.values as {
            departmentId?: string;
          }
        )?.departmentId || departmentId;

      const actualDraftPatient = {
        ...data,
        ...refSteps?.current?.[activeStep]?.values,
        municipalityId: actualMunicipalityId || null,
        institutionId: actualInstitutionId || null,
        departmentId: actualDepartmentId || null,
        step: activeStep,
      };

      if (
        draftPatient &&
        refSteps?.current?.[activeStep]?.values &&
        !areDraftPatientsEqual(draftPatient, actualDraftPatient)
      ) {
        await updateDraftPatients(draftPatient.id, actualDraftPatient);
      }
    };

    syncDraftPatient();

    const intervalId = setInterval(syncDraftPatient, DRAFT_PATIENT_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    refSteps?.current?.[activeStep]?.values,
    draftPatient,
    municipalityId,
    institutionId,
    departmentId,
    activeStep,
    data,
    createdPatient,
    wasDepartmentChanged,
    wasInstitutionChanged,
    wasMunicipalityChanged,
  ]);

  useEffect(() => {
    if (departmentId && createdPatient) {
      usersStore.fetchAllNursesFromDepartment(departmentId);
    }
  }, [departmentId, createdPatient]);

  useEffect(() => {
    if (nurses) {
      setNursesToCreateAccess(
        nurses.reduce((obj: Record<User['id'], Access['role']>, {id}) => {
          obj[id] = ACCESS_ROLES.ADMIN;
          return obj;
        }, {})
      );
    }
  }, [nurses]);

  const onChange = useCallback(
    (value: Partial<Patient>) => {
      return setData({...data, ...value});
    },
    [data]
  );

  const onUpload = useCallback(
    async (file: File) => {
      if (!draftPatient) {
        return '';
      }
      return uploadPhotoForDraftPatient(draftPatient.id, file);
    },
    [uploadPhotoForDraftPatient, draftPatient]
  );

  const onChangeDepartment = useCallback(
    ({departmentId}: {departmentId: Unit['id'] | null}) => {
      setWasDepartmentChanged(true);
      return setDepartmentId(departmentId || null);
    },
    [data]
  );

  const onClickBack = useCallback(() => {
    const ref = refSteps.current?.[activeStep];

    if (ref) {
      onChange(ref.values);
    }
    setActiveStep(activeStep - 1);
  }, [activeStep, refSteps, onChange]);

  const onClickNext = useCallback(async () => {
    const ref = refSteps.current?.[activeStep];

    if (ref) {
      await ref.submitForm();
      const errors = await ref.validateForm();
      if (isEmpty(errors)) {
        setActiveStep(activeStep + 1);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, refSteps]);

  const createPatient = useCallback(async () => {
    setLoading(true);
    const result = await patientsStore.create(departmentId ? {...data, departmentId} : data);
    if (result && data.image) {
      await patientsStore.updateWithPhotoUrl(result.id, data, data.image);
    }

    setLoading(false);
    if (!result) {
      setError(true);
    } else {
      setCreatedPatient(result);
    }
    if (result && draftPatient) {
      await deleteDraftPatients(draftPatient.id);
    }
  }, [data, departmentId, patientsStore.create, draftPatient, deleteDraftPatients]);

  const onClickFinish = useCallback(async () => {
    const ref = refSteps.current?.[activeStep];

    if (ref) {
      await ref.submitForm();
      const errors = await ref.validateForm();
      if (isEmpty(errors)) {
        return createPatient();
      }
    } else {
      return createPatient();
    }
  }, [refSteps, activeStep, createPatient]);

  const handleClose = useCallback((_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  }, []);

  const onContinueDraftPatient = useCallback(() => {
    if (draftPatient) {
      setData({
        ...omit(draftPatient, ['step', 'institutionId', 'municipalityId', 'departmentId']),
        birthDate: draftPatient.birthDate && new Date(draftPatient.birthDate),
      } as Patient);
      setActiveStep(draftPatient.step);
      setDepartmentId(draftPatient.departmentId || null);
      setWasDepartmentChanged(true);
      setInstitutionId(draftPatient.institutionId || null);
      setWasInstitutionChanged(true);
      setMunicipalityId(draftPatient.municipalityId || null);
      setWasMunicipalityChanged(true);
      setContinueDraftPatient(true);
    }
  }, [draftPatient]);

  const onStartNewDraftPatient = useCallback(() => {
    if (draftPatient) {
      deleteDraftPatients(draftPatient.id);
    }
  }, [draftPatient, deleteDraftPatients]);

  const onClickGiveAccess = useCallback(async () => {
    if (!createdPatient || !departmentId) {
      return;
    }
    setLoading(true);
    await accessesStore.createManyAccessesByPatientAndDepartmentId(
      createdPatient.id,
      departmentId,
      Object.entries(nursesToCreateAccess).map(([userId, role]) => ({
        userId,
        role,
      }))
    );
    setLoading(false);
    setShowSuccessInvitedDepartment(true);
  }, [createdPatient, accessesStore.createManyAccessesByPatientAndDepartmentId, nursesToCreateAccess, departmentId]);

  const onClickSelectAllNurses = useCallback(
    (role: Access['role']) => {
      setNursesToCreateAccess(
        nurses.reduce((obj: Record<User['id'], Access['role']>, {id}) => {
          obj[id] = role;
          return obj;
        }, {})
      );
    },
    [nurses]
  );

  const onClickSelectNurse = useCallback(
    (id: User['id'], role: Access['role']) => {
      setNursesToCreateAccess({...nursesToCreateAccess, [id]: role});
    },
    [nursesToCreateAccess]
  );

  const onClickRemoveAllNurses = useCallback(() => {
    setNursesToCreateAccess({});
  }, []);

  const onClickRemoveNurse = useCallback(
    (id: User['id']) => {
      setNursesToCreateAccess(omit(nursesToCreateAccess, id));
    },
    [nursesToCreateAccess]
  );

  const municipalities = useMemo(() => {
    if (loadingUnits !== false || !units?.length) {
      return [];
    }

    return findUnitsByType(units as Unit[], MUNICIPALITY_UNIT_TYPE);
  }, [units, loadingUnits]);

  const institutions = useMemo(() => {
    if (loadingUnits !== false || !units?.length) {
      return [];
    }

    return findUnitsByType(units as Unit[], INSTITUTION_UNIT_TYPE);
  }, [units, loadingUnits]);

  const allDepartments = useMemo(() => {
    if (loadingUnits !== false || !units?.length) {
      return [];
    }

    return findUnitsByType(units as Unit[], DEPARTMENT_UNIT_TYPE);
  }, [units, loadingUnits]);

  const departments = useMemo(() => {
    if (!institutionId) {
      return allDepartments;
    }

    return allDepartments.filter(({parents}) => parents && parents.some(({id}) => id === institutionId));
  }, [allDepartments, institutionId]);

  const onChangeMunicipalityInstitution = useCallback(
    ({municipalityId, institutionId}: {municipalityId: Unit['id'] | null; institutionId: Unit['id'] | null}) => {
      setWasMunicipalityChanged(true);
      setMunicipalityId(municipalityId || null);
      setWasInstitutionChanged(true);
      setInstitutionId(institutionId || null);
      const departments = findUnitOptionsByParentId(allDepartments, institutionId || undefined);

      setDepartmentId(departments.length === 1 ? departments[0].id : null);
    },
    [data, allDepartments]
  );

  const steps = useMemo(() => {
    return [
      ...STEPS.map((step, index) => (
        <PatientFormWrapperComponent key={index} title={step.title && t(step.title, data)}>
          <step.form
            {...data}
            onChange={onChange}
            upload={onUpload}
            ref={ref => {
              if (refSteps.current && ref) {
                refSteps.current[index] = ref;
              }
            }}
          />
        </PatientFormWrapperComponent>
      )),
      <PatientFormWrapperComponent
        key={'profile-municipality-institution-form'}
        title={t('profile-municipality-institution-form.title')}
      >
        <PatientMunicipalityInstitutionForm
          municipalityId={municipalityId}
          institutionId={institutionId}
          municipalities={municipalities}
          institutions={institutions}
          onChange={onChangeMunicipalityInstitution}
          ref={ref => {
            if (refSteps.current && ref) {
              refSteps.current[STEPS.length] = ref;
            }
          }}
        />
      </PatientFormWrapperComponent>,

      <PatientFormWrapperComponent key={'profile-department-form'} title={t('profile-department-form.title')}>
        <PatientDepartmentForm
          departmentId={departmentId}
          items={departments}
          onChange={onChangeDepartment}
          ref={ref => {
            if (refSteps.current && ref) {
              refSteps.current[STEPS.length + 1] = ref;
            }
          }}
        />
      </PatientFormWrapperComponent>,
      <PatientReviewForm
        key={'profile-review-form'}
        {...data}
        municipalityId={municipalityId}
        institutionId={institutionId}
        departmentId={departmentId}
        onChange={onChange}
        municipalities={municipalities}
        institutions={institutions}
        departments={departments}
        ref={ref => {
          if (refSteps.current && ref) {
            refSteps.current[STEPS.length + 2] = ref;
          }
        }}
      />,
    ];
  }, [
    refSteps,
    onChange,
    data,
    t,
    municipalities,
    institutions,
    onChangeMunicipalityInstitution,
    onChangeDepartment,
    municipalityId,
    institutionId,
    departmentId,
  ]);

  if (authStore.loading || loadingDraftItems || loadingDeleteDraftItem || loadingUnits) {
    return <PatientsNewLoadingComponent />;
  }

  if (!authStore.me) {
    return <LoginComponent />;
  }

  if (!continueDraftPatient && draftPatient) {
    return <DraftPatientExistsComponent onContinue={onContinueDraftPatient} onReset={onStartNewDraftPatient} />;
  }

  return (
    <Box
      sx={theme => ({
        maxWidth: theme.breakpoints.values.md,
        margin: '0 auto',
      })}
    >
      {!createdPatient ? (
        <MultiStepsForm
          steps={steps}
          activeStep={activeStep}
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          finishButtonText={t('create-profile.button')}
          onClickFinish={onClickFinish}
        />
      ) : (
        <>
          {!showSuccessInvitedDepartment && (
            <PatientCreatedDepartmentAccessComponent
              departmentName={allDepartments?.find(({id}) => id === departmentId)?.name || ''}
              nursesAccesses={nursesToCreateAccess}
              onClickGiveAccess={onClickGiveAccess}
              nurses={nurses}
              onClickSelectAll={onClickSelectAllNurses}
              onClickSelect={onClickSelectNurse}
              onClickRemoveAll={onClickRemoveAllNurses}
              onClickRemove={onClickRemoveNurse}
              loadingNurses={departmentId ? usersStore.loadingAllNursesByDepartmentId[departmentId] : undefined}
              {...createdPatient}
            />
          )}
          {showSuccessInvitedDepartment && (
            <PatientCreatedSuccessInvitedDepartmentComponent
              departmentName={allDepartments?.find(({id}) => id === departmentId)?.name || ''}
              {...createdPatient}
            />
          )}
        </>
      )}
      <BackdropLoadingComponent open={loading} />
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
          {t('create-profile.error')}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export const PatientsNewContainer = observer(PatientsNew);
