const DEFAULT_PARENTS_UNIT_DEPTH = 5;

export function getUnitParentsJoin(prefix?: string, depth = DEFAULT_PARENTS_UNIT_DEPTH): string[] {
  const join: string[] = [];
  for (let i = 0; i < depth; i++) {
    let joinItem = prefix ? `${prefix}.parents` : 'parents';
    for (let j = 0; j < i; j++) {
      joinItem += '.parents';
    }

    join.push(joinItem);
  }

  return join;
}
