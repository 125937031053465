import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import {FieldProps} from 'formik';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {AssistiveTextComponent} from '../../shared/assistive-text.component';

export interface FieldWrapperProps extends FieldProps {
  children: React.ReactNode;
  assistiveText?: string;
  assistiveTextPosition?: 'top' | 'bottom';
}

function FieldWrapper({meta, field, assistiveText, children, assistiveTextPosition = 'bottom'}: FieldWrapperProps) {
  const {t} = useTranslation();

  const isError = useMemo(() => {
    return meta.touched && !!meta.error;
  }, [meta.touched, meta.error]);
  return (
    <>
      {assistiveText && assistiveTextPosition === 'top' && (
        <AssistiveTextComponent>{assistiveText}</AssistiveTextComponent>
      )}
      <Box
        sx={{
          animation: isError ? 'shake 0.45s' : undefined,
          '@keyframes shake': {
            '0%': {
              transform: 'translateX(0)',
            },
            '15%': {
              transform: 'translateX(-2px)',
            },
            '30%': {
              transform: 'translateX(2px)',
            },
            '45%': {
              transform: 'translateX(-2px)',
            },
            '60%': {
              transform: 'translateX(2px)',
            },
            '75%': {
              transform: 'translateX(-2px)',
            },
            '90%': {
              transform: 'translateX(2px)',
            },
            '100%': {
              transform: 'translateX(0)',
            },
          },
        }}
      >
        {children}
      </Box>
      {isError && (
        <FormHelperText error id={`${field.name}-input`}>
          {t(meta.error as string, meta.error)}
        </FormHelperText>
      )}
      {assistiveText && assistiveTextPosition === 'bottom' && (
        <AssistiveTextComponent>{assistiveText}</AssistiveTextComponent>
      )}
    </>
  );
}

export const FieldWrapperComponent = memo(FieldWrapper);
