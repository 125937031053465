import {observer} from 'mobx-react-lite';
import React from 'react';
import {IntercomProviderProps as IntercomProviderPropsBase} from 'react-use-intercom';

import {IntercomProviderComponent} from '../../components/intercom/intercom-provider.component';
import intercomConfig from '../../configs/intercom-config';
import {useAllUnits} from '../../hooks/units/use-all-units';
import {useStores} from '../../hooks/use-stores';
import {Unit} from '../../interfaces/entities/unit.interface';

interface IntercomProviderProps extends Omit<IntercomProviderPropsBase, 'appId'> {
  appId?: string;
}

function IntercomProvider(props: IntercomProviderProps) {
  const {authStore} = useStores();

  const {items: units, loading: loadingUnits} = useAllUnits();

  return (
    <IntercomProviderComponent
      {...props}
      appId={intercomConfig.applicationId}
      user={authStore.me}
      loadingUser={authStore.loading}
      units={units as Unit[]}
      loadingUnits={loadingUnits}
    />
  );
}

export const IntercomProviderContainer = observer(IntercomProvider);
