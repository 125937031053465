import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {memo, ReactNode} from 'react';

import {HeaderContainer} from '../containers/header.container';
import {FooterComponent} from './footer.component';
import {NavigationComponent} from './navigation/navigation.component';

interface LayoutProps {
  children: ReactNode;
}

function Layout({children}: LayoutProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <CssBaseline />
      <HeaderContainer open />
      <NavigationComponent />
      <Container
        component="main"
        maxWidth={false}
        disableGutters
        sx={theme => ({
          padding: 2,
          minHeight: '77vh',
          [theme.breakpoints.down('sm')]: {
            minHeight: '84vh',
            px: 1,
            paddingBottom: 9,
          },
        })}
      >
        {children}
      </Container>
      {!mobileView && <FooterComponent />}
    </Box>
  );
}

export const LayoutComponent = memo(Layout);
