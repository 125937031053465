import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import formatISO from 'date-fns/formatISO';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import {Formik, FormikProps} from 'formik';
import {omit} from 'lodash';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {SaveAndCancelButtonsComponent} from '../components/buttons/save-and-cancel-buttons.component';
import {Story} from '../interfaces/entities/story.interface';
import {MIN_DATE} from '../utils/constants';
import {isBodyEmpty} from './helpers/is-body-empty';
import {AssistiveTextComponent} from './shared/assistive-text.component';
import {DateFieldComponent} from './shared/date-field.component';
import {TextFieldComponent} from './shared/text-field.component';
import {WysiwygFieldComponent} from './shared/wysiwyg/wysiwyg-field.component';

interface StoryFormProps extends Partial<Story> {
  onSave?: (data: Partial<Story>) => void;
  onCancel?: () => void;
  resetFormAfterCancel?: boolean;
}

function StoryFormBase({resetFormAfterCancel = true, ...props}: StoryFormProps) {
  const {t} = useTranslation();

  const [dateValue, setDateValue] = useState(props?.date || null);
  const [dateTouched, setDateTouched] = useState(false);
  const [dateError, setDateError] = useState('');

  const [bodyValue, setBodyValue] = useState(props?.body || '');
  const [bodyTouched, setBodyTouched] = useState(false);
  const [bodyError, setBodyError] = useState('');

  const onSave = useCallback(
    (updatedData: Partial<Story>) => {
      if (dateError) {
        return;
      }
      const {onSave} = props;
      const data = omit({...props, ...updatedData, date: dateValue, body: bodyValue}, ['onSave', 'onCancel']);

      if (!onSave) {
        return;
      }

      if (bodyError) {
        return;
      }

      if (isBodyEmpty(bodyValue)) {
        setBodyError(t('required-field-not-filled.error'));
        setBodyTouched(true);
        return;
      }

      onSave(data);
    },
    [props, dateValue, bodyError, bodyValue, setBodyError, setBodyTouched, t, dateError]
  );

  const onCancel = useCallback(
    (resetForm: () => void) => {
      if (props.onCancel) {
        props.onCancel();
      }
      if (resetFormAfterCancel) {
        resetForm();
      }
    },
    [props.onCancel, resetFormAfterCancel]
  );

  const onChangeBirthDate = useCallback(
    (value: Date | null) => {
      setDateError('');
      if (!value) {
        return setDateValue(null);
      }

      if (!isValid(value) || isAfter(value, new Date()) || isBefore(value, MIN_DATE)) {
        return setDateError('story-when-it-happened.error');
      }

      const newDate = formatISO(value, {representation: 'date'});
      const touched = dateValue !== newDate || dateTouched;
      setDateTouched(touched);
      setDateValue(newDate);
    },
    [dateValue, dateTouched, setDateTouched, setDateValue, setDateError]
  );

  const onChangeBody = useCallback(
    (value: string) => {
      const touched = bodyValue !== value || bodyTouched;
      setBodyValue(value);
      setBodyTouched(touched);
      setBodyError(touched ? (isBodyEmpty(value) ? t('required-field-not-filled.error') : '') : '');
    },
    [bodyValue, bodyTouched, setBodyTouched, setBodyValue, setBodyError, t]
  );

  return (
    <Formik<Partial<Story>>
      initialValues={props || {}}
      onSubmit={onSave}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('required-field-not-filled.error'),
      })}
    >
      {(props: FormikProps<Partial<Story>>) => {
        const {values, touched, errors, handleChange, handleSubmit, resetForm} = props;

        return (
          <Grid container direction="row" justifyContent="flex-start" spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Box>
                <TextFieldComponent
                  id={'name'}
                  label={t('stories-edit-title.label')}
                  value={values.name || ''}
                  handleChange={handleChange}
                  error={errors.name}
                  touched={touched.name}
                />
                <AssistiveTextComponent>{t('stories-edit-title.assistive')}</AssistiveTextComponent>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Box>
                <DateFieldComponent
                  label={t('stories-edit-when-it-happened.label')}
                  onChange={onChangeBirthDate}
                  value={dateValue && new Date(dateValue)}
                  error={dateError}
                  touched={dateTouched}
                />
                <AssistiveTextComponent>{t('story-when-it-happened.assistive')}</AssistiveTextComponent>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={12}>
                  <WysiwygFieldComponent
                    id={'body'}
                    onEditorChange={onChangeBody}
                    value={bodyValue}
                    name={t('body')}
                    error={bodyError}
                    touched={bodyTouched}
                  />
                  <AssistiveTextComponent>{t('stories-content.label')}</AssistiveTextComponent>
                </Grid>
                <Grid item xs={12}>
                  <SaveAndCancelButtonsComponent
                    onClickCancel={() => onCancel(resetForm)}
                    onClickSave={handleSubmit}
                    sx={theme => ({
                      float: 'right',
                      width: '100%',

                      [theme.breakpoints.up('md')]: {
                        width: '45%',
                      },
                      [theme.breakpoints.up('lg')]: {
                        width: '35%',
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}

export const StoryForm = memo(StoryFormBase);
