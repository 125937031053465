import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';

import {PROFILE_ROUTE_ALBUMS} from '../../configs/profile-routes';
import {Album} from '../../interfaces/entities/album.interface';
import {EMPTY_IMAGE} from '../../utils/constants';
import {getAlbumTitleTranslationKey} from '../../utils/get-album-title-translation-key';
import {addQualityToImageURL} from '../../utils/images/add-quality-to-image-url';
import {isVideoUrl} from '../../utils/is-video-url';

const IMAGE_QUALITY = 'auto';
const IMAGE_HEIGHT = 300;

export interface AlbumCardProps extends Partial<Album> {
  defaultImage?: string;
  Icon?: React.ReactElement;
  loadingCount?: boolean;
}

function AlbumCard({id, image, name, filesCount, Icon, defaultImage = EMPTY_IMAGE, loadingCount}: AlbumCardProps) {
  const {t} = useTranslation();

  const imageUrl = useMemo(() => {
    return image ? addQualityToImageURL(image, IMAGE_QUALITY, IMAGE_HEIGHT) : defaultImage;
  }, [image]);

  const translatedName = useMemo(() => {
    return t(getAlbumTitleTranslationKey(name || ''), name);
  }, [t, name]);

  const countTitle = useMemo(() => {
    if (!filesCount) {
      return '';
    }
    if (filesCount > 1) {
      return `${filesCount} ${t('albums-picture-counter-plural.label')}`.toLowerCase();
    }
    return `1 ${t('albums-picture-counter-single.label')}`.toLowerCase();
  }, [t, filesCount]);

  return (
    <Link to={`${PROFILE_ROUTE_ALBUMS.path}/${id}`} component={RouterLink} underline="none">
      <Card
        sx={{
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
        }}
      >
        <CardActionArea>
          {Icon ? (
            <CardMedia
              sx={{
                paddingTop: '65%',
                position: 'relative',
                bgcolor: 'primary.light',
                color: 'primary.main',
                '& svg': {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '130px',
                },
              }}
            >
              {Icon}
            </CardMedia>
          ) : (
            <CardMedia
              image={imageUrl}
              sx={{
                position: 'relative',
                paddingTop: '65%',
                backgroundSize: 'contain',
              }}
            >
              {isVideoUrl(imageUrl) && (
                <video
                  src={imageUrl}
                  muted
                  autoPlay
                  style={{
                    position: 'absolute',
                    width: '100%',
                    top: '50%',
                    transform: 'translate(0,-50%)',
                    maxHeight: '100%',
                  }}
                />
              )}
            </CardMedia>
          )}
          <CardContent>
            <Typography
              gutterBottom
              variant="subtitle1"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {translatedName}
            </Typography>
            {!loadingCount ? (
              <Typography
                variant="body2"
                sx={theme => ({
                  minHeight: theme.typography.body2.lineHeight,
                })}
              >
                {countTitle}
              </Typography>
            ) : (
              <Typography variant="body2">
                <Skeleton width={'100px'} />
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

export const AlbumCardComponent = memo(AlbumCard);
