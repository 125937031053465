import Stack from '@mui/material/Stack';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, DatePickerProps} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {FieldProps} from 'formik';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorWrapperComponent} from '../../../components/error-wrapper.component';
import {LOCALES} from '../../../utils/constants';
import {LOCALES_MAPPER} from '../../shared/date-field.component';
import {FieldWrapperComponent, FieldWrapperProps} from './field-wrapper.component';

interface DateFieldProps extends FieldProps, Pick<FieldWrapperProps, 'assistiveText' | 'assistiveTextPosition'> {
  label?: string;
  inputFormat?: string;
  readOnly?: DatePickerProps<any>['readOnly'];
}

function CustomTextField({error, ...props}: TextFieldProps) {
  return (
    <ErrorWrapperComponent error={error}>
      <TextField {...props} fullWidth error={error} />
    </ErrorWrapperComponent>
  );
}

function DateFieldBase({label, inputFormat = 'dd/MM/yyyy', readOnly, ...props}: DateFieldProps) {
  const {i18n} = useTranslation();

  const locale = useMemo(() => {
    return LOCALES_MAPPER[i18n.language as LOCALES] || LOCALES_MAPPER.nb;
  }, [i18n.language]);

  return (
    <FieldWrapperComponent {...props}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <Stack spacing={3}>
          <DatePicker
            {...props.field}
            disableFuture
            label={label}
            format={inputFormat}
            readOnly={readOnly}
            slots={{
              textField: CustomTextField,
            }}
            slotProps={{
              textField: {
                onBlur: props.field.onBlur,
                error: props.meta.touched && !!props.meta.error,
                sx: {width: '100%'},
              },
              popper: {
                sx: theme => ({
                  zIndex: theme.zIndex.tooltip + 1,
                }),
              },
            }}
            onChange={(date?: Date | null) => props.form.setFieldValue(props.field.name, date || null)}
          />
        </Stack>
      </LocalizationProvider>
    </FieldWrapperComponent>
  );
}

export const DateField = memo(DateFieldBase);
