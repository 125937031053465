import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, {memo, ReactElement} from 'react';

import {AlbumFilesListLoadingComponent} from '../album-files/album-files-list-loading.component';

function AlbumsOneEditLoading(): ReactElement {
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" wrap="nowrap" spacing={2}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap" spacing={1}>
          <Grid item>
            <Typography variant={'h5'}>
              <Skeleton
                sx={theme => ({
                  width: '175px',
                  [theme.breakpoints.up('sm')]: {
                    width: '300px',
                  },
                })}
              />
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              fontStyle={'italic'}
              fontWeight={'normal'}
              fontSize={'14px'}
              lineHeight={'20px'}
              letterSpacing={'0.25px'}
            >
              <Skeleton
                sx={theme => ({
                  width: '75px',
                  [theme.breakpoints.up('sm')]: {
                    width: '150px',
                  },
                })}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant={'body2'}>
          <Skeleton
            sx={theme => ({
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '450px',
              },
            })}
          />
          <Skeleton
            sx={theme => ({
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '450px',
              },
              marginTop: 1,
            })}
          />
        </Typography>
      </Grid>

      <Grid item>
        <AlbumFilesListLoadingComponent />
      </Grid>
    </Grid>
  );
}

export const AlbumsOneEditLoadingComponent = memo(AlbumsOneEditLoading);
